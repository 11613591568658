import React from "react";
import { ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import ActionButton from "../components/ActionButton";

const RebalanceActionButtons = () => (
  <ButtonToolbar className="mb-3">
    <ButtonGroup className="me-4">
      <ActionButton
        as={Link}
        to="/rebalances/new"
        label="New"
        icon="/icons/new.svg"
        variant="secondary"
      />
    </ButtonGroup>
    <ButtonGroup className="me-4">
      <ActionButton
        as={Link}
        to="/firm/risk-allocations"
        label="Settings"
        icon="/icons/settings.svg"
        variant="secondary"
      />
    </ButtonGroup>
    {process.env.REACT_APP_FEATURE_MANUAL_REBALANCE !== "true" ? null : (
      <ButtonGroup className="me-4">
        <ActionButton
          as={Link}
          to="/rebalances/manual"
          label="Manual"
          icon="/icons/analyze.svg"
          variant="secondary"
        />
      </ButtonGroup>
    )}
  </ButtonToolbar>
);

export default RebalanceActionButtons;
