import { useMemo } from "react";
import { Alert } from "react-bootstrap";
import { AxisOptions, UserSerie } from "react-charts";
import type { DashboardController } from "../../../api/src/dashboard/dashboard.controller";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { useAuthenticatedFetch } from "../lib/api";
import { formatCurrency } from "../lib/numbers";
import HistoricalChart, { TimeSeries } from "./HistoricalChart";

const HistoricalAumAccountsChart = () => {
  const {
    isPending: isPendingHistoricalAum,
    isError: isErrorHistoricalAum,
    data: dataHistoricalAum,
  } = useAuthenticatedFetch<
    UnpackResponse<DashboardController["getHistoricalAUM"]>
  >("/dashboard/aum/historical");
  const historicalAUM = dataHistoricalAum?.data;

  const {
    isPending: isPendingHistoricalAccountCount,
    isError: isErrorHistoricalAccountCount,
    data: dataHistoricalAccountCount,
  } = useAuthenticatedFetch<
    UnpackResponse<DashboardController["getHistoricalAccountCount"]>
  >("/dashboard/count/accounts");
  const historicalAccountCount = dataHistoricalAccountCount?.data;

  const primaryAxis = useMemo(
    (): AxisOptions<TimeSeries> => ({
      getValue: (item) => item.date,
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<TimeSeries>[] => [
      {
        id: "value",
        getValue: (item) => item.value,
        elementType: "line",
        formatters: {
          scale: (value: number | null) =>
            value === null
              ? ""
              : formatCurrency(value, 1, {
                  notation: "compact",
                  compactDisplay: "short",
                }),
        },
      } as AxisOptions<TimeSeries>,
      {
        id: "count",
        getValue: (item) => item.value,
        elementType: "line",
        formatters: {
          scale: (value: number | null) => value?.toLocaleString() ?? "",
        },
      } as AxisOptions<TimeSeries>,
    ],
    [],
  );

  const data = useMemo(
    (): UserSerie<TimeSeries>[] => [
      {
        label: "AUM",
        axisFamily: "time",
        secondaryAxisId: "value",
        data: (historicalAUM ?? []).map((item) => ({
          value: item.aum.totalValue,
          date: new Date(item.date),
        })),
      } as UserSerie<TimeSeries>,
      {
        label: "Active Accounts",
        axisFamily: "time",
        secondaryAxisId: "count",
        data: (historicalAccountCount ?? []).map((item) => ({
          value: item.count,
          date: new Date(item.date),
        })),
      } as UserSerie<TimeSeries>,
    ],
    [historicalAUM, historicalAccountCount],
  );

  return isPendingHistoricalAum || isPendingHistoricalAccountCount ? (
    <Loading message="AUM Chart" />
  ) : isErrorHistoricalAum || isErrorHistoricalAccountCount ? (
    <Alert variant="danger">Failed to load historical AUM and accounts</Alert>
  ) : (
    <HistoricalChart
      primaryAxis={primaryAxis}
      secondaryAxes={secondaryAxes}
      data={data}
    />
  );
};

export default HistoricalAumAccountsChart;
