import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import type { Workflow } from "../../../api/src/tasks/tasks.service";
import { Table, useTable } from "../components/Table/Table";
import {
  AssignedToFilter,
  multiSelectIncludes,
} from "../components/Table/filters";
import { useQueryCrmUsers } from "../firm/lib";

const WorkflowList = ({ workflows }: { workflows: Workflow[] }) => {
  const { data: dataAssignees } = useQueryCrmUsers();

  const workflowsWithAssignedUsers = useMemo(
    () =>
      workflows.map((workflow) => ({
        ...workflow,
        assignedUser: dataAssignees?.data.find(
          (assignee) => assignee.id === workflow.assignedTo,
        ),
      })),
    [dataAssignees?.data, workflows],
  );

  const columnHelper = useMemo(() => createColumnHelper<Workflow>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) =>
          info.row.original.service === "wealthbox" ? (
            <a
              href={`${process.env.REACT_APP_WEALTHBOX_APP_ROOT}/workflows/${info.row.original.id}`}
              rel="noreferrer"
              target="_blank"
            >
              {info.getValue()}
            </a>
          ) : info.row.original.service === "redtail" ? (
            <a
              href={`${process.env.REACT_APP_REDTAIL_APP_ROOT}/workflows/${info.row.original.id}`}
              rel="noreferrer"
              target="_blank"
            >
              {info.getValue()}
            </a>
          ) : (
            info.getValue()
          ),
        header: () => "Name",
      }),
      columnHelper.accessor("description", {
        cell: (info) =>
          info.row.original.service === "wealthbox" ? (
            <a
              href={`${process.env.REACT_APP_WEALTHBOX_APP_ROOT}/workflows/${info.row.original.id}`}
              rel="noreferrer"
              target="_blank"
            >
              {info.getValue()}
            </a>
          ) : info.row.original.service === "redtail" ? (
            <a
              href={`${process.env.REACT_APP_REDTAIL_APP_ROOT}/workflows/${info.row.original.id}`}
              rel="noreferrer"
              target="_blank"
            >
              {info.getValue()}
            </a>
          ) : (
            info.getValue()
          ),
        header: () => "Description",
      }),
      columnHelper.accessor("activeStepName", {
        cell: (info) => info.getValue(),
        header: () => "Current Step",
      }),
      columnHelper.accessor((row) => row.assignedUser?.id.toString() ?? "", {
        id: "assignedUserId",
        cell: (info) => info.row.original.assignedUser?.name ?? "",
        header: () => "Assigned To",
        minSize: 100,
        filterFn: multiSelectIncludes,
        meta: {
          filterComponent: AssignedToFilter,
        },
      }),
      columnHelper.accessor("startedAt", {
        cell: (info) => info.getValue()?.toLocaleDateString() ?? "",
        header: () => "Start Date",
        enableColumnFilter: false,
      }),
    ],
    [columnHelper],
  );

  const { table } = useTable({
    columns,
    data: workflowsWithAssignedUsers,
    getRowId: (row) => row.id.toString(),
    initialState: {
      sorting: [{ id: "startedAt", desc: false }],
    },
  });

  return <Table table={table} />;
};

export default WorkflowList;
