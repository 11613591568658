import { useCallback } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  Path,
  UseFieldArrayRemove,
  UseFormRegister,
} from "react-hook-form";
import type { FeeStructureTier } from "../../../api/src/billing/lib";
import ActionButton from "../components/ActionButton";
import FormFieldError from "../components/FormFieldError";

type FeeTierField = FeeStructureTier & {
  _field_id: string;
};

const emptyTier: FeeTierField = {
  min: 0,
  max: 999999999,
  feeRate: 0,
  _field_id: "",
};

function FeeTiers<TForm extends { tiers: FeeStructureTier[] }>({
  fields,
  add,
  remove,
  register,
  errors,
}: {
  fields: FeeTierField[];
  add: (balance: FeeTierField) => void;
  remove: UseFieldArrayRemove;
  register: UseFormRegister<TForm>;
  errors?: Merge<
    FieldError,
    (Merge<FieldError, FieldErrorsImpl<FeeStructureTier>> | undefined)[]
  >;
}) {
  const addTier = useCallback(() => {
    add({ ...emptyTier });
  }, [add]);

  const removeTier = useCallback(
    (idx: number) => () => {
      remove(idx);
    },
    [remove],
  );

  return (
    <>
      <Table className="form-table">
        <thead>
          <tr>
            <th>Min balance</th>
            <th>Max balance</th>
            <th>Fee Rate</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <tr key={field._field_id}>
              <td className="align-top">
                <Form.Group>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      {...register(`tiers.${index}.min` as Path<TForm>)}
                      min={0}
                    />
                  </InputGroup>
                  <FormFieldError field={errors?.[index]?.min} />
                </Form.Group>
              </td>
              <td className="align-top">
                <Form.Group>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      {...register(`tiers.${index}.max` as Path<TForm>)}
                      min={0}
                    />
                  </InputGroup>
                  <FormFieldError field={errors?.[index]?.max} />
                </Form.Group>
              </td>
              <td className="align-top">
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      {...register(`tiers.${index}.feeRate` as Path<TForm>)}
                      min={0}
                      step={0.01}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <FormFieldError field={errors?.[index]?.feeRate} />
                </Form.Group>
              </td>
              <td className="align-top">
                <ActionButton
                  variant="icon"
                  icon="/icons/trash.svg"
                  label="Delete"
                  type="button"
                  onClick={removeTier(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button onClick={addTier} variant="link">
        Add Level
      </Button>
    </>
  );
}

export default FeeTiers;
