import { ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { InsightsController } from "../../../api/src/insights/insights.controller";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { useAuthenticatedQuery } from "../lib/api";

function useQueryUnassignedAccountsInsight() {
  return useAuthenticatedQuery<
    UnpackResponse<InsightsController["unassignedAccounts"]>,
    number[],
    [string, string]
  >({
    queryKey: ["insights", "unassigned-accounts"],
    queryFn: async (unassignedAccounts) => unassignedAccounts.data,
  });
}

const UnassignedAccounts = () => {
  const {
    isPending: isPendingUnassignedAccounts,
    data: dataUnassignedAccounts,
  } = useQueryUnassignedAccountsInsight();

  return isPendingUnassignedAccounts ? (
    <ListGroupItem>
      <Loading />
    </ListGroupItem>
  ) : typeof dataUnassignedAccounts === "undefined" ? null : (
    <ListGroupItem>
      There are{" "}
      {dataUnassignedAccounts.length <= 0 ? (
        "0 unassigned accounts"
      ) : (
        <Link to="/clients/accounts/mapping">
          {dataUnassignedAccounts.length} unassigned accounts
        </Link>
      )}{" "}
      in your firm.
    </ListGroupItem>
  );
};

export default UnassignedAccounts;
