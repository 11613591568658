import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { Outlet, useParams } from "react-router-dom";
import type { HouseholdWithAccounts } from "../../../api/src/households/households.service";
import type { RebalanceWithMetrics } from "../../../api/src/rebalances/rebalances.service";
import Loading from "../Loading";
import { useQueryHousehold } from "../clients/household/lib";
import FormError from "../components/FormError";
import TabContainerWithTabs from "../components/TabContainer";
import RebalanceHeader from "./RebalanceHeader";
import RebalanceNav from "./RebalanceNav";
import { useQueryRebalance } from "./lib";

export const RebalanceContext = React.createContext<{
  rebalance?: RebalanceWithMetrics;
  household?: HouseholdWithAccounts;
}>({});

const RebalanceInfo = () => {
  const { rebalanceId: rebalanceIdStr } = useParams();
  const rebalanceId =
    typeof rebalanceIdStr === "undefined"
      ? undefined
      : parseInt(rebalanceIdStr);

  const {
    isPending: isPendingRebalance,
    isError: isErrorRebalance,
    data: rebalance,
  } = useQueryRebalance(rebalanceId ?? -1, {
    includeMetrics: true,
    includeTrades: true,
    enabled: typeof rebalanceId !== "undefined",
  });

  const {
    isLoading: isLoadingHousehold,
    isError: isErrorHousehold,
    data: household,
  } = useQueryHousehold(rebalance?.householdId ?? -1, {
    includeAccounts: true,
    enabled: typeof rebalance !== "undefined" && rebalance.householdId > 0,
  });

  return isPendingRebalance || isLoadingHousehold ? (
    <Loading />
  ) : isErrorRebalance || isErrorHousehold ? (
    <FormError message="An error occurred" />
  ) : !rebalance || !household ? (
    <Alert>Rebalance not found</Alert>
  ) : (
    <RebalanceContext.Provider value={{ rebalance, household }}>
      <RebalanceHeader />
      <Row>
        <Col>
          <TabContainerWithTabs tabs={RebalanceNav}>
            <Col>
              <Outlet />
            </Col>
          </TabContainerWithTabs>
        </Col>
      </Row>
    </RebalanceContext.Provider>
  );
};

export default RebalanceInfo;
