import React, { FC } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const RebalancesNav: FC = () => (
  <nav>
    <Nav variant="tabs" defaultActiveKey="/rebalances/today">
      <Nav.Item>
        <NavLink to="/rebalances/today" className="nav-link">
          Today Suggested
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/rebalances/approved" className="nav-link">
          Today Approved
        </NavLink>
      </Nav.Item>
    </Nav>
  </nav>
);

export default RebalancesNav;
