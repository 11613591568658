import React from "react";

const FastTrackAttribution = () => (
  <small>
    Data by{" "}
    <a href="http://www.fasttrack.net" target="_blank" rel="noreferrer">
      www.FastTrack.Net
    </a>
  </small>
);

export default FastTrackAttribution;
