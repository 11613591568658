import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { SecuritiesController } from "../../../api/src/securities/securities.controller";
import type { Security } from "../../../api/src/securities/securities.service";
import { deserializeDate, useAuthenticatedQuery } from "./api";

export const SECURITY_INFO_URL = "https://finance.yahoo.com/quote/";

export const CASH_SYMBOLS = [
  "CASH-1",
  "FCASH",
  "FTEXX",
  "FNXSS",
  "SPAXX",
  "FDRXX",
  "FDUXX",
  "SPRXX",
];

export function deserializeSecurity(
  security: SerializedObject<Security>,
): Security {
  return {
    ...security,
    createdTime: deserializeDate(security.createdTime),
    updatedTime: deserializeDate(security.updatedTime),
  };
}

/**
 * Get all security records for the given symbols.
 * @param symbols The collection of security symbols to fetch
 * @param param1 The optional query configuration
 * @returns A query result with a collection of security records
 */
export function useQuerySecurities(symbols: string[], { enabled = true } = {}) {
  return useAuthenticatedQuery<
    UnpackResponse<SecuritiesController["getMultipleSecurities"]>,
    Security[],
    [string, string[]]
  >({
    queryKey: ["securities", symbols],
    queryFn: async (securities) => securities.data.map(deserializeSecurity),
    urlBuilderFn: (key) => `/${key[0]}`,
    fetchOptions: {
      method: "POST",
      body: { symbols },
    },
    queryOptions: { enabled: enabled && symbols.length > 0 },
  });
}
