import { useMutation } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";
import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Alert,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { RebalancesController } from "../../../api/src/rebalances/rebalances.controller";
import type {
  RebalanceRequest,
  RebalanceType,
} from "../../../api/src/rebalances/rebalances.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import { useQueryAccounts } from "../clients/account/lib";
import { useQueryHouseholds } from "../clients/household/lib";
import ActionButton from "../components/ActionButton";
import Content from "../components/Content";
import HelpTooltip from "../components/HelpTooltip";
import IndeterminateCheckbox from "../components/IndeterminateCheckbox";
import { RowExpander } from "../components/Table/RowExpander";
import {
  Table,
  metaDefault,
  useSkipper,
  useTable,
} from "../components/Table/Table";
import { useAuthenticatedMutation } from "../lib/api";
import { displayAccountName } from "../lib/display";
import { setNestedCollectionField } from "../lib/forms";
import { formatCurrency } from "../lib/numbers";
import { rebalanceTypeDisplay } from "./lib";

const RebalanceTypeHelp = () => (
  <section className="text-start">
    <h5>Tolerance</h5>
    <p>
      Realign a household portfolio with their model (i.e. target) portfolio.
    </p>
    <h5>Raise Cash</h5>
    <p>Sell holdings to generate cash in an account.</p>
    <h5>Spend Cash</h5>
    <p>Use cash in an account to buy securities.</p>
    <h5>Manual</h5>
    <p>Enter individual trades manually.</p>
  </section>
);

type RebalanceRowFormState = Omit<
  RebalanceRequest,
  "householdId" | "householdName" | "type" | "trades" | "accounts" | "status"
> & {
  id: number;
  name: string;
  type: "household" | "account";
  balance: number;
  cashBalance: number;
  raiseSpendCashAmount?: number;
  accounts?: RebalanceRowFormState[];
  parentId?: string;
};

const columnHelper = createColumnHelper<RebalanceRowFormState>();

const CreateRebalance = () => {
  const navigate = useNavigate();

  const [households, setHouseholds] = useState<RebalanceRowFormState[]>([]);

  const {
    isPending: isPendingHouseholds,
    isError: isErrorHouseholds,
    data: dataHouseholds,
  } = useQueryHouseholds({ includeBalances: true });

  const {
    isPending: isPendingAccounts,
    isError: isErrorAccounts,
    data: dataAccounts,
  } = useQueryAccounts({
    includeBalances: true,
    pageSize: 10000,
  });

  useEffect(() => {
    if (
      typeof dataHouseholds !== "undefined" &&
      typeof dataAccounts !== "undefined"
    ) {
      const accountsMap = _.groupBy(dataAccounts.data, "householdId");
      const households = dataHouseholds.data;
      setHouseholds(
        households
          .filter(
            (household) =>
              typeof household.assignedModelId !== "undefined" ||
              (accountsMap[household.id] ?? []).some(
                (account) => typeof account.assignedModelId !== "undefined",
              ),
          )
          .map((household) => ({
            id: household.id,
            name: household.name,
            type: "household",
            balance: household.householdBalance ?? 0,
            cashBalance: household.cashBalance ?? 0,
            accounts: (accountsMap[household.id] ?? [])
              .filter(
                (account) =>
                  typeof household.assignedModelId !== "undefined" ||
                  typeof account.assignedModelId !== "undefined",
              )
              .map((account) => ({
                id: account.id,
                name: displayAccountName(
                  account.displayName,
                  account.displayNumber,
                ),
                type: "account",
                balance: account.accountBalance ?? 0,
                cashBalance: account.cashBalance ?? 0,
                parentId: `household-${household.id}`,
              })),
          })),
      );
    }
  }, [dataHouseholds, dataAccounts]);

  const [rebalanceType, setRebalanceType] = useState<RebalanceType | "">();
  const rebalanceTypeIsSet =
    typeof rebalanceType !== "undefined" && rebalanceType !== "";

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: (info) => (
          <IndeterminateCheckbox
            checked={info.table.getIsAllRowsSelected()}
            indeterminate={info.table.getIsSomeRowsSelected()}
            onChange={info.table.getToggleAllPageRowsSelectedHandler()}
          />
        ),
        cell: (info) =>
          rebalanceType === "tolerance" || rebalanceType === "manual" ? null : (
            <IndeterminateCheckbox
              id={`select-${info.row.original.id}`}
              checked={info.row.getIsSelected()}
              indeterminate={info.row.getIsSomeSelected()}
              onChange={info.row.getToggleSelectedHandler()}
            />
          ),
        aggregatedCell: (info) => (
          <IndeterminateCheckbox
            id={`select-${info.row.original.id}`}
            checked={info.row.getIsSelected()}
            indeterminate={info.row.getIsSomeSelected()}
            onChange={info.row.getToggleSelectedHandler()}
          />
        ),
      }),
      columnHelper.accessor((row) => row.name, {
        id: "name",
        cell: (info) => (
          <div
            style={{
              // Since rows are flattened by default,
              // we can use the row.depth property
              // and paddingLeft to visually indicate the depth
              // of the row
              paddingLeft: `${info.row.depth * 2}rem`,
            }}
          >
            <Link
              to={`/clients/${info.row.original.type}s/${info.row.original.id}`}
            >
              {info.getValue()}
            </Link>
          </div>
        ),
        aggregatedCell: (info) => (
          <>
            {info.row.getCanExpand() ? <RowExpander row={info.row} /> : ""}{" "}
            <Link
              to={`/clients/${info.row.original.type}s/${info.row.original.id}`}
            >
              {info.getValue()}
            </Link>
          </>
        ),
        header: () => "Household",
      }),
      columnHelper.accessor("balance", {
        cell: (info) => formatCurrency(info.getValue()),
        aggregatedCell: (info) => formatCurrency(info.getValue()),
        header: () => "AUM",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.cashBalance ?? 0, {
        id: "cashBalance",
        cell: (info) => formatCurrency(info.getValue()),
        aggregatedCell: (info) => formatCurrency(info.getValue()),
        header: () => "Cash",
        enableColumnFilter: false,
      }),
      ...(rebalanceType === "raise_cash" || rebalanceType === "spend_cash"
        ? [
            columnHelper.accessor((row) => row.raiseSpendCashAmount, {
              id: "raiseSpendCashAmount",
              cell: (info) =>
                info.row.original.type !== "account" ? null : (
                  <Form.Control
                    type="number"
                    min="0"
                    max={
                      rebalanceType === "spend_cash"
                        ? info.row.original.cashBalance
                        : info.row.original.balance -
                          info.row.original.cashBalance
                    }
                    value={info.getValue()}
                    formNoValidate
                    onChange={(e) => {
                      if (
                        info.row.depth > 0 &&
                        typeof info.row.original.parentId === "undefined"
                      ) {
                        throw new Error(
                          `Parent row not set for subrow ${info.row.id}`,
                        );
                      }

                      if (
                        typeof info.table.options.meta?.setCellData ===
                        "undefined"
                      ) {
                        throw new Error(
                          "setCellData not defined for create rebalances table",
                        );
                      }

                      // As of writing this, we only display this column in the
                      // child account rows, so depth should be greater than 0
                      // and parentRow should be defined.
                      const parentRow =
                        info.row.depth <= 0
                          ? null
                          : info.table.getRow(
                              info.row.original.parentId as string,
                            );

                      info.table.options.meta?.setCellData(
                        parentRow === null
                          ? [info.row.index]
                          : [parentRow.index, "accounts", info.row.index],
                        info.column.id,
                        e.target.value,
                        "number",
                      );
                    }}
                  />
                ),
              header: () =>
                rebalanceType === "spend_cash"
                  ? "Cash to Spend"
                  : "Cash to Raise",
              enableColumnFilter: false,
            }),
          ]
        : // : [] rebalanceType === "dca"
          // ? [
          //     columnHelper.accessor((row) => row.dcaStartDate, {
          //       id: "dcaStartDate",
          //       cell: (info) => (
          //         <DatePicker
          //           selected={info.getValue()}
          //           onChange={(value) =>
          //             typeof info.table.options.meta?.setCellData ===
          //               "function" &&
          //             info.table.options.meta?.setCellData(
          //               info.row.index,
          //               info.column.id,
          //               value,
          //             )
          //           }
          //         />
          //       ),
          //       header: () => "Start Date",
          //       enableColumnFilter: false,
          //     }),
          //     columnHelper.accessor((row) => row.dcaEndDate, {
          //       id: "dcaEndDate",
          //       cell: (info) => (
          //         <DatePicker
          //           selected={info.getValue()}
          //           onChange={(value) =>
          //             typeof info.table.options.meta?.setCellData ===
          //               "function" &&
          //             info.table.options.meta?.setCellData(
          //               info.row.index,
          //               info.column.id,
          //               value,
          //             )
          //           }
          //         />
          //       ),
          //       header: () => "End Date",
          //       enableColumnFilter: false,
          //     }),
          //     columnHelper.accessor((row) => row.dcaInterval, {
          //       id: "dcaInterval",
          //       cell: (info) => (
          //         <Form.Control
          //           type="number"
          //           min="1"
          //           max={51}
          //           value={info.getValue()}
          //           formNoValidate
          //           onChange={(e) =>
          //             typeof info.table.options.meta?.setCellData ===
          //               "function" &&
          //             info.table.options.meta?.setCellData(
          //               info.row.index,
          //               info.column.id,
          //               e.target.value,
          //             )
          //           }
          //         />
          //       ),
          //       header: () => "Invest Every X Weeks",
          //       enableColumnFilter: false,
          //     }),
          //     columnHelper.accessor((row) => row.dcaCash, {
          //       id: "cashAmount",
          //       cell: (info) => (
          //         <Form.Control
          //           type="number"
          //           min="1"
          //           max={
          //             info.row.original.dcaBuySell === "sell"
          //               ? info.row.original.balance -
          //                 info.row.original.cashBalance
          //               : info.row.original.dcaBuySell === "buy"
          //               ? info.row.original.cashBalance
          //               : undefined
          //           }
          //           value={info.getValue()}
          //           formNoValidate
          //           onChange={(e) =>
          //             typeof info.table.options.meta?.setCellData ===
          //               "function" &&
          //             info.table.options.meta?.setCellData(
          //               info.row.index,
          //               info.column.id,
          //               e.target.value,
          //             )
          //           }
          //         />
          //       ),
          //       header: () => "Cash to DCA",
          //       enableColumnFilter: false,
          //     }),
          //     columnHelper.accessor((row) => row.dcaBuySell, {
          //       id: "dcaBuySell",
          //       cell: (info) => (
          //         <Form.Select
          //           placeholder="Select trade type"
          //           value={info.getValue()}
          //           onChange={(e) =>
          //             typeof info.table.options.meta?.setCellData ===
          //               "function" &&
          //             info.table.options.meta?.setCellData(
          //               info.row.index,
          //               info.column.id,
          //               e.target.value,
          //             )
          //           }
          //         >
          //           <option value="buy">Invest Cash</option>
          //           <option value="sell">Raise Cash</option>
          //         </Form.Select>
          //       ),
          //       header: () => "Buy / Sell",
          //       enableColumnFilter: false,
          //     }),
          //   ]
          []),
    ],
    [rebalanceType],
  );

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const { table, rowSelection } = useTable({
    columns,
    data: households,
    getRowId: (row) => `${row.type}-${row.id}`,
    getSubRows: (row) => (row.accounts ?? []) as RebalanceRowFormState[],
    autoResetPageIndex,
    meta: {
      ...metaDefault,
      setCellData: (properties, columnId, value, type = "text") => {
        // Skip age index reset until after next rerender
        skipAutoResetPageIndex();
        setNestedCollectionField(
          [...properties, columnId],
          households,
          setHouseholds,
          value,
          type,
        );
      },
    },
  });

  const createRebalance = useAuthenticatedMutation<
    UnpackResponse<RebalancesController["createRebalances"]>
  >("/rebalances", {
    method: "POST",
    body: JSON.stringify(
      households
        .filter((household) =>
          rebalanceType === "tolerance" || rebalanceType === "manual"
            ? rowSelection[`household-${household.id}`]
            : (household.accounts ?? []).some(
                (account) => rowSelection[`account-${account.id}`],
              ),
        )
        .map(
          (household) =>
            ({
              ...household,
              householdId: household.id,
              householdName: household.name,
              type: rebalanceType,
              trades: [],
              status: rebalanceType === "manual" ? "proposed" : "requested",
              accounts: (household.accounts ?? [])
                .filter((account) => rowSelection[`account-${account.id}`])
                .map((account) => ({
                  id: account.id,
                  accountName: account.name,
                  raiseSpendCashAmount: parseFloat(
                    (account.raiseSpendCashAmount as string | undefined) ?? "0",
                  ),
                  trades: [],
                })),
            }) as RebalanceRequest,
        ),
    ),
  });

  const notificationContext = useContext(NotificationContext);

  const mutation = useMutation({
    mutationFn: async (event: FormEvent) => {
      event.preventDefault();

      if (!rebalanceTypeIsSet) {
        console.error("RebalanceType not set");
        return;
      }

      await createRebalance();
      notificationContext.pushNotification({
        id: "rebalance",
        header: "Rebalance Created",
        body: `${rebalanceTypeDisplay[rebalanceType]} rebalance created`,
        variant: "success",
      });

      navigate("..");
    },
  });

  const setRebalanceTypeHandler = useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) =>
      setRebalanceType(ev.target.value as RebalanceType | ""),
    [],
  );

  return (
    <Form onSubmit={mutation.mutate}>
      <Row>
        <Col>
          <h1>Create Trade</h1>
        </Col>
        <Col md="auto">
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-4">
              <ActionButton
                label="Run Rebalance"
                icon="/icons/save.svg"
                type="submit"
                disabled={!rebalanceTypeIsSet}
              />
            </ButtonGroup>
            <ButtonGroup>
              <ActionButton
                as={Link}
                to="/rebalances"
                label="Exit"
                icon="/icons/exit.svg"
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Content>
        <Row>
          <Col md={3} className="mb-3">
            <Form.Group controlId="formType">
              <Form.Label>Rebalance Type</Form.Label>
              <div className="d-flex">
                <Form.Select
                  placeholder="Select trade type"
                  value={rebalanceType}
                  onChange={setRebalanceTypeHandler}
                  className="me-1"
                >
                  <option value="">Select...</option>
                  {Object.entries(rebalanceTypeDisplay).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Form.Select>
                <HelpTooltip tooltip={<RebalanceTypeHelp />} />
              </div>
            </Form.Group>
          </Col>
        </Row>
        {rebalanceTypeIsSet && (
          <Row>
            <Col>
              {isPendingHouseholds || isPendingAccounts ? (
                <Loading />
              ) : isErrorHouseholds || isErrorAccounts ? (
                <Alert variant="danger">
                  An error occurred loading households and accounts
                </Alert>
              ) : (
                <Table table={table} />
              )}
            </Col>
          </Row>
        )}
      </Content>
    </Form>
  );
};

export default CreateRebalance;
