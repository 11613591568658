import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { TasksController } from "../../../api/src/tasks/tasks.controller";
import type { EditableTask, Task } from "../../../api/src/tasks/tasks.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import { useQueryCrmUsers } from "../firm/lib";
import { useAuthenticatedFetch } from "../lib/api";
import { InlineError } from "../lib/display";
import {
  dateSchema,
  getSchemaFieldLabel,
  nullableNumberSchema,
  optionalStringValueTransform,
} from "../lib/forms";
import { useErrorMessage } from "./FormError";
import FormFieldError from "./FormFieldError";
import SubmitButton from "./SubmitButton";

const schema: yup.ObjectSchema<EditableTask> = yup.object({
  name: yup.string().required().label("Name"),
  description: yup.string().label("Description"),
  priority: yup
    .string()
    .transform(optionalStringValueTransform)
    .oneOf(["Low", "Medium", "High"])
    .label("Priority"),
  dueDate: dateSchema.label("Due Date"),
  category: nullableNumberSchema.label("Category"),
  assignedTo: yup.number().required().label("Assignee"),
});

const CreateTask = ({
  onCreate,
}: {
  onCreate: (data: EditableTask) => Promise<Task | null>;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    reset,
  } = useForm<EditableTask>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { setError, resetError, errorMessage } = useErrorMessage();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: EditableTask) => {
      resetError();

      try {
        const body = await onCreate(data);

        if (body !== null) {
          notificationContext.pushNotification({
            id: `task-${body.id}`,
            header: "Task Created",
            body: `${body.name} created`,
            variant: "success",
          });
        }
        reset();
      } catch (err) {
        const message = "Failed to save task";
        console.error(message, err);
        setError(message);
      }
    },
  });

  const {
    isPending: isPendingCategories,
    isError: isErrorCategories,
    data: categoriesData,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<TasksController["getCategories"]>>
  >("/tasks/categories");

  const categoryOptions = useMemo(
    () => [
      <option key={null} value="">
        Select a category...
      </option>,
      ...(categoriesData?.data ?? [])
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        )),
    ],
    [categoriesData?.data],
  );

  const {
    isPending: isPendingAssignees,
    isError: isErrorAssignees,
    isFetched: isFetchedAssignees,
    data: assigneesData,
  } = useQueryCrmUsers();

  const assigneeOptions = useMemo(
    () =>
      (assigneesData?.data ?? [])
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((assignee) => (
          <option key={assignee.id} value={assignee.id}>
            {assignee.name}
          </option>
        )),
    [assigneesData?.data],
  );

  const isPending = isPendingAssignees || isPendingCategories;

  useEffect(() => {
    if (
      isFetchedAssignees &&
      (assigneesData?.data ?? []).length > 0 &&
      isNaN(getValues().assignedTo)
    ) {
      setValue("assignedTo", (assigneesData?.data ?? [])[0].id);
    }
  }, [isFetchedAssignees, assigneesData?.data, setValue, getValues]);

  return isPending ? (
    <Loading />
  ) : (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      {errorMessage}
      <Form.Group className="mb-3" controlId="form-name">
        <Form.Label>{getSchemaFieldLabel(schema.fields.name)}</Form.Label>
        <Form.Control type="text" {...register("name")} />
        <FormFieldError field={errors.name} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="form-description">
        <Form.Label>
          {getSchemaFieldLabel(schema.fields.description)}
        </Form.Label>
        <Form.Control type="text" {...register("description")} />
        <FormFieldError field={errors.description} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="form-dueDate">
        <Form.Label>{getSchemaFieldLabel(schema.fields.dueDate)}</Form.Label>
        <Form.Control type="date" {...register("dueDate")} />
        <FormFieldError field={errors.dueDate} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="form-assignee">
        <Form.Label>{getSchemaFieldLabel(schema.fields.assignedTo)}</Form.Label>
        {isErrorAssignees ? (
          <InlineError>There was an error loading assignees</InlineError>
        ) : (
          <Form.Select {...register("assignedTo")}>
            {assigneeOptions}
          </Form.Select>
        )}
        <FormFieldError field={errors.assignedTo} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="form-category">
        <Form.Label>{getSchemaFieldLabel(schema.fields.category)}</Form.Label>
        {isErrorCategories ? (
          <InlineError>There was an error loading categories</InlineError>
        ) : (
          <Form.Select {...register("category")}>{categoryOptions}</Form.Select>
        )}
        <FormFieldError field={errors.category} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="form-priority">
        <Form.Label>{getSchemaFieldLabel(schema.fields.priority)}</Form.Label>
        <Form.Select {...register("priority")}>
          <option value="">Select a priority...</option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </Form.Select>
        <FormFieldError field={errors.priority} />
      </Form.Group>
      <SubmitButton isSubmitting={isSubmitting} label="Create" />
    </Form>
  );
};

export default CreateTask;
