import { useMutation } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Badge, Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Contact } from "../../../../api/src/contacts/lib";
import type { EditableHousehold } from "../../../../api/src/households-base/households-base.service";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { SerializedObject, UnpackResponse } from "../../../../api/src/lib";
import type { ProfileController } from "../../../../api/src/profile/profile.controller";
import { NotificationContext } from "../../Notifications";
import PerformancePeriodSelector from "../../components/PerformancePeriodSelector";
import SubmitButton from "../../components/SubmitButton";
import {
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../../lib/api";
import HouseholdHistoricalAumChart from "./HouseholdHistoricalAumChart";
import HouseholdHistoricalPerformanceChart from "./HouseholdHistoricalPerformanceChart";
import { HouseholdContext } from "./HouseholdInfo";
import HouseholdMembers from "./HouseholdMembers";
import HouseholdPerformance from "./HouseholdPerformance";
import HouseholdTaxSummary from "./HouseholdTaxSummary";

const HouseholdOverview = () => {
  const { household } = useContext(HouseholdContext);
  const { householdId } = useParams();

  const { handleSubmit } = useFormContext<EditableHousehold>();
  const [customReportPeriodParams, setCustomReportPeriodParams] = useState("");

  const { data: profileBody } =
    useAuthenticatedFetch<UnpackResponse<ProfileController["getProfile"]>>(
      "/profile",
    );
  const user = profileBody?.data;

  const [members, setMembers] = useState<Contact[]>([]);

  useEffect(() => {
    if (typeof household !== "undefined") {
      setMembers(household.members);
    }
  }, [household]);

  const updateHouseholdMembers = useAuthenticatedMutationAsync<
    SerializedObject<UnpackResponse<HouseholdsController["updateMembers"]>>
  >(`/households/${householdId}/members`, async (members: Contact[]) => ({
    method: "PUT",
    body: JSON.stringify(members.map((contact) => contact.id)),
  }));

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async () => {
      try {
        await updateHouseholdMembers(members);
        notificationContext.pushNotification({
          id: `household-${householdId}`,
          header: "Household Updated",
          body: `${household?.name} updated`,
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save household", err);
        notificationContext.pushNotification({
          id: `household-${householdId}`,
          header: "Failed to Save Household",
          body: `${household?.name} was not saved`,
          variant: "danger",
        });
      }
    },
  });

  const reportTypes = ["AUM", "Cash", "Performance"];
  const [reportType, setReportType] = useState("AUM");

  const onChange = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    setReportType(ev.currentTarget.value);
  }, []);

  return (
    <Form onSubmit={handleSubmit(() => onSubmit.mutate())}>
      <Row>
        <Col xxl={4} xl={6} className="mb-3">
          {household?.archiveDate && (
            <Row>
              <Col as="dt" sm={4}>
                Archive Date
              </Col>
              <Col as="dd">{household.archiveDate.toLocaleDateString()}</Col>
            </Row>
          )}
          {household?.tags && (
            <Row>
              <Col as="dt" sm={4}>
                Tags
              </Col>
              <Col as="dd">
                {household.tags?.map((tag) => (
                  <Badge key={tag.name} bg="secondary" pill className="me-1">
                    {tag.name}
                  </Badge>
                ))}
              </Col>
            </Row>
          )}
          <HouseholdMembers
            members={members}
            setMembers={setMembers}
            readOnly={
              !user?.connections.wealthbox && !user?.connections.redtail
            }
          />
          <SubmitButton isSubmitting={onSubmit.isPending} />
        </Col>
        <Col xl={4} className="mb-3">
          <h4>Performance</h4>
          <HouseholdPerformance useall={true} />
        </Col>
        <Col xl={4} className="mb-3">
          <HouseholdTaxSummary useall={true} />
        </Col>
      </Row>
      <Row>
        <Col xxl={5} xl={6} lg={3} md={4}>
          <h5>Reports</h5>
        </Col>
        <Col xl={4} sm={4}>
          <Form.Select value={reportType} onChange={onChange}>
            {reportTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <PerformancePeriodSelector
            setCustomPeriodParams={setCustomReportPeriodParams}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {reportType === "AUM" && (
            <HouseholdHistoricalAumChart
              valueType="totalValue"
              customReportPeriodParams={customReportPeriodParams}
            />
          )}
          {reportType === "Cash" && (
            <HouseholdHistoricalAumChart
              valueType="cashValue"
              customReportPeriodParams={customReportPeriodParams}
            />
          )}
          {reportType === "Performance" && (
            <HouseholdHistoricalPerformanceChart
              customReportPeriodParams={customReportPeriodParams}
            />
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default HouseholdOverview;
