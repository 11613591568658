import React, { useState } from "react";
import { Button, ButtonProps, Offcanvas } from "react-bootstrap";
import {
  OffcanvasPlacement,
  OffcanvasProps,
} from "react-bootstrap/esm/Offcanvas";

const HelpButton = ({
  title,
  body,
  placement = "end",
  canvasProps,
  buttonProps,
}: {
  title: string;
  body: React.ReactNode;
  placement?: OffcanvasPlacement;
  canvasProps?: OffcanvasProps;
  buttonProps?: ButtonProps;
}) => {
  const [show, setShow] = useState(false);

  const handleClose: React.MouseEventHandler<HTMLButtonElement> = (ev) =>
    setShow(false);
  const handleShow: React.MouseEventHandler<HTMLButtonElement> = (ev) =>
    setShow(true);

  return (
    <>
      <Button variant="outline-secondary" onClick={handleShow} {...buttonProps}>
        Help
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={placement}
        {...canvasProps}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{body}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default HelpButton;
