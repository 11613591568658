import { Header } from "@tanstack/react-table";
import _ from "lodash";
import { useCallback } from "react";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { RebalancesController } from "../../../api/src/rebalances/rebalances.controller";
import type {
  RebalanceAccount,
  RebalanceMetrics,
  RebalanceStatus,
  RebalanceType,
  RebalanceWithMetrics,
  Trade,
} from "../../../api/src/rebalances/rebalances.service";
import type { RiskAllocation } from "../../../api/src/securities/securities.service";
import MultiSelector from "../components/MultiSelector";
import { deserializeDate, useAuthenticatedQuery } from "../lib/api";

export const rebalanceTypeDisplay: Record<RebalanceType, string> = {
  tolerance: "Tolerance",
  // dca: "Dollar Cost Average",
  raise_cash: "Raise Cash",
  spend_cash: "Spend Cash",
  manual: "Manual",
};

export const rebalanceTypeFilterOptions = Object.values(
  rebalanceTypeDisplay,
).map((display, index) => ({
  label: display,
  value: index + 1,
}));

export function deserializeRebalance(
  rebalance: SerializedObject<RebalanceWithMetrics>,
): RebalanceWithMetrics {
  return {
    ...rebalance,
    lastTradeDate: deserializeDate(rebalance.lastTradeDate) ?? null,
    proposedExecutedTime: deserializeDate(rebalance.proposedExecutedTime),
    actualExecutedTime: deserializeDate(rebalance.actualExecutedTime) ?? null,
    createdTime: deserializeDate(rebalance.createdTime),
    updatedTime: deserializeDate(rebalance.updatedTime),
    dcaStartDate: deserializeDate(rebalance.dcaStartDate),
    dcaEndDate: deserializeDate(rebalance.dcaEndDate),
    accounts: rebalance.accounts.map(
      (account): RebalanceAccount & RebalanceMetrics => ({
        ...account,
        lastTradeDate: deserializeDate(account.lastTradeDate) ?? null,
        trades: account.trades.map(
          (trade): Trade => ({
            ...trade,
            executedTime: deserializeDate(trade.executedTime) ?? null,
            createdTime: deserializeDate(trade.createdTime),
            updatedTime: deserializeDate(trade.updatedTime),
          }),
        ),
      }),
    ),
  };
}

export function useQueryRebalance(
  id: number,
  { includeMetrics = false, includeTrades = false, enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<RebalancesController["getRebalance"]>,
    RebalanceWithMetrics,
    [
      string,
      number,
      {
        includeMetrics: boolean;
        includeTrades: boolean;
      },
    ]
  >({
    queryKey: ["rebalances", id, { includeMetrics, includeTrades }],
    queryFn: async (rebalance) => deserializeRebalance(rebalance.data),
    urlBuilderFn: (key) =>
      `/${key[0]}/${key[1]}/?metrics=${key[2].includeMetrics}&trades=${key[2].includeTrades}`,
    queryOptions: { enabled },
  });
}

export function getRiskAllocationDisplay(
  riskAllocation: RiskAllocation = { growth: 0, defensive: 0, unknown: 0 },
  sum = 100,
) {
  let { growth, defensive } = getRiskAllocation(riskAllocation, sum);

  growth *= sum;
  defensive *= sum;

  // In some cases, we were getting floating point rather than whole numbers
  // for these values. Round them off to ensure they are both whole numbers.
  growth = growth % 1 < 0.5 ? Math.floor(growth) : Math.ceil(growth);
  defensive =
    defensive % 1 < 0.5 ? Math.floor(defensive) : Math.ceil(defensive);

  return `${growth} / ${defensive}`;
}

export function getRiskAllocation(
  riskAllocation: RiskAllocation = { growth: 0, defensive: 0, unknown: 0 },
  expectedSum = 100,
) {
  let growth = parseInt((riskAllocation.growth * 100).toFixed(0));
  let defensive = parseInt((riskAllocation.defensive * 100).toFixed(0));
  const riskSum = growth + defensive;

  const reduction = riskSum === 0 ? 1 : expectedSum / riskSum;
  growth *= reduction;
  defensive *= reduction;

  growth /= expectedSum;
  defensive /= expectedSum;

  return { growth, defensive };
}

export const RebalanceReasonFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const onChange = useCallback(
    (values: number[]) => {
      header.column.setFilterValue(values);
    },
    [header.column],
  );

  return (
    <MultiSelector
      options={rebalanceTypeFilterOptions}
      placeholder="All"
      setValue={onChange}
    />
  );
};

export type RebalanceRow = {
  level: "household" | "account";
  rebalanceId: number;
  id: number;
  name: string;
  status: RebalanceStatus;
  type?: RebalanceType;
  accounts?: RebalanceRow[];
  actualExecutionDate?: Date;
} & RebalanceMetrics;

export function mapRebalancesToRebalanceRows(
  rebalances: RebalanceWithMetrics[] = [],
) {
  return rebalances.map(
    (rebalance): RebalanceRow => ({
      ...rebalance,
      level: "household",
      rebalanceId: rebalance.id,
      id: rebalance.householdId,
      name: rebalance.householdName,
      accounts: rebalance.accounts.map(
        (account): RebalanceRow => ({
          ...account,
          level: "account",
          name: account.accountName,
          status: rebalance.status,
          rebalanceId: rebalance.id,
        }),
      ),
      actualExecutionDate:
        deserializeDate(rebalance.actualExecutedTime) ?? undefined,
    }),
  );
}

export function getSelectedRebalanceRows(
  rowSelection: Record<string, boolean>,
) {
  return _.uniq(
    Object.entries(rowSelection)
      .filter(([id, isSelected]) => isSelected)
      .map(([id]) => {
        const rebalanceIdSegment = id.substring(0, id.indexOf("-"));
        return parseInt(rebalanceIdSegment);
      }),
  );
}
