import React from "react";
import { OverlayTrigger, OverlayTriggerProps, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";

const HelpTooltip = ({
  tooltip,
  overlayPlacement = "auto",
  overlayProps,
  buttonProps,
}: {
  tooltip: React.ReactNode;
  overlayPlacement?: Placement;
  overlayProps?: OverlayTriggerProps;
  buttonProps?: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
}) => (
  <OverlayTrigger
    placement={overlayPlacement}
    overlay={
      <Tooltip>
        <div className="text-start">{tooltip}</div>
      </Tooltip>
    }
    {...overlayProps}
  >
    <img
      src="/icons/help.svg"
      alt="Help icon"
      width={24}
      {...buttonProps}
      className={`svg ${buttonProps?.className ?? ""}`}
    />
  </OverlayTrigger>
);

export default HelpTooltip;
