import type { SerializedObject } from "../../../api/src/lib";
import type { Note } from "../../../api/src/notes/notes.service";
import { deserializeDate } from "./api";

export function deserializeNote(note: SerializedObject<Note>): Note {
  return {
    ...note,
    createdAt: deserializeDate(note.createdAt),
    updatedAt: deserializeDate(note.updatedAt),
  };
}
