import React from "react";
import { Col, Row } from "react-bootstrap";
import Content from "./Content";

/**
 * Tabbed child content
 */
export const TabContainer = ({ children }: React.PropsWithChildren) => (
  <Content className="rounded-0 rounded-bottom">{children}</Content>
);

/**
 * Tabbed navigation with child content
 */
const TabContainerWithTabs = ({
  tabs: Tabs,
  children,
}: React.PropsWithChildren<{
  /** The component type for the tab portion */
  tabs: React.ElementType;
}>) => (
  <>
    <Row>
      <Col>
        <Tabs />
      </Col>
    </Row>
    <TabContainer>{children}</TabContainer>
  </>
);

export default TabContainerWithTabs;
