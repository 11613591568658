import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { RebalancesController } from "../../../api/src/rebalances/rebalances.controller";
import Loading from "../Loading";
import ActionButton from "../components/ActionButton";
import { RowExpander } from "../components/Table/RowExpander";
import { Table, metaDefault, useTable } from "../components/Table/Table";
import {
  onColumnFiltersChange,
  onPaginationChange,
  onSortingChange,
  useTableSettings,
} from "../components/Table/tableSettings";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutation,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import { processFileResponse } from "../lib/file";
import { formatCurrency } from "../lib/numbers";
import {
  RebalanceReasonFilter,
  RebalanceRow,
  getRiskAllocationDisplay,
  getSelectedRebalanceRows,
  mapRebalancesToRebalanceRows,
  rebalanceTypeDisplay,
  rebalanceTypeFilterOptions,
} from "./lib";
import { multiSelectIncludes } from "../components/Table/filters";

const columnHelper = createColumnHelper<RebalanceRow>();

const RebalancesApprovedTable = ({ householdId }: { householdId?: string }) => {
  const { isPending: isPendingRebalances, data: dataRebalances } =
    useAuthenticatedFetch<
      UnpackResponse<RebalancesController["getAllApprovedRebalances"]>
    >(
      `/rebalances/approved?metrics=true${
        householdId ? `&householdId=${householdId}` : ""
      }`,
    );
  const rebalances = dataRebalances?.data;

  const rebalanceRows = useMemo(
    () => mapRebalancesToRebalanceRows(rebalances),
    [rebalances],
  );

  const queryClient = useQueryClient();

  const executeRebalances = useAuthenticatedMutationAsync<
    UnpackResponse<RebalancesController["changeRebalancesState"]>
  >(
    "/rebalances",
    async () => {
      const rebalanceIds = getSelectedRebalanceRows(rowSelection);

      return {
        method: "PATCH",
        body: JSON.stringify({
          state: "executing",
          rebalanceIds,
        }),
      };
    },
    processFileResponse,
  );

  const mutation = useMutation({
    mutationFn: async (event: FormEvent) => {
      event.preventDefault();

      // Get these before the async request for data consistency
      const selectedRebalanceIds = getSelectedRebalanceRows(rowSelection);
      const unselectedRebalances = (rebalances ?? [])?.filter(
        (rebalance) => !selectedRebalanceIds.includes(rebalance.id),
      );

      await executeRebalances();

      // Manually reset the row selection; otherwise the previous row selection
      // remains in the table state even after modifying its data.
      setRowSelection({});
      queryClient.setQueryData(
        [
          `/rebalances/approved?metrics=true${
            householdId ? `&householdId=${householdId}` : ""
          }`,
        ],
        {
          data: unselectedRebalances,
        },
      );
    },
  });

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: (info) => (
          <Form.Check
            checked={info.table.getIsAllRowsSelected()}
            onChange={info.table.getToggleAllPageRowsSelectedHandler()}
          />
        ),
        aggregatedCell: (info) => (
          <Form.Check
            id={`select-${info.row.original.rebalanceId}`}
            checked={info.row.getIsSelected()}
            onChange={info.row.getToggleSelectedHandler()}
          />
        ),
      }),
      columnHelper.accessor("name", {
        cell: (info) => (
          <div
            style={{
              // Since rows are flattened by default,
              // we can use the row.depth property
              // and paddingLeft to visually indicate the depth
              // of the row
              paddingLeft: `${info.row.depth * 2}rem`,
            }}
          >
            {info.getValue()}
          </div>
        ),
        aggregatedCell: (info) => (
          <>
            {info.row.getCanExpand() ? <RowExpander row={info.row} /> : ""}{" "}
            <Link to={`/rebalances/${info.row.original.rebalanceId}`}>
              {info.getValue()}
            </Link>
          </>
        ),
        header: () => "Household",
        minSize: 225,
      }),
      columnHelper.accessor(
        (row: RebalanceRow) => {
          if (row.level === "account" || typeof row.type === "undefined") {
            return "";
          } else if (
            row.type &&
            Object.prototype.hasOwnProperty.call(rebalanceTypeDisplay, row.type)
          ) {
            const option = rebalanceTypeFilterOptions.find(
              (a) =>
                a.label ===
                rebalanceTypeDisplay[
                  row.type as keyof typeof rebalanceTypeDisplay
                ],
            );
            return option?.value.toString() ?? "";
          }
          return "";
        },
        {
          id: "type",
          header: () => "Rebalance Reason",
          cell: (info) => {
            const option = rebalanceTypeFilterOptions.find(
              (a) => a.value.toString() === info.getValue(),
            );
            return option?.label ?? "";
          },
          aggregatedCell: (info) => {
            const option = rebalanceTypeFilterOptions.find(
              (a) => a.value.toString() === info.getValue(),
            );
            return option?.label ?? "";
          },
          filterFn: multiSelectIncludes,
          meta: {
            filterComponent: RebalanceReasonFilter,
          },
        },
      ),
      columnHelper.accessor((row) => row.riskAllocation?.target.growth ?? 0, {
        id: "target",
        cell: (info) =>
          getRiskAllocationDisplay(info.row.original.riskAllocation?.target),
        aggregatedCell: (info) =>
          getRiskAllocationDisplay(info.row.original.riskAllocation?.target),
        header: () => "Target Allocation",
        enableColumnFilter: false,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.riskAllocation?.current.growth ?? 0, {
        id: "current",
        cell: (info) =>
          getRiskAllocationDisplay(info.row.original.riskAllocation?.current),
        aggregatedCell: (info) =>
          getRiskAllocationDisplay(info.row.original.riskAllocation?.current),
        header: () => "Current Allocation",
        enableColumnFilter: false,
        enableSorting: false,
      }),
      columnHelper.accessor(
        (row) => row.riskAllocation?.postRebalance.growth ?? 0,
        {
          id: "postRebalance",
          cell: (info) =>
            getRiskAllocationDisplay(
              info.row.original.riskAllocation?.postRebalance,
            ),
          aggregatedCell: (info) =>
            getRiskAllocationDisplay(
              info.row.original.riskAllocation?.postRebalance,
            ),
          header: () => "Post-Rebalance Allocation",
          enableColumnFilter: false,
          enableSorting: false,
        },
      ),
      columnHelper.accessor((row) => row.totalBalance ?? 0, {
        id: "totalBalance",
        cell: (info) => formatCurrency(info.getValue()),
        aggregatedCell: (info) => formatCurrency(info.getValue()),
        header: () => "AUM",
        enableColumnFilter: false,
        meta: {
          headerClassName: "text-end",
          className: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.cashBalance ?? 0, {
        id: "cashBalance",
        cell: (info) => formatCurrency(info.getValue()),
        aggregatedCell: (info) => formatCurrency(info.getValue()),
        header: () => "Cash",
        enableColumnFilter: false,
        meta: {
          headerClassName: "text-end",
          className: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.buyTotal ?? 0, {
        id: "buyTotal",
        cell: (info) => formatCurrency(info.getValue()),
        aggregatedCell: (info) => formatCurrency(info.getValue()),
        header: () => "Buy",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.sellTotal ?? 0, {
        id: "sellTotal",
        cell: (info) => formatCurrency(info.getValue()),
        aggregatedCell: (info) => formatCurrency(info.getValue()),
        header: () => "Sell",
        enableColumnFilter: false,
        meta: {
          headerClassName: "text-end",
          className: "text-end",
        },
      }),
      columnHelper.accessor(
        (row) => (row.buyTotal ?? 0) - (row.sellTotal ?? 0),
        {
          id: "net",
          cell: (info) => formatCurrency(info.getValue()),
          aggregatedCell: (info) => formatCurrency(info.getValue()),
          header: () => "Net",
          enableColumnFilter: false,
          meta: {
            headerClassName: "text-end",
            className: "text-end",
          },
        },
      ),
      columnHelper.accessor(
        (row) => (row.realizedGainLossSt ?? 0) - (row.realizedGainLossLt ?? 0),
        {
          id: "realizedGainLoss",
          cell: (info) => formatCurrency(info.getValue()),
          aggregatedCell: (info) => formatCurrency(info.getValue()),
          header: () => "Realized Gain/Loss",
          enableColumnFilter: false,
          meta: {
            headerClassName: "text-end",
            className: "text-end",
          },
        },
      ),
      columnHelper.display({
        id: "actions",
        meta: {
          className: "text-nowrap",
        },
        aggregatedCell: (info) => {
          const rebalance = info.row.original;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const rejectRebalance = useAuthenticatedMutation<
            UnpackResponse<RebalancesController["changeRebalancesState"]>
          >(
            "/rebalances",
            {
              method: "PATCH",
              body: JSON.stringify({
                state: "proposed",
                rebalanceIds: [rebalance.rebalanceId],
              }),
            },
            processEmptyResponse,
          );

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const cancelRebalance = useAuthenticatedMutation<
            UnpackResponse<RebalancesController["changeRebalancesState"]>
          >(
            "/rebalances",
            {
              method: "PATCH",
              body: JSON.stringify({
                state: "canceled",
                rebalanceIds: [rebalance.rebalanceId],
              }),
            },
            processEmptyResponse,
          );

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const reject = useMutation({
            mutationFn: async () => {
              await rejectRebalance();

              const remainingRebalances = (rebalances ?? [])?.filter(
                (r) => r.id !== rebalance.rebalanceId,
              );

              queryClient.setQueryData(
                [
                  `/rebalances/approved?metrics=true${
                    householdId ? `&householdId=${householdId}` : ""
                  }`,
                ],
                {
                  data: remainingRebalances,
                },
              );
            },
          });

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const cancel = useMutation({
            mutationFn: async () => {
              await cancelRebalance();

              const remainingRebalances = (rebalances ?? [])?.filter(
                (r) => r.id !== rebalance.rebalanceId,
              );

              queryClient.setQueryData(
                [
                  `/rebalances/approved?metrics=true${
                    householdId ? `&householdId=${householdId}` : ""
                  }`,
                ],
                {
                  data: remainingRebalances,
                },
              );
            },
          });

          return (
            <>
              <ActionButton
                variant="icon"
                icon="/icons/reject.svg"
                label="Reject"
                onClick={() => reject.mutate()}
                disabled={reject.isPending}
                type="button"
                className="me-2"
              />
              <ActionButton
                variant="icon"
                icon="/icons/trash.svg"
                label="Cancel"
                onClick={() => cancel.mutate()}
                disabled={cancel.isPending}
                type="button"
              />
            </>
          );
        },
      }),
    ],
    [householdId, queryClient, rebalances],
  );

  const rejectRow = useCallback(
    (id: number) => {
      const remainingRebalances = (rebalances ?? [])?.filter(
        (rebalance) => rebalance.id !== id,
      );

      queryClient.setQueryData(["/rebalances/approved?metrics=true"], {
        data: remainingRebalances,
      });
    },
    [queryClient, rebalances],
  );

  const approveRow = useCallback(
    (id: number) => {
      const remainingRebalances = (rebalances ?? [])?.filter(
        (rebalance) => rebalance.id !== id,
      );

      queryClient.setQueryData(["/rebalances/approved?metrics=true"], {
        data: remainingRebalances,
      });
    },
    [queryClient, rebalances],
  );

  const [tableSettings, setTableSettings] =
    useTableSettings("rebalances-today");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    tableSettings.filters,
  );
  const [sorting, setSorting] = useState<SortingState>(tableSettings.sorting);
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: tableSettings.pageSize,
    pageIndex: 0,
  });

  const { table, rowSelection, setRowSelection } = useTable({
    columns,
    data: rebalanceRows,
    getRowId: (row) => `${row.rebalanceId}-${row.level}-${row.id}`,
    getSubRows: (row) => row.accounts ?? [],
    state: { columnFilters, sorting, pagination },
    onColumnFiltersChange: onColumnFiltersChange(
      columnFilters,
      setColumnFilters,
      tableSettings,
      setTableSettings,
    ),
    onSortingChange: onSortingChange(
      sorting,
      setSorting,
      tableSettings,
      setTableSettings,
    ),
    onPaginationChange: onPaginationChange(
      pagination,
      setPagination,
      tableSettings,
      setTableSettings,
    ),
    autoResetPageIndex: true,
    meta: {
      ...metaDefault,
      approveRow,
      deleteRow: rejectRow,
    },
  });

  return isPendingRebalances ? (
    <Loading />
  ) : (
    <>
      <Form onSubmit={mutation.mutate}>
        <Row className="mb-3">
          <Col>
            <ActionButton
              label="Execute Trades"
              icon="/icons/trade.svg"
              variant="secondary"
              type="submit"
              disabled={mutation.isPending}
            />
          </Col>
        </Row>
      </Form>
      <Table table={table} />
    </>
  );
};

export default RebalancesApprovedTable;
