import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ModelsController } from "../../../api/src/models/models.controller";
import type { ModelRequest } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import FastTrackAttribution from "../components/FastTrackAttribution";
import { useAuthenticatedFetch } from "../lib/api";
import { useDebounce } from "../lib/forms";
import { formatPercent } from "../lib/numbers";

const ModelPerformance = ({
  model,
  isLoading = false,
}: {
  model: Partial<Pick<ModelRequest, "primarySecurities" | "sleeves">>;
  isLoading: boolean;
}) => {
  const {
    isPending: isPendingPerformance,
    isError: isErrorPerformance,
    data: dataPerformance,
    refetch,
  } = useAuthenticatedFetch<
    UnpackResponse<ModelsController["getModelPerformanceRealtime"]>
  >(
    `/models/performance`,
    {
      method: "POST",
      body: JSON.stringify({
        ...model,
        primarySecurities:
          model?.primarySecurities?.map((security) => ({
            ...security,
            weight:
              typeof security.weight === "number"
                ? security.weight
                : parseInt(security.weight),
          })) ?? [],
        sleeves:
          model?.sleeves
            ?.filter(
              (sleeve) => sleeve.id && sleeve.id !== -1 && sleeve.weight !== 0,
            )
            .map((sleeve) => ({
              ...sleeve,
              weight:
                typeof sleeve.weight === "number"
                  ? sleeve.weight
                  : parseInt(sleeve.weight),
            })) ?? [],
      }),
    },
    { enabled: false, gcTime: 0 },
  );
  const performance = dataPerformance?.data;

  const [debouncedRefetch] = useDebounce(
    async () => {
      await refetch();
    },
    isPendingPerformance ? 0 : 1000,
  );

  useEffect(() => {
    if (!isLoading) {
      debouncedRefetch();
    }
    // debounceRefetch is a new instance every timer cycle;
    // Using it as a dependency causes this hook to refire repeatedly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, isLoading]);

  return isLoading || isPendingPerformance ? (
    <Loading />
  ) : isErrorPerformance || typeof performance === "undefined" ? (
    <Alert variant="danger">Failed to load model performance</Alert>
  ) : (
    <div>
      <h4>Performance</h4>
      <dl>
        <dt>Expense Ratio</dt>
        <dd>{formatPercent(performance.expenseRatio, 2)}</dd>
        <dt>Dividend Yield</dt>
        <dd>{formatPercent(performance.dividendYield, 2)}</dd>
        <dt>1 Year</dt>
        <dd>{formatPercent(performance.returns.oneYear, 2)}</dd>
        <dt>3 Years</dt>
        <dd>{formatPercent(performance.returns.threeYear, 2)}</dd>
        <dt>5 Years</dt>
        <dd>{formatPercent(performance.returns.fiveYear, 2)}</dd>
      </dl>
      <p>
        <FastTrackAttribution />
      </p>
    </div>
  );
};

export default ModelPerformance;
