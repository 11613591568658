import { useCallback, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

export interface MultiSelectorValue<TValue> {
  label: string;
  value: TValue;
}

const MultiSelector = <TValue,>({
  options,
  setValue,
  placeholder,
  value,
  defaultValues,
}: {
  options: MultiSelectorValue<TValue>[];
  setValue?: (value: TValue[]) => void;
  placeholder?: string;
  value?: TValue[];
  defaultValues?: TValue[];
}) => {
  const [selected, setSelected] = useState<MultiSelectorValue<TValue>[]>(
    options.filter((option) => (defaultValues ?? []).includes(option.value)),
  );
  const [show, setShow] = useState(false);

  const handleFocus = useCallback(() => {
    setShow(true);
  }, [setShow]);

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setShow(false);
  }, []);

  const handleIgnore = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => e.preventDefault(),
    [],
  );

  const handleItemClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    option: MultiSelectorValue<TValue>,
  ) => {
    const values = selected.includes(option)
      ? selected.filter((item) => item.value !== option.value)
      : [...selected, option];

    setSelected(values);

    if (typeof setValue !== "undefined") {
      setValue(values.map((item) => item.value));
    }
  };

  const handleClearAll = () => {
    setSelected([]);
    if (typeof setValue !== "undefined") {
      setValue([]);
    }
  };

  useEffect(() => {
    if (typeof value !== "undefined") {
      setSelected(options.filter((option) => value.includes(option.value)));
    }
  }, [options, setSelected, value]);

  return (
    <>
      <Form.Control
        className="multi-selector-box"
        value={selected.map((item) => item.label).join(", ")}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        role="button"
        readOnly
      />
      <Button onClick={handleClearAll}>Clear All</Button>
      {show ? (
        <div className="w-100 position-relative">
          <div className="multi-selector-container">
            {options.length <= 0 ? (
              <p className="text-center mb-0">No available data</p>
            ) : (
              options.map((option, idx) => (
                <div
                  key={idx}
                  className="multi-selector-item"
                  onClick={(e) => handleItemClick(e, option)}
                  onMouseDown={handleIgnore}
                  role="button"
                >
                  <Form.Check
                    type="checkbox"
                    checked={selected.some(
                      (item) => item.value === option.value,
                    )}
                    label={option.label}
                    readOnly
                  />
                </div>
              ))
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MultiSelector;
