import { Alert, Col, Row } from "react-bootstrap";
import type { DashboardController } from "../../../api/src/dashboard/dashboard.controller";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import AllocationBar from "../components/AllocationBar/AllocationBar";
import SummaryPill from "../components/SummaryPill";
import { useAuthenticatedFetch } from "../lib/api";
import { formatCurrency } from "../lib/numbers";
import { getRiskAllocation } from "../rebalances/lib";

const FirmSummaryTotals = () => {
  const {
    isPending: isPendingFirmTotals,
    isError: isErrorFirmTotals,
    data: dataFirmTotals,
  } = useAuthenticatedFetch<
    UnpackResponse<DashboardController["getFirmTotals"]>
  >("/dashboard/firm-totals");
  const firmTotals = dataFirmTotals?.data;

  return isPendingFirmTotals ? (
    <Loading message="Totals" />
  ) : isErrorFirmTotals || typeof firmTotals === "undefined" ? (
    <Alert variant="danger">Failed to load totals</Alert>
  ) : (
    <Row>
      <Col className="mb-4">
        <SummaryPill label="AUM" value={formatCurrency(firmTotals.aum)} />
      </Col>
      <Col className="mb-4">
        <SummaryPill
          label="Cash"
          value={formatCurrency(firmTotals.cashTotal)}
        />
      </Col>
      <Col className="mb-4">
        <SummaryPill
          label="Households"
          value={firmTotals.householdCount.toLocaleString()}
        />
      </Col>
      <Col className="mb-4">
        <SummaryPill
          label="Accounts"
          value={firmTotals.accountCount.toLocaleString()}
        />
      </Col>
      <Col className="mb-4">
        <div className="summary-pill rounded p-3">
          {/* Match the size of the other pills */}
          <div style={{ height: "3.6rem", width: "238px" }}>
            <AllocationBar
              data={firmTotals.riskAllocation}
              converter={getRiskAllocation}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default FirmSummaryTotals;
