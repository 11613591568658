import { ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { InsightsController } from "../../../api/src/insights/insights.controller";
import { useAuthenticatedFetch } from "../lib/api";
import Loading from "../Loading";

const RebalanceOpportunities = () => {
  const {
    isPending: isPendingAccountOpportunities,
    data: dataAccountOpportunities,
  } = useAuthenticatedFetch<
    UnpackResponse<InsightsController["accountOpportunities"]>
  >("/insights/account-opportunities");
  const accountOpportunities = dataAccountOpportunities?.data;

  return isPendingAccountOpportunities ? (
    <ListGroupItem>
      <Loading />
    </ListGroupItem>
  ) : !accountOpportunities ? null : (
    <ListGroupItem>
      <Link to="/rebalances/today">
        {accountOpportunities.accountOutOfRebalanceCount} Accounts
      </Link>{" "}
      are out of rebalance tolerance and{" "}
      {accountOpportunities.accountsWithExcessCash.length <= 0 ? (
        "0 Accounts"
      ) : (
        <Link
          to={`/clients/accounts?${accountOpportunities.accountsWithExcessCash
            .map((id) => `id=${id}`)
            .join("&")}`}
        >
          {accountOpportunities.accountsWithExcessCash.length} Accounts
        </Link>
      )}{" "}
      have excess cash.
    </ListGroupItem>
  );
};

export default RebalanceOpportunities;
