import { useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import type {
  EditableAccount,
  FeeLocation,
} from "../../../../api/src/accounts/accounts.service";
import type { BillingController } from "../../../../api/src/billing/billing.controller";
import type { SerializedObject, UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import DateInput from "../../components/DateInput";
import FormError from "../../components/FormError";
import FormFieldError from "../../components/FormFieldError";
import MultiSelector from "../../components/MultiSelector";
import { useAuthenticatedFetch } from "../../lib/api";
import { InlineError, displayAccountName } from "../../lib/display";
import {
  dateSchema,
  getSchemaFieldLabel,
  idOptionalSelectorSchema,
  idsOptionalSelectorSchema,
  optionalStringValueTransform,
} from "../../lib/forms";
import { useQueryHouseholdAccounts } from "../household/lib";
import { useQueryAccount } from "./lib";

const feeLocations: FeeLocation[] = ["S", "G", "A"];

const mapFeeLocations = {
  S: "This Account",
  G: "Household Distribution",
  A: "Other Account",
};

export type AccountBillingSettings = Pick<
  EditableAccount,
  | "feeStructureId"
  | "billingSplitsIds"
  | "assetAdjustmentIds"
  | "feeLocation"
  | "feeLocationAccountId"
  | "billingStartDate"
>;

export const schema: yup.ObjectSchema<AccountBillingSettings> = yup
  .object({
    feeStructureId: idOptionalSelectorSchema.label("Fee Structure"),
    billingSplitsIds: idsOptionalSelectorSchema
      .required()
      .label("Billing Splits"),
    assetAdjustmentIds: idsOptionalSelectorSchema
      .required()
      .label("Asset Exclusions"),
    feeLocation: yup
      .string()
      .oneOf<FeeLocation>(feeLocations)
      .transform(optionalStringValueTransform)
      .label("Fee Location"),
    feeLocationAccountId: idOptionalSelectorSchema.label("Referenced Account"),
    billingStartDate: dateSchema.required().label("Billing Start Date"),
  })
  .test({
    name: "reference-account-defined",
    test: (value) =>
      value.feeLocation !== "A" ||
      (value.feeLocation === "A" &&
        typeof value.feeLocationAccountId !== "undefined"),
    message: "You should select a reference account to transfer fee.",
  });

const AccountBillingInfo = () => {
  const {
    register,
    reset,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<AccountBillingSettings>();

  const { accountId } = useParams();
  const {
    isPending: isPendingAccount,
    isError: isErrorAccount,
    data: account,
  } = useQueryAccount(parseInt(accountId ?? ""), {
    includeBalances: true,
  });

  const {
    isPending: isPendingFeeStructures,
    isError: isErrorFeeStructures,
    data: dataFeeStructures,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<BillingController["getAllFeeStructures"]>>
  >("/billing/fee-structures");

  const {
    isPending: isPendingBillingSplits,
    isError: isErrorBillingSplits,
    data: dataBillingSplits,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<BillingController["getAllBillingSplits"]>>
  >("/billing/billing-splits");

  const {
    isPending: isPendingAssetExclusions,
    isError: isErrorAssetExclusions,
    data: dataAssetExclusions,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<BillingController["getAllAssetExclusions"]>>
  >("/billing/asset-exclusions");

  const {
    isFetching: isFetchingAccounts,
    isError: isErrorAccounts,
    data: dataAccounts,
  } = useQueryHouseholdAccounts(account?.householdId ?? -1, {
    includeBalances: false,
    enabled: typeof account?.householdId !== "undefined",
  });

  const isPending =
    isPendingAccount ||
    isPendingFeeStructures ||
    isPendingAssetExclusions ||
    isPendingBillingSplits ||
    isFetchingAccounts;

  useEffect(() => {
    if (typeof account !== "undefined" && !isPending) {
      reset({
        ...getValues(),
        ...account,
      });
    }
  }, [account, getValues, isPending, reset]);

  const feeLocation = useWatch({
    control,
    name: "feeLocation",
  });

  const feeLocationAccountId = useWatch({
    control,
    name: "feeLocationAccountId",
  });

  const feeLocationOtherAccounts = (dataAccounts ?? [])
    .filter((acc) => acc.id !== account?.id)
    .sort((a, b) =>
      a.displayName < b.displayName
        ? -1
        : a.displayName > b.displayName
          ? 1
          : 0,
    );

  useEffect(() => {
    if (
      feeLocation === "A" &&
      (typeof feeLocationAccountId === "undefined" ||
        `${feeLocationAccountId}` === "")
    ) {
      setValue("feeLocationAccountId", feeLocationOtherAccounts[0]?.id);
    }
  }, [feeLocation, feeLocationAccountId, feeLocationOtherAccounts, setValue]);

  const accountsOptions = feeLocationOtherAccounts.map((account) => (
    <option key={account.id} value={account.id}>
      {displayAccountName(account.displayName, account.displayNumber)}
    </option>
  ));

  const feeStructureOptions = [
    <option key={null} value="">
      None
    </option>,
    ...(dataFeeStructures?.data ?? [])
      .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
      .map((feeStructure) => (
        <option
          key={feeStructure.feeStructureId}
          value={feeStructure.feeStructureId}
        >
          {feeStructure.name}
        </option>
      )),
  ];

  const assetExclusionOptions = useMemo(
    () =>
      (dataAssetExclusions?.data ?? [])
        .filter((assetExclusion) => assetExclusion.level === "Account")
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((assetExclusion) => ({
          label: assetExclusion.name,
          value: assetExclusion.id,
        })),
    [dataAssetExclusions?.data],
  );

  const firmAssetExclusions = (dataAssetExclusions?.data ?? []).filter(
    (assetExclusion) => assetExclusion.level === "Firm",
  );

  const billingSplitOptions = useMemo(
    () =>
      (dataBillingSplits?.data ?? [])
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((billingSplit) => ({
          label: billingSplit.name,
          value: billingSplit.id,
        })),
    [dataBillingSplits?.data],
  );

  return isPending ? (
    <Loading />
  ) : isErrorAccount ? (
    <FormError message="Failed to load billing settings" />
  ) : typeof account === "undefined" ? (
    <FormError message="Billing settings not found" />
  ) : (
    <>
      <Row>
        <Col as="dt" xxl={4} md={3}>
          {getSchemaFieldLabel(schema.fields.feeStructureId)}
        </Col>
        <Col as="dd" xxl={8} md={6}>
          {isErrorFeeStructures ? (
            <InlineError>There was an error loading fee structures</InlineError>
          ) : (
            <Form.Select
              {...register("feeStructureId")}
              placeholder="Select assigned fee structure"
            >
              {feeStructureOptions}
            </Form.Select>
          )}
          <FormFieldError field={errors.feeStructureId} />
        </Col>
      </Row>
      <Row>
        <Col as="dt" xxl={4} md={3}>
          {getSchemaFieldLabel(schema.fields.feeLocation)}
        </Col>
        <Col as="dd" xxl={8} md={6}>
          <Form.Select
            {...register("feeLocation")}
            placeholder="Select fee location"
          >
            {feeLocations.map((feeLocation, idx) => (
              <option
                key={idx}
                value={feeLocation}
                disabled={
                  (feeLocation === "A" || feeLocation === "G") &&
                  typeof account.householdId === "undefined"
                }
              >
                {mapFeeLocations[feeLocation]}
              </option>
            ))}
          </Form.Select>
          <FormFieldError field={errors.feeLocation} />
        </Col>
      </Row>
      {feeLocation === "A" ? (
        <Row>
          <Col as="dt" xxl={4} md={3}>
            {getSchemaFieldLabel(schema.fields.feeLocationAccountId)}
          </Col>
          <Col as="dd" xl={8} md={6}>
            {isErrorAccounts ? (
              <InlineError>
                There was an error loading household accounts
              </InlineError>
            ) : (
              <Form.Select
                {...register("feeLocationAccountId")}
                placeholder="Select referenced account"
              >
                {accountsOptions}
              </Form.Select>
            )}
            <FormFieldError field={errors.feeLocationAccountId} />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col as="dt" xxl={4} md={3}>
          {getSchemaFieldLabel(schema.fields.assetAdjustmentIds)}
        </Col>
        <Col as="dd" xxl={8} md={6}>
          {isErrorAssetExclusions ? (
            <InlineError>Error while loading asset exclusions</InlineError>
          ) : (
            <MultiSelector
              options={assetExclusionOptions}
              placeholder={firmAssetExclusions
                .map((assetExclusion) => assetExclusion.name)
                .join(", ")}
              defaultValues={account.assetAdjustmentIds}
              setValue={(value) => setValue("assetAdjustmentIds", value)}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col as="dt" xxl={4} md={3}>
          {getSchemaFieldLabel(schema.fields.billingSplitsIds)}
        </Col>
        <Col as="dd" xxl={8} md={6}>
          {isErrorBillingSplits ? (
            <InlineError>Error while loading billing splits</InlineError>
          ) : (
            <MultiSelector
              options={billingSplitOptions}
              placeholder="Select assigned billing splits"
              defaultValues={account.billingSplitsIds}
              setValue={(value) => setValue("billingSplitsIds", value)}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col as="dt" xxl={4} md={3}>
          Billing Start Date
        </Col>
        <Col as="dd" xxl={8} md={6}>
          <DateInput name="billingStartDate" control={control} />
          <FormFieldError field={errors.billingStartDate} />
        </Col>
      </Row>
    </>
  );
};

export default AccountBillingInfo;
