import { useContext } from "react";
import { AxisOptions, Chart, UserSerie } from "react-charts";
import { ThemeContext } from "../lib/display";

export type TimeSeries = { date: Date; value: number };

function HistoricalChart<TItem extends TimeSeries>({
  primaryAxis,
  secondaryAxes,
  data,
}: {
  primaryAxis: AxisOptions<TimeSeries>;
  secondaryAxes: AxisOptions<TItem>[];
  data: UserSerie<TItem>[];
}) {
  const { currentTheme } = useContext(ThemeContext);
  const defaultColors = ["#00B0B0", "#54636d", "#000"];

  const dataSets = data.filter((series) => series.data.length > 0);

  return dataSets.length <= 0 ? null : (
    <div>
      <div style={{ height: "400px" }}>
        <Chart
          options={{
            data: dataSets,
            primaryAxis,
            secondaryAxes,
            defaultColors,
            dark: currentTheme === "dark",
          }}
        />
      </div>
      <div className="text-center">
        <div className="d-inline-flex justify-content-between gap-4 border rounded px-3 py-1">
          {dataSets.map((series, index) => (
            <div key={index} className="d-inline-block">
              <span className="align-middle me-2">{series.label ?? ""}</span>
              <div
                className="d-inline-block rounded-circle align-middle"
                style={{
                  backgroundColor: defaultColors[index],
                  width: "1rem",
                  height: "1rem",
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HistoricalChart;
