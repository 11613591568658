import type { BridgeFTRoiCustodianCode } from "../../../api/src/bridgeft/firms.service";
import type { BridgeFTAccountCustodianCode } from "../../../api/src/bridgeft/lib";
import type { CustodianCode } from "../../../api/src/firms/firms.service";

export function custodianCodeToDisplayName(code: CustodianCode) {
  switch (code) {
    case "SWB":
      return "Schwab";
    case "NFS":
      return "Fidelity";
    case "PER":
      return "Pershing";
    case "TDA":
      return "TD Ameritrade";
    case "IBK":
      return "Interactive Brokers";
    case "TIA":
      return "TIAA";
    case "APX":
      return "Apex Clearing";
    case "RJA":
      return "Raymond James";
    case "MLT":
      return "Millennium Trust";
    case "DST":
      return "DST Systems";
    case "HDG":
      return "Manual";
    default:
      return "Unknown";
  }
}

export const roiCustodians: BridgeFTRoiCustodianCode[] = [
  "PER",
  "IBK",
  "EGB",
  "MLT",
];

export const custodianHelpUrlMap: Record<BridgeFTAccountCustodianCode, string> =
  {
    SWB: "https://docs.bridgeft.com/docs/data-feed-guide-schwab",
    NFS: "https://docs.bridgeft.com/docs/data-feed-guide-fidelity",
    DST: "https://docs.bridgeft.com/docs/data-feed-guide-dst",
    APX: "https://docs.bridgeft.com/docs/data-feed-guide-apex",
    TDA: "https://docs.bridgeft.com/docs/data-feed-guide-overview",
    PER: "https://docs.bridgeft.com/docs/data-feed-guide-overview",
    IBK: "https://docs.bridgeft.com/docs/data-feed-guide-overview",
    TIA: "https://docs.bridgeft.com/docs/data-feed-guide-overview",
    MLT: "https://docs.bridgeft.com/docs/data-feed-guide-overview",
    RJA: "https://docs.bridgeft.com/docs/data-feed-guide-overview",
    EGB: "https://docs.bridgeft.com/docs/data-feed-guide-overview",
    HDG: "https://docs.bridgeft.com/docs/data-feed-guide-overview",
  };
