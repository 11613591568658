import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type { AssetExclusion } from "../../../api/src/billing/lib";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import ActionButton from "../components/ActionButton";
import IndeterminateCheckbox from "../components/IndeterminateCheckbox";
import TabContainerWithTabs from "../components/TabContainer";
import { Table, useTable } from "../components/Table/Table";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutation,
} from "../lib/api";
import { formatPercent } from "../lib/numbers";
import BillingNav from "./BillingNav";

type AssetExclusionRow = AssetExclusion;

const columnHelper = createColumnHelper<AssetExclusionRow>();

const columns = [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor((row) => row.name, {
    id: "name",
    cell: (info) => (
      <Link to={`/billing/asset-exclusion/${info.row.original.id}`}>
        {info.getValue()}
      </Link>
    ),
    header: () => "Name",
  }),
  columnHelper.accessor((row) => row.weight, {
    id: "weight",
    cell: (info) => formatPercent(+info.getValue() / 100, 2),
    header: () => "Weight",
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.accounts?.length ?? 0, {
    id: "noOfAccounts",
    header: () => "# of Accounts",
    enableColumnFilter: false,
    meta: {
      className: "text-end",
      headerClassName: "text-end",
    },
  }),
  columnHelper.accessor((row) => row.securities?.length ?? 0, {
    id: "noOfSecurities",
    header: () => "# of Securities",
    enableColumnFilter: false,
    meta: {
      className: "text-end",
      headerClassName: "text-end",
    },
  }),
];

const AssetExclusionTable = () => {
  const { isPending, data } = useAuthenticatedFetch<
    UnpackResponse<BillingController["getAllAssetExclusions"]>
  >("/billing/asset-exclusions");

  const { table, rowSelection, setRowSelection } = useTable({
    columns,
    data: data?.data ?? [],
  });

  const removeAssetExclusions = useAuthenticatedMutation<
    UnpackResponse<BillingController["deleteAssetExclusions"]>
  >(
    `/billing/asset-exclusions/delete-many`,
    {
      method: "POST",
      body: JSON.stringify(
        data?.data.reduce((result, assetExclusion, idx) => {
          return Object.keys(rowSelection).includes(`${idx}`)
            ? [...result, assetExclusion.id]
            : result;
        }, [] as number[]),
      ),
    },
    processEmptyResponse,
  );

  const queryClient = useQueryClient();

  const del = useMutation({
    mutationFn: async () => {
      if (Object.keys(rowSelection).length === 0) return;
      try {
        await removeAssetExclusions();
        queryClient.setQueryData(["/billing/asset-exclusions"], {
          data: data?.data.filter(
            (assetExclusion, idx) =>
              !Object.keys(rowSelection).includes(`${idx}`),
          ),
        });
        setRowSelection({});
      } catch (err) {
        const message = "Failed to delete asset exclusions.";
        console.error(message, err);
      }
    },
  });

  return (
    <TabContainerWithTabs tabs={BillingNav}>
      <Row>
        <Col className="d-flex justify-content-between mb-3">
          <ButtonToolbar className="gap-3">
            <ButtonGroup>
              <ActionButton
                as={Link}
                to="new"
                variant="secondary"
                label="Create"
                icon="/icons/new.svg"
              />
            </ButtonGroup>
            <ButtonGroup>
              <ActionButton
                variant="secondary"
                label="Delete"
                icon="/icons/trash.svg"
                onClick={() => del.mutate()}
                disabled={
                  del.isPending || Object.keys(rowSelection).length === 0
                }
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>{isPending ? <Loading /> : <Table table={table} />}</Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default AssetExclusionTable;
