import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { createRoot } from "react-dom/client";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import App from "./App";
import routes from "./Routes";
import "./index.scss";
import { ThemeProvider } from "./lib/display";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV ?? "development",
  release: process.env.REACT_APP_COMMIT_HASH,
  dist: "client",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllText: false,
    }),
  ],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate.
  profilesSampleRate: 1.0,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 1.0,
  // Only trace requests to the current hostname (i.e. our own API).
  tracePropagationTargets: [/^\//],
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production.
  replaysSessionSampleRate: 0.2,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: Auth0ProviderOptions) => {
  const navigate = useNavigate();
  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      navigate(appState?.returnTo ?? window.location.pathname);
    },
    [navigate],
  );

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const AppWithSentryProfiler = Sentry.withProfiler(App);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: (
      <Auth0ProviderWithRedirectCallback
        domain={
          process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN ??
          process.env.REACT_APP_AUTH0_DOMAIN ??
          ""
        }
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ""}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/auth/auth0`,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }}
      >
        <AppWithSentryProfiler />
      </Auth0ProviderWithRedirectCallback>
    ),
    children: routes,
  },
]);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
