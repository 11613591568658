import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import type { BridgeFTRoiCustodianCode } from "../../api/src/bridgeft/firms.service";
import type { FirmsController } from "../../api/src/firms/firms.controller";
import type { CustodianCode, NewFirm } from "../../api/src/firms/firms.service";
import type { UnpackResponse } from "../../api/src/lib";
import Loading from "./Loading";
import Content from "./components/Content";
import { useErrorMessage } from "./components/FormError";
import FormFieldError from "./components/FormFieldError";
import MaskedInput from "./components/MaskedInput";
import SubmitButton from "./components/SubmitButton";
import AdvisorCodesEditor, {
  advisorCodeSchema,
} from "./firm/AdvisorCodeEditor";
import { usePublicdMutationAsync } from "./lib/api";
import {
  custodianCodeToDisplayName,
  custodianHelpUrlMap,
  roiCustodians,
} from "./lib/custodian";
import {
  emailSchema,
  familyNameSchema,
  getSchemaFieldLabel,
  givenNameSchema,
  phoneNumberSchema,
} from "./lib/forms";
import { phoneMask } from "./lib/masks";

const schema: yup.ObjectSchema<NewFirm> = yup.object({
  name: yup.string().required().label("Firm Name"),
  phoneNumber: phoneNumberSchema,
  supportEmail: emailSchema.required(),
  givenName: givenNameSchema.required(),
  familyName: familyNameSchema.required(),
  password: yup.string().min(12).required().label("Password"),
  advisorCodes: advisorCodeSchema,
});

const SignUpSuccess = ({ custodians }: { custodians: CustodianCode[] }) => (
  <Content>
    <Row>
      <Col>
        <p>
          <strong>Thank you for signing up for AdviceCloud!</strong>
        </p>
        <p>
          In order to complete the setup for your firm, you will need to follow
          the instructions linked below in order to connect your custodian
          master accounts to AdviceCloud's custody data provider,{" "}
          <a href="https://www.bridgeft.com" target="_blank" rel="noreferrer">
            BridgeFT
          </a>
          . This process takes approximately 5–10 business days. You will not
          have access to investment accounts in AdviceCloud until this data
          connection is finalized.
        </p>
        <p>
          <small>
            *Instructions hosted by{" "}
            <a href="https://www.bridgeft.com" target="_blank" rel="noreferrer">
              BridgeFT
            </a>
          </small>
        </p>
        <ul>
          {custodians
            .filter(
              (custodian) =>
                !roiCustodians.includes(custodian as BridgeFTRoiCustodianCode),
            )
            .map((custodian) =>
              typeof custodianHelpUrlMap[custodian] === "undefined" ? null : (
                <li>
                  <a
                    href={custodianHelpUrlMap[custodian]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {custodianCodeToDisplayName(custodian)} Instructions
                  </a>
                </li>
              ),
            )}
        </ul>
        <p>
          <strong>
            Once you have completed the provided instructions for all of your
            custodian(s), you may continue to the{" "}
            <Link to="/login">AdviceCloud log-In page</Link>.
          </strong>
        </p>
      </Col>
    </Row>
  </Content>
);

const SignUp = () => {
  // If we are already logged in, navigate to the home page.
  // Do not allow an existing user to create a new firm.
  const { isLoading, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate("/");
    }
  }, [isLoading, isAuthenticated, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<NewFirm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    fields: advisorCodes,
    append: appendAdvisorCode,
    remove: removeAdvisorCode,
    replace: replaceAdvisorCode,
  } = useFieldArray({
    name: "advisorCodes",
    control,
    keyName: "_field_id",
  });

  const { setError, resetError, errorMessage } = useErrorMessage();

  const createFirm = usePublicdMutationAsync<
    UnpackResponse<FirmsController["createFirm"]>
  >("/firm/signup", async (data: NewFirm) => ({
    method: "POST",
    body: JSON.stringify(data),
  }));

  const onSubmit = useMutation({
    mutationFn: async (data: NewFirm) => {
      resetError();

      try {
        const result = await createFirm(data);
        setSignupResults({
          isSuccess: true,
          roiResults: result.data.roiResults,
          custodians: _.uniq(
            data.advisorCodes.map((advisorCode) => advisorCode.custodian),
          ),
        });
      } catch (err) {
        const message = "Sign Up failed";
        console.error(message, err);
        setError(message);
      }
    },
  });

  const [signupResults, setSignupResults] = useState<{
    isSuccess: boolean;
    custodians: CustodianCode[];
    roiResults: { custodian: CustodianCode; status: "Sent" | "Error" }[];
  }>({
    isSuccess: false,
    custodians: [],
    roiResults: [],
  });

  return isLoading || isAuthenticated ? (
    <Loading />
  ) : signupResults.isSuccess ? (
    <SignUpSuccess custodians={signupResults.custodians} />
  ) : (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      <Row>
        <Col>
          <h1>Sign Up</h1>
        </Col>
      </Row>
      <Content>
        {errorMessage}
        <Row>
          <Col>
            <Row>
              <Col lg={4} sm={12}>
                <Form.Group className="mb-3" controlId="form-givenName">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.givenName)}
                  </Form.Label>
                  <Form.Control type="text" {...register("givenName")} />
                  <FormFieldError field={errors.givenName} />
                </Form.Group>
              </Col>
              <Col lg={4} sm={12}>
                <Form.Group className="mb-3" controlId="form-familyName">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.familyName)}
                  </Form.Label>
                  <Form.Control type="text" {...register("familyName")} />
                  <FormFieldError field={errors.familyName} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={4} sm={12}>
                <Form.Group className="mb-3" controlId="form-supportEmail">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.supportEmail)}
                  </Form.Label>
                  <Form.Control type="text" {...register("supportEmail")} />
                  <FormFieldError field={errors.supportEmail} />
                </Form.Group>
              </Col>
              <Col lg={4} sm={12}>
                <Form.Group className="mb-3" controlId="form-password">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.password)}
                  </Form.Label>
                  <Form.Control type="password" {...register("password")} />
                  <Form.Text>
                    Password must be at least 12 letters long and contain at
                    least 1 uppercase letter, 1 lowercase letter, and 1 number.
                  </Form.Text>
                  <FormFieldError field={errors.password} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={4} sm={12}>
                <Form.Group className="mb-3" controlId="form-name">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.name)}
                  </Form.Label>
                  <Form.Control type="text" {...register("name")} />
                  <FormFieldError field={errors.name} />
                </Form.Group>
              </Col>
              <Col lg={4} sm={12}>
                <Form.Group className="mb-3" controlId="form-phoneNumber">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.phoneNumber)}
                  </Form.Label>
                  <MaskedInput
                    name="phoneNumber"
                    type="tel"
                    mask={phoneMask.mask}
                    placeholder="+x (xxx)-xxx-xxxx"
                    control={control}
                  />
                  <FormFieldError field={errors.phoneNumber} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={8} sm={12}>
                <h3>Master Accounts</h3>
                <AdvisorCodesEditor
                  fields={advisorCodes}
                  replace={replaceAdvisorCode}
                  add={appendAdvisorCode}
                  remove={removeAdvisorCode}
                  register={register}
                  errors={errors.advisorCodes}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} label="Sign Up" />
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

export default SignUp;
