import { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  PerformancePeriod,
  getStartOfYear,
  performancePeriodToDateRange,
  performancePeriodToDisplay,
  performancePeriods,
  toISODateNoTimezone,
} from "../lib/date";

const PerformancePeriodSelector = ({
  setCustomPeriodParams,
}: {
  setCustomPeriodParams: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [performancePeriod, setPerformancePeriod] =
    useState<PerformancePeriod>("ytd");

  const now = new Date();
  const [performancePeriodStartDate, setPerformancePeriodStartDate] = useState(
    getStartOfYear(now.getFullYear()),
  );
  const [performancePeriodEndDate, setPerformancePeriodEndDate] = useState(now);

  useEffect(() => {
    const { startDate, endDate } = performancePeriodToDateRange(
      performancePeriod,
      performancePeriodStartDate,
      performancePeriodEndDate,
    );

    const customPeriodParams = `&start-date=${toISODateNoTimezone(
      startDate ?? "",
    )}&end-date=${toISODateNoTimezone(endDate)}`;

    setCustomPeriodParams(customPeriodParams);
  }, [
    performancePeriod,
    performancePeriodEndDate,
    performancePeriodStartDate,
    setCustomPeriodParams,
  ]);

  const onChangePeriod = useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) => {
      setPerformancePeriod(ev.currentTarget.value as PerformancePeriod);
    },
    [],
  );

  const onChangeStartDate = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setPerformancePeriodStartDate(new Date(ev.currentTarget.value));
    },
    [],
  );

  const onChangeEndDate = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setPerformancePeriodEndDate(new Date(ev.currentTarget.value));
    },
    [],
  );

  return (
    <>
      <Row className="mb-2">
        <Col xl={6} sm={4}>
          <Form.Select value={performancePeriod} onChange={onChangePeriod}>
            {performancePeriods.map((period) => (
              <option key={period} value={period}>
                {performancePeriodToDisplay(period)}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      {performancePeriod !== "custom" ? null : (
        <Row className="mb-2">
          <Col>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={toISODateNoTimezone(performancePeriodStartDate)}
                onChange={onChangeStartDate}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={toISODateNoTimezone(performancePeriodEndDate)}
                onChange={onChangeEndDate}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PerformancePeriodSelector;
