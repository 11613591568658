import _ from "lodash";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import type {
  DataMapping,
  TargetMappingData,
} from "../../../api/src/lib/dataMapping";
import Loading from "../Loading";
import Content from "./Content";
import SubmitButton from "./SubmitButton";
import { capitalize } from "../lib/display";

const DraggableText = ({
  previousTargetId,
  sourceId,
  name,
  handleReleaseLink,
}: {
  previousTargetId?: number;
  sourceId: number;
  name: string;
  handleReleaseLink?: (sourceId: number) => void;
}) => {
  const handleDoubleClick = useCallback(() => {
    if (
      typeof handleReleaseLink !== "undefined" &&
      typeof sourceId !== "undefined"
    )
      handleReleaseLink(sourceId);
  }, [sourceId, handleReleaseLink]);

  const handleDragStart = (event: React.DragEvent<HTMLElement>) => {
    event.dataTransfer.setData(
      "text",
      JSON.stringify({
        previousTargetId,
        sourceId,
      }),
    );
  };

  return (
    <div
      className="draggable-item border border-secondary rounded-pill px-3 py-1 user-select-none"
      draggable
      onDragStart={handleDragStart}
      role="button"
      onDoubleClick={handleDoubleClick}
    >
      {name}
    </div>
  );
};

const DropBox = ({
  className = "",
  children,
  targetId,
  handleDropItem,
}: {
  className?: string;
  children: React.ReactNode;
  targetId?: number;
  handleDropItem: (
    transfered: {
      previousTargetId?: number;
      sourceId: number;
    },
    targetId?: number,
  ) => void;
}) => {
  const [dragOver, setDragOver] = useState(false);
  const handleDragOverStart = () => setDragOver(true);
  const handleDragOverEnd = () => setDragOver(false);

  const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const transferedData = event.dataTransfer.getData("text");
    handleDropItem(JSON.parse(transferedData), targetId);
    setDragOver(false);
  };

  return (
    // Keep the drag target area large, but wrap the items narrowly
    <div
      className={`${className} w-100 user-select-none ${
        dragOver ? "mapping-box-drag-over" : ""
      }`}
      onDragOver={enableDropping}
      onDrop={handleDrop}
      onDragEnter={handleDragOverStart}
      onDragLeave={handleDragOverEnd}
    >
      <div className="d-flex flex-wrap align-items-start gap-2">{children}</div>
    </div>
  );
};

function DataMappingModal<TData>({
  linkHeader,
  title,
  targetTitle,
  mappedTitle,
  sourceTitle,
  providerOptions = [],
  isLoading,
  isError,
  mappingData,
  handleSubmit,
  isSubmitting,
  onAutoSuggest = () => null,
  onSetService = () => null,
  isLoadingSuggestions = false,
  oneToMany = true,
  additionalButtons,
  showNewRows = false,
}: {
  linkHeader?: string;
  title: string;
  targetTitle: string;
  sourceTitle: string;
  mappedTitle?: string;
  providerOptions?: string[];
  isLoading: boolean;
  isError: boolean;
  mappingData: DataMapping<TData>;
  handleSubmit: (data: TargetMappingData<TData>[]) => Promise<void>;
  isSubmitting: boolean;
  onAutoSuggest?: () => void;
  onSetService?: (service: string) => void;
  isLoadingSuggestions?: boolean;
  oneToMany?: boolean;
  additionalButtons?: ReactNode;
  showNewRows?: boolean;
}) {
  const [targetData, setTargetData] = useState<TargetMappingData<TData>[]>([]);
  const [selectedService, setSelectedService] = useState<string>(
    providerOptions[0] ?? "",
  );

  useEffect(() => {
    if (selectedService === "") {
      const updatedService =
        providerOptions.length > 0
          ? providerOptions[0]
          : (Object.keys(mappingData.source)[0] ?? "");

      setSelectedService(updatedService);
      onSetService(updatedService);
    }
  }, [mappingData.source, onSetService, providerOptions, selectedService]);

  const sourceDataMap = useMemo(
    () => _.keyBy(mappingData.source[selectedService] ?? [], "id"),
    [mappingData.source, selectedService],
  );
  const targetDataMap = useMemo(() => _.keyBy(targetData, "id"), [targetData]);

  useEffect(() => {
    if (selectedService !== "") {
      setTargetData(
        mappingData.target.map((target) => ({
          ...target,
          assigned: {
            ...target.assigned,
            [selectedService]: [
              ...(target.assigned[selectedService] ?? []),
              ...(typeof mappingData.suggested[target.id] !== "undefined"
                ? mappingData.suggested[target.id].map((id) => ({
                    id,
                    name: sourceDataMap[id]?.name ?? "",
                  }))
                : []),
            ],
          },
        })),
      );
    }
  }, [mappingData, selectedService, sourceDataMap]);

  // New Row data structure
  type AssignedItem = {
    id: number;
    name: string;
  };

  type DataRow = {
    id: number;
    name: string;
    assigned?: { [key: string]: AssignedItem[] };
  };

  const [newRows, setNewRows] = useState<DataRow[]>([{ id: -1, name: "New" }]);

  // Get unlinked data
  const getUnlinkedData = (selectedService: string) => {
    return (mappingData.source[selectedService] ?? []).filter(
      (data) =>
        !targetData.some((target) =>
          (target.assigned[selectedService] ?? []).some(
            (assignedItem) => assignedItem.id === data.id,
          ),
        ) && // not assigned to any target
        !newRows.some((row) =>
          (row.assigned?.[selectedService] ?? []).some(
            (assignedItem) => assignedItem.id === data.id,
          ),
        ), // not assigned to any new Rows
    );
  };
  const unlinkedData = getUnlinkedData(selectedService);

  // Check if there are unlinked data or assigned rows in newRows
  const shouldShowNewRows =
    showNewRows &&
    (unlinkedData.length > 0 ||
      (unlinkedData.length === 0 && newRows.some((row) => "assigned" in row)));

  // The handleAddRow function is used to add a new row to the newRows state.
  // If there are no previous rows, the id is set to -1.
  // The new object has an id which is one less than the id of the last row in the previous rows.
  const handleAddRow = () => {
    setNewRows((prevRows) => [
      ...prevRows,
      {
        id: prevRows.length ? prevRows[prevRows.length - 1].id - 1 : -1,
        name: "",
      },
    ]);
  };

  const handleDropItem = useCallback(
    (
      draggedItem: {
        previousTargetId?: number;
        sourceId: number;
      },
      targetId?: number,
    ) => {
      type AssignedType = { [key: string]: { id: number; name: string }[] };

      // Function to update the assigned items of a target
      const updateAssignedItems = (
        assigned: AssignedType | undefined,
        shouldRemove: boolean,
      ) => {
        const assignedItems = assigned?.[selectedService] ?? [];
        // Determine the updated items based on whether the item should be removed or added
        const updatedItems = shouldRemove
          ? assignedItems.filter((item) => item.id !== draggedItem.sourceId)
          : oneToMany // Check if oneToMany is true
            ? [
                ...assignedItems,
                {
                  id: draggedItem.sourceId,
                  name: sourceDataMap[draggedItem.sourceId]?.name ?? "",
                }, // Add the dragged item
              ]
            : assignedItems.length > 0 // Check if there is already an item in the target
              ? assignedItems // If there is already an item in the target, return the existing assigned items
              : [
                  {
                    id: draggedItem.sourceId,
                    name: sourceDataMap[draggedItem.sourceId]?.name ?? "",
                  }, // Add the dragged item
                ];

        return { ...assigned, [selectedService]: updatedItems };
      };

      // Function to update the new rows when new row item is dragged in or out
      const updateNewRows = (
        targetId: number | undefined,
        draggedItem: { previousTargetId?: number; sourceId: number },
      ) => {
        setNewRows((prevRows) =>
          prevRows.map((row) => {
            const shouldRemove =
              // If the item was not dropped on a target and not dragged from a target
              (typeof targetId === "undefined" &&
                typeof draggedItem.previousTargetId === "undefined") ||
              // If the item was dropped on a target but not dragged from a target
              (typeof targetId !== "undefined" &&
                targetId > 0 &&
                typeof draggedItem.previousTargetId === "undefined") ||
              // If the item was already assigned to the selected service
              (row.assigned?.[selectedService]?.some(
                (item) => item.id === draggedItem.sourceId,
              ) ??
                false);

            // Determine if the row should be updated based on whether the item was dropped on this row or should be removed
            const shouldUpdate = row.id === targetId || shouldRemove;

            // If the item was dropped on this row or removed from it, update the assigned items
            return shouldUpdate
              ? {
                  ...row,
                  assigned: updateAssignedItems(row.assigned, shouldRemove),
                }
              : row; // Otherwise, return the row as is
          }),
        );
      };

      // Function to update the target data
      const updateTargetData = (
        targetId: number | undefined,
        draggedItem: { previousTargetId?: number; sourceId: number },
      ) => {
        setTargetData((targetData) =>
          targetData.map((target) => {
            // Check if the item was dragged from this target
            const shouldRemove = target.id === draggedItem.previousTargetId;
            // Check if the item was dropped on this target or removed from it
            const shouldUpdate = target.id === targetId || shouldRemove;

            // If the item was dropped on this target or removed from it, update the assigned items
            return shouldUpdate
              ? {
                  ...target,
                  assigned: updateAssignedItems(target.assigned, shouldRemove),
                }
              : target; // Otherwise, return the target as is
          }),
        );
      };

      // Determine the target where the item was dropped
      // If targetId is undefined or less than 0, set foundTarget to null
      // Otherwise, find the target in targetDataMap using targetId
      const foundTarget =
        typeof targetId === "undefined" || targetId < 0
          ? null
          : targetDataMap[targetId];

      // If the item was not dropped on a target (targetId is undefined)
      // and the item was not dragged from a target (draggedItem.previousTargetId is undefined)
      // then update the new rows
      if (
        typeof targetId === "undefined" &&
        typeof draggedItem.previousTargetId === "undefined"
      ) {
        updateNewRows(targetId, draggedItem);
      } else if (
        // If the target is empty (foundTarget is null or has no assigned items)
        // or the target allows multiple items (oneToMany is true)
        // and the item was not dragged from this target (targetId is not equal to draggedItem.previousTargetId)

        (foundTarget === null ||
          (foundTarget.assigned[selectedService] ?? []).length <= 0 ||
          oneToMany) &&
        targetId !== draggedItem.previousTargetId
      ) {
        // Check if the target is a new row (targetId is not null and less than 0)
        if (targetId != null && targetId < 0) {
          // If the target is a new row, update the new rows with the dragged item
          updateNewRows(targetId, draggedItem);

          // If the dragged item was previously assigned to a target, update the target data
          if (draggedItem.previousTargetId) {
            updateTargetData(draggedItem.previousTargetId, draggedItem);
          }
        } else {
          // If the target is not a new row, update the target data

          // If the dragged item was not previously assigned to a target, update the new rows
          if (typeof draggedItem.previousTargetId === "undefined") {
            updateNewRows(targetId, draggedItem);
          }

          // Update the target data with the dragged item
          updateTargetData(targetId, draggedItem);
        }
      }
    },
    [targetDataMap, selectedService, oneToMany, sourceDataMap],
  );

  const handleReleaseLink = useCallback(
    (sourceId: number) => {
      setTargetData((targetData) =>
        targetData.map((target) => ({
          ...target,
          assigned: {
            ...target.assigned,
            [selectedService]: (target.assigned[selectedService] ?? []).filter(
              (assignedItem) => assignedItem.id !== sourceId,
            ),
          },
        })),
      );
      setNewRows((newRows) =>
        newRows.map((row) => ({
          ...row,
          assigned: {
            ...row.assigned,
            [selectedService]: (row.assigned?.[selectedService] ?? []).filter(
              (assignedItem) => assignedItem.id !== sourceId,
            ),
          },
        })),
      );
    },
    [selectedService],
  );

  const handleResetClick = useCallback(() => {
    setTargetData(mappingData.target);
    setNewRows([{ id: -1, name: "New" }]);
  }, [mappingData.target, setTargetData]);

  const handleKeepChange = useCallback(async () => {
    // Map over the newRows array and ensure each row has an 'assigned' property
    // Cast each row to the TargetMappingData<TData> type
    const mappedNewRows = newRows.map(
      (row) =>
        ({ ...row, assigned: row.assigned ?? {} }) as TargetMappingData<TData>,
    );

    // If showNewRows is true, combine the targetData and mappedNewRows arrays
    // Otherwise, use the targetData array as is
    const combinedData = showNewRows
      ? [...targetData, ...mappedNewRows]
      : targetData;

    await handleSubmit(combinedData);

    // Reset the newRows state to its initial value
    setNewRows([{ id: -1, name: "New" }]);
  }, [showNewRows, targetData, newRows, handleSubmit]);

  const [unChanged, setUnChanged] = useState(false);
  useEffect(() => {
    // Create a new array that includes all items from targetData and, if any newRows have an 'assigned' property, all newRows
    const combinedData = [
      ...targetData,
      ...(newRows.some((row) => "assigned" in row)
        ? newRows.map((row) => ({ ...row, assigned: row.assigned ?? {} }))
        : []),
    ];

    // For each row in the combined data, get the IDs of the assigned items for the selected service and sort them
    const sortedAssignedIds = combinedData.map((row) =>
      (row.assigned[selectedService] ?? [])
        .map((assignedItem) => assignedItem.id)
        .sort(),
    );

    // Do the same for the original mapping data
    const originalSortedAssignedIds = mappingData.target.map((target) =>
      (target.assigned[selectedService] ?? [])
        .map((assignedItem) => assignedItem.id)
        .sort(),
    );

    // Compare the two arrays. If they are the same, setUnChanged will be set to true. If they are different, setUnChanged will be set to false.
    setUnChanged(
      JSON.stringify(sortedAssignedIds) ===
        JSON.stringify(originalSortedAssignedIds),
    );
  }, [targetData, mappingData, setUnChanged, selectedService, newRows]); // Dependencies for the useEffect hook

  const [searchValue, setSearchValue] = useState("");
  const onSetSearchValue = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(ev.currentTarget.value);
    },
    [],
  );

  return (
    <>
      <h1>{title}</h1>
      <Content>
        <Row>
          <Col md={8}>
            <Row>
              <Col className="d-flex align-items-end">
                <h3>{targetTitle}</h3>
              </Col>
              <Col>
                {providerOptions.length > 0 ? (
                  <Form.Group controlId="form-provider" className="mb-2">
                    <Form.Label>Data Provider</Form.Label>
                    <Form.Select
                      value={selectedService}
                      onChange={(ev) => {
                        setSelectedService(ev.currentTarget.value);
                        onSetService(ev.currentTarget.value);
                      }}
                    >
                      {providerOptions.map((providerOption) => (
                        <option key={providerOption} value={providerOption}>
                          {capitalize(providerOption)}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                ) : typeof mappedTitle === "undefined" ? null : (
                  <h3>{mappedTitle}</h3>
                )}
              </Col>
            </Row>
            <Row>
              <Col xl={6}>
                <InputGroup size="sm" className="mb-2">
                  <InputGroup.Text>Search</InputGroup.Text>
                  <Form.Control type="text" onChange={onSetSearchValue} />
                </InputGroup>
              </Col>
            </Row>
            {isLoading ? (
              <Loading />
            ) : isError ? (
              <Alert variant="danger">Error occurred</Alert>
            ) : targetData.length <= 0 && !shouldShowNewRows ? (
              <Alert>No data</Alert>
            ) : (
              <Row>
                <Col>
                  <div className="mapping-table-box border border-secondary rounded mb-3">
                    <Table className="mb-0">
                      <tbody>
                        {shouldShowNewRows
                          ? newRows.map((row, index) => {
                              // Check if the current row is the last one
                              const isLastRow = newRows.length - 1 === index;
                              // Check if there are multiple rows
                              const hasMultipleRows = newRows.length > 1;

                              return (
                                <tr key={row.id}>
                                  <td className="w-50">New</td>
                                  <td className="w-50">
                                    <DropBox
                                      handleDropItem={handleDropItem}
                                      targetId={row.id}
                                    >
                                      {(row.assigned?.[selectedService] ?? [])
                                        .length === 0 ? (
                                        <div className="text-secondary ps-3">
                                          Empty
                                        </div>
                                      ) : (
                                        row.assigned?.[selectedService].map(
                                          (item, index) => (
                                            <DraggableText
                                              key={item.id}
                                              sourceId={item.id}
                                              name={item.name}
                                              handleReleaseLink={
                                                handleReleaseLink
                                              }
                                            />
                                          ),
                                        )
                                      )}
                                    </DropBox>
                                  </td>
                                  <td>
                                    <ButtonGroup>
                                      {isLastRow && (
                                        <Button onClick={handleAddRow}>
                                          +
                                        </Button>
                                      )}
                                      {hasMultipleRows && (
                                        <Button
                                          onClick={() =>
                                            setNewRows((prevRows) =>
                                              prevRows.filter(
                                                (r) => r.id !== row.id,
                                              ),
                                            )
                                          }
                                        >
                                          -
                                        </Button>
                                      )}
                                    </ButtonGroup>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                        {targetData
                          .filter(
                            (target) =>
                              target.name
                                .toUpperCase()
                                .includes(searchValue.toUpperCase()) ||
                              (target.assigned[selectedService] ?? []).some(
                                (assigned) =>
                                  (assigned.name ?? "")
                                    .toUpperCase()
                                    .includes(searchValue.toUpperCase()),
                              ),
                          )
                          .map((target) => (
                            <tr key={target.id}>
                              <td className="w-50">
                                {typeof linkHeader !== "undefined" ? (
                                  <Link
                                    to={`${linkHeader}${target.id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {target.name}
                                  </Link>
                                ) : (
                                  target.name
                                )}
                              </td>
                              <td className="w-50">
                                <DropBox
                                  handleDropItem={handleDropItem}
                                  targetId={target.id}
                                >
                                  {(target.assigned[selectedService] ?? [])
                                    .length === 0 ? (
                                    <div className="text-secondary ps-3">
                                      Empty
                                    </div>
                                  ) : (
                                    (
                                      target.assigned[selectedService] ?? []
                                    ).map((assignedItem) => (
                                      <DraggableText
                                        key={assignedItem.id}
                                        previousTargetId={target.id}
                                        sourceId={assignedItem.id}
                                        name={
                                          sourceDataMap[assignedItem.id]
                                            ?.name ?? ""
                                        }
                                        handleReleaseLink={handleReleaseLink}
                                      />
                                    ))
                                  )}
                                </DropBox>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col md={4}>
            <h3>{sourceTitle}</h3>
            {isLoading ? (
              <Loading />
            ) : isError ? (
              <Alert variant="danger">Error occurred</Alert>
            ) : selectedService === "" ||
              (mappingData.source[selectedService]?.length ?? 0) <= 0 ? (
              <Alert>No {sourceTitle} data</Alert>
            ) : (
              <div className="mapping-table-box border rounded p-3 d-flex align-items-stretch">
                <DropBox handleDropItem={handleDropItem}>
                  {unlinkedData.map((data) => (
                    <DraggableText
                      key={data.id}
                      sourceId={data.id}
                      name={data.name}
                    />
                  ))}
                </DropBox>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <ButtonToolbar>
              <SubmitButton
                className="me-2"
                disabled={isLoading || unChanged}
                isSubmitting={isSubmitting}
                onClick={handleKeepChange}
              />
              <Button
                variant="danger"
                onClick={handleResetClick}
                disabled={isLoading || isSubmitting || unChanged}
              >
                Revert
              </Button>
            </ButtonToolbar>
            <ButtonToolbar>
              {additionalButtons}
              <SubmitButton
                onClick={onAutoSuggest}
                disabled={isLoading || isSubmitting}
                isSubmitting={isLoadingSuggestions}
                label="Auto-Match"
                className="ms-2"
              />
            </ButtonToolbar>
          </Col>
        </Row>
      </Content>
    </>
  );
}

export default DataMappingModal;
