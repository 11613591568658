import { useQueryClient } from "@tanstack/react-query";
import { AccountWithMetrics } from "../../../../api/src/accounts/accounts.service";
import type {
  EditableHouseholdTrading,
  Household,
} from "../../../../api/src/households-base/households-base.service";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type {
  HouseholdWithAccounts,
  HouseholdWithMetrics,
} from "../../../../api/src/households/households.service";
import type { SerializedObject, UnpackResponse } from "../../../../api/src/lib";
import type {
  EditableWorkflow,
  Workflow,
} from "../../../../api/src/tasks/tasks.service";
import {
  deserializeDate,
  useAuthenticatedMutationNew,
  useAuthenticatedQuery,
} from "../../lib/api";
import { processFileResponse } from "../../lib/file";
import {
  deserializeEvent,
  deserializeTask,
  deserializeWorkflow,
} from "../../tasks/lib";
import { deserializeAccountWithMetrics } from "../account/lib";
import { deserializeContact } from "../contact/lib";

export function useQueryHouseholds({
  includeBalances = false,
  includeInactive = false,
  includeCRM = false,
  page = 1,
  enabled = true,
} = {}) {
  return useAuthenticatedQuery<
    UnpackResponse<HouseholdsController["getAll"]>,
    { data: HouseholdWithMetrics[]; page: number },
    [
      string,
      {
        includeBalances: boolean;
        includeInactive: boolean;
        includeCRM: boolean;
      },
      number,
    ]
  >({
    queryKey: [
      "households",
      { includeBalances, includeInactive, includeCRM },
      page,
    ],
    queryFn: async (households) => ({
      data: households.data.map(deserializeHouseholdWithMetrics),
      page,
    }),
    urlBuilderFn: (key) =>
      `/${key[0]}?balances=${key[1].includeBalances}&include-inactive=${key[1].includeInactive}&crm=${key[1].includeCRM}&page=${key[2]}`,
    queryOptions: { enabled },
  });
}

export function useQueryHousehold(
  id: number,
  { includeAccounts = false, includeCRM = false, enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<HouseholdsController["getOne"]>,
    HouseholdWithAccounts,
    [
      string,
      number,
      {
        includeAccounts: boolean;
        includeCRM: boolean;
      },
    ]
  >({
    queryKey: ["households", id, { includeAccounts, includeCRM }],
    queryFn: async (household) =>
      deserializeHouseholdWithMetrics(household.data),
    urlBuilderFn: (key) =>
      `/${key[0]}/${key[1]}/?accounts=${key[2].includeAccounts}&crm=${key[2].includeCRM}`,
    queryOptions: { enabled },
  });
}

export function useQueryHouseholdAccounts(
  id: number,
  { includeBalances = false, enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<HouseholdsController["getHouseholdAccounts"]>,
    AccountWithMetrics[],
    [
      string,
      number,
      string,
      {
        includeBalances: boolean;
      },
    ]
  >({
    queryKey: ["households", id, "accounts", { includeBalances }],
    queryFn: async (account) => account.data.map(deserializeAccountWithMetrics),
    urlBuilderFn: (key) =>
      `/${key[0]}/${key[1]}/${key[2]}/?balances=${key[3].includeBalances}`,
    queryOptions: { enabled },
  });
}

export function useUpdateHouseholdModelTrading(householdId: number) {
  const queryClient = useQueryClient();

  return useAuthenticatedMutationNew<
    UnpackResponse<HouseholdsController["updateTradingSettings"]>,
    Household,
    EditableHouseholdTrading
  >({
    mutationKey: ["PUT", "households", householdId, "trading"],
    mutationFn: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ["households", householdId, { includeCRM: true }],
      });
      queryClient.invalidateQueries({
        queryKey: [`/households/${householdId}/allocation-details`],
      });

      return deserializeHouseholdWithMetrics(data);
    },
  });
}

export function useCreateHouseholdWorkflow(householdId: number) {
  return useAuthenticatedMutationNew<
    UnpackResponse<HouseholdsController["createWorkflow"]>,
    Workflow | null,
    EditableWorkflow
  >({
    mutationKey: ["POST", "households", householdId, "workflows"],
    mutationFn: ({ data }) =>
      data === null ? null : deserializeWorkflow(data),
  });
}

export function displayHouseholdStatus(household: Pick<Household, "isActive">) {
  return household.isActive ? "Active" : "Inactive";
}

export function deserializeHouseholdWithMetrics<
  THousehold extends HouseholdWithAccounts,
>(household: SerializedObject<THousehold>): THousehold {
  return {
    ...household,
    accounts: household.accounts?.map(deserializeAccountWithMetrics),
    rebalancePeriodStartDate: deserializeDate(
      household.rebalancePeriodStartDate,
    ),
    lastRebalanceDate: deserializeDate(household.lastRebalanceDate),
    latestRebalanceDate: deserializeDate(household.latestRebalanceDate),
    memberDates: household.memberDates.map((member) => ({
      ...member,
      anniversary: deserializeDate(member.anniversary),
      birthDate: deserializeDate(member.birthDate),
      clientSinceDate: deserializeDate(member.clientSinceDate),
      retirementDate: deserializeDate(member.retirementDate),
    })),
    members: household.members.map(deserializeContact),
    events: household.events.map(deserializeEvent),
    tasks: household.tasks.map(deserializeTask),
    workflows: household.workflows.map(deserializeWorkflow),
    archiveDate: deserializeDate(household.archiveDate),
  } as THousehold;
}

export function useQueryExportHouseholds() {
  return useAuthenticatedQuery<
    UnpackResponse<HouseholdsController["exportHouseholds"]>,
    void,
    [string, string]
  >({
    queryKey: ["households", "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}
