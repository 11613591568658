import { ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { InsightsController } from "../../../api/src/insights/insights.controller";
import type { UnpackResponse } from "../../../api/src/lib";
import { useAuthenticatedFetch } from "../lib/api";
import Loading from "../Loading";

const CashActivity = () => {
  const {
    isPending: isPendingAccountCashActivity,
    data: dataAccountCashActivity,
  } = useAuthenticatedFetch<UnpackResponse<InsightsController["cashActivity"]>>(
    "/insights/cash-activity",
  );
  const accountCashActivity = dataAccountCashActivity?.data;

  return isPendingAccountCashActivity ? (
    <ListGroupItem>
      <Loading />
    </ListGroupItem>
  ) : !accountCashActivity ? null : (
    <ListGroupItem>
      {accountCashActivity.accountsWithCashActivity.length <= 0 ? (
        "0"
      ) : (
        <Link
          to={`/clients/accounts?${accountCashActivity.accountsWithCashActivity
            .map((id) => `id=${id}`)
            .join("&")}`}
        >
          {accountCashActivity.accountsWithCashActivity.length} Accounts
        </Link>
      )}{" "}
      received cash deposits over the past 5 days.
    </ListGroupItem>
  );
};

export default CashActivity;
