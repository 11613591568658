import { Spinner as BsSpinner, SpinnerProps } from "react-bootstrap";

const Spinner = ({
  animation = "border",
  role = "status",
  ...props
}: Omit<SpinnerProps, "animation" | "role"> &
  Partial<Pick<SpinnerProps, "animation" | "role">>) => (
  <BsSpinner animation={animation} role={role} {...props}>
    <span className="visually-hidden">Loading...</span>
  </BsSpinner>
);

export default Spinner;
