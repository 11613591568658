import { createColumnHelper } from "@tanstack/react-table";
import _, { groupBy } from "lodash";
import { useMemo } from "react";
import { Alert } from "react-bootstrap";
import type { AccountHoldingResponse } from "../../../../api/src/accounts/accounts.service";
import type { AccountHoldingWithSecurity } from "../../../../api/src/positions/positions.service";
import type { Transaction } from "../../../../api/src/transactions/lib";
import SecurityLink from "../../components/SecurityLink";
import { Table, useTable } from "../../components/Table/Table";
import { capitalize } from "../../lib/display";
import { formatCurrencyComponent, formatPercent } from "../../lib/numbers";
import { CASH_SYMBOLS } from "../../lib/security";

type RiskAllocationCategory = "growth" | "defensive" | "cash";

type AccountHoldingTotal = Required<
  Pick<
    AccountHoldingResponse,
    "value" | "realizedGainLoss" | "unrealizedGainLoss"
  >
> & {
  units: number;
  appraisedUnitPrice: number;
  weight: number;
  modelWeight: number;
  modelWeightDifference: number;
  income: number;
};

const totalKeys: (keyof AccountHoldingTotal)[] = [
  "units",
  "appraisedUnitPrice",
  "value",
  "unrealizedGainLoss",
  "realizedGainLoss",
  "weight",
  "modelWeight",
  "modelWeightDifference",
  "income",
];
export type HoldingsFieldMode =
  | "units"
  | "appraisedUnitPrice"
  | "value"
  | "unrealizedGainLoss"
  | "realizedGainLoss"
  | "weight"
  | "modelWeight"
  | "modelWeightDifference"
  | "income";

const holdingsFieldModeMap: Record<HoldingsFieldMode, string> = {
  units: "Amount",
  appraisedUnitPrice: "Unit Price",
  value: "Value",
  unrealizedGainLoss: "Unrealized Gain/Loss",
  realizedGainLoss: "Realized Gain/Loss",
  weight: "Weight",
  modelWeight: "Model Weight",
  modelWeightDifference: "Model Weight Difference",
  income: "Income",
};

type AccountHoldingRow = {
  securityId: number;
  riskAllocation: RiskAllocationCategory;
  security: AccountHoldingWithSecurity["security"];
  totals: AccountHoldingTotal;
  accounts: Record<number, AccountHoldingTotal>;
  // appraisedUnitPrice: number;
  // units: number;
  // value: number;
  // unrealizedGainLoss: number;
};

// type AccountHoldingRow = Pick<
//   AccountHoldingWithSecurity,
//   | "id"
//   | "appraisedUnitPrice"
//   | "units"
//   | "value"
//   | "security"
//   | "unrealizedGainLoss"
//   | "accountId"
// >;

function getTableValue(fieldMode: HoldingsFieldMode, value: number) {
  return value === 0
    ? "–"
    : fieldMode === "weight" ||
        fieldMode === "modelWeight" ||
        fieldMode === "modelWeightDifference"
      ? formatPercent(value, 2)
      : fieldMode === "units"
        ? value.toLocaleString()
        : formatCurrencyComponent(value, 2);
}

const AccountHoldingsTable = ({
  holdings,
  transactions,
  modelSummaries,
}: {
  holdings: AccountHoldingWithSecurity[];
  transactions: Transaction[];
  modelSummaries: {
    accountId: number;
    model: Record<string, number>;
  }[];
}) => {
  const columnHelper = useMemo(
    () => createColumnHelper<AccountHoldingRow>(),
    [],
  );

  const flatColumns = useMemo(
    () => [
      columnHelper.accessor((row) => row.security?.identifier ?? "", {
        id: "symbol",
        header: () => "Symbol",
        cell: (info) => (
          <SecurityLink
            symbol={info.getValue()}
            description={info.row.original.security?.description}
          />
        ),
        minSize: 260,
        enableColumnFilter: false,
      }),
      ...totalKeys.map((key) =>
        columnHelper.accessor((row) => row.totals[key], {
          id: key,
          cell: (info) => getTableValue(key, info.getValue()),
          header: () => holdingsFieldModeMap[key],
          aggregatedCell: (info) => (
            <span className="fw-bold">
              {getTableValue(key, info.getValue())}
            </span>
          ),
          minSize: 180,
          size: 180,
          enableColumnFilter: false,
          meta: {
            className: "text-end",
            headerClassName: "text-end",
          },
        }),
      ),
    ],
    [columnHelper],
  );

  function createRowsFromHoldings(
    holdings: AccountHoldingWithSecurity[],
    transactions: Transaction[],
    modelSummaries: {
      accountId: number;
      model: Record<string, number>;
    }[],
  ) {
    const growthHoldings = holdings.filter(
      (holding) =>
        !CASH_SYMBOLS.includes(holding.security?.symbol ?? "") &&
        (holding.security?.riskAllocation.growth ?? 0.5) > 0,
    );
    const defensiveHoldings = holdings.filter(
      (holding) =>
        !CASH_SYMBOLS.includes(holding.security?.symbol ?? "") &&
        (holding.security?.riskAllocation.defensive ?? 0.5) > 0,
    );
    const cashHoldings = holdings.filter((holding) =>
      CASH_SYMBOLS.includes(holding.security?.symbol ?? ""),
    );

    const allHoldings: Record<
      RiskAllocationCategory,
      AccountHoldingWithSecurity[]
    > = {
      growth: growthHoldings,
      defensive: defensiveHoldings,
      cash: cashHoldings,
    };

    const transactionsBySecurity = groupBy(transactions, "securityId");
    const modelSummariesMap = _.keyBy(modelSummaries, "accountId");

    const totalValue = holdings.reduce(
      (sum, holding) => sum + holding.value,
      0,
    );

    return Object.entries(allHoldings).flatMap(
      ([riskAllocationCategory, riskAllocationHoldings]) => {
        const holdingsBySecurity = groupBy(
          riskAllocationHoldings,
          "securityId",
        );

        return Object.values(holdingsBySecurity).map(
          (securityHoldings): AccountHoldingRow => {
            const security = securityHoldings[0].security;
            const riskAllocationRatio =
              riskAllocationCategory === "cash"
                ? 1
                : riskAllocationCategory === "growth"
                  ? (security?.riskAllocation.growth ?? 0.5)
                  : (security?.riskAllocation.defensive ?? 0.5);
            const securityTransactions =
              transactionsBySecurity[securityHoldings[0].securityId] ?? [];

            const holdingsByAccount = groupBy(securityHoldings, "accountId");
            const transactionsByAccount = groupBy(
              securityTransactions,
              "accountId",
            );

            const holdingsByAccountTotals = Object.entries(
              holdingsByAccount,
            ).reduce<Record<number, AccountHoldingTotal>>(
              (result, [accountId, holdings]) => ({
                ...result,
                [parseInt(accountId)]: holdings.reduce(
                  (sum, holding): AccountHoldingTotal => {
                    const weight =
                      sum.weight +
                      (totalValue === 0 ? 0 : holding.value / totalValue) *
                        riskAllocationRatio;
                    const modelWeight =
                      sum.modelWeight +
                      (typeof security?.symbol === "undefined"
                        ? 0
                        : (modelSummariesMap[accountId]?.model[
                            security.symbol
                          ] ?? 0)) *
                        riskAllocationRatio;

                    return {
                      units: sum.units + holding.units * riskAllocationRatio,
                      appraisedUnitPrice:
                        (sum.appraisedUnitPrice + holding.appraisedUnitPrice) *
                        riskAllocationRatio,
                      value: sum.value + holding.value * riskAllocationRatio,
                      unrealizedGainLoss:
                        sum.unrealizedGainLoss +
                        holding.unrealizedGainLoss * riskAllocationRatio,
                      realizedGainLoss:
                        sum.realizedGainLoss +
                        (holding.realizedGainLoss ?? 0) * riskAllocationRatio,
                      weight,
                      modelWeight: modelWeight,
                      modelWeightDifference: weight - modelWeight,
                      income:
                        sum.income +
                        (transactionsByAccount[accountId] ?? []).reduce(
                          (sum, transaction) => sum + (transaction.amount ?? 0),
                          0,
                        ) *
                          riskAllocationRatio,
                    };
                  },
                  {
                    units: 0,
                    appraisedUnitPrice: 0,
                    value: 0,
                    unrealizedGainLoss: 0,
                    realizedGainLoss: 0,
                    weight: 0,
                    modelWeight: 0,
                    modelWeightDifference: 0,
                    income: 0,
                  },
                ),
              }),
              {},
            );

            return {
              securityId: securityHoldings[0].securityId,
              riskAllocation: riskAllocationCategory as RiskAllocationCategory,
              security,
              totals: Object.values(
                holdingsByAccountTotals,
              ).reduce<AccountHoldingTotal>(
                (sum, accountTotals) => {
                  const weight =
                    sum.weight +
                    (totalValue === 0 ? 0 : accountTotals.value / totalValue);
                  const modelWeight =
                    sum.modelWeight + accountTotals.modelWeight;

                  return {
                    units: sum.units + accountTotals.units,
                    appraisedUnitPrice:
                      sum.appraisedUnitPrice + accountTotals.appraisedUnitPrice,
                    value: sum.value + accountTotals.value,
                    unrealizedGainLoss:
                      sum.unrealizedGainLoss + accountTotals.unrealizedGainLoss,
                    realizedGainLoss:
                      sum.realizedGainLoss + accountTotals.realizedGainLoss,
                    weight,
                    modelWeight,
                    modelWeightDifference: weight - modelWeight,
                    income: sum.income + accountTotals.income,
                  };
                },
                {
                  units: 0,
                  appraisedUnitPrice: 0,
                  value: 0,
                  unrealizedGainLoss: 0,
                  realizedGainLoss: 0,
                  weight: 0,
                  modelWeight: 0,
                  modelWeightDifference: 0,
                  income: 0,
                },
              ),
              accounts: holdingsByAccountTotals,
            };
          },
        );
      },
    );
  }

  const accountsWithHoldings = useMemo(
    () => createRowsFromHoldings(holdings, transactions, modelSummaries),
    [holdings, modelSummaries, transactions],
  );

  const groupedColumns = useMemo(
    () => [
      columnHelper.group({
        id: "category",
        header: "Category",
        columns: [
          columnHelper.accessor((row) => row.riskAllocation, {
            id: "riskAllocation",
            header: "Risk Category",
            cell: (info) => (
              <span className="fw-bold">{capitalize(info.getValue())}</span>
            ),
            minSize: 180,
            size: 180,
            enableColumnFilter: false,
            meta: {
              className: "position-sticky border-end",
              headerClassName: "position-sticky z-1",
              headerStyle: { left: 0 },
              style: { left: 0 },
            },
          }),
        ],
      }),
      ...flatColumns,
    ],
    [columnHelper, flatColumns],
  );
  const { table } = useTable({
    columns: groupedColumns,
    data: accountsWithHoldings,
    initialState: {
      sorting: [{ id: "value", desc: true }],
      grouping: ["riskAllocation"],
      pagination: {
        pageSize: 9999,
      },
    },
    manualPagination: true,
    getRowId: (row) => `${row.riskAllocation}-${row.securityId}`,
  });

  return (
    <>
      <Table table={table} />
      {holdings.length > 0 ? null : <Alert>No holdings found</Alert>}
    </>
  );
};

export default AccountHoldingsTable;
