import React from "react";
import { Button, Image } from "react-bootstrap";
import IconBox from "./IconBox";

const ActionButton = ({
  label,
  icon,
  variant = "primary",
  as,
  to,
  type,
  disabled,
  onClick,
  className = "",
}: {
  label: string;
  as?: any;
  to?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
  className?: string;
} & (
  | { variant?: "primary" | "secondary"; icon?: string }
  | { variant: "icon"; icon: string }
)) => {
  return (
    <Button
      className={`btn-action ${
        variant === "icon" ? "btn-icon" : ""
      } ${className}`}
      as={as}
      to={to}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {variant === "icon" ? (
        <Image src={icon} alt={label} height={18} className="svg-light" />
      ) : (
        <span className="me-1">{label}</span>
      )}
      {!icon || variant === "icon" ? null : <IconBox src={icon} alt={label} />}
    </Button>
  );
};

export default ActionButton;
