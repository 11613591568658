import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import SubmitButton from "../../components/SubmitButton";
import {
  processEmptyResponse,
  useAuthenticatedMutationAsync,
} from "../../lib/api";

const AccountArchiveDialog = ({
  showModal,
  setShowModal,
  archiveAccountId,
  archiveAccountName,
  title,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  archiveAccountId: number;
  archiveAccountName: string;
  title: string;
}) => {
  const handleModalClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const [archiveEnabled, setArchiveEnabled] = useState(false);

  const checkArchiveEnabled = (value: string) => {
    if (value === archiveAccountName) {
      setArchiveEnabled(true);
    } else {
      setArchiveEnabled(false);
    }
  };

  const archiveAccount = useAuthenticatedMutationAsync<
    UnpackResponse<AccountsController["archiveAccount"]>
  >(
    `/accounts/${archiveAccountId}/archive`,
    async () => ({
      method: "POST",
    }),
    processEmptyResponse,
  );
  const queryClient = useQueryClient();

  const handleArchiveAccount = useMutation({
    mutationFn: () => archiveAccount(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/accounts/${archiveAccountId}?balances=true`],
      });
      setShowModal(false);
    },
  });

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Archive Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Are you sure you would like to archive {archiveAccountName}?
        </Form.Label>
        <Form.Control
          style={{ width: "90%" }}
          type="text"
          placeholder="Enter account name"
          onChange={(e) => checkArchiveEnabled(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Discard
        </Button>
        <SubmitButton
          variant="danger"
          label="Confirm"
          disabled={!archiveEnabled}
          onClick={() => handleArchiveAccount.mutate()}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default AccountArchiveDialog;
