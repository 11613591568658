import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const BillingNav = () => (
  <nav>
    <Nav variant="tabs" defaultActiveKey="/billing/summary">
      <Nav.Item>
        <NavLink to="/billing/summary" className="nav-link">
          Overview
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/billing/reports" className="nav-link">
          Billing Reports
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/billing/fee-structure" className="nav-link">
          Fee Structures
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/billing/asset-exclusion" className="nav-link">
          Asset Exclusion
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/billing/billing-minimums" className="nav-link">
          Minimums
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/billing/billing-splits" className="nav-link">
          Fee Splits
        </NavLink>
      </Nav.Item>
    </Nav>
  </nav>
);

export default BillingNav;
