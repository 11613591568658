import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { Outlet, useParams } from "react-router-dom";
import type {
  AccountWithMetrics,
  EditableAccount,
} from "../../../../api/src/accounts/accounts.service";
import Loading from "../../Loading";
import FormError from "../../components/FormError";
import TabContainerWithTabs from "../../components/TabContainer";
import { schema as billingSchema } from "./AccountBillingInfo";
import AccountHeader from "./AccountHeader";
import { schema as tradingSchema } from "./AccountModelTrading";
import AccountNav from "./AccountNav";
import { schema as profileSchema } from "./AccountProfile";
import { useQueryAccount } from "./lib";

export const AccountContext = React.createContext<{
  account?: AccountWithMetrics;
}>({});

const AccountInfo = () => {
  const form = useForm<EditableAccount>({
    mode: "onBlur",
    resolver: yupResolver(
      billingSchema.concat(tradingSchema).concat(profileSchema),
    ),
  });

  const { accountId } = useParams();
  const {
    isPending: isPendingAccount,
    isError: isErrorAccount,
    data: account,
  } = useQueryAccount(parseInt(accountId ?? ""), {
    includeBalances: true,
  });

  return isPendingAccount ? (
    <Loading />
  ) : isErrorAccount ? (
    <FormError message="Failed to load account" />
  ) : typeof account === "undefined" ? (
    <FormError message="Account not found" />
  ) : (
    <AccountContext.Provider value={{ account }}>
      <AccountHeader />
      <FormProvider {...form}>
        <Row>
          <Col>
            <TabContainerWithTabs tabs={AccountNav}>
              <Col>
                <Outlet />
              </Col>
            </TabContainerWithTabs>
          </Col>
        </Row>
      </FormProvider>
    </AccountContext.Provider>
  );
};

export default AccountInfo;
