import { useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import SubmitButton from "../components/SubmitButton";
import { serviceToDisplayName } from "../lib/display";

const IntegrationDisconnectConfirmation = ({
  show,
  onConfirm,
  onHide,
  serviceName,
  isSubmitting = false,
}: {
  show: boolean;
  onConfirm: () => void | Promise<void>;
  onHide: () => void;
  serviceName: string;
  isSubmitting?: boolean;
}) => {
  const onConfirmCombined = useCallback(async () => {
    await Promise.resolve(onConfirm());
    onHide();
  }, [onConfirm, onHide]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton={!isSubmitting}>
        <Modal.Title>Disconnect Integration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you would like to disconnect{" "}
        {serviceToDisplayName(serviceName)}? You will no longer be able to view
        or modify data from this service.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={isSubmitting}>
          Cancel
        </Button>
        <SubmitButton
          label="Confirm"
          variant="danger"
          onClick={onConfirmCombined}
          isSubmitting={isSubmitting}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default IntegrationDisconnectConfirmation;
