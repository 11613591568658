import { useMutation } from "@tanstack/react-query";
import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { RebalancesController } from "../../../api/src/rebalances/rebalances.controller";
import type {
  RebalanceAccount,
  RebalanceMetrics,
} from "../../../api/src/rebalances/rebalances.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import SubmitButton from "../components/SubmitButton";
import { processEmptyResponse, useAuthenticatedMutation } from "../lib/api";
import RebalanceAccountInfo, { AccountRequest } from "./RebalanceAccountInfo";
import { RebalanceContext } from "./RebalanceInfo";

const RebalanceTrades = () => {
  const { rebalance, household } = useContext(RebalanceContext);
  const { rebalanceId } = useParams();

  const [rebalanceEditable, setRebalanceEditable] = useState<{
    accounts: AccountRequest[];
  }>({
    accounts: [],
  });

  useEffect(() => {
    if (typeof rebalance !== "undefined") {
      const accountsWithMetrics = rebalance.accounts as (RebalanceAccount &
        Required<RebalanceMetrics>)[];

      setRebalanceEditable({ accounts: accountsWithMetrics });
    }
  }, [rebalance]);

  const setAccount = useCallback(
    (updatedAccount: AccountRequest) => {
      const index = rebalanceEditable.accounts.findIndex(
        (account) => account.id === updatedAccount.id,
      );

      rebalanceEditable.accounts[index] = updatedAccount;

      setRebalanceEditable({
        ...rebalanceEditable,
        accounts: rebalanceEditable.accounts,
      });
    },
    [rebalanceEditable],
  );

  const saveRebalance = useAuthenticatedMutation<
    UnpackResponse<RebalancesController["updateRebalance"]>
  >(
    `/rebalances/${rebalanceId}`,
    {
      method: "PUT",
      body: JSON.stringify(rebalance),
    },
    processEmptyResponse,
  );

  const notificationContext = useContext(NotificationContext);

  const save = useMutation({
    mutationFn: async (event: FormEvent) => {
      event.preventDefault();

      await saveRebalance();
      notificationContext.pushNotification({
        id: `rebalance-${rebalanceId}`,
        header: "Rebalance Updated",
        body: `Rebalance ${rebalanceId} updated`,
        variant: "success",
      });
    },
  });

  return typeof rebalance === "undefined" ? (
    <Loading />
  ) : rebalance?.status === "requested" ? (
    <Alert>Rebalance is being calculated...</Alert>
  ) : (
    <Form onSubmit={save.mutate}>
      <Row>
        <Col>
          <h4 className="mt-2">Accounts</h4>
          {rebalanceEditable.accounts.map((rebalanceAccount) => (
            <React.Fragment key={rebalanceAccount.id}>
              <hr />
              <RebalanceAccountInfo
                rebalanceAccount={rebalanceAccount}
                rebalanceType={rebalance.type}
                editable={rebalance.status === "proposed"}
                householdModelId={household?.assignedModelId}
                setAccount={setAccount}
              />
            </React.Fragment>
          ))}
        </Col>
      </Row>
      <Row>
        <Col>
          <SubmitButton isSubmitting={save.isPending} />
        </Col>
      </Row>
    </Form>
  );
};

export default RebalanceTrades;
