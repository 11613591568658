import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { RebalancesController } from "../../../api/src/rebalances/rebalances.controller";
import Loading from "../Loading";
import TabContainerWithTabs from "../components/TabContainer";
import { RowExpander } from "../components/Table/RowExpander";
import { Table, metaDefault, useTable } from "../components/Table/Table";
import { multiSelectIncludes } from "../components/Table/filters";
import { useAuthenticatedFetch } from "../lib/api";
import { formatCurrency } from "../lib/numbers";
import RebalanceActionButtons from "./RebalanceActionButtons";
import RebalanceListSummary from "./RebalanceListSummary";
import RebalancesNav from "./RebalancesNav";
import {
  RebalanceReasonFilter,
  RebalanceRow,
  getRiskAllocationDisplay,
  mapRebalancesToRebalanceRows,
  rebalanceTypeDisplay,
  rebalanceTypeFilterOptions,
} from "./lib";

const columnHelper = createColumnHelper<RebalanceRow>();

const RebalancesFuture = () => {
  const { isPending: isPendingRebalances, data: dataRebalances } =
    useAuthenticatedFetch<
      UnpackResponse<RebalancesController["getAllFutureTrades"]>
    >("/rebalances/future?metrics=true");
  const rebalances = dataRebalances?.data;

  const rebalanceRows = useMemo(
    () => mapRebalancesToRebalanceRows(rebalances),
    [rebalances],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => (
          <div
            style={{
              // Since rows are flattened by default,
              // we can use the row.depth property
              // and paddingLeft to visually indicate the depth
              // of the row
              paddingLeft: `${info.row.depth * 2}rem`,
            }}
          >
            {info.getValue()}
          </div>
        ),
        aggregatedCell: (info) => (
          <>
            {info.row.getCanExpand() ? <RowExpander row={info.row} /> : ""}{" "}
            <Link to={`/rebalances/${info.row.original.rebalanceId}`}>
              {info.getValue()}
            </Link>
          </>
        ),
        header: () => "Household",
        minSize: 225,
      }),
      columnHelper.accessor(
        (row: RebalanceRow) => {
          if (row.level === "account" || typeof row.type === "undefined") {
            return "";
          } else if (
            row.type &&
            Object.prototype.hasOwnProperty.call(rebalanceTypeDisplay, row.type)
          ) {
            const option = rebalanceTypeFilterOptions.find(
              (a) =>
                a.label ===
                rebalanceTypeDisplay[
                  row.type as keyof typeof rebalanceTypeDisplay
                ],
            );
            return option?.value.toString() ?? "";
          }
          return "";
        },
        {
          id: "type",
          header: () => "Rebalance Reason",
          cell: (info) => {
            const option = rebalanceTypeFilterOptions.find(
              (a) => a.value.toString() === info.getValue(),
            );
            return option?.label ?? "";
          },
          aggregatedCell: (info) => {
            const option = rebalanceTypeFilterOptions.find(
              (a) => a.value.toString() === info.getValue(),
            );
            return option?.label ?? "";
          },
          filterFn: multiSelectIncludes,
          meta: {
            filterComponent: RebalanceReasonFilter,
          },
        },
      ),
      columnHelper.accessor((row) => row.riskAllocation?.target.growth ?? 0, {
        id: "target",
        cell: (info) =>
          getRiskAllocationDisplay(info.row.original.riskAllocation?.target),
        aggregatedCell: (info) =>
          getRiskAllocationDisplay(info.row.original.riskAllocation?.target),
        header: () => "Target Allocation",
        enableColumnFilter: false,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.riskAllocation?.current.growth ?? 0, {
        id: "current",
        cell: (info) =>
          getRiskAllocationDisplay(info.row.original.riskAllocation?.current),
        aggregatedCell: (info) =>
          getRiskAllocationDisplay(info.row.original.riskAllocation?.current),
        header: () => "Current Allocation",
        enableColumnFilter: false,
        enableSorting: false,
      }),
      columnHelper.accessor(
        (row) => row.riskAllocation?.postRebalance.growth ?? 0,
        {
          id: "postRebalance",
          cell: (info) =>
            getRiskAllocationDisplay(
              info.row.original.riskAllocation?.postRebalance,
            ),
          aggregatedCell: (info) =>
            getRiskAllocationDisplay(
              info.row.original.riskAllocation?.postRebalance,
            ),
          header: () => "Post-Rebalance Allocation",
          enableColumnFilter: false,
          enableSorting: false,
        },
      ),
      columnHelper.accessor((row) => row.totalBalance ?? 0, {
        id: "totalBalance",
        cell: (info) => formatCurrency(info.getValue()),
        aggregatedCell: (info) => formatCurrency(info.getValue()),
        header: () => "AUM",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.cashBalance ?? 0, {
        id: "cashBalance",
        cell: (info) => formatCurrency(info.getValue()),
        aggregatedCell: (info) => formatCurrency(info.getValue()),
        header: () => "Cash",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.buyTotal ?? 0, {
        id: "buyTotal",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "Buy",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.sellTotal ?? 0, {
        id: "sellTotal",
        cell: (info) => formatCurrency(info.getValue()),
        aggregatedCell: (info) => formatCurrency(info.getValue()),
        header: () => "Sell",
        enableColumnFilter: false,
      }),
      columnHelper.accessor(
        (row) => (row.buyTotal ?? 0) - (row.sellTotal ?? 0),
        {
          id: "net",
          cell: (info) => formatCurrency(info.getValue()),
          aggregatedCell: (info) => formatCurrency(info.getValue()),
          header: () => "Net",
          enableColumnFilter: false,
        },
      ),
      columnHelper.accessor(
        (row) => (row.realizedGainLossSt ?? 0) - (row.realizedGainLossLt ?? 0),
        {
          id: "realizedGainLoss",
          cell: (info) => formatCurrency(info.getValue()),
          aggregatedCell: (info) => formatCurrency(info.getValue()),
          header: () => "Realized Gain/Loss",
          enableColumnFilter: false,
          meta: {
            headerClassName: "text-end",
            className: "text-end",
          },
        },
      ),
    ],
    [],
  );

  const { table } = useTable({
    columns,
    data: rebalanceRows,
    getRowId: (row) => `${row.rebalanceId}-${row.level}-${row.id}`,
    getSubRows: (row) => row.accounts ?? [],
    autoResetPageIndex: true,
    meta: metaDefault,
  });

  return (
    <>
      <Row>
        <Col>
          <RebalanceActionButtons />
        </Col>
      </Row>
      <TabContainerWithTabs tabs={RebalancesNav}>
        {isPendingRebalances ? (
          <Loading />
        ) : (
          <>
            <RebalanceListSummary rebalances={rebalances ?? []} />
            <Table table={table} />
          </>
        )}
      </TabContainerWithTabs>
    </>
  );
};

export default RebalancesFuture;
