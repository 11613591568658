import React, { createContext, useCallback, useState } from "react";
import type { AppTheme } from "../../../api/src/auth0/auth0.service";
import type { BridgeFTPositionDirection } from "../../../api/src/bridgeft/lib";

export const naLabel = <span className="fst-italic">N/A</span>;
export const pendingLabel = <span className="fst-italic">Pending</span>;
export const unassignedLabel = <span className="fst-italic">Unassigned</span>;
export const addRemoveLabel = "Add / Remove";

export const InlineError = ({ children }: { children: string }) => (
  <em className="text-danger">{children}</em>
);

export function boolToYesNo(value: boolean) {
  return value ? "Yes" : "No";
}

export function serviceToDisplayName(serviceName: string) {
  switch (serviceName) {
    case "wealthbox":
      return "Wealthbox";
    case "redtail":
      return "Redtail";
    case "docusign":
      return "DocuSign";
    default:
      return serviceName;
  }
}

export function directionToDisplayName(direction: BridgeFTPositionDirection) {
  switch (direction) {
    case "L":
      return "Long";
    case "S":
      return "Short";
    default:
      return direction;
  }
}

export function formatAddress({
  address1,
  address2,
  address3,
  city,
  state,
  zipCode,
  country,
}: {
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}) {
  const stringAndNewline = (val?: string) =>
    typeof val === "undefined" || val === "" ? "" : val + "\n";

  const streetAddress = `${stringAndNewline(address1)}${stringAndNewline(
    address2,
  )}${stringAndNewline(address3)}`;

  const cityLine = `${typeof city === "undefined" ? "" : city + ", "}${
    typeof state === "undefined" ? "" : state + " "
  }${zipCode ?? ""}`;

  return `${streetAddress === "" ? "" : streetAddress}${
    cityLine === "" ? "" : cityLine + "\n"
  }${country ?? ""}`;
}

export function newlinesToJSX(value: string) {
  const segments = value.split("\n");

  return segments.map((segment, idx) => (
    <React.Fragment key={idx}>
      {segment}
      <br />
    </React.Fragment>
  ));
}

/**
 * Capitalizes each word in a given string.
 * @param str String to be capitalized
 * @return A new capitalized string value
 */
export function capitalize(str: string) {
  return str.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
}

export function setGlobalTheme(
  theme: AppTheme,
  setCurrentTheme: React.Dispatch<React.SetStateAction<"light" | "dark">>,
) {
  if (
    theme === "system" &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    const currentTheme = "dark";
    document.documentElement.setAttribute("data-bs-theme", currentTheme);
    setCurrentTheme(currentTheme);
  } else if (
    theme === "system" &&
    window.matchMedia("(prefers-color-scheme: light)").matches
  ) {
    const currentTheme = "light";
    document.documentElement.setAttribute("data-bs-theme", currentTheme);
    setCurrentTheme(currentTheme);
  } else if (theme === "light" || theme === "dark") {
    document.documentElement.setAttribute("data-bs-theme", theme);
    setCurrentTheme(theme);
  }
}

export const ThemeContext = createContext<{
  theme: AppTheme;
  setTheme: (theme: AppTheme) => void;
  currentTheme: "light" | "dark";
}>({ theme: "system", setTheme: () => null, currentTheme: "light" });

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState<AppTheme>("system");
  const [currentTheme, setCurrentTheme] = useState<"light" | "dark">("light");

  const setThemeCallback = useCallback((theme: AppTheme) => {
    setTheme(theme);
    setGlobalTheme(theme, setCurrentTheme);
  }, []);

  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", () => {
      setGlobalTheme(theme, setCurrentTheme);
    });

  const context = {
    theme,
    setTheme: setThemeCallback,
    currentTheme,
  };

  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  );
};

export function displayAccountName(
  displayName?: string,
  displayNumber?: string,
) {
  return typeof displayName === "undefined" || displayName.trim() === ""
    ? typeof displayNumber === "undefined" || displayNumber.trim() === ""
      ? "Unknown"
      : displayNumber
    : displayName;
}
