import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ModelsController } from "../../../api/src/models/models.controller";
import SubmitButton from "../components/SubmitButton";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../lib/api";

const ModelDeleteDialog = ({
  showModal,
  setShowModal,
  deleteModelId,
  setDeleteModelId,
  selectedModelId,
  setModelId,
  modelOptions,
  title,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteModelId: number;
  setDeleteModelId: React.Dispatch<React.SetStateAction<number>>;
  selectedModelId: number;
  setModelId: React.Dispatch<React.SetStateAction<number>>;
  modelOptions: JSX.Element[];
  title: string;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // get assigned household and accounts count
  const { isLoading: isLoadingSuggestions, data: assignmentCount } =
    useAuthenticatedFetch<
      UnpackResponse<ModelsController["getModelAssignmentsCount"]>
    >(`/models/models/${deleteModelId}/assignments-count`, undefined, {
      enabled: deleteModelId !== 0,
    });

  // Delete action
  const handleModalClose = useCallback(() => {
    setModelId(0);
    setShowModal(false);
  }, [setModelId, setShowModal]);

  // first replace current Model assignment to new for accounts and household
  const replaceAccountHouseholdModel = useAuthenticatedMutationAsync<
    UnpackResponse<ModelsController["replaceModelForAccountsAndHouseholds"]>
  >(
    `/models/models/replace/${deleteModelId}?newModelId=${selectedModelId}`,
    async (newModelId) => ({
      method: "PUT",
    }),
    processEmptyResponse,
  );

  const handleSaveChanges = useMutation({
    mutationFn: () => replaceAccountHouseholdModel(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["/models/models?metrics=true"],
      });
      setShowModal(false);
      setModelId(0);
      navigate("/models");
    },
  });

  return (
    <Modal
      show={showModal && !isLoadingSuggestions}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Delete Model</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {assignmentCount?.data.assignedHouseholdCount === 0 &&
        assignmentCount?.data.assignedAccountCount === 0 ? (
          <Row className="m-2">
            Are you sure you would like to delete this model?
          </Row>
        ) : (
          <>
            <Row className="m-2">
              This model is assigned to{" "}
              {assignmentCount?.data.assignedHouseholdCount} household(s) and{" "}
              {assignmentCount?.data.assignedAccountCount} account(s). Which
              model would you instead like to assign to these household(s) and
              account(s)?
            </Row>
            <Row className="m-2">
              <Form.Select
                placeholder="Select assigned model"
                value={selectedModelId}
                onChange={(e) => setModelId(+e.target.value)}
              >
                {modelOptions}
              </Form.Select>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Discard
        </Button>
        <SubmitButton
          variant="danger"
          label="Delete"
          onClick={() => handleSaveChanges.mutate()}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ModelDeleteDialog;
