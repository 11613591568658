import { useCallback } from "react";
import { Offcanvas } from "react-bootstrap";
import type {
  EditableWorkflow,
  Workflow,
} from "../../../api/src/tasks/tasks.service";
import CreateWorkflow from "./CreateWorkflow";

const CreateWorkflowPane = ({
  show,
  setShow,
  onCreate,
}: {
  show: boolean;
  setShow: (val: boolean) => void;
  onCreate: (data: EditableWorkflow) => Promise<Workflow | null>;
}) => {
  const hidePane = useCallback(() => setShow(false), [setShow]);

  const onCreateInner = useCallback(
    async (data: EditableWorkflow) => {
      const body = await onCreate(data);
      setShow(false);

      return body;
    },
    [onCreate, setShow],
  );

  return (
    <Offcanvas show={show} onHide={hidePane} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Create Workflow</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <CreateWorkflow onCreate={onCreateInner} />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CreateWorkflowPane;
