import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useMemo } from "react";
import {
  Alert,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Dropdown,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type { BillingReport } from "../../../api/src/billing/lib";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import ActionButton from "../components/ActionButton";
import HelpButton from "../components/HelpButton";
import TabContainerWithTabs from "../components/TabContainer";
import { Table, metaDefault, useTable } from "../components/Table/Table";
import {
  YesNoSingleFilter,
  multiSelectIncludes,
  yesNoOptions,
} from "../components/Table/filters";
import {
  useAuthenticatedFetch,
  useAuthenticatedFileFetch,
  useAuthenticatedMutation,
} from "../lib/api";
import { formatCurrency, formatPercent } from "../lib/numbers";
import BillingNav from "./BillingNav";
import { deserializeBillingReport } from "./BillingReportInfo";
import { boolToYesNo } from "../lib/display";
import { BillingHelp, useQueryExportBillingReport } from "./lib";

type BillingReportRow = BillingReport;

const columnHelper = createColumnHelper<BillingReportRow>();

function getBillingPeriodAnnum(billingReport: BillingReport) {
  const yearInSeconds = 31556952;
  return (
    1 /
    ((billingReport.endDate.valueOf() - billingReport.startDate.valueOf()) /
      yearInSeconds /
      1000)
  );
}

const BillingReportsTable = () => {
  const { isPending, isError, data } =
    useAuthenticatedFetch<
      SerializedObject<
        UnpackResponse<BillingController["getAllBillingReports"]>
      >
    >("/billing/reports");

  const billingReports: BillingReport[] = useMemo(
    () => (data?.data ?? []).map(deserializeBillingReport),
    [data?.data],
  );

  const queryClient = useQueryClient();

  const columns = useMemo(
    () => [
      columnHelper.accessor("endDate", {
        cell: (info) => (
          <Link to={`/billing/reports/${info.row.original.id}`}>
            {info.getValue().toLocaleDateString()}
          </Link>
        ),
        header: () => "Billing Date",
      }),
      columnHelper.accessor("createdTime", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "Created",
      }),
      columnHelper.accessor(
        (row) => {
          const firmWide = yesNoOptions.find(
            (a) => a.label === (row.isFirmWideExecution ? "Yes" : "No"),
          );
          return firmWide?.value.toString() ?? "";
        },
        {
          id: "isFirmWideExecution",
          cell: (info) => {
            return info.getValue() ? "Yes" : "No";
          },
          filterFn: multiSelectIncludes,
          header: () => "Firm-Wide?",
          meta: {
            filterComponent: YesNoSingleFilter,
          },
        },
      ),
      columnHelper.accessor("billableAUM", {
        cell: (info) =>
          info.row.original.status !== "complete" ? (
            "Pending..."
          ) : (
            <div>
              {formatCurrency(info.getValue())}
              <br />
              <span className="text-muted">
                AUM {formatCurrency(info.row.original.totalAUM)}
              </span>
            </div>
          ),
        header: () => "Billable Balance",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("effectiveFeeRate", {
        cell: (info) =>
          info.row.original.status !== "complete"
            ? null
            : formatPercent(info.getValue(), 2),
        header: () => "Effective Fee Rate",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("totalFee", {
        cell: (info) =>
          info.row.original.status !== "complete" ? null : (
            <div>
              {formatCurrency(info.getValue())}
              <br />
              <span className="text-muted">
                {formatCurrency(
                  info.getValue() * getBillingPeriodAnnum(info.row.original),
                )}{" "}
                p.a.
              </span>
            </div>
          ),
        header: () => "Debited",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("totalAssetAdjustmentDollarAmount", {
        cell: (info) =>
          info.row.original.status !== "complete"
            ? null
            : formatCurrency(info.getValue()),
        header: () => "Total Exclusion",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("totalFeeSplit", {
        cell: (info) =>
          info.row.original.status !== "complete" ? null : (
            <div>
              {formatCurrency(info.getValue())}
              <br />
              <span className="text-muted">
                {formatCurrency(
                  info.getValue() * getBillingPeriodAnnum(info.row.original),
                )}{" "}
                p.a.
              </span>
            </div>
          ),
        header: () => "Total Split",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor(
        (row) => {
          const invoiceCreated = yesNoOptions.find(
            (a) =>
              a.label ===
              (typeof row.latestInvoiceDownloadTime !== "undefined"
                ? "Yes"
                : "No"),
          );
          return invoiceCreated?.value.toString() ?? "";
        },
        {
          id: "latestInvoiceDownloadTime",
          header: () => "Invoices Created?",
          cell: (info) => {
            return info.row.original.status !== "complete"
              ? null
              : boolToYesNo(info.getValue() === "true");
          },
          filterFn: multiSelectIncludes,
          meta: {
            filterComponent: YesNoSingleFilter,
          },
        },
      ),
      columnHelper.display({
        id: "actions",
        cell: (info) => {
          const report = info.row.original;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { refetch: downloadInvoices } = useAuthenticatedFileFetch(
            `/billing/reports/${report.id}/invoices`,
            { method: "POST" },
            {
              enabled: false,
            },
          );

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const downloadInvoicesMutation = useMutation({
            mutationFn: async () => {
              await downloadInvoices();
            },
          });

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { refetch: downloadFeeUpload } = useAuthenticatedFileFetch(
            `/billing/reports/${report.id}/fee-upload`,
            { method: "POST" },
            {
              enabled: false,
            },
          );

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const downloadFeeUploadMutation = useMutation({
            mutationFn: async () => {
              await downloadFeeUpload();
            },
          });

          const { isLoading: isLoadingExport, refetch: refetchExport } =
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useQueryExportBillingReport(report.id);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const onExport = useCallback(async () => {
            await refetchExport();
          }, [refetchExport]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const deleteReport = useAuthenticatedMutation(
            `/billing/reports/${report.id}`,
            { method: "DELETE" },
          );

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const deleteReportMutation = useMutation({
            mutationFn: async () => {
              await deleteReport();
              queryClient.setQueryData(["/billing/reports"], {
                data: data?.data.filter(
                  (billingReport) => billingReport.id !== report.id,
                ),
              });
            },
          });

          return (
            <Dropdown>
              <Dropdown.Toggle />
              <Dropdown.Menu
                popperConfig={{
                  modifiers: [
                    {
                      name: "flip",
                      options: {
                        fallbackPlacements: ["bottom", "top"],
                      },
                    },
                  ],
                }}
              >
                <Dropdown.Header>Actions</Dropdown.Header>
                {info.row.original.status !== "complete" ? null : (
                  <>
                    <Dropdown.Item
                      onClick={() => downloadInvoicesMutation.mutate()}
                    >
                      Download Invoices
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => downloadFeeUploadMutation.mutate()}
                    >
                      Download Fee Upload
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={onExport}
                      disabled={isLoadingExport}
                    >
                      Export
                    </Dropdown.Item>
                  </>
                )}
                <Dropdown.Item onClick={() => deleteReportMutation.mutate()}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      }),
    ],
    [data?.data, queryClient],
  );

  const { table } = useTable({
    columns,
    data: billingReports,
    getRowId: (row) => row.id.toString(),
    autoResetPageIndex: true,
    meta: metaDefault,
    initialState: {
      sorting: [{ id: "endDate", desc: true }],
    },
  });

  return (
    <TabContainerWithTabs tabs={BillingNav}>
      <Row>
        <Col className="d-flex justify-content-between mb-3">
          <ButtonToolbar>
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Run Firm-Wide Billing"
                icon="/icons/new.svg"
                className="btn-action_secondary_wide"
                as={Link}
                to="/billing/reports/run?type=standard"
              />
            </ButtonGroup>
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Run One-Off Billing"
                icon="/icons/new.svg"
                className="btn-action_secondary_wide"
                as={Link}
                to="/billing/reports/run?type=custom"
              />
            </ButtonGroup>
          </ButtonToolbar>
          <HelpButton
            title="Help – Billing"
            body={<BillingHelp />}
            buttonProps={{ className: "mb-3" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {isPending ? (
            <Loading />
          ) : isError ? (
            <Alert variant="danger">An error occurred</Alert>
          ) : !billingReports || billingReports.length <= 0 ? (
            <Alert>No billing reports found</Alert>
          ) : (
            <Table table={table} />
          )}
        </Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default BillingReportsTable;
