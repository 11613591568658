import { useCallback, useContext } from "react";
import { ButtonGroup, ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ReportsController } from "../../../api/src/reports/reports.controller";
import ActionButton from "../components/ActionButton";
import Content from "../components/Content";
import FormError from "../components/FormError";
import { useAuthenticatedMutationNew } from "../lib/api";
import { boolToYesNo, capitalize } from "../lib/display";
import { processFileResponse } from "../lib/file";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import { useDeleteReportBatch, useQueryReportBatch } from "./lib";
import ReportsTable from "./ReportsTable";

export function useBulkDownloadReports() {
  return useAuthenticatedMutationNew<
    UnpackResponse<ReportsController["getMultipleReports"]>,
    void,
    { reportIds: number[] }
  >({
    mutationKey: ["POST", "reports", "bulk"],
    mutationFn: () => null,
    responseCallback: processFileResponse,
  });
}

const ReportBatchInfo = () => {
  const { reportBatchId } = useParams();
  const navigate = useNavigate();

  const {
    isFetched,
    isError,
    data: reportBatch,
  } = useQueryReportBatch(parseInt(reportBatchId ?? ""));

  const notificationContext = useContext(NotificationContext);
  const downloadReportsMutation = useBulkDownloadReports();

  const onDownload = useCallback(async () => {
    try {
      await downloadReportsMutation.mutateAsync({
        reportIds: (reportBatch?.reports ?? []).map((report) => report.id),
      });
      notificationContext.pushNotification({
        id: "report-bulk",
        header: "Reports downloaded",
        body: "Reports downloaded successfully",
        variant: "success",
      });
    } catch (ex) {
      console.error("Failed to download reports", ex);
      notificationContext.pushNotification({
        id: "report-bulk",
        header: "Failed to download reports",
        body: "An error occurred",
        variant: "danger",
      });
    }
  }, [downloadReportsMutation, notificationContext, reportBatch?.reports]);

  const deleteReportBatch = useDeleteReportBatch(parseInt(reportBatchId ?? ""));

  const onDelete = useCallback(async () => {
    await deleteReportBatch.mutateAsync();
    notificationContext.pushNotification({
      id: `report-batch-${reportBatchId}`,
      header: "Report Batch Deleted",
      body: `Report batch for ${reportBatch?.reportDate.toLocaleDateString()} deleted`,
      variant: "warning",
    });
    navigate("..");
  }, [
    deleteReportBatch,
    navigate,
    notificationContext,
    reportBatch?.reportDate,
    reportBatchId,
  ]);

  return !isFetched ? (
    <Loading />
  ) : isError ? (
    <FormError message="Failed to load reports" />
  ) : typeof reportBatch === "undefined" ? (
    <FormError message="Report not found" />
  ) : (
    <>
      <Row>
        <Col>
          <h1>Report Details</h1>
        </Col>
        <Col md="auto">
          <ButtonToolbar className="gap-3 mb-3 justify-content-end">
            <ButtonGroup>
              <ActionButton
                variant="secondary"
                label="Download"
                icon="/icons/folded-list.svg"
                onClick={onDownload}
                disabled={
                  downloadReportsMutation.isPending ||
                  reportBatch.reports?.length === 0
                }
              />
            </ButtonGroup>
            <ButtonGroup>
              <ActionButton
                variant="secondary"
                label="Delete"
                icon="/icons/trash.svg"
                onClick={onDelete}
                disabled={deleteReportBatch.isPending}
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Content>
        <Row>
          <Col xxl={2} md={4} sm={6} className="mb-2">
            <Form.Group controlId="form-name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={reportBatch.name}
                plaintext
                readOnly
              />
            </Form.Group>
          </Col>
          <Col xxl={2} md={4} sm={6} className="mb-2">
            <Form.Group controlId="form-period">
              <Form.Label>Report Date</Form.Label>
              <Form.Control
                type="text"
                value={reportBatch.reportDate.toLocaleDateString()}
                plaintext
                readOnly
              />
            </Form.Group>
          </Col>
          <Col xxl={2} md={4} sm={6} className="mb-2">
            <Form.Group controlId="form-period">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="text"
                value={reportBatch.startDate.toLocaleDateString()}
                plaintext
                readOnly
              />
            </Form.Group>
          </Col>
          <Col xxl={2} md={4} sm={6} className="mb-2">
            <Form.Group controlId="form-period">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="text"
                value={reportBatch.endDate.toLocaleDateString()}
                plaintext
                readOnly
              />
            </Form.Group>
          </Col>
          <Col xl={2} md={4} sm={6} className="mb-2">
            <Form.Group controlId="form-isFirmWideExecution">
              <Form.Label>Is Firm-Wide Execution?</Form.Label>
              <Form.Control
                type="text"
                value={boolToYesNo(reportBatch.isFirmWideExecution)}
                plaintext
                readOnly
              />
            </Form.Group>
          </Col>
          <Col xl={2} md={4} sm={6} className="mb-2">
            <Form.Group controlId="form-status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                type="text"
                value={capitalize(reportBatch.status)}
                plaintext
                readOnly
              />
            </Form.Group>
          </Col>
          <Col xxl={2} md={4} sm={6} className="mb-2">
            <Form.Group controlId="form-createdTime">
              <Form.Label>Created Date</Form.Label>
              <Form.Control
                type="text"
                value={reportBatch.createdTime.toLocaleString()}
                plaintext
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <h2>Reports</h2>
        </Row>
        <Row>
          <Col>
            <ReportsTable reports={reportBatch.reports ?? []} />
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default ReportBatchInfo;
