import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import SubmitButton from "../../components/SubmitButton";
import {
  processEmptyResponse,
  useAuthenticatedMutationAsync,
} from "../../lib/api";

const HouseholdArchiveDialog = ({
  showModal,
  setShowModal,
  archiveHouseholdId,
  archiveHouseholdName,
  title,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  archiveHouseholdId: number;
  archiveHouseholdName: string;
  title: string;
}) => {
  const handleModalClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const [archiveEnabled, setArchiveEnabled] = useState(false);

  const checkArchiveEnabled = (value: string) => {
    if (value === archiveHouseholdName) {
      setArchiveEnabled(true);
    } else {
      setArchiveEnabled(false);
    }
  };

  const archiveHousehold = useAuthenticatedMutationAsync<
    UnpackResponse<HouseholdsController["archiveHousehold"]>
  >(
    `/households/${archiveHouseholdId}/archive`,
    async () => ({
      method: "POST",
    }),
    processEmptyResponse,
  );
  const queryClient = useQueryClient();

  const handleArchiveHousehold = useMutation({
    mutationFn: () => archiveHousehold(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/households/${archiveHouseholdId}?accounts=true&crm=true`],
      });
      setShowModal(false);
    },
  });

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Archive Household</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Are you sure you would like to archive {archiveHouseholdName}?
        </Form.Label>
        <Form.Control
          style={{ width: "90%" }}
          type="text"
          placeholder="Enter household name"
          onChange={(e) => checkArchiveEnabled(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Discard
        </Button>
        <SubmitButton
          variant="danger"
          label="Confirm"
          disabled={!archiveEnabled}
          onClick={() => handleArchiveHousehold.mutate()}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default HouseholdArchiveDialog;
