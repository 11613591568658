import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import type { AccountWithMetrics } from "../../../../api/src/accounts/accounts.service";
import type { AccountCashSetting } from "../../../../api/src/cash-settings/lib";
import { feeLocationDisplay } from "../../billing/lib";
import { Table, useTable } from "../../components/Table/Table";
import {
  displayCashTarget,
  displayMinMaxCashTarget,
  getCashTargetPercentage,
} from "../../cash/lib";
import { displayAccountName } from "../../lib/display";
import { formatCurrency, formatPercent } from "../../lib/numbers";

export type HouseholdCashBillingRow = AccountWithMetrics &
  Partial<AccountCashSetting> & {
    feeStructureName?: string;
    isFeeStructureInherited: boolean;
    feeLocation?: string;
    feeLocationAccountId?: number;
    feeLocationAccountName?: string;
  };

const columnHelper = createColumnHelper<HouseholdCashBillingRow>();

const HouseholdCashBillingTable = ({
  accounts,
}: {
  accounts: HouseholdCashBillingRow[];
}) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) => displayAccountName(row.displayName, row.displayNumber),
        {
          id: "name",
          cell: (info) => (
            <Link to={info.row.original.id.toString()}>{info.getValue()}</Link>
          ),
          header: () => "Account",
          minSize: 225,
        },
      ),
      columnHelper.accessor((row) => row.accountBalance ?? 0, {
        id: "accountBalance",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "Total Assets",
        enableColumnFilter: false,
      }),
      columnHelper.accessor(
        (row) =>
          row.accountBalance === 0
            ? 0
            : (row.cashBalance ?? 0) /
              (row.accountBalance ?? row.cashBalance ?? 1),
        {
          id: "cashWeight",
          cell: (info) => formatPercent(info.getValue(), 2),
          header: () => "Cash %",
          enableColumnFilter: false,
        },
      ),
      columnHelper.accessor(
        (row) =>
          getCashTargetPercentage(
            row.accountBalance ?? 0,
            "percent",
            row.cashTargetMinAbsolute,
            row.cashTargetMinPercent,
          ),
        {
          id: "cashTargetMin",
          cell: (info) =>
            displayMinMaxCashTarget(
              info.row.original.accountBalance ?? 0,
              info.row.original.cashTargetMinCondition ?? "and",
              info.row.original.cashTargetMinAbsolute,
              info.row.original.cashTargetMinPercent,
            ),
          header: () => "Min Cash Trigger",
          enableColumnFilter: false,
        },
      ),
      columnHelper.accessor(
        (row) =>
          getCashTargetPercentage(
            row.accountBalance ?? 0,
            row.cashTargetType ?? "absolute",
            row.cashTargetAbsolute,
            row.cashTargetPercent,
          ),
        {
          id: "cashTarget",
          cell: (info) =>
            displayCashTarget(
              info.row.original.accountBalance ?? 0,
              info.row.original.cashTargetType ?? "absolute",
              info.row.original.cashTargetAbsolute,
              info.row.original.cashTargetPercent,
            ),
          header: () => "Cash Target",
          enableColumnFilter: false,
        },
      ),
      columnHelper.accessor(
        (row) =>
          getCashTargetPercentage(
            row.accountBalance ?? 0,
            "percent",
            row.cashTargetMaxAbsolute,
            row.cashTargetMaxPercent,
          ),
        {
          id: "cashTargetMax",
          cell: (info) =>
            displayMinMaxCashTarget(
              info.row.original.accountBalance ?? 0,
              info.row.original.cashTargetMaxCondition ?? "and",
              info.row.original.cashTargetMaxAbsolute,
              info.row.original.cashTargetMaxPercent,
            ),
          header: () => "Max Cash Trigger",
          enableColumnFilter: false,
        },
      ),
      columnHelper.accessor((row) => row.feeStructureName ?? "", {
        id: "feeStructure",
        cell: (info) => (
          <Link
            to={`/billing/fee-structure/${info.row.original.feeStructureId}`}
          >
            {info.row.original.isFeeStructureInherited ? (
              <em>{info.getValue()}</em>
            ) : (
              info.getValue()
            )}
          </Link>
        ),
        header: () => "Fee Structure",
        minSize: 225,
      }),
      columnHelper.accessor(
        (row) =>
          feeLocationDisplay(
            row.feeLocation ?? "S",
            row.feeLocationAccountName,
          ),
        {
          id: "feeLocation",
          cell: (info) =>
            info.row.original.feeLocation !== "A" ? (
              info.getValue()
            ) : (
              <Link
                to={`/clients/accounts/${info.row.original.feeLocationAccountId}`}
              >
                {info.getValue()}
              </Link>
            ),
          header: () => "Fee Location",
          enableColumnFilter: false,
        },
      ),
    ],
    [],
  );

  const { table } = useTable({
    columns,
    data: accounts,
    getRowId: (row) => row.id.toString(),
  });

  return <Table table={table} />;
};

export default HouseholdCashBillingTable;
