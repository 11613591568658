import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop";
import { useDebounce } from "../lib/forms";
import { getCroppedImageBlob } from "./lib/imageCrop";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

const aspect = 3;

const ImagePicker = ({
  uploadFunc,
  uploadRef,
  afterUpload,
}: {
  uploadFunc: (data: Blob) => Promise<{ data: any }>;
  uploadRef: React.MutableRefObject<HTMLInputElement | null>;
  afterUpload: (data: any) => void;
}) => {
  const [imgSrc, setImgSrc] = useState("");
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [blob, setBlob] = useState<Blob | undefined>();
  const [isUploading, setIsUploading] = useState(false);

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || ""),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const [redraw] = useDebounce(async () => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      const { blob } = await getCroppedImageBlob(
        imgRef.current,
        completedCrop,
        scale,
      );
      setBlob(blob);
    }
  }, 100);

  useEffect(() => {
    redraw();
  }, [completedCrop, scale, redraw]);

  const handleClose = useCallback(() => {
    setImgSrc("");
  }, [setImgSrc]);

  const handleScaleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setScale(+e.target.value);
    },
    [],
  );

  const handleUploadImage = useCallback(async () => {
    if (typeof blob !== "undefined") {
      setIsUploading(true);
      try {
        const result = await uploadFunc(blob);
        afterUpload(result.data);
        setImgSrc("");
      } catch (err) {
        const message = "Failed to upload image";
        console.error(message, err);
      }
      setIsUploading(false);
    }
  }, [afterUpload, blob, uploadFunc]);

  return (
    <div>
      <input
        ref={uploadRef}
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        hidden
      />
      <Modal
        size="lg"
        show={!!imgSrc}
        centered
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Crop your image.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale})` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <Row>
            <Col md={6}>
              <Form.Label>Zoom</Form.Label>
              <Form.Range
                min={1}
                max={2}
                step={0.1}
                value={scale}
                onChange={handleScaleChange}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUploadImage} disabled={isUploading}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Discard
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImagePicker;
