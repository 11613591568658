import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { setUser } from "@sentry/react";
import { Nav, NavDropdown, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfilePicture from "./profile/ProfilePicture";

/**
 * Header login or profile button, depending on authentication state.
 */
const LoginButton = ({
  className,
  variant = "primary",
}: {
  className?: string;
  variant?: "primary" | "light";
}) => {
  // Use to manually close the popover when clicking a link inside of it
  const [showPopover, setShowPopover] = useState<boolean>();
  const { isAuthenticated, logout, user } = useAuth0();

  const popover = !user ? (
    <Popover />
  ) : (
    // Use the dropdown-menu class so that the NavDropdown items style correctly
    <Popover className="profile-popover dropdown-menu p-0">
      <Popover.Header as="h3">{user.name ?? user.email}</Popover.Header>
      <Popover.Body className="p-0">
        <Nav variant="pills" as="ul" className="flex-column">
          <NavDropdown.Item as="li">
            <Link
              to="/profile"
              className="nav-link"
              onClick={() => setShowPopover(false)}
            >
              Profile
            </Link>
          </NavDropdown.Item>
          <NavDropdown.Item as="li">
            <Link
              to="/firm"
              className="nav-link"
              onClick={() => setShowPopover(false)}
            >
              Firm
            </Link>
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item as="li" className="rounded-0 rounded-bottom">
            <button
              className="nav-link w-100 text-start"
              onClick={() => {
                logout({ logoutParams: { returnTo: window.location.origin } });
                setUser(null);
                setShowPopover(false);
              }}
            >
              Log Out
            </button>
          </NavDropdown.Item>
        </Nav>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className={className}>
      {!isAuthenticated ? (
        <Link to="/login" className={`btn btn-${variant}`}>
          Log In
        </Link>
      ) : !user ? null : (
        <OverlayTrigger
          overlay={popover}
          trigger="click"
          placement="bottom"
          rootClose
          show={showPopover}
          onToggle={(nextShow) => {
            // If the trigger is trying to show the popover, reset our manual state
            // so that it doesn't get stuck closed.
            if (nextShow) {
              setShowPopover(undefined);
            }
          }}
        >
          <button className="bg-transparent border-0 p-0">
            <ProfilePicture width={40} height={40} roundedCircle={true} />
          </button>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default LoginButton;
