import io, { Socket } from "socket.io-client";

export class WebSocketGatewayClient {
  private static instance: WebSocketGatewayClient;
  private socket: Socket;

  constructor(serverUrl: string, token?: string, timeout = 60000) {
    console.log(
      `WS Gateway: Client is starting and trying to connect to ${serverUrl}`,
    );
    this.socket = io(serverUrl, {
      path: "/socket.io",
      timeout,
      auth: { token },
    });
    this.socket.on("connect", this.handleConnect.bind(this));
    this.socket.on("serverReady", this.handleServerReady.bind(this));
    this.socket.on("disconnect", this.handleDisconnect.bind(this));
  }

  public static getInstance(token?: string, timeout?: number) {
    if (!WebSocketGatewayClient.instance) {
      WebSocketGatewayClient.instance = new WebSocketGatewayClient(
        `wss://${window.location.host}`,
        token,
        timeout,
      );
    }

    return WebSocketGatewayClient.instance;
  }

  // START private methods for handling events
  private handleConnect() {
    console.log("WS Gateway: Client Connected to server");
  }

  private handleServerReady(message: string) {
    console.log("WS Gateway:", message);
  }

  private handleNotification(event: string) {
    console.debug("WS Gateway: Received notification:", event);
  }

  private handleDisconnect() {
    console.log("WS Gateway: Client disconnected from server");
  }
  // END private methods for handling events

  // START public access to event handlers
  public onNotification<TNotification>(
    event: string,
    callback: (notification: TNotification) => void,
  ) {
    console.debug(`Registering websocket event listener for "${event}"`);
    this.socket.on(event, (notification: TNotification) => {
      this.handleNotification(event);
      callback(notification);
    });
  }

  // Remove event listeners
  public offNotification<TNotification>(
    event: string,
    callback: (notification: TNotification) => void,
  ) {
    console.debug(`Unregistering websocket event listener for "${event}"`);
    this.socket.off(event, callback);
  }

  disconnect() {
    this.socket.disconnect();
  }
  // END public access to event handlers
}
