import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ProfileController } from "../../../api/src/profile/profile.controller";
import type { NewUser } from "../../../api/src/users/users.service";
import { NotificationContext } from "../Notifications";
import FormFieldError from "../components/FormFieldError";
import SubmitButton from "../components/SubmitButton";
import { useAuthenticatedMutationAsync } from "../lib/api";
import {
  familyNameSchema,
  getSchemaFieldLabel,
  givenNameSchema,
  phoneNumberSchema,
} from "../lib/forms";
import ProfilePicture from "./ProfilePicture";

const schema: yup.ObjectSchema<NewUser> = yup.object({
  givenName: givenNameSchema.required(),
  familyName: familyNameSchema.required(),
  phoneNumber: phoneNumberSchema,
});

const CreateUser = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<NewUser>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth0();

  const createUser = useAuthenticatedMutationAsync<
    UnpackResponse<ProfileController["createProfile"]>
  >("/profile", async (data: NewUser) => ({
    method: "POST",
    body: JSON.stringify(data),
  }));

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: NewUser) => {
      try {
        await createUser(data);
        if (isAuthenticated && user !== undefined) {
          user.given_name = data.givenName;
          user.family_name = data.familyName;
        }
        notificationContext.pushNotification({
          id: `user-${user?.id}`,
          header: "User Profile Updated",
          body: "User profile updated",
          variant: "success",
        });
        navigate("/profile");
      } catch (err) {
        console.error("Failed to create user", err);
        notificationContext.pushNotification({
          id: `user-${user?.id}`,
          header: "Failed to Update User Profile",
          body: "User profile was not update",
          variant: "danger",
        });
      }
    },
  });

  return (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      <h1>Create User</h1>
      <Form.Group className="mb-3" controlId="form-picture">
        <ProfilePicture />
      </Form.Group>
      <Form.Group className="mb-3" controlId="form-givenName">
        <Form.Label>{getSchemaFieldLabel(schema.fields.givenName)}</Form.Label>
        <Form.Control type="text" {...register("givenName")} />
        <FormFieldError field={errors.givenName} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="form-familyName">
        <Form.Label>{getSchemaFieldLabel(schema.fields.familyName)}</Form.Label>
        <Form.Control type="text" {...register("familyName")} />
        <FormFieldError field={errors.familyName} />
      </Form.Group>
      <Row>
        <Col>
          <SubmitButton isSubmitting={isSubmitting} label="Create" />
        </Col>
      </Row>
    </Form>
  );
};

export default CreateUser;
