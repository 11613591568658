import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const DashboardNav = () => (
  <nav>
    <Nav variant="tabs" defaultActiveKey="/dashboard/firm">
      <Nav.Item>
        <NavLink to="/dashboard/firm" className="nav-link">
          Firm Summary
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/dashboard/clients" className="nav-link">
          Households
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/dashboard/transactions" className="nav-link">
          Transactions
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/dashboard/tasks" className="nav-link">
          Tasks
        </NavLink>
      </Nav.Item>
    </Nav>
  </nav>
);

export default DashboardNav;
