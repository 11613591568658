import React, { useCallback, useState } from "react";

const AccountNumber = ({
  fullNumber,
  displayNumber,
}: {
  fullNumber: string;
  displayNumber: string;
}) => {
  const [showFullNumber, setShowFullNumber] = useState(false);
  const onHover = useCallback(() => {
    setShowFullNumber(true);
  }, []);
  const onBlur = useCallback(() => {
    setShowFullNumber(false);
  }, []);

  return (
    <span
      onMouseOver={onHover}
      onFocus={onHover}
      onMouseOut={onBlur}
      onBlur={onBlur}
    >
      {showFullNumber ? fullNumber : displayNumber}
    </span>
  );
};

export default AccountNumber;
