import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RenderSuggestionParams } from "react-autosuggest";
import { Alert, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { ContactsController } from "../../../../api/src/contacts/contacts.controller";
import type { Contact } from "../../../../api/src/contacts/lib";
import type { SerializedObject, UnpackResponse } from "../../../../api/src/lib";
import ActionButton from "../../components/ActionButton";
import Autocomplete, { Suggestion } from "../../components/Autocomplete";
import { useAuthenticatedFetch } from "../../lib/api";
import { capitalize } from "../../lib/display";
import { deserializeContact } from "../contact/lib";

const PREFIX_LENGTH = 1;

const HouseholdMemberRow = ({
  member,
  members,
  setMembers,
}: {
  member: Contact;
  members: Contact[];
  setMembers: React.Dispatch<React.SetStateAction<Contact[]>>;
}) => {
  return (
    <tr>
      <td>
        <Link to={`/clients/contacts/${member.id}`}>{member.name}</Link>
      </td>
      <td>
        {capitalize(member.title ?? "")}
        {/* <Form.Select value={member.title} disabled>
          {Object.values(HouseholdTitle).map((title) => (
            <option key={title} value={title} />
          ))}
        </Form.Select> */}
      </td>
      <td className="text-end">
        <ActionButton
          variant="icon"
          icon="/icons/trash.svg"
          label="Remove Member"
          type="button"
          onClick={() => {
            const updatedMembers = members.filter((m) => m.id !== member.id);
            setMembers(updatedMembers);
          }}
        />
      </td>
    </tr>
  );
};

const MemberSelector = ({
  members,
  setMembers,
}: {
  members: Contact[];
  setMembers: React.Dispatch<React.SetStateAction<Contact[]>>;
}) => {
  const [name, setName] = useState("");
  const [prefix, setPrefix] = useState("");
  const { data, refetch, isError, isFetching } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<ContactsController["filterUnassigned"]>>
  >(
    `/contacts/filter/unassigned?name=${prefix}`,
    undefined,
    { enabled: false },
    ["/contacts/filter/unassigned", prefix],
  );

  const contacts = useMemo(
    () =>
      typeof data === "undefined"
        ? undefined
        : data.data.map(deserializeContact),
    [data],
  );

  const onSelect = useCallback(
    (value: Contact) => {
      setMembers([...members, value]);
    },
    [setMembers, members],
  );

  const loadSuggestions = useCallback(
    (value: string) => {
      if (value.length >= PREFIX_LENGTH)
        setPrefix(value.slice(0, PREFIX_LENGTH));
      setName(value);
    },
    [setPrefix, setName],
  );

  const getSuggestionValue = useCallback(
    (suggestion: Contact) => `${suggestion.givenName} ${suggestion.familyName}`,
    [],
  );

  const renderSuggestion = useCallback(
    (suggestion: Contact, params: RenderSuggestionParams) => (
      <Suggestion active={params.isHighlighted}>
        {suggestion.givenName} {suggestion.familyName}
      </Suggestion>
    ),
    [],
  );

  useEffect(() => {
    if (prefix.length >= PREFIX_LENGTH) refetch();
  }, [refetch, prefix]);

  return (
    <Form.Group className="mb-3" controlId="form-add-member">
      <Form.Label>Add Member</Form.Label>
      <Autocomplete
        suggestions={(contacts ?? [])
          .filter(
            (member) =>
              `${member.givenName} ${member.familyName}`
                .toUpperCase()
                .includes(name.toUpperCase()) &&
              members.every(
                (existingMember) => existingMember.id !== member.id,
              ),
          )
          .slice(0, 10)}
        isLoading={isFetching}
        onSelect={onSelect}
        loadSuggestions={loadSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        placeholder="Search contacts by name..."
      />
      {!isError ? null : (
        <Form.Text className="text-danger">Failed to load contacts</Form.Text>
      )}
    </Form.Group>
  );
};

const HouseholdMembers = ({
  members,
  setMembers,
  readOnly = false,
}: {
  members: Contact[];
  setMembers: React.Dispatch<React.SetStateAction<Contact[]>>;
  readOnly?: boolean;
}) => {
  return (
    <div>
      <h4>Members</h4>
      {readOnly ? null : (
        <MemberSelector members={members} setMembers={setMembers} />
      )}
      {members.length <= 0 ? (
        <Alert>No household members</Alert>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Title</th>
              <th>{/* Empty */}</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => (
              <HouseholdMemberRow
                key={member.id}
                member={member}
                members={members}
                setMembers={setMembers}
              />
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default HouseholdMembers;
