import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type { FirmsController } from "../../../api/src/firms/firms.controller";
import type {
  CustodianCode,
  EditableFirm,
  Firm,
  TradeOrderType,
} from "../../../api/src/firms/firms.service";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import ActionButton from "../components/ActionButton";
import FormError from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import ImagePicker from "../components/ImagePicker";
import MaskedInput from "../components/MaskedInput";
import SubmitButton from "../components/SubmitButton";
import TabContainerWithTabs from "../components/TabContainer";
import {
  deserializeDate,
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import { useRoles } from "../lib/auth";
import { InlineError } from "../lib/display";
import {
  emailSchema,
  getSchemaFieldLabel,
  idOptionalSelectorSchema,
  phoneNumberSchema,
} from "../lib/forms";
import { phoneMask } from "../lib/masks";
import AdvisorCodesEditor, { advisorCodeSchema } from "./AdvisorCodeEditor";
import FirmNav from "./FirmNav";
import UsersManager from "./UsersManager";

const schema: yup.ObjectSchema<EditableFirm> = yup.object({
  name: yup.string().required().label("Firm Name"),
  phoneNumber: phoneNumberSchema,
  supportEmail: emailSchema,
  disclosures: yup.string().label("Disclosures"),
  primaryColor: yup.string().label("Primary Color"),
  accentColor: yup.string().label("Accent Color"),
  advisorCodes: advisorCodeSchema,
  defaultFeeStructureId: idOptionalSelectorSchema.label(
    "Default Fee Structure",
  ),
  tradeOrderType: yup
    .string()
    .required()
    .oneOf<TradeOrderType>(["market", "limit"])
    .label("Trade Order Type"),
});

const FirmInfo = () => {
  const roles = useRoles();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<EditableFirm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    fields: advisorCodes,
    append: appendAdvisorCode,
    remove: removeAdvisorCode,
    replace: replaceAdvisorCode,
  } = useFieldArray({
    name: "advisorCodes",
    control,
    keyName: "_field_id",
  });

  const uploadRef = useRef<HTMLInputElement | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    isPending: isPendingFirm,
    isError: isErrorFirm,
    data: dataFirm,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<FirmsController["getCurrent"]>>
  >("/firm/current");
  const firm: Firm | undefined = useMemo(
    () =>
      typeof dataFirm?.data === "undefined"
        ? undefined
        : {
            ...dataFirm.data,
            advisorCodes: dataFirm.data.advisorCodes.map((advisorCode) => ({
              ...advisorCode,
              closeDate: deserializeDate(advisorCode.closeDate),
            })),
          },
    [dataFirm?.data],
  );

  useEffect(() => {
    if (typeof firm !== "undefined" && firm !== null) {
      reset(firm);
    }
  }, [firm, reset]);

  const {
    isPending: isPendingFeeStructures,
    isError: isErrorFeeStructures,
    data: dataFeeStructures,
  } = useAuthenticatedFetch<
    UnpackResponse<BillingController["getAllFeeStructures"]>
  >("/billing/fee-structures");

  const updateFirm = useAuthenticatedMutationAsync<
    UnpackResponse<FirmsController["updateCurrent"]>
  >("/firm/current", async (data: EditableFirm) => ({
    method: "PUT",
    body: JSON.stringify(data),
  }));

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: EditableFirm) => {
      try {
        const body = await updateFirm(data);
        reset(body.data);
        notificationContext.pushNotification({
          id: `firm-${firm?.id}`,
          header: "Firm Updated",
          body: "Firm settings updated",
          variant: "success",
        });
      } catch (ex) {
        console.error("Failed to save firm details", ex);
        notificationContext.pushNotification({
          id: `firm-${firm?.id}`,
          header: "Failed to Update Firm",
          body: "Firm settings were not saved",
          variant: "danger",
        });
      }
    },
  });

  const { data: dataLogo, isPending: isPendingLogo } =
    useAuthenticatedFetch<UnpackResponse<FirmsController["getFirmLogo"]>>(
      "/firm/logo",
    );

  const uploadLogo = useAuthenticatedMutationAsync<
    UnpackResponse<FirmsController["uploadLogo"]>
  >("/firm/logo-upload", async (logo: Blob | null) => {
    const formData = new FormData();
    formData.append("logo", logo === null ? "" : logo);
    return {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    };
  });

  const queryClient = useQueryClient();

  const updateLogo = useCallback(
    (updatedLogo: any) => {
      queryClient.setQueryData(["/firm/logo"], {
        data: updatedLogo,
      });
    },
    [queryClient],
  );

  const handleSelectImage = useCallback(() => {
    if (roles.includes("Admin") && uploadRef !== null)
      uploadRef.current?.click();
  }, [uploadRef, roles]);

  const handleDeleteLogo = useCallback(async () => {
    if (roles.includes("Admin") && !isPendingLogo && dataLogo?.data) {
      setIsDeleting(true);
      try {
        await uploadLogo(undefined);
        updateLogo(undefined);
      } catch (err) {
        const message = "Failed to delete logo";
        console.error(message, err);
      }
      setIsDeleting(false);
    }
  }, [roles, isPendingLogo, dataLogo?.data, updateLogo, uploadLogo]);

  const onRetireAdvisorCode = useCallback(
    (code: string, custodian: CustodianCode, closeDate: Date) => {
      queryClient.setQueryData(["/firm/current"], {
        data: {
          ...firm,
          advisorCodes: firm?.advisorCodes.map((advisorCode) =>
            advisorCode.code !== code || advisorCode.custodian !== custodian
              ? advisorCode
              : { ...advisorCode, closeDate: closeDate.toISOString() },
          ),
        },
      });
    },
    [firm, queryClient],
  );

  return (
    <TabContainerWithTabs tabs={FirmNav}>
      {isPendingFirm || isPendingFeeStructures ? (
        <Loading />
      ) : isErrorFirm ? (
        <FormError message="Failed to load firm" />
      ) : typeof firm === "undefined" ? (
        <FormError message="Firm not found" />
      ) : (
        <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
          <fieldset disabled={!roles.includes("Admin")}>
            <Row>
              <Col lg={6} xs={12} className="mb-3 position-relative">
                <Row>
                  <Col xxl={8} lg={12} sm={8}>
                    {!roles.includes("Admin") &&
                    (isPendingLogo || !dataLogo?.data) ? null : (
                      <>
                        <Form.Label>Logo</Form.Label>
                        <div className="p-2 border rounded mb-3 position-relative">
                          {roles.includes("Admin") ? (
                            <ImagePicker
                              uploadFunc={uploadLogo}
                              uploadRef={uploadRef}
                              afterUpload={updateLogo}
                            />
                          ) : null}

                          <Image
                            src={
                              !isPendingLogo && dataLogo?.data
                                ? `data:${dataLogo?.data?.mimetype};base64,${dataLogo?.data?.image}`
                                : "/replace.png"
                            }
                            width="100%"
                            role={roles.includes("Admin") ? "button" : "img"}
                            onClick={handleSelectImage}
                          />

                          {roles.includes("Admin") &&
                          !isPendingLogo &&
                          dataLogo?.data ? (
                            <ActionButton
                              className="position-absolute top-13 end-13"
                              variant="icon"
                              icon="/icons/trash.svg"
                              label="Remove"
                              type="button"
                              onClick={handleDeleteLogo}
                              disabled={isDeleting}
                            />
                          ) : null}
                        </div>
                      </>
                    )}
                  </Col>
                  <Col xs={12}>
                    <Form.Group className="mb-3" controlId="form-name">
                      <Form.Label>
                        {getSchemaFieldLabel(schema.fields.name)}
                      </Form.Label>
                      <Form.Control type="text" {...register("name")} />
                      <FormFieldError field={errors.name} />
                    </Form.Group>
                  </Col>
                  <Col xxl={6}>
                    <Form.Group className="mb-3" controlId="form-supportEmail">
                      <Form.Label>
                        {getSchemaFieldLabel(schema.fields.supportEmail)}
                      </Form.Label>
                      <Form.Control type="text" {...register("supportEmail")} />
                      <FormFieldError field={errors.supportEmail} />
                    </Form.Group>
                  </Col>
                  <Col xxl={6}>
                    <Form.Group className="mb-3" controlId="form-phoneNumber">
                      <Form.Label>
                        {getSchemaFieldLabel(schema.fields.phoneNumber)}
                      </Form.Label>
                      <MaskedInput
                        name="phoneNumber"
                        type="tel"
                        mask={phoneMask.mask}
                        placeholder="+x (xxx)-xxx-xxxx"
                        control={control}
                      />
                      <FormFieldError field={errors.phoneNumber} />
                    </Form.Group>
                  </Col>
                  {!roles.includes("Admin") ? null : (
                    <Col xxl={6} className="mb-3">
                      <Form.Label>Billing</Form.Label>
                      <br />
                      <a
                        href={`${
                          process.env.REACT_APP_BILLING_URL
                        }?prefilled_email=${firm.supportEmail ?? ""}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Billing Information
                      </a>
                    </Col>
                  )}
                  <Col xs={12}>
                    <Form.Group className="mb-3" controlId="form-disclosures">
                      <Form.Label>
                        {getSchemaFieldLabel(schema.fields.disclosures)}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        {...register("disclosures")}
                      />
                      <FormFieldError field={errors.disclosures} />
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="form-defaultFeeStructure"
                    >
                      <Form.Label>
                        {getSchemaFieldLabel(
                          schema.fields.defaultFeeStructureId,
                        )}
                      </Form.Label>
                      {isErrorFeeStructures ? (
                        <InlineError>Failed to load fee structures</InlineError>
                      ) : (
                        <Form.Select {...register("defaultFeeStructureId")}>
                          <option value="">None</option>
                          {(dataFeeStructures?.data ?? []).map(
                            (feeStructure) => (
                              <option
                                key={feeStructure.feeStructureId}
                                value={feeStructure.feeStructureId}
                              >
                                {feeStructure.name}
                              </option>
                            ),
                          )}
                        </Form.Select>
                      )}
                      <FormFieldError field={errors.defaultFeeStructureId} />
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="form-tradeOrderType"
                    >
                      <Form.Label>
                        {getSchemaFieldLabel(schema.fields.tradeOrderType)}
                      </Form.Label>
                      <Form.Select {...register("tradeOrderType")}>
                        <option value="market">Market</option>
                        <option value="limit">Limit</option>
                      </Form.Select>
                      <FormFieldError field={errors.tradeOrderType} />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} xs={12}>
                {roles.includes("Admin") ? <UsersManager /> : null}
                <Col>
                  <h3>Master Accounts</h3>
                  <AdvisorCodesEditor
                    fields={advisorCodes}
                    replace={replaceAdvisorCode}
                    add={appendAdvisorCode}
                    remove={removeAdvisorCode}
                    register={register}
                    errors={errors.advisorCodes}
                    onRetire={onRetireAdvisorCode}
                  />
                </Col>
              </Col>
            </Row>
            <Row>
              <Col>
                {roles.includes("Admin") ? (
                  <SubmitButton isSubmitting={isSubmitting} />
                ) : null}
              </Col>
            </Row>
          </fieldset>
        </Form>
      )}
    </TabContainerWithTabs>
  );
};

export default FirmInfo;
