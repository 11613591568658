import { useEffect, useState } from "react";
import { formatPercent } from "../../lib/numbers";

interface AllocationBarProps<TData> {
  width?: string;
  data: TData;
  converter: (data: TData) => Record<string, number>;
}

type AllocationDataType = {
  title: string;
  value: number;
};

export default function AllocationBar<TData>({
  width,
  data,
  converter,
}: AllocationBarProps<TData>) {
  const barStyle = {
    width: width ?? "100%",
    display: "flex",
  };

  const [allocations, setAllocations] = useState([{ title: "", value: 0 }]);

  const colors = ["#F290B5", "#8FD9E3", "#FFCBA2"];

  useEffect(() => {
    const converted_data = converter(data);
    const allocation = Object.entries<number>(
      converted_data,
    ).map<AllocationDataType>(([key, value]) => ({
      title: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
      value: value,
    }));
    setAllocations(allocation);
  }, [data, converter, setAllocations]);

  return (
    <>
      <div style={barStyle}>
        {allocations.map((item: AllocationDataType, idx) => (
          <div
            key={idx}
            style={{ width: formatPercent(item.value), textAlign: "center" }}
          >
            <div>{formatPercent(item.value)}</div>
            <div
              className="vertical-bar"
              style={{ backgroundColor: colors[idx] }}
            ></div>
            <div
              className="segment"
              title={`${item.title}: ${formatPercent(item.value)}`}
              style={{
                backgroundColor: colors[idx],
                borderRadius:
                  idx === 0
                    ? "5px 0px 0px 5px"
                    : idx === allocations.length - 1
                      ? "0px 5px 5px 0px"
                      : 0,
              }}
            ></div>
          </div>
        ))}
      </div>
      <div className="helper-box">
        {allocations.map((item: AllocationDataType, idx) => (
          <div key={idx} className="helper-item">
            <div
              className="pointer"
              style={{ backgroundColor: colors[idx] }}
            ></div>
            {item.title}
          </div>
        ))}
      </div>
    </>
  );
}
