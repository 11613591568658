import { useContext, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import AllocationBar from "../../components/AllocationBar/AllocationBar";
import PerformancePeriodSelector from "../../components/PerformancePeriodSelector";
import { useAuthenticatedFetch } from "../../lib/api";
import { formatCurrencyComponent, formatPercent } from "../../lib/numbers";
import { getRiskAllocation } from "../../rebalances/lib";
import { AccountContext } from "./AccountInfo";

const AccountPerformance = () => {
  const { account } = useContext(AccountContext);
  const { accountId } = useParams();

  const [customAccountPeriodParams, setCustomAccountPeriodParams] =
    useState("");
  const {
    data: dataAccountPerformance,
    isPending: isPendingAccountPerformance,
    isError: isErrorAccountPerformance,
  } = useAuthenticatedFetch<
    UnpackResponse<AccountsController["getAccountPerformance"]>
  >(`/accounts/${accountId}/performance?${customAccountPeriodParams}`);
  const accountPerformance = dataAccountPerformance?.data;

  return (
    <Row className="mb-2">
      <Col>
        <h4>Performance</h4>
        <Row>
          <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
            Risk Allocation
          </Col>
          <Col as="dd">
            <AllocationBar
              data={account?.riskAllocation?.current}
              converter={getRiskAllocation}
            />
          </Col>
        </Row>
        <PerformancePeriodSelector
          setCustomPeriodParams={setCustomAccountPeriodParams}
        />
        {isPendingAccountPerformance ? (
          <Loading message="Performance" />
        ) : isErrorAccountPerformance ? (
          <Alert variant="danger">Failed to load performance</Alert>
        ) : (
          <>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Performance
              </Col>
              <Col as="dd">
                {formatPercent(accountPerformance?.performance ?? 0, 2)}
              </Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Dividends/Interest
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(accountPerformance?.income ?? 0, 2)} (
                {formatPercent(
                  (account?.accountBalance || 0) === 0
                    ? Infinity
                    : (accountPerformance?.income || 0) /
                        (account?.accountBalance || 0),
                  2,
                )}
                )
              </Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Contributions
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(
                  accountPerformance?.contributions ?? 0,
                  2,
                )}
                (
                {formatPercent(
                  (account?.accountBalance || 0) === 0
                    ? Infinity
                    : (accountPerformance?.contributions || 0) /
                        (account?.accountBalance || 0),
                  2,
                )}
                )
              </Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Distributions
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(
                  accountPerformance?.withdrawals ?? 0,
                  2,
                )}{" "}
                (
                {formatPercent(
                  (account?.accountBalance || 0) === 0
                    ? Infinity
                    : (accountPerformance?.withdrawals || 0) /
                        (account?.accountBalance || 0),
                  2,
                )}
                )
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default AccountPerformance;
