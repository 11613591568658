import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import AssetAllocationBarChart, {
  AssetAllocationSeries,
} from "../../components/AssetAllocationBarChart";
import { useAuthenticatedFetch } from "../../lib/api";

const AccountAssetAllocationChart = () => {
  const { accountId } = useParams();

  const {
    isPending: isPendingAllocationDetails,
    isError: isErrorAllocationDetails,
    data: currentAllocationDetails,
  } = useAuthenticatedFetch<
    UnpackResponse<AccountsController["getAllocationDetails"]>
  >(`/accounts/${accountId}/allocation-details`);

  const data: AssetAllocationSeries[] = [
    {
      label: "Current",
      data: currentAllocationDetails?.data.current ?? [],
    },
    {
      label: "Account Target",
      data: currentAllocationDetails?.data.target ?? [],
    },
  ];

  return isPendingAllocationDetails ? (
    <Loading message="Asset Allocation" />
  ) : isErrorAllocationDetails ? (
    <Alert variant="danger">Failed to load Allocation Details</Alert>
  ) : (currentAllocationDetails?.data.current.length ?? 0) === 0 &&
    (currentAllocationDetails?.data.target.length ?? 0) === 0 ? (
    <Alert variant="info">No Allocation Details</Alert>
  ) : (
    <AssetAllocationBarChart data={data} />
  );
};

export default AccountAssetAllocationChart;
