import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  Updater,
} from "@tanstack/react-table";
import { useCallback, useState } from "react";

export type TableSettings = {
  filters: ColumnFiltersState;
  sorting: SortingState;
  pageSize: number;
};

export const DEFAULT_TABLE_SETTINGS: TableSettings = {
  filters: [],
  sorting: [],
  pageSize: 10,
};

export function getTableSettings(tableName: string): TableSettings {
  const tableSettingsStr = localStorage.getItem(`table.${tableName}`);
  const tableSettings =
    tableSettingsStr === null
      ? DEFAULT_TABLE_SETTINGS
      : JSON.parse(tableSettingsStr);
  return tableSettings;
}

export function setTableSettings(
  tableName: string,
  tableSettings: TableSettings,
) {
  const tableSettingsExisting = getTableSettings(tableName);
  localStorage.setItem(
    `table.${tableName}`,
    JSON.stringify({ ...tableSettingsExisting, ...tableSettings }),
  );
}

export function useTableSettings(
  tableName: string,
  defaults?: Partial<TableSettings>,
) {
  const [tableSettings, setTableSettingsState] = useState({
    ...defaults,
    ...getTableSettings(tableName),
  });

  const setTableSettingsCallback = useCallback(
    (tableSettings: TableSettings) => {
      setTableSettings(tableName, tableSettings);
      setTableSettingsState(tableSettings);
    },
    [tableName],
  );

  return [tableSettings, setTableSettingsCallback] as const;
}

export function onColumnFiltersChange(
  filters: ColumnFiltersState,
  setFilters: (value: ColumnFiltersState) => void,
  tableSettings: TableSettings,
  setTableSettings: (tableSettings: TableSettings) => void,
) {
  return (filtersUpdater: Updater<ColumnFiltersState>) => {
    const newFilters =
      typeof filtersUpdater === "function"
        ? filtersUpdater(filters)
        : filtersUpdater;

    setFilters(newFilters);
    setTableSettings({
      ...tableSettings,
      filters: newFilters,
    });
  };
}

export function onSortingChange(
  sorting: SortingState,
  setSorting: (value: SortingState) => void,
  tableSettings: TableSettings,
  setTableSettings: (tableSettings: TableSettings) => void,
) {
  return (sortingUpdater: Updater<SortingState>) => {
    const newSorting =
      typeof sortingUpdater === "function"
        ? sortingUpdater(sorting)
        : sortingUpdater;

    setSorting(newSorting);
    setTableSettings({
      ...tableSettings,
      sorting: newSorting,
    });
  };
}

export function onPaginationChange(
  pagination: PaginationState,
  setPagination: (value: PaginationState) => void,
  tableSettings: TableSettings,
  setTableSettings: (tableSettings: TableSettings) => void,
) {
  return (paginationUpdater: Updater<PaginationState>) => {
    const newPagination =
      typeof paginationUpdater === "function"
        ? paginationUpdater(pagination)
        : paginationUpdater;

    setPagination(newPagination);
    setTableSettings({
      ...tableSettings,
      pageSize: newPagination.pageSize,
    });
  };
}
