import { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { getStartOfYear, toISODateNoTimezone } from "../lib/date";

const TaxPerformancePeriodSelector = ({
  inceptionDate,
  setCustomPeriodParams,
}: {
  inceptionDate?: Date;
  setCustomPeriodParams: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [performancePeriod, setPerformancePeriod] = useState("ytd");

  useEffect(() => {
    const now = new Date();
    const { startDate, endDate } =
      performancePeriod === "ytd"
        ? { startDate: getStartOfYear(now.getFullYear()), endDate: now }
        : {
            startDate: getStartOfYear(parseInt(performancePeriod)),
            endDate: getStartOfYear(parseInt(performancePeriod) + 1),
          };

    const customPeriodParams = `&start-date=${toISODateNoTimezone(
      startDate,
    )}&end-date=${toISODateNoTimezone(endDate)}`;

    setCustomPeriodParams(customPeriodParams);
  }, [performancePeriod, setCustomPeriodParams]);

  const taxPeriods = ["ytd"];

  if (typeof inceptionDate !== "undefined") {
    for (
      let year = new Date().getFullYear();
      year >= inceptionDate.getFullYear();
      year--
    ) {
      taxPeriods.push(year.toString());
    }
  }

  const onChange = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    setPerformancePeriod(ev.currentTarget.value);
  }, []);

  return (
    <Form.Select value={performancePeriod} onChange={onChange}>
      {taxPeriods.map((period) => (
        <option key={period} value={period}>
          {period.toLocaleUpperCase()}
        </option>
      ))}
    </Form.Select>
  );
};

export default TaxPerformancePeriodSelector;
