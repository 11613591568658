import { CashSettingsController } from "../../../api/src/cash-settings/cash-settings.controller";
import type {
  AccountCashSetting,
  CashSetting,
  CashTargetCondition,
  CashTargetType,
} from "../../../api/src/cash-settings/lib";
import { UnpackResponse } from "../../../api/src/lib";
import type { RecurringTransferFrequency } from "../../../api/src/transfers/transfers.service";
import { useAuthenticatedMutationNew, useAuthenticatedQuery } from "../lib/api";
import { naLabel } from "../lib/display";
import { formatCurrency, formatPercent } from "../lib/numbers";

export function getCashTarget(
  accountBalance: number,
  cashTargetType: CashTargetType,
  cashTargetAbsolute?: number | null,
  cashTargetPercent?: number | null,
) {
  return cashTargetType === "percent"
    ? ((cashTargetPercent ?? 0) / 100) * accountBalance
    : accountBalance === 0
      ? 0
      : (cashTargetAbsolute ?? 0);
}

export function getCashTargetPercentage(
  accountBalance: number,
  cashTargetType: CashTargetType,
  cashTargetAbsolute?: number | null,
  cashTargetPercent?: number | null,
) {
  return cashTargetType === "percent"
    ? (cashTargetPercent ?? 0) / 100
    : accountBalance === 0
      ? 0
      : (cashTargetAbsolute ?? 0) / accountBalance;
}

export function displayCashTarget(
  accountBalance: number,
  cashTargetType: CashTargetType,
  cashTargetAbsolute?: number | null,
  cashTargetPercent?: number | null,
) {
  const cashTarget = getCashTarget(
    accountBalance,
    cashTargetType,
    cashTargetAbsolute,
    cashTargetPercent,
  );
  const percentageValue = getCashTargetPercentage(
    accountBalance,
    cashTargetType,
    cashTargetAbsolute,
    cashTargetPercent,
  );

  let primaryValue: string | undefined;
  let secondaryValue = "";
  if (cashTargetType === "absolute") {
    primaryValue = formatCurrency(cashTarget);
    secondaryValue = formatPercent(percentageValue, 2);
  } else if (cashTargetType === "percent") {
    primaryValue = formatPercent(percentageValue, 2);
    secondaryValue = formatCurrency(cashTarget);
  }

  return typeof primaryValue === "undefined"
    ? naLabel
    : `${primaryValue} (${secondaryValue})`;
}

export function displayMinMaxCashTarget(
  accountBalance: number,
  cashTargetCondition: CashTargetCondition,
  cashTargetAbsolute?: number | null,
  cashTargetPercent?: number | null,
) {
  if (cashTargetAbsolute === null && cashTargetPercent === null) {
    return naLabel;
  } else if (cashTargetAbsolute === null) {
    return displayCashTarget(
      accountBalance,
      "percent",
      cashTargetAbsolute,
      cashTargetPercent,
    );
  } else if (cashTargetPercent === null) {
    return displayCashTarget(
      accountBalance,
      "absolute",
      cashTargetAbsolute,
      cashTargetPercent,
    );
  } else {
    const absoluteDisplay = displayCashTarget(
      accountBalance,
      "absolute",
      cashTargetAbsolute,
      cashTargetPercent,
    );
    const percentDisplay = displayCashTarget(
      accountBalance,
      "percent",
      cashTargetAbsolute,
      cashTargetPercent,
    );

    return `${absoluteDisplay} ${cashTargetCondition} ${percentDisplay}`;
  }
}

export function displayForecastedTransferFrequency(
  frequency: RecurringTransferFrequency,
) {
  switch (frequency) {
    case "first":
      return "First";
    case "annually":
      return "Annually";
    case "biweekly":
      return "Every Two Weeks";
    case "monthly":
      return "Monthly";
    case "quarterly":
      return "Quarterly";
    case "semiannually":
      return "Semi-Annually";
    case "other":
      return "Other";
    default:
      throw new Error(`Unknown frequency type ${frequency}`);
  }
}

export const CashSettingsHelp = () => (
  <section>
    <p>
      Cash Settings are used to define the target cash balance for an account.
      They have three components to them: target value, min threshold, and max
      threshold. The target value is the desired cash balance for the account.
      The minimum and maximum thresholds are used to trigger rebalances.
    </p>
    <h3>Cash Target</h3>
    <p>
      The cash target is used in the target model for an account when
      calculating rebalances. Cash targets sit <em>alongside</em> an assigned
      model, meaning the target model will be applied to the total balance of
      the account minus the target cash value. Cash targets should <em>not</em>{" "}
      be used as an asset allocation mechanism, for example managing risk. They
      should only be used to manage liquidity, such as for management fees and
      other planned expenses.
    </p>
  </section>
);

export function useQueryCashSettings({ enabled = true } = {}) {
  return useAuthenticatedQuery<
    UnpackResponse<CashSettingsController["getAll"]>,
    { data: CashSetting[] },
    [string]
  >({
    queryKey: ["cash-settings"],
    queryFn: async (cashSettings) => ({
      data: cashSettings.data,
    }),
    queryOptions: { enabled },
  });
}

export function useQueryCashSetting(id: number, { enabled = true } = {}) {
  return useAuthenticatedQuery<
    UnpackResponse<CashSettingsController["getOne"]>,
    CashSetting,
    [string, number]
  >({
    queryKey: ["cash-settings", id],
    queryFn: async (cashSettings) => cashSettings.data,
    queryOptions: { enabled },
  });
}

export function useCreateCashSetting() {
  return useAuthenticatedMutationNew<
    UnpackResponse<CashSettingsController["create"]>,
    number,
    Omit<CashSetting, "id">
  >({
    mutationKey: ["POST", "cash-settings"],
    mutationFn: ({ data }: { data: number }) => data,
  });
}

export function useUpdateCashSetting(cashSettingId: number) {
  return useAuthenticatedMutationNew<
    UnpackResponse<CashSettingsController["update"]>,
    void,
    Omit<CashSetting, "id">
  >({
    mutationKey: ["PUT", "cash-settings", cashSettingId],
    mutationFn: () => null,
  });
}

export function useDeleteCashSetting(deleteCashSettingId: number) {
  return useAuthenticatedMutationNew<
    UnpackResponse<CashSettingsController["delete"]>,
    void,
    { newCashSettingId: number | null }
  >({
    mutationKey: ["PUT", "cash-settings", deleteCashSettingId, "replace"],
    mutationFn: () => null,
  });
}

export function useQueryCashSettingAssignmentCounts(
  id: number,
  { enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<
      CashSettingsController["getCashSettingAccountAssignmentCount"]
    >,
    number,
    [string, number, string]
  >({
    queryKey: ["cash-settings", id, "assignments-count"],
    queryFn: async (cashSettings) => cashSettings.data,
    queryOptions: { enabled },
  });
}

export function useQueryAccountCashSetting(
  id: number,
  { enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<CashSettingsController["getAccountSetting"]>,
    AccountCashSetting,
    [string, string, number]
  >({
    queryKey: ["cash-settings", "accounts", id],
    queryFn: async (cashSettings) => cashSettings.data,
    queryOptions: { enabled },
  });
}
