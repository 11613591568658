import IMask, { Masked, FactoryOpts } from "imask";

function masker<TValue>({
  masked,
  transform,
}: {
  masked: Masked | FactoryOpts;
  transform?: (value: any) => TValue;
}) {
  return (() => {
    const unmask = IMask.createPipe(
      masked,
      IMask.PIPE_TYPE.MASKED,
      IMask.PIPE_TYPE.UNMASKED,
    );

    const mask = masked.mask;

    return {
      mask,
      transform: transform ?? unmask,
      unmask,
    };
  })();
}

export const phoneMask = masker({
  masked: new IMask.MaskedPattern({
    mask: "{+1} (000)-000-0000",
  }),
});

export const zipCodeMask = masker({
  masked: new IMask.MaskedPattern({
    mask: "00000[-0000]",
  }),
});
