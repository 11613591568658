import { Col, Row } from "react-bootstrap";
import TabContainerWithTabs from "../components/TabContainer";
import RebalanceActionButtons from "./RebalanceActionButtons";
import RebalancesNav from "./RebalancesNav";
import RebalancesApprovedTable from "./RebalancesApprovedTable";

const RebalancesApproved = ({
  householdId,
  canPerformAction,
}: {
  householdId?: string;
  canPerformAction?: boolean;
}) => {
  return (
    <>
      <Row>
        <Col>
          <RebalanceActionButtons />
        </Col>
      </Row>
      <TabContainerWithTabs tabs={RebalancesNav}>
        <RebalancesApprovedTable />
      </TabContainerWithTabs>
    </>
  );
};

export default RebalancesApproved;
