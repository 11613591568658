import { useMutation } from "@tanstack/react-query";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type {
  BillingReport,
  BillingReportHousehold,
} from "../../../api/src/billing/lib";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import { useQueryAccounts } from "../clients/account/lib";
import { useQueryHouseholds } from "../clients/household/lib";
import ActionButton from "../components/ActionButton";
import Content from "../components/Content";
import FormError from "../components/FormError";
import { RowExpander } from "../components/Table/RowExpander";
import { Table, metaDefault, useTable } from "../components/Table/Table";
import {
  deserializeDate,
  useAuthenticatedFetch,
  useAuthenticatedFileFetch,
  useAuthenticatedMutation,
} from "../lib/api";
import { DateWithoutTime } from "../lib/date";
import { boolToYesNo, capitalize, displayAccountName } from "../lib/display";
import { formatCurrency, formatPercent } from "../lib/numbers";
import { useQueryExportBillingReport } from "./lib";

type BillingHouseholdRow = {
  type: "household" | "account";
  id?: number;
  name?: string;
  accounts?: BillingHouseholdRow[];
  totalAUM: number;
  totalFee: number;
  totalDebited?: number;
  effectiveFeeRate: number;
  totalAssetAdjustmentDollarAmount: number;
  totalFeeSplit: number;
  startDate?: Date;
  endDate?: Date;
  relocatedAccountId?: number;
  relocatedAccountName?: string;
  totalCash?: number;
};

const householdColumnHelper = createColumnHelper<BillingHouseholdRow>();
const cashMinusDebitedCellRender = (
  info: CellContext<BillingHouseholdRow, unknown>,
) => {
  const value = info.getValue() as number;
  const className = value < 0 ? "text-end text-danger" : "text-end";
  return <span className={className}>{formatCurrency(value, 2)}</span>;
};

const BillingHouseholdsTable = ({
  households,
  viewMode,
}: {
  households: BillingReportHousehold[];
  viewMode: string;
}) => {
  const viewModeRef = useRef(viewMode);

  useEffect(() => {
    viewModeRef.current = viewMode;
  }, [viewMode]);

  const columns = useMemo(
    () => [
      householdColumnHelper.accessor("name", {
        cell: (info) => (
          <div
            style={{
              // Since rows are flattened by default,
              // we can use the row.depth property
              // and paddingLeft to visually indicate the depth
              // of the row
              paddingLeft: `${info.row.depth * 2}rem`,
            }}
          >
            {info.row.original.type === "household" &&
            typeof info.row.original.id === "undefined" ? (
              <em>Unassigned</em>
            ) : (
              <Link
                to={`/clients/${info.row.original.type}s/${info.row.original.id}`}
              >
                {info.getValue() ?? ""}
              </Link>
            )}
          </div>
        ),
        aggregatedCell: (info) => (
          <>
            {info.row.getCanExpand() ? <RowExpander row={info.row} /> : ""}{" "}
            <Link
              to={`/clients/${info.row.original.type}s/${info.row.original.id}`}
            >
              {info.getValue()} {viewModeRef.current}
            </Link>
          </>
        ),
        header: () => "Name",
      }),
      householdColumnHelper.accessor((row) => row.totalAUM ?? 0, {
        id: "totalAUM",
        cell: (info) => formatCurrency(info.getValue(), 2),
        aggregatedCell: (info) => formatCurrency(info.getValue(), 2),
        header: () => "AUM",
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
        enableColumnFilter: false,
      }),
      householdColumnHelper.accessor("totalFee", {
        cell: (info) => formatCurrency(info.getValue(), 2),
        aggregatedCell: (info) => formatCurrency(info.getValue(), 2),
        header: () => "Fee",
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
        enableColumnFilter: false,
      }),
      householdColumnHelper.accessor(
        (row) => row.totalDebited ?? row.totalFee,
        {
          id: "totalDebited",
          cell: (info) => formatCurrency(info.getValue(), 2),
          aggregatedCell: (info) => formatCurrency(info.getValue(), 2),
          header: () => "Debited",
          meta: {
            className: "text-end",
            headerClassName: "text-end",
          },
          enableColumnFilter: false,
        },
      ),
      householdColumnHelper.accessor(
        (row) => (row.totalCash ?? 0) - (row.totalDebited ?? row.totalFee),
        {
          id: "cashMinusDebited",
          cell: cashMinusDebitedCellRender,
          aggregatedCell: cashMinusDebitedCellRender,
          header: () => "CashMinusDebited",
          meta: {
            className: "text-end",
            headerClassName: "text-end",
          },
          enableColumnFilter: false,
        },
      ),
      householdColumnHelper.accessor("effectiveFeeRate", {
        cell: (info) => formatPercent(info.getValue(), 2),
        aggregatedCell: (info) => formatPercent(info.getValue(), 3),
        header: () => "Effective Fee Rate",
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
        enableColumnFilter: false,
      }),
      householdColumnHelper.accessor("totalAssetAdjustmentDollarAmount", {
        cell: (info) => formatCurrency(info.getValue(), 2),
        aggregatedCell: (info) => formatCurrency(info.getValue(), 2),
        header: () => "Asset Exclusions",
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
        enableColumnFilter: false,
      }),
      householdColumnHelper.accessor("totalFeeSplit", {
        cell: (info) => formatCurrency(info.getValue(), 2),
        aggregatedCell: (info) => formatCurrency(info.getValue(), 2),
        header: () => "Fee Splits",
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
        enableColumnFilter: false,
      }),
      householdColumnHelper.accessor("startDate", {
        cell: (info) => info.getValue()?.toLocaleDateString(),
        header: () => "Start Date",
      }),
      householdColumnHelper.accessor("endDate", {
        cell: (info) => info.getValue()?.toLocaleDateString(),
        header: () => "End Date",
      }),
      householdColumnHelper.accessor((row) => row.relocatedAccountName ?? "", {
        id: "relocatedAccountName",
        cell: (info) =>
          typeof info.row.original.relocatedAccountId === "undefined" ? null : (
            <Link
              to={`/clients/accounts/${info.row.original.relocatedAccountId}`}
            >
              {info.getValue()}
            </Link>
          ),
        header: () => "Relocated To",
      }),
    ],
    [],
  );

  const {
    isPending: isPendingAccounts,
    isError: isErrorAccounts,
    data: dataAccounts,
  } = useQueryAccounts({ includeInactive: true, pageSize: 10000 });

  const {
    isPending: isPendingHouseholds,
    isError: isErrorHouseholds,
    data: dataHouseholds,
  } = useQueryHouseholds();

  const accountsMap = useMemo(
    () => _.keyBy(dataAccounts?.data ?? [], "id"),
    [dataAccounts?.data],
  );

  const householdsMap = useMemo(
    () => _.keyBy(dataHouseholds?.data ?? [], "id"),
    [dataHouseholds?.data],
  );

  const householdRows: BillingHouseholdRow[] = useMemo(
    () =>
      households.map((household) => ({
        ...household,
        type: "household",
        id: household.householdId,
        name:
          typeof household.householdId === "undefined"
            ? undefined
            : householdsMap[household.householdId]?.name,
        accounts: household.accounts.map(
          (account): BillingHouseholdRow => ({
            ...account,
            type: "account",
            id: account.accountId,
            name: displayAccountName(
              accountsMap[account.accountId]?.displayName,
              accountsMap[account.accountId]?.displayNumber,
            ),
            relocatedAccountName:
              typeof account.relocatedAccountId === "undefined"
                ? undefined
                : displayAccountName(
                    accountsMap[account.relocatedAccountId]?.displayName,
                    accountsMap[account.relocatedAccountId]?.displayNumber,
                  ),
            startDate: account.startDate,
            endDate: account.endDate,
          }),
        ),
      })),
    [accountsMap, households, householdsMap],
  );

  const accountRows: BillingHouseholdRow[] = useMemo(
    () =>
      householdRows.flatMap(
        (household) =>
          household?.accounts?.map(
            (account): BillingHouseholdRow => ({
              ...account,
              type: "account",
              id: account.id,
              name: account.name,
              relocatedAccountName: account.relocatedAccountName,
              startDate: account.startDate,
              endDate: account.endDate,
            }),
          ) || [],
      ),
    [householdRows],
  );

  const { table } = useTable({
    columns,
    data: viewMode === "Household" ? householdRows : accountRows,
    getRowId: (row) => `${row.type}-${row.id ?? ""}`,
    getSubRows: (row) => (row.accounts ?? []) as BillingHouseholdRow[],
    autoResetPageIndex: true,
    meta: metaDefault,
    initialState: {
      sorting: [{ id: "name", desc: false }],
    },
  });

  return isPendingHouseholds || isPendingAccounts ? (
    <Loading />
  ) : isErrorHouseholds || isErrorAccounts ? (
    <FormError message="Failed to load households and accounts" />
  ) : (
    <Table table={table} />
  );
};

type BillingSplitRow = {
  amount: number;
  name: string;
  splitterName: string;
};

const splitColumnHelper = createColumnHelper<BillingSplitRow>();

const BillingSplitsTable = ({
  households,
}: {
  households: BillingReportHousehold[];
}) => {
  const columns = useMemo(
    () => [
      splitColumnHelper.accessor("name", {
        header: () => "Name",
      }),
      splitColumnHelper.accessor("splitterName", {
        header: () => "Splitter",
        enableColumnFilter: false,
      }),
      splitColumnHelper.accessor("amount", {
        cell: (info) => formatCurrency(info.getValue(), 2),
        header: () => "Total",
        enableColumnFilter: false,
      }),
    ],
    [],
  );

  const rows: BillingSplitRow[] = useMemo(() => {
    const billingSplits = households
      .flatMap((household) => household.accounts)
      .flatMap((account) => account.billingSplits);

    const combinedBillingSplits = _.groupBy(billingSplits, "name");

    return Object.entries(combinedBillingSplits).map(([name, splits]) => ({
      name,
      splitterName: splits[0].splitterName,
      amount: splits.reduce((sum, split) => sum + split.amount, 0),
    }));
  }, [households]);

  const { table } = useTable({
    columns,
    data: rows,
    autoResetPageIndex: true,
    meta: metaDefault,
    initialState: {
      sorting: [{ id: "name", desc: false }],
    },
  });

  return <Table table={table} />;
};

const BillingReportInfo = () => {
  const { reportId } = useParams();

  const {
    isPending: isPendingBillingReports,
    isError: isErrorBillingReports,
    data: billingReportBody,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<BillingController["getBillingReport"]>>
  >(`/billing/reports/${reportId}`);

  const billingReport: BillingReport | undefined = useMemo(() => {
    if (typeof billingReportBody?.data !== "undefined") {
      return deserializeBillingReport(billingReportBody.data);
    }
  }, [billingReportBody?.data]);

  const { isLoading: isLoadingInvoices, refetch: downloadInvoices } =
    useAuthenticatedFileFetch(
      `/billing/reports/${reportId}/invoices`,
      { method: "POST" },
      {
        enabled: false,
      },
    );

  const { isLoading: isLoadingFeeUploadFile, refetch: downloadFeeUploadFile } =
    useAuthenticatedFileFetch(
      `/billing/reports/${reportId}/fee-upload`,
      { method: "POST" },
      {
        enabled: false,
      },
    );

  const deleteReport = useAuthenticatedMutation(
    `/billing/reports/${reportId}`,
    { method: "DELETE" },
  );

  const navigate = useNavigate();
  const notificationContext = useContext(NotificationContext);

  const deleteReportMutation = useMutation({
    mutationFn: async () => {
      await deleteReport();
      notificationContext.pushNotification({
        id: `billing-report-${reportId}`,
        header: "Billing Report Deleted",
        body: `Billing report for ${billingReport?.endDate.toLocaleDateString()} deleted`,
        variant: "warning",
      });
      navigate("..");
    },
  });

  const ViewModeSelector = ({
    viewMode,
    setViewMode,
  }: {
    viewMode: string;
    setViewMode: (val: string) => void;
  }) => {
    const handleChangeViewMode = useCallback(
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        setViewMode(e.target.value);
      },
      [setViewMode],
    );

    return (
      <Col sm={8} md={6} xl={4} xxl={3} className="mb-3">
        <Form.Group controlId="form-calculation-type">
          <Form.Label>View by</Form.Label>
          <Form.Select value={viewMode} onChange={handleChangeViewMode}>
            {["Household", "Account"].map((mode) => (
              <option key={mode} value={mode}>
                {mode}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
    );
  };
  const [viewMode, setViewMode] = useState("Household");
  const viewModeSelector = (
    <ViewModeSelector viewMode={viewMode} setViewMode={setViewMode} />
  );

  const { isLoading: isLoadingExport, refetch: refetchExport } =
    useQueryExportBillingReport(parseInt(reportId ?? ""));

  const onExport = useCallback(async () => {
    await refetchExport();
  }, [refetchExport]);

  return isPendingBillingReports ? (
    <Loading />
  ) : isErrorBillingReports ? (
    <FormError message="Failed to load billing report" />
  ) : typeof billingReport === "undefined" ? (
    <FormError message="Billing Report not found" />
  ) : (
    <>
      <Row>
        <Col>
          <h1>Billing Report Details</h1>
        </Col>
        <Col md="auto">
          <ButtonToolbar className="mb-3 justify-content-end">
            <ActionButton
              variant="secondary"
              label="Download Invoices"
              icon="/icons/audit.svg"
              onClick={() => downloadInvoices()}
              className="me-2"
              disabled={isLoadingInvoices}
            />
            <ActionButton
              variant="secondary"
              label="Download Fee Upload"
              icon="/icons/buy.svg"
              onClick={() => downloadFeeUploadFile()}
              className="me-2"
              disabled={isLoadingFeeUploadFile}
            />
            <ActionButton
              variant="secondary"
              label="Export"
              icon="/icons/folded-list.svg"
              onClick={onExport}
              className="me-2"
              disabled={isLoadingExport}
            />
            <ActionButton
              variant="secondary"
              label="Delete"
              icon="/icons/trash.svg"
              onClick={() => deleteReportMutation.mutate()}
              disabled={deleteReportMutation.isPending}
            />
          </ButtonToolbar>
        </Col>
      </Row>
      <Content>
        <Row>
          <Col xxl={2} md={4} sm={6} className="mb-2">
            <Form.Group controlId="form-period">
              <Form.Label>Billing Date</Form.Label>
              <Form.Control
                type="text"
                value={billingReport.endDate.toLocaleDateString()}
                plaintext
                readOnly
              />
            </Form.Group>
          </Col>
          <Col xl={2} md={4} sm={6} className="mb-2">
            <Form.Group controlId="form-isFirmWideExecution">
              <Form.Label>Is Firm-Wide Execution?</Form.Label>
              <Form.Control
                type="text"
                value={boolToYesNo(billingReport.isFirmWideExecution)}
                plaintext
                readOnly
              />
            </Form.Group>
          </Col>
          {billingReport.status !== "complete" ? (
            <Col xl={2} md={4} sm={6} className="mb-2">
              <Form.Group controlId="form-status">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  type="text"
                  value={capitalize(billingReport.status)}
                  plaintext
                  readOnly
                />
              </Form.Group>
            </Col>
          ) : (
            <>
              <Col xl={2} md={4} sm={6} className="mb-2">
                <Form.Group controlId="form-aum">
                  <Form.Label>Billable AUM</Form.Label>
                  <Form.Control
                    type="text"
                    value={formatCurrency(billingReport.billableAUM, 2)}
                    plaintext
                    readOnly
                  />
                  <Form.Text>
                    Total AUM: {formatCurrency(billingReport.totalAUM, 2)}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xl={2} md={4} sm={6} className="mb-2">
                <Form.Group controlId="form-debited">
                  <Form.Label>Total Debited</Form.Label>
                  <Form.Control
                    type="text"
                    value={formatCurrency(billingReport.totalFee, 2)}
                    plaintext
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col xl={2} md={4} sm={6} className="mb-2">
                <Form.Group controlId="form-effectiveFeeRate">
                  <Form.Label>Effective Fee Rate</Form.Label>
                  <Form.Control
                    type="text"
                    value={formatPercent(billingReport.effectiveFeeRate, 2)}
                    plaintext
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col xl={2} md={4} sm={6} className="mb-2">
                <Form.Group controlId="form-totalAssetAdjustmentDollarAmount">
                  <Form.Label>Total Asset Exlusions</Form.Label>
                  <Form.Control
                    type="text"
                    value={formatCurrency(
                      billingReport.totalAssetAdjustmentDollarAmount,
                      2,
                    )}
                    plaintext
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col xl={2} md={4} sm={6} className="mb-2">
                <Form.Group controlId="form-totalRelocatedFees">
                  <Form.Label>Total Relocated Fees</Form.Label>
                  <Form.Control
                    type="text"
                    value={formatCurrency(billingReport.totalRelocatedFees, 2)}
                    plaintext
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col xl={2} md={4} sm={6} className="mb-2">
                <Form.Group controlId="form-totalFeeSplit">
                  <Form.Label>Total Fee Splits</Form.Label>
                  <Form.Control
                    type="text"
                    value={formatCurrency(billingReport.totalFeeSplit ?? 0, 2)}
                    plaintext
                    readOnly
                  />
                </Form.Group>
              </Col>
            </>
          )}
          <Col xxl={2} md={4} sm={6} className="mb-2">
            <Form.Group controlId="form-createdTime">
              <Form.Label>Created Date</Form.Label>
              <Form.Control
                type="text"
                value={billingReport.createdTime.toLocaleString()}
                plaintext
                readOnly
              />
            </Form.Group>
          </Col>
          {billingReport.status !== "complete" ? null : (
            <>
              <Col xxl={2} md={4} sm={6} className="mb-2">
                <Form.Group controlId="form-latestInvoiceDownloadTime">
                  <Form.Label>Invoices Download Time</Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      billingReport.latestInvoiceDownloadTime?.toLocaleString() ??
                      "N/A"
                    }
                    plaintext
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} md={4} sm={6} className="mb-2">
                <Form.Group controlId="form-latestCustodianFeeFileDownloadTime">
                  <Form.Label>Fee File Download Time</Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      billingReport.latestCustodianFeeFileDownloadTime?.toLocaleString() ??
                      "N/A"
                    }
                    plaintext
                    readOnly
                  />
                </Form.Group>
              </Col>
            </>
          )}
        </Row>
        {billingReport.status !== "complete" ? null : (
          <>
            <Row>
              {viewModeSelector}
              <>
                <h2>{viewMode === "Household" ? "Households" : "Accounts"}</h2>
                <BillingHouseholdsTable
                  households={billingReport.households}
                  viewMode={viewMode}
                />
              </>
            </Row>
            {billingReport.households
              .flatMap((household) => household.accounts)
              .flatMap((account) => account.billingSplits).length <=
            0 ? null : (
              <Row className="mt-3">
                <h2>Billing Splits</h2>
                <BillingSplitsTable households={billingReport.households} />
              </Row>
            )}
          </>
        )}
      </Content>
    </>
  );
};

export default BillingReportInfo;

export function deserializeBillingReport(
  billingReport: SerializedObject<BillingReport>,
) {
  return {
    ...billingReport,
    startDate: new DateWithoutTime(billingReport.startDate),
    endDate: new DateWithoutTime(billingReport.endDate),
    createdTime: deserializeDate(billingReport.createdTime),
    latestCustodianFeeFileDownloadTime: deserializeDate(
      billingReport.latestCustodianFeeFileDownloadTime,
    ),
    latestInvoiceDownloadTime: deserializeDate(
      billingReport.latestInvoiceDownloadTime,
    ),
    households: billingReport.households.map((household) => ({
      ...household,
      accounts: household.accounts.map((account) => ({
        ...account,
        startDate: new DateWithoutTime(account.startDate),
        endDate: new DateWithoutTime(account.endDate),
      })),
    })),
  };
}
