import { Breadcrumb } from "react-bootstrap";
import { matchRoutes, NavLink, RouteObject } from "react-router-dom";

const Breadcrumbs = ({
  routes,
  location,
}: {
  routes: RouteObject[];
  location: string;
}) => {
  const matches = (matchRoutes(routes, location) ?? []).filter(
    (match) => match.route.handle,
  );

  return (
    <Breadcrumb
      className="breadcrumbs-custom pb-1"
      aria-label="breadcrumbs"
      role="list"
    >
      {matches.map((crumb, index) => (
        <Breadcrumb.Item
          key={index}
          active={matches.length - 1 === index}
          linkAs={NavLink}
          linkProps={{ to: crumb.pathnameBase }}
        >
          {crumb.route.handle?.crumb()}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
