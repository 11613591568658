import {
  Cell,
  ColumnDef,
  ExpandedState,
  GroupingState,
  Header,
  RowData,
  RowSelectionState,
  SortingState,
  TableOptions,
  TableState,
  Table as TanTable,
  Row as TanTableRow,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Table as BsTable } from "react-bootstrap";
import SubmitButton from "../SubmitButton";
import ColumnSearchBox from "./ColumnSearchBox";
import { EmptyEditableTableRow } from "./EditableTableRow";
import { RowExpander, AllRowsExpander } from "./RowExpander";
import TablePager from "./TablePager";

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    editedRows: Record<number, TData>;
    setEditedRows: React.Dispatch<React.SetStateAction<Record<number, TData>>>;
    saveRow: (row: TData) => void;
    deleteRow: (id: number) => void;
    approveRow: (id: number) => void;
    setCellData?: <TValue>(
      properties: (string | number)[],
      columnId: string,
      value: TValue,
      type?: string,
    ) => void;
  }

  interface ColumnMeta<TData, TValue> {
    filterComponent?: React.ComponentType<{
      header: Header<TData, TValue>;
    }>;
    editableComponent?: (cell: Cell<TData, TValue>) => React.ReactNode;
    headerClassName?: string;
    className?: string;
    headerStyle?: React.CSSProperties;
    style?: React.CSSProperties;
  }
}

export const metaDefault = {
  editedRows: {},
  setEditedRows: () => null,
  saveRow: () => null,
  deleteRow: () => null,
  approveRow: () => null,
  setCellData: () => null,
};

export function useSkipper() {
  const shouldSkipRef = useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export function useTable<TRow>({
  state,
  defaultColumn,
  ...props
}: {
  state?: Partial<TableState>;
  defaultColumn?: Partial<ColumnDef<TRow, unknown>>;
} & Omit<TableOptions<TRow>, "getCoreRowModel">) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>(
    props.initialState?.sorting ?? [],
  );
  const [expanded, setExpanded] = React.useState<ExpandedState>(
    props.initialState?.expanded ?? {},
  );
  const [grouping, setGrouping] = React.useState<GroupingState>(
    props.initialState?.grouping ?? [],
  );

  const table = useReactTable({
    state: {
      sorting,
      rowSelection,
      expanded,
      grouping,
      ...state,
    },
    defaultColumn: {
      minSize: undefined,
      maxSize: undefined,
      size: undefined,
      ...defaultColumn,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    onGroupingChange: setGrouping,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...props,
  });

  return { table, rowSelection, setRowSelection };
}

// Use function syntax below, because generics on arrow syntax don't work without hacks.

function TableRow<TRow>({ row }: { row: TanTableRow<TRow> }) {
  return (
    <tr>
      {row.getVisibleCells().map((cell) => (
        <td
          key={cell.id}
          id={cell.id}
          className={cell.column.columnDef.meta?.className}
          style={cell.column.columnDef.meta?.headerStyle}
        >
          {cell.getIsGrouped() ? (
            // If it's a grouped cell, add an expander and row count
            <>
              <RowExpander row={cell.row} />
              {flexRender(cell.column.columnDef.cell, cell.getContext())} (
              {row.subRows.length})
            </>
          ) : cell.getIsAggregated() ? (
            // If the cell is aggregated, use the Aggregated
            // renderer for cell
            flexRender(
              cell.column.columnDef.aggregatedCell ??
                cell.column.columnDef.cell,
              cell.getContext(),
            )
          ) : cell.getIsPlaceholder() ? null : (
            flexRender(cell.column.columnDef.cell, cell.getContext())
          )}
        </td>
      ))}
    </tr>
  );
}

export function Table<TRow>({
  table,
  RowComp = TableRow,
  disablePagination = false,
  onSave,
  onEdit,
  onDiscard,
  editComp,
  enableAddRemove,
  onAdd,
  onRemove,
  showSearchButton = false,
  showSearchGroup = true,
  handleSearchClick = () => null,
  isSearching = false,
  isDisableGrouping = true,
  isFixedGrouping = true,
  className,
}: {
  table: TanTable<TRow>;
  RowComp?: React.ElementType;
  disablePagination?: boolean;
  onSave?: (row: TanTableRow<TRow>) => void;
  onEdit?: (row: TanTableRow<TRow>) => void;
  onDiscard?: (row: TanTableRow<TRow>) => void;
  editComp?: (row: TanTableRow<TRow>) => React.ReactNode;
  enableAddRemove?: boolean;
  onAdd?: (row?: TanTableRow<TRow>) => void;
  onRemove?: (row: TanTableRow<TRow>) => void;
  showSearchButton?: boolean;
  showSearchGroup?: boolean;
  handleSearchClick?: () => void;
  isSearching?: boolean;
  isDisableGrouping?: boolean;
  isFixedGrouping?: boolean;
  className?: string;
}) {
  const handleEnterKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
        handleSearchClick();
      }
    },
    [handleSearchClick],
  );

  const headerGroups = table
    .getHeaderGroups()
    .slice(table.getHeaderGroups().length - 1);

  return (
    <div>
      {showSearchGroup || showSearchButton ? (
        <div className="column-search-group" onKeyUp={handleEnterKeyUp}>
          {showSearchGroup &&
            headerGroups.map((headerGroup) =>
              headerGroup.headers.map((header) =>
                header.column.getCanFilter() ? (
                  <div className="column-search-item mb-3" key={header.id}>
                    <ColumnSearchBox
                      header={header}
                      FilterComp={header.column.columnDef.meta?.filterComponent}
                    />
                  </div>
                ) : null,
              ),
            )}
          {showSearchButton ? (
            <div className="column-search-item mb-3">
              <SubmitButton
                label="Search"
                size="sm"
                isSubmitting={isSearching}
                onClick={handleSearchClick}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      <BsTable responsive className={className}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    minWidth:
                      typeof header.column.columnDef.minSize === "undefined"
                        ? undefined
                        : header.getSize(),
                    width:
                      typeof header.column.columnDef.size === "undefined"
                        ? undefined
                        : header.getSize(),
                    ...header.column.columnDef.meta?.headerStyle,
                  }}
                  className={header.column.columnDef.meta?.headerClassName}
                >
                  {header.isPlaceholder ? null : (
                    <>
                      {header.column.getCanGroup() &&
                      !isFixedGrouping &&
                      !isDisableGrouping ? (
                        // If the header can be grouped, let's add a toggle
                        <div
                          onClick={header.column.getToggleGroupingHandler()}
                          className="d-inline user-select-none pe-auto me-2"
                          role="button"
                        >
                          {header.column.getIsGrouped() ? (
                            <img
                              src="/icons/exit.svg"
                              alt="layers icon"
                              width={20}
                              title="Ungroup"
                            />
                          ) : (
                            <img
                              src="/icons/layers.svg"
                              alt="layers icon"
                              width={20}
                              title="Group"
                            />
                          )}
                        </div>
                      ) : null}
                      {!header.column.getIsGrouped() ? null : (
                        <AllRowsExpander table={table} />
                      )}
                      <div
                        onClick={header.column.getToggleSortingHandler()}
                        className={
                          header.column.getCanSort()
                            ? `th-sortable d-inline user-select-none ${
                                header.column.getIsSorted() === false
                                  ? ""
                                  : `th-sortable-${header.column.getIsSorted()}`
                              }`
                            : ""
                        }
                        role={header.column.getCanSort() ? "button" : ""}
                      >
                        <span>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </span>
                      </div>
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {enableAddRemove && table.getRowModel().rows.length <= 0
            ? [<EmptyEditableTableRow key="" onAdd={onAdd} />]
            : table
                .getRowModel()
                .rows.map((row) => (
                  <RowComp
                    key={row.id}
                    row={row}
                    onSave={onSave}
                    onEdit={onEdit}
                    onDiscard={onDiscard}
                    editComp={editComp}
                    enableAddRemove={enableAddRemove}
                    onAdd={onAdd}
                    onRemove={onRemove}
                  />
                ))}
        </tbody>
      </BsTable>
      {disablePagination ? null : <TablePager table={table} />}
    </div>
  );
}
