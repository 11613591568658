import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Row as TanTableRow, createColumnHelper } from "@tanstack/react-table";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import type { FirmsController } from "../../../api/src/firms/firms.controller";
import type {
  SecurityAllocationRequest,
  SecurityCategoryAssetClass,
  SecurityCategoryAssetClassColumns,
} from "../../../api/src/firms/firms.service";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import ActionButton from "../components/ActionButton";
import TabContainerWithTabs from "../components/TabContainer";
import EditableTableRow from "../components/Table/EditableTableRow";
import { Table, useTable } from "../components/Table/Table";
import {
  deserializeDate,
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
  useAuthenticatedQuery,
} from "../lib/api";
import { useRoles } from "../lib/auth";
import { processFileResponse } from "../lib/file";
import { formatPercent } from "../lib/numbers";
import FirmNav from "./FirmNav";
import { securityCategoryAssetClassColumnsMap } from "./lib";

function useQueryExportCategoryAssetClasses() {
  return useAuthenticatedQuery<
    UnpackResponse<FirmsController["exportSecurityCategoriesAssetClasses"]>,
    void
  >({
    queryKey: ["firm", "security-category-asset-classes-allocations", "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}

type SecurityCategoryAssetClassRow = SecurityCategoryAssetClass;

const CategoryAssetClass = () => {
  const roles = useRoles();
  const notificationContext = useContext(NotificationContext);

  // keeps Rows edit details in form of <Category_assetClass_allocation, value>
  const [fields, setFields] = useState<Record<string, number>>({});

  // keeps track of security allocations along with the changes made in form of
  // [{securityCategory: "category", usLargeCapEquity: {allocation: 10, isOverride: false}, updatedTime: "2021-10-10T00:00:00.000Z"}]
  const [securityAllocationsEdited, setCategoryAssetAllocation] = useState<
    SecurityCategoryAssetClass[]
  >([]);

  // keeps track of changed security allocations to submit to database for update in form of
  // {securityCategory: "category", mode: insertUpdate, usLargeCapEquity: 10}
  const [changedSecurityAllocations, setChangedSecurityAllocations] = useState<
    Record<string, SecurityAllocationRequest>
  >({});

  const columnHelper = useMemo(
    () => createColumnHelper<SecurityCategoryAssetClassRow>(),
    [],
  );

  // 1. get all security categories with asset class
  const {
    isPending: isPendingCategoryAssetClasses,
    data: dataCategoryAssetClasses,
  } = useAuthenticatedFetch<
    SerializedObject<
      UnpackResponse<FirmsController["getSecurityCategoriesAssetClasses"]>
    >
  >("/firm/security-category-asset-classes");

  const securityAssetAllocations = useMemo(() => {
    return (dataCategoryAssetClasses?.data ?? []).map(
      (securityCategoryAssetAllocation) => ({
        ...securityCategoryAssetAllocation,
        updatedTime: deserializeDate(
          securityCategoryAssetAllocation.updatedTime,
        ),
      }),
    );
  }, [dataCategoryAssetClasses?.data]);

  // 2. create asset class columns for each security category
  const assetClassColumns = Array.from(
    securityCategoryAssetClassColumnsMap.keys(),
  ) as SecurityCategoryAssetClassColumns[];

  // 3. define onChange for each cell
  const onChangeText = useCallback(
    (cellId: string, rowId: string, value: string) => {
      const updatedValue = +value;
      setFields({
        ...fields,
        [`${cellId}_allocation`]: updatedValue,
      });
    },
    [fields],
  );

  // 4. create columns for table
  const columns = useMemo(() => {
    const dynamicColumns = assetClassColumns.map((key) =>
      columnHelper.accessor(key, {
        header: () => securityCategoryAssetClassColumnsMap.get(key)?.label,
        cell: (info) => `${formatPercent(info.getValue().allocation / 100, 2)}`,
        enableColumnFilter: false,
        meta: {
          editableComponent: (cell) => (
            <InputGroup style={{ width: "115px" }}>
              <Form.Control
                type="number"
                value={fields[cell.id + "_allocation"]}
                onChange={(e) => {
                  return onChangeText(cell.id, cell.row.id, e.target.value);
                }}
              />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          ),
        },
      }),
    );
    return [
      columnHelper.accessor("securityCategory", {
        header: () => "Category",
      }),
      ...dynamicColumns,
      columnHelper.accessor("updatedTime", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "Updated Time",
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: "actions",
        size: 120,
      }),
    ];
  }, [assetClassColumns, columnHelper, fields, onChangeText]);

  // 5. onEditRow to update state of fields in memory with new values of columns
  const onEditRow = useCallback(
    (row: TanTableRow<SecurityCategoryAssetClassRow>) => {
      const securityAllocationsMap = new Map(
        securityAllocationsEdited.map((item) => [item.securityCategory, item]),
      );

      const currentRow = securityAllocationsMap.get(
        row.original.securityCategory,
      );

      if (typeof currentRow === "undefined") {
        throw new Error("Row not found in table: ", {
          cause: row?.id,
        });
      }

      const currentColumnValues: Record<string, number> = {};

      for (const key of assetClassColumns) {
        const value = currentRow[key as keyof SecurityCategoryAssetClass];
        if (
          typeof value === "object" &&
          value !== null &&
          "allocation" in value
        ) {
          currentColumnValues[`${row.id}_${key}_allocation`] = value.allocation;
        }
      }
      setFields({
        ...fields,
        ...currentColumnValues,
      });
    },
    [securityAllocationsEdited, fields, assetClassColumns],
  );

  // 6. Each row update that handles updating state of ui object and update the database to update the security allocations
  const onUpdateAssetAllocation = useCallback(
    (security: string, assetClassFields: Record<string, number>) => {
      setCategoryAssetAllocation(
        securityAllocationsEdited.map((securityAllocation) => {
          // work with current security edit
          if (securityAllocation.securityCategory !== security) {
            return securityAllocation;
          }

          // If total is not 100, return to original state
          const sum = Object.values(assetClassFields).reduce(
            (a, b) => a + b,
            0,
          );
          if (sum !== 100) {
            notificationContext.pushNotification({
              id: "asset-allocations",
              header: `Check total for ${security} Asset Allocations`,
              body: "Firm Asset Allocations do Not sum to 100 for the selected category",
              variant: "danger",
            });
            return securityAllocation;
          }

          // current state is same as default allocations for all asset classes
          const areAllDefaultAllocationsMatching = assetClassColumns.every(
            (column) => {
              const securityAllocationValue =
                securityAllocation[column]?.defaultAllocation;
              const assetClassFieldValue =
                assetClassFields[`${security}_${column}_allocation`];
              return securityAllocationValue === assetClassFieldValue;
            },
          );

          // current state is same as database (default or override) allocations for all asset classes
          const areAllOriginalAllocationsMatching = assetClassColumns.every(
            (column) => {
              const securityAllocationValue =
                securityAllocation[column]?.originalAllocation;
              const assetClassFieldValue =
                assetClassFields[`${security}_${column}_allocation`];
              return securityAllocationValue === assetClassFieldValue;
            },
          );

          // update db state values : if no changes are made compared to db values, just remove from update list
          if (areAllOriginalAllocationsMatching) {
            setChangedSecurityAllocations((changedSecurityAllocations) => {
              delete changedSecurityAllocations[security];
              return { ...changedSecurityAllocations };
            });
          } else if (areAllDefaultAllocationsMatching) {
            // update db state values : if all default allocations are matching, We need to delete from override table
            setChangedSecurityAllocations((changedSecurityAllocations) => ({
              ...changedSecurityAllocations,
              [security]: {
                mode: "DELETE",
              },
            }));
          } else {
            // update db state values : Insert or update the security allocation
            setChangedSecurityAllocations((changedSecurityAllocations) => ({
              ...changedSecurityAllocations,
              [security]: {
                mode: "INSERTUPDATE",
                ...assetClassColumns.reduce(
                  (
                    acc: Record<SecurityCategoryAssetClassColumns, number>,
                    column,
                  ) => {
                    acc[column] =
                      assetClassFields[`${security}_${column}_allocation`];
                    return acc;
                  },
                  {} as Record<SecurityCategoryAssetClassColumns, number>,
                ),
              },
            }));
          }

          function isSecurityCategoryAssetClassColumns(
            key: string,
          ): key is SecurityCategoryAssetClassColumns {
            return assetClassColumns.includes(
              key as SecurityCategoryAssetClassColumns,
            );
          }

          const keysToUse = Object.keys(securityAllocation).filter(
            isSecurityCategoryAssetClassColumns,
          );

          // update the security allocation with new values
          return keysToUse.reduce((result, key) => {
            const assetClassFieldKey = `${securityAllocation.securityCategory}_${key}_allocation`;
            const assetClassFieldValue = assetClassFields[assetClassFieldKey];
            return {
              ...result,
              [key]: {
                ...securityAllocation[key],
                allocation: assetClassFieldValue,
                isOverride:
                  assetClassFieldValue !==
                  securityAllocation[key].defaultAllocation,
              },
            };
          }, securityAllocation);
        }),
      );
    },
    [assetClassColumns, notificationContext, securityAllocationsEdited],
  );

  // 7. Reset Row
  const originalAssetClassValues = useMemo(() => {
    function isSecurityCategoryAssetClassColumns(
      key: string,
    ): key is SecurityCategoryAssetClassColumns {
      return assetClassColumns.includes(
        key as SecurityCategoryAssetClassColumns,
      );
    }

    return securityAssetAllocations.reduce(
      (result, securityCategoryAssetAllocation) => {
        const keysToUse = Object.keys(securityCategoryAssetAllocation).filter(
          isSecurityCategoryAssetClassColumns,
        );
        return {
          ...result,
          ...keysToUse.reduce(
            (result, key) => ({
              ...result,
              [`${securityCategoryAssetAllocation.securityCategory}_${key}_allocation`]:
                securityCategoryAssetAllocation[key].defaultAllocation,
            }),
            {},
          ),
        };
      },
      {},
    );
  }, [assetClassColumns, securityAssetAllocations]);

  const onResetRow = useCallback(
    (row: TanTableRow<SecurityCategoryAssetClassRow>) => {
      const category = row.original.securityCategory;

      onUpdateAssetAllocation(
        category,
        Object.entries(originalAssetClassValues)
          .filter(([key]) => key.startsWith(`${category}_`))
          .reduce(
            (acc, [key, value]) => {
              if (typeof value === "number") {
                return { ...acc, [key]: value };
              }
              return acc;
            },
            {} as Record<string, number>,
          ),
      );
    },
    [onUpdateAssetAllocation, originalAssetClassValues],
  );

  const hasAssetClassOverride = useMemo(() => {
    return (category: string) => {
      return assetClassColumns.some(
        (column) =>
          securityAllocationsEdited.find(
            (securityAllocation) =>
              securityAllocation.securityCategory === category,
          )?.[column]?.isOverride,
      );
    };
  }, [assetClassColumns, securityAllocationsEdited]);

  // 8. On Discard Row reset the fields to original values
  const onDiscardRow = useCallback(
    (row: TanTableRow<SecurityCategoryAssetClassRow>) => {
      const category = row.original.securityCategory;
      setFields({
        ...fields,
        ...Object.entries(originalAssetClassValues)
          .filter(([key]) => key.startsWith(`${category}_`))
          .reduce(
            (acc, [key, value]) => {
              if (typeof value === "number") {
                return { ...acc, [key]: value };
              }
              return acc;
            },
            {} as Record<string, number>,
          ),
      });
    },
    [fields, originalAssetClassValues],
  );

  // 9. Confirm Row Update
  const onConfirmRow = useCallback(
    (row: TanTableRow<SecurityCategoryAssetClassRow>) => {
      const rowFields = Object.keys(fields).reduce(
        (result, key) => {
          if (key.startsWith(`${row.id}_`)) {
            result[key] = fields[key];
          }
          return result;
        },
        {} as { [key: string]: number },
      );
      onUpdateAssetAllocation(row.id, rowFields);
    },
    [onUpdateAssetAllocation, fields],
  );

  // 10. Handle Discard Changes (Database Submit / Discard)
  const handleDiscardChanges = useCallback(() => {
    setCategoryAssetAllocation(securityAssetAllocations);
    setChangedSecurityAllocations({});
  }, [securityAssetAllocations]);

  // 11. Submit Changes to Database
  const queryClient = useQueryClient();

  const updateAssetAllocations = useAuthenticatedMutationAsync<
    SerializedObject<
      UnpackResponse<FirmsController["updateSecurityCategoriesAssetClasses"]>
    >
  >(
    "/firm/security-category-asset-classes-allocations",
    async (data: Record<string, SecurityAllocationRequest>) => ({
      method: "PUT",
      body: JSON.stringify(data),
    }),
  );
  const onSubmit = useMutation({
    mutationFn: async () => {
      try {
        const updatedAssetAllocations = await updateAssetAllocations(
          changedSecurityAllocations,
        );
        queryClient.setQueryData(
          ["/firm/security-category-asset-classes-allocations"],
          {
            data: updatedAssetAllocations.data,
          },
        );
        setChangedSecurityAllocations({});
        notificationContext.pushNotification({
          id: "asset-allocations",
          header: "Asset Allocations Updated",
          body: "Firm Asset allocation settings updated",
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save Asset allocations details", err);
        notificationContext.pushNotification({
          id: "asset-allocations",
          header: "Failed to Save Asset Allocations",
          body: "Firm Asset allocation settings not saved",
          variant: "danger",
        });
      }
    },
  });

  useEffect(() => {
    const setFieldsFromSecurityAllocation = () => {
      const newFields: Record<string, number> = {};

      securityAssetAllocations.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key !== "securityCategory" && key !== "updatedTime") {
            const typedKey = key as SecurityCategoryAssetClassColumns;
            const newKey = `${item.securityCategory}_${typedKey}_allocation`;
            newFields[newKey] = item[typedKey].allocation;
          }
        });
      });

      setFields(newFields);
    };
    if (
      !isPendingCategoryAssetClasses &&
      typeof dataCategoryAssetClasses?.data !== "undefined"
    ) {
      // on load, set the category allocations and fields
      setCategoryAssetAllocation(securityAssetAllocations);
      setFieldsFromSecurityAllocation();
    }
  }, [
    dataCategoryAssetClasses?.data,
    isPendingCategoryAssetClasses,
    securityAssetAllocations,
  ]);

  const { table } = useTable({
    columns: columns,
    data: securityAllocationsEdited,
    getRowId: (row) => row.securityCategory,
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    autoResetAll: false,
  });

  const { isLoading: isLoadingExport, refetch: refetchExport } =
    useQueryExportCategoryAssetClasses();

  const onExport = useCallback(async () => {
    await refetchExport();
  }, [refetchExport]);

  return (
    <TabContainerWithTabs tabs={FirmNav}>
      <Row>
        <Col>
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Export"
                icon="/icons/folded-list.svg"
                onClick={onExport}
                disabled={isLoadingExport}
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row
        hidden={Object.keys(changedSecurityAllocations).length <= 0}
        className="mb-1"
      >
        <Col className="d-grid gap-1" xl={6} md={12}>
          <Button
            className="mb-1"
            variant="success"
            onClick={() => onSubmit.mutateAsync()}
            disabled={onSubmit.isPending}
          >
            Submit{" "}
            {onSubmit.isPending ? <Spinner animation="border" size="sm" /> : ""}
          </Button>
        </Col>
        <Col className="d-grid gap-1" xl={6} md={12}>
          <Button
            className="mb-1"
            variant="danger"
            onClick={handleDiscardChanges}
            disabled={onSubmit.isPending}
          >
            Discard
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ pointerEvents: onSubmit.isPending ? "none" : "auto" }}>
          {isPendingCategoryAssetClasses ? (
            <Loading />
          ) : (
            <Table
              table={table}
              RowComp={roles.includes("Admin") ? EditableTableRow : undefined}
              onSave={onConfirmRow}
              onEdit={onEditRow}
              onDiscard={onDiscardRow}
              editComp={(row) => (
                <ActionButton
                  type="button"
                  onClick={() => onResetRow(row)}
                  disabled={
                    !hasAssetClassOverride(row.original.securityCategory)
                  }
                  variant="icon"
                  label="Reset"
                  icon="/icons/redo.svg"
                />
              )}
            />
          )}
        </Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default CategoryAssetClass;
