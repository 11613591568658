import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { Form, Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type { AuthController } from "../../../api/src/auth/auth.controller";
import type { UnpackResponse } from "../../../api/src/lib";
import Content from "../components/Content";
import { useErrorMessage } from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import SubmitButton from "../components/SubmitButton";
import { useAuthenticatedMutationAsync } from "../lib/api";
import { capitalize } from "../lib/display";
import { getSchemaFieldLabel } from "../lib/forms";

type UserCredentials = {
  username: string;
  password: string;
};

const schema: yup.ObjectSchema<UserCredentials> = yup.object({
  username: yup.string().required().label("Username"),
  password: yup.string().required().label("Password"),
});

const IntegrationCredentials = () => {
  const { service } = useParams();
  const navigate = useNavigate();

  const icon = service === "redtail" ? "/icons/redtail-logo.jpg" : "";

  const submitCredentials = useAuthenticatedMutationAsync<
    UnpackResponse<AuthController["authorizeService"]>
  >(`/auth/authorize`, async (code: string) => ({
    method: "POST",
    body: JSON.stringify({
      service,
      code,
    }),
  }));

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserCredentials>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { errorMessage, resetError, setError } = useErrorMessage();

  const onSubmit = useMutation({
    mutationFn: async ({ username, password }: UserCredentials) => {
      resetError();

      try {
        await submitCredentials(`${username}:${password}`);
        window.opener.location.reload();
        window.opener?.console.debug(`Authorized Service: ${service}`);
        window.close();
      } catch (err) {
        console.error("Failed to log in", err);
        setError("Username/password combination is invalid");
      }
    },
  });

  useEffect(() => {
    if (window.opener === null || window.opener === window) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="p-4">
      <Content>
        <div className="d-flex flex-column align-items-center">
          <div className="mb-3 bg-light p-1 rounded">
            <Image src="/logo.svg" height={70} />
          </div>
          <h1 className="mb-1d-flex align-items-center">
            Log In to{" "}
            <div className="d-inline-block bg-light p-1 me-1 rounded">
              <Image src={icon} height={40} />
            </div>
            {capitalize(service ?? "")}
          </h1>
          <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
            {errorMessage}
            <Form.Group controlId="form-username" className="mb-2">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.username)}
              </Form.Label>
              <Form.Control type="text" {...register("username")} />
              <FormFieldError field={errors.username} />
            </Form.Group>
            <Form.Group controlId="form-password" className="mb-3">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.password)}
              </Form.Label>
              <Form.Control type="password" {...register("password")} />
              <FormFieldError field={errors.password} />
            </Form.Group>
            <div>
              <SubmitButton label="Log In" isSubmitting={isSubmitting} />
            </div>
          </Form>
        </div>
      </Content>
    </div>
  );
};

export default IntegrationCredentials;
