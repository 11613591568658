import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ReportsController } from "../../../api/src/reports/reports.controller";
import DateInput from "../components/DateInput";
import FormFieldError from "../components/FormFieldError";
import SubmitButton from "../components/SubmitButton";
import { useAuthenticatedQuery } from "../lib/api";
import { toISODateNoTimezone } from "../lib/date";
import { processFileResponse } from "../lib/file";
import { getSchemaFieldLabel } from "../lib/forms";

export function useQueryExportAccountPositions(date: Date) {
  return useAuthenticatedQuery<
    UnpackResponse<ReportsController["exportAccountPositions"]>,
    void
  >({
    queryKey: ["reports", "exports", "positions", toISODateNoTimezone(date)],
    queryFn: async () => {},
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}

const schema = yup.object({
  date: yup.date().required().label("Date"),
});

const ExportAccountPositions = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      date: new Date(),
    },
  });

  const date = watch("date");

  const { isLoading: isLoadingExport, refetch: refetchExport } =
    useQueryExportAccountPositions(date);

  const onExport = useCallback(async () => {
    await refetchExport();
  }, [refetchExport]);

  return (
    <Form onSubmit={handleSubmit((data) => onExport())}>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group controlId="form-date">
            <Form.Label>{getSchemaFieldLabel(schema.fields.date)}</Form.Label>
            <DateInput name="date" control={control} />
            <FormFieldError field={errors.date} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <SubmitButton label="Export" isSubmitting={isLoadingExport} />
        </Col>
      </Row>
    </Form>
  );
};

export default ExportAccountPositions;
