import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import TabContainerWithTabs from "../components/TabContainer";
import ReportsNav from "./ReportsNav";

const DataExports = () => (
  <TabContainerWithTabs tabs={ReportsNav}>
    <ListGroup>
      <ListGroup.Item>
        <Link to="balances">Account Balances</Link>
      </ListGroup.Item>
      <ListGroup.Item>
        <Link to="positions">Account Positions</Link>
      </ListGroup.Item>
    </ListGroup>
  </TabContainerWithTabs>
);

export default DataExports;
