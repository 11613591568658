import { Button, ButtonProps, Image } from "react-bootstrap";
import Spinner from "./Spinner";

const SubmitButton = ({
  isSubmitting = false,
  label = "Save",
  icon,
  variant = "primary",
  ...props
}: {
  isSubmitting?: boolean;
  label?: string;
} & (
  | { variant?: "primary" | "secondary" | "danger"; icon?: string }
  | { variant: "icon"; icon: string }
) &
  ButtonProps) => (
  <Button
    variant="primary"
    type="submit"
    disabled={isSubmitting}
    {...props}
    className={`btn-primary btn-${variant} ${props.className ?? ""}`}
  >
    {variant === "icon" ? (
      !isSubmitting ? (
        <Image src={icon} alt={label} height={18} className="svg-light" />
      ) : (
        <Spinner as="span" size="sm" aria-hidden />
      )
    ) : (
      <>
        {label}{" "}
        {!isSubmitting ? null : <Spinner as="span" size="sm" aria-hidden />}
      </>
    )}
  </Button>
);

export default SubmitButton;
