import { useCallback, useContext } from "react";
import { Alert, ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import ActionButton from "../../components/ActionButton";
import { useAuthenticatedQuery } from "../../lib/api";
import { processFileResponse } from "../../lib/file";
import TaskList from "../../tasks/TaskList";
import WorkflowList from "../../tasks/WorkflowList";
import HouseholdEvents from "./HouseholdEvents";
import { HouseholdContext } from "./HouseholdInfo";

function useQueryExportTasks(householdId: number) {
  return useAuthenticatedQuery<
    UnpackResponse<HouseholdsController["exportTasks"]>,
    void
  >({
    queryKey: ["households", householdId, "tasks", "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}

const HouseholdTasks = () => {
  const { household } = useContext(HouseholdContext);

  function useQueryExportWorkflows(householdId: number) {
    return useAuthenticatedQuery<
      UnpackResponse<HouseholdsController["exportWorkflows"]>,
      void
    >({
      queryKey: ["households", householdId, "workflows", "export"],
      queryFn: async () => undefined,
      queryOptions: { enabled: false },
      responseCallback: processFileResponse,
    });
  }

  const {
    isLoading: isLoadingExportWorkflows,
    refetch: refetchExportWorkflows,
  } = useQueryExportWorkflows(household?.id ?? -1);

  const onExportWorkflows = useCallback(async () => {
    await refetchExportWorkflows();
  }, [refetchExportWorkflows]);

  const { isLoading: isLoadingExport, refetch: refetchExport } =
    useQueryExportTasks(household?.id ?? -1);

  const onExport = useCallback(async () => {
    await refetchExport();
  }, [refetchExport]);

  const isNotConnectedToCRM =
    typeof household?.idExternal.wealthbox === "undefined" &&
    typeof household?.idExternal.redtail === "undefined";

  return (
    <Row>
      <Col xxl={3} xl={4} className="mb-3">
        <h3>Reminders</h3>
        <HouseholdEvents />
      </Col>
      <Col xxl={9} xl={8} className="mb-3">
        <h3>Tasks</h3>
        <Row>
          <Col className="d-flex justify-content-between">
            <ButtonToolbar className="mb-3">
              <ButtonGroup className="me-3">
                <ActionButton
                  variant="secondary"
                  label="Export"
                  icon="/icons/folded-list.svg"
                  onClick={onExport}
                  className="me-2"
                  disabled={isLoadingExport}
                />
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
        {isNotConnectedToCRM || (household?.tasks.length ?? 0) <= 0 ? (
          <Alert>No Tasks</Alert>
        ) : (
          <TaskList tasks={household?.tasks ?? []} />
        )}
      </Col>
      <Col>
        <h3>Workflows</h3>
        <Row>
          <Col className="d-flex justify-content-between">
            <ButtonToolbar className="mb-3">
              <ButtonGroup className="me-3">
                <ActionButton
                  variant="secondary"
                  label="Export"
                  icon="/icons/folded-list.svg"
                  onClick={onExportWorkflows}
                  className="me-2"
                  disabled={isLoadingExportWorkflows}
                />
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
        {isNotConnectedToCRM || (household?.workflows.length ?? 0) <= 0 ? (
          <Alert>No Workflows</Alert>
        ) : (
          <WorkflowList workflows={household?.workflows ?? []} />
        )}
      </Col>
    </Row>
  );
};

export default HouseholdTasks;
