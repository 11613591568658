import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";

export function useRoles() {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((token) => {
          setRoles(
            () =>
              jwtDecode<{
                user_roles: [];
              }>(token).user_roles,
          );
        })
        .catch(() => {
          console.error("Failed to retrieve an access token.");
        });
    }
  }, [getAccessTokenSilently, isAuthenticated, user]);

  return roles;
}
