import { useEffect } from "react";
import { useParams } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ReportsController } from "../../../api/src/reports/reports.controller";
import { useAuthenticatedFileFetch } from "../lib/api";

const ReportInfo = () => {
  const { reportId } = useParams();

  const { isFetched } = useAuthenticatedFileFetch<
    UnpackResponse<ReportsController["getReport"]>
  >(`/reports/${reportId}`);

  useEffect(() => {
    if (isFetched) {
      window.close();
    }
  }, [isFetched]);

  return null;
};

export default ReportInfo;
