import React from "react";
import { ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import ActionButton from "../components/ActionButton";

const FeeStructureInfoActionButtons = () => (
  <ButtonToolbar className="mb-3">
    <ButtonGroup className="me-4">
      <ActionButton label="Save" icon="/icons/save.svg" type="submit" />
    </ButtonGroup>
    <ButtonGroup>
      <ActionButton
        as={Link}
        to="/billing/fee-structure"
        label="Exit"
        icon="/icons/exit.svg"
      />
    </ButtonGroup>
  </ButtonToolbar>
);

export default FeeStructureInfoActionButtons;
