import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Row as TanTableRow, createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFieldArray,
  useForm,
} from "react-hook-form";
import * as yup from "yup";
import type { FirmsController } from "../../../api/src/firms/firms.controller";
import type { SecurityAssetCategory } from "../../../api/src/firms/firms.service";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { SecuritiesController } from "../../../api/src/securities/securities.controller";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import ActionButton from "../components/ActionButton";
import FormError from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import SymbolField from "../components/SymbolField";
import TabContainerWithTabs from "../components/TabContainer";
import EditableTableRow, {
  EditableRowExtension,
} from "../components/Table/EditableTableRow";
import { Table, useTable } from "../components/Table/Table";
import {
  deserializeDate,
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
  useAuthenticatedQuery,
} from "../lib/api";
import { useRoles } from "../lib/auth";
import { InlineError } from "../lib/display";
import { processFileResponse } from "../lib/file";
import { useQuerySecurities } from "../lib/security";
import FirmNav from "./FirmNav";

function useQueryExportSecurityCategoryOverrides() {
  return useAuthenticatedQuery<
    UnpackResponse<FirmsController["exportSecurityAssetCategoryOverrides"]>,
    void
  >({
    queryKey: ["firm", "securities", "asset-categories", "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}

/**
 * This is my latest attempt at an architecture for editable tables. Prior attempts
 * included manually rendering our own table without @tanstack/react-table and
 * using meta properties on @tanstack/react-table components to enable row editing
 * and add/delete functionality.
 *
 * We first fetch the table data. We then instantiate a local state collection, as
 * well as a react-hook-form object with the fetched values. The state collection
 * stores the rows once they are confirmed. The form object is used for rows that
 * are in an edit state, and manages the input field values. Once a row is "saved",
 * the field values are written back to the state collection. When the table is
 * submitted, use the state collection as the source of truth.
 */

type SecurityAssetCategoryRequest = {
  category: string;
  mode: "INSERT" | "DELETE";
};

type SecurityAssetCategoryRow = SecurityAssetCategory & EditableRowExtension;
type SecurityAssetCategoryFields = Pick<
  SecurityAssetCategoryRow,
  "symbol" | "category"
>;

const schema: yup.ObjectSchema<{
  securityAssetCategories: SecurityAssetCategoryFields[];
}> = yup.object({
  securityAssetCategories: yup
    .array()
    .of(
      yup.object({
        symbol: yup
          .string()
          .required()
          .label("Symbol")
          .test({
            name: "unique-symbol",
            test: (val, context) => {
              return (
                (context.from?.[1]?.value.securityAssetCategories ?? []).filter(
                  (row: SecurityAssetCategoryFields) => row.symbol === val,
                ).length <= 1
              );
            },
            message: "This symbol may only be specified once.",
          }),
        category: yup.string().required().label("Asset Category"),
      }),
    )
    .required(),
});

const SecurityAssetCategoryOverrides = () => {
  const roles = useRoles();
  const {
    control,
    register,
    reset,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { insert: insertField, remove: removeField } = useFieldArray({
    name: "securityAssetCategories",
    control,
    keyName: "_field_id",
  });

  const [securityAssetCategoriesEdited, setSecurityAssetCategories] = useState<
    SecurityAssetCategory[]
  >([]);
  const [changedSecurities, setChangedSecurities] = useState<
    Record<string, SecurityAssetCategoryRequest>
  >({});

  const handleUpdateSecurityAssetCategories = useCallback(
    (index: number, symbol: string, category: string) => {
      setSecurityAssetCategories(
        securityAssetCategoriesEdited.map((security, indexInner) => {
          if (index !== indexInner) {
            return security;
          }

          setChangedSecurities((changedSecurities) => ({
            ...changedSecurities,
            [symbol]: {
              category,
              mode: "INSERT",
            },
          }));

          return {
            ...security,
            symbol,
            category,
            _isNew: false,
          };
        }),
      );
    },
    [securityAssetCategoriesEdited],
  );

  const onConfirmRow = useCallback(
    (row: TanTableRow<SecurityAssetCategoryRow>) => {
      const vals = getValues(`securityAssetCategories.${row.index}`);
      handleUpdateSecurityAssetCategories(
        row.index,
        vals.symbol,
        vals.category,
      );
    },
    [getValues, handleUpdateSecurityAssetCategories],
  );

  const onEditRow = useCallback(
    (row: TanTableRow<SecurityAssetCategoryRow>) => {
      setValue(`securityAssetCategories.${row.index}`, row.original);
    },
    [setValue],
  );

  const onDiscardRow = useCallback(
    (row: TanTableRow<SecurityAssetCategoryRow>) => {
      setValue(`securityAssetCategories.${row.index}`, row.original);
    },
    [setValue],
  );

  const onAddRow = useCallback(
    (row?: TanTableRow<SecurityAssetCategoryRow>) => {
      const startIndex = (row?.index ?? 0) + 1;
      const newRow = {
        symbol: "",
        category: "",
        defaultCategory: "",
        originalCategory: "",
        updatedTime: new Date(),
        _isNew: true,
      };

      insertField(startIndex, newRow, { shouldFocus: true });
      setSecurityAssetCategories([
        ...securityAssetCategoriesEdited.slice(0, startIndex),
        newRow,
        ...securityAssetCategoriesEdited.slice(startIndex),
      ]);
    },
    [insertField, securityAssetCategoriesEdited],
  );

  const onRemoveRow = useCallback(
    (row: TanTableRow<SecurityAssetCategoryRow>) => {
      removeField(row.index);
      setSecurityAssetCategories(
        securityAssetCategoriesEdited.filter(
          (security, index) => index !== row.index,
        ),
      );

      if (row.original.originalCategory === "") {
        delete changedSecurities[row.original.symbol];
        setChangedSecurities(changedSecurities);
      } else {
        setChangedSecurities({
          ...changedSecurities,
          [row.original.symbol]: {
            category: row.original.category,
            mode: "DELETE",
          },
        });
      }
    },
    [changedSecurities, removeField, securityAssetCategoriesEdited],
  );

  const {
    isPending: isPendingAssetCategories,
    isError: isErrorAssetCategories,
    data: dataAssetCategories,
  } = useAuthenticatedFetch<
    UnpackResponse<SecuritiesController["getAssetCategories"]>
  >("/securities/asset-categories");

  const assetCategoryOptions = useMemo(
    () =>
      (dataAssetCategories?.data ?? [])
        .sort((a, b) =>
          a.fasttrackCategory < b.fasttrackCategory
            ? -1
            : a.fasttrackCategory > b.fasttrackCategory
              ? 1
              : 0,
        )
        .map((assetCategory) => (
          <option
            key={assetCategory.fasttrackCategory}
            value={assetCategory.fasttrackCategory}
          >
            {assetCategory.fasttrackCategory}
          </option>
        )),
    [dataAssetCategories?.data],
  );

  const columnHelper = useMemo(
    () => createColumnHelper<SecurityAssetCategoryRow>(),
    [],
  );

  const securityErrors = errors.securityAssetCategories as Merge<
    FieldError,
    (Merge<FieldError, FieldErrorsImpl<SecurityAssetCategoryRow>> | undefined)[]
  >;

  const { data: dataSecurities } = useQuerySecurities(
    securityAssetCategoriesEdited.map((security) => security.symbol),
  );

  const symbolsMap = useMemo(
    () => _.keyBy(dataSecurities ?? [], "identifier"),
    [dataSecurities],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("symbol", {
        header: () => "Symbol",
        cell: (info) => (
          <div>
            {info.getValue()}
            <br />
            <Form.Text>{symbolsMap[info.getValue()]?.description}</Form.Text>
          </div>
        ),
        meta: {
          editableComponent: (cell) => (
            <>
              <SymbolField
                fieldName={`securityAssetCategories.${cell.row.index}.symbol`}
                register={register}
                control={control}
                getValues={getValues}
              />
              <FormFieldError
                field={securityErrors?.[cell.row.index]?.symbol}
              />
            </>
          ),
        },
      }),
      columnHelper.accessor("category", {
        header: () => "Asset Category",
        enableColumnFilter: false,
        meta: {
          editableComponent: (cell) =>
            isErrorAssetCategories ? (
              <InlineError>Failed to load asset categories</InlineError>
            ) : (
              <>
                <Form.Select
                  {...register(
                    `securityAssetCategories.${cell.row.index}.category`,
                  )}
                >
                  {assetCategoryOptions}
                </Form.Select>
                <FormFieldError
                  field={securityErrors?.[cell.row.index]?.category}
                />
              </>
            ),
        },
      }),
      columnHelper.accessor("updatedTime", {
        cell: (info) => info.getValue()?.toLocaleDateString(),
        header: () => "Updated Time",
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: "actions",
        size: 120,
      }),
      columnHelper.display({
        id: "add-remove",
      }),
    ],
    [
      columnHelper,
      register,
      control,
      getValues,
      symbolsMap,
      securityErrors,
      isErrorAssetCategories,
      assetCategoryOptions,
    ],
  );

  const {
    isPending: isPendingSecurityAssetCategories,
    isError: isErrorSecurityAssetCategories,
    data: dataSecurityAssetCategories,
  } = useAuthenticatedFetch<
    SerializedObject<
      UnpackResponse<FirmsController["getAllSecurityAssetCategoryOverrides"]>
    >
  >("/firm/securities/asset-categories");

  const securities = useMemo(
    () =>
      (dataSecurityAssetCategories?.data ?? []).map((security) => ({
        ...security,
        updatedTime: deserializeDate(security.updatedTime),
      })),
    [dataSecurityAssetCategories?.data],
  );

  useEffect(() => {
    if (
      !isPendingSecurityAssetCategories &&
      typeof dataSecurityAssetCategories?.data !== "undefined"
    ) {
      reset({ securityAssetCategories: securities });
      setSecurityAssetCategories(securities);
    }
  }, [
    isPendingSecurityAssetCategories,
    dataSecurityAssetCategories?.data,
    securities,
    reset,
  ]);

  const { table } = useTable({
    columns: columns,
    data: securityAssetCategoriesEdited,
    initialState: {
      pagination: {
        pageSize: 40,
      },
      sorting: [{ id: "symbol", desc: false }],
    },
    autoResetAll: false,
  });

  const updateSecurityAssetCategories = useAuthenticatedMutationAsync<
    SerializedObject<
      UnpackResponse<FirmsController["updateSecurityAssetCategoryOverrides"]>
    >
  >(
    "/firm/securities/asset-categories",
    async (data: Record<string, SecurityAssetCategoryRequest>) => ({
      method: "PUT",
      body: JSON.stringify(data),
    }),
  );

  const handleDiscardChanges = useCallback(() => {
    setSecurityAssetCategories(securities);
    reset({ securityAssetCategories: securities });
    setChangedSecurities({});
  }, [reset, securities]);

  const isListValid =
    new Set(securityAssetCategoriesEdited.map((security) => security.symbol))
      .size === securityAssetCategoriesEdited.length;

  const queryClient = useQueryClient();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async () => {
      if (isValid && isListValid) {
        try {
          const updatedSecurityAssetCategories =
            await updateSecurityAssetCategories(changedSecurities);
          queryClient.setQueryData(["/firm/securities/asset-categories"], {
            data: updatedSecurityAssetCategories.data,
          });
          setChangedSecurities({});
          notificationContext.pushNotification({
            id: "security-asset-categories",
            header: "Security Asset Categories Updated",
            body: "Firm security asset category overrides updated",
            variant: "success",
          });
        } catch (err) {
          console.error(
            "Failed to save security asset category overrides",
            err,
          );
          notificationContext.pushNotification({
            id: "security-asset-categories",
            header: "Failed to Save Security Asset Categories",
            body: "Firm security asset category overrides not saved",
            variant: "danger",
          });
        }
      }
    },
  });

  const { isLoading: isLoadingExport, refetch: refetchExport } =
    useQueryExportSecurityCategoryOverrides();

  const onExport = useCallback(async () => {
    await refetchExport();
  }, [refetchExport]);

  return (
    <TabContainerWithTabs tabs={FirmNav}>
      {isPendingSecurityAssetCategories || isPendingAssetCategories ? (
        <Loading />
      ) : isErrorSecurityAssetCategories ? (
        <Alert variant="danger">
          Failed to retrieve security asset category overrides
        </Alert>
      ) : (
        <>
          <Row>
            <Col>
              <ButtonToolbar className="mb-3">
                <ButtonGroup className="me-3">
                  <ActionButton
                    variant="secondary"
                    label="Export"
                    icon="/icons/folded-list.svg"
                    onClick={onExport}
                    disabled={isLoadingExport}
                  />
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
          {!roles.includes("Admin") ||
          Object.keys(changedSecurities).length <= 0 ? null : (
            <Row className="mb-2">
              <Col className="d-grid gap-1" xl={6} md={12}>
                <Button
                  className="mb-1"
                  variant="success"
                  onClick={() => onSubmit.mutateAsync()}
                  disabled={onSubmit.isPending || !isListValid}
                >
                  Submit{" "}
                  {onSubmit.isPending ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    ""
                  )}
                </Button>
              </Col>
              <Col className="d-grid gap-1" xl={6} md={12}>
                <Button
                  className="mb-1"
                  variant="danger"
                  onClick={handleDiscardChanges}
                  disabled={onSubmit.isPending}
                >
                  Discard
                </Button>
              </Col>
            </Row>
          )}
          <Row>
            <Col
              style={{ pointerEvents: onSubmit.isPending ? "none" : "auto" }}
            >
              <FormError
                hasError={!isValid || !isListValid}
                message="All symbols must be filled and unique."
              />
              <Table
                table={table}
                RowComp={roles.includes("Admin") ? EditableTableRow : undefined}
                onSave={onConfirmRow}
                onEdit={onEditRow}
                onDiscard={onDiscardRow}
                enableAddRemove
                onAdd={onAddRow}
                onRemove={onRemoveRow}
              />
            </Col>
          </Row>
        </>
      )}
    </TabContainerWithTabs>
  );
};

export default SecurityAssetCategoryOverrides;
