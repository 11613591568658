import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { Transaction } from "../../../api/src/transactions/lib";
import type { TransactionsController } from "../../../api/src/transactions/transactions.controller";
import TabContainerWithTabs from "../components/TabContainer";
import TransactionsTable, {
  deserializeTransaction,
} from "../components/TransactionsTable";
import { useAuthenticatedQuery } from "../lib/api";
import { processFileResponse } from "../lib/file";
import DashboardNav from "./DashboardNav";

export function useQueryTransactions() {
  return useAuthenticatedQuery<
    UnpackResponse<TransactionsController["getAllTransactions"]>,
    Transaction[],
    [string]
  >({
    queryKey: ["transactions"],
    queryFn: async (transactions) =>
      (transactions?.data ?? []).map(deserializeTransaction),
  });
}

export function useQueryExportTransactions() {
  return useAuthenticatedQuery<
    UnpackResponse<TransactionsController["exportTransactions"]>,
    void,
    [string, string]
  >({
    queryKey: ["transactions", "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}

type TransactionRow = Transaction;

const TransactionSummary = () => {
  const columnHelper = useMemo(() => createColumnHelper<TransactionRow>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("accountNumber", {
        cell: (info) => (
          <Link to={`/clients/accounts/${info.row.original.accountId}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => "Account Number",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.householdName ?? "", {
        id: "householdName",
        cell: (info) => (
          <Link to={`/clients/households/${info.row.original.householdId}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => "Household",
        minSize: 225,
      }),
    ],
    [columnHelper],
  );

  const { isPending, isError, data: transactions } = useQueryTransactions();

  const queryExport = useQueryExportTransactions();

  return (
    <TabContainerWithTabs tabs={DashboardNav}>
      <TransactionsTable
        additionalColumns={columns}
        isLoading={isPending}
        isError={isError}
        transactions={transactions ?? []}
        queryExport={queryExport}
      />
    </TabContainerWithTabs>
  );
};

export default TransactionSummary;
