import React from "react";
import { Form } from "react-bootstrap";
import { FieldError, Merge } from "react-hook-form";

const FormFieldError = ({
  field,
}: {
  field?:
    | FieldError
    | Merge<FieldError, (FieldError | undefined)[]>
    | undefined;
}) =>
  typeof field === "undefined" ? null : (
    <Form.Text className="text-danger d-block">{field?.message}</Form.Text>
  );

export default FormFieldError;
