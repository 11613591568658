import { Col, Container, Row } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Nav from "./Nav";
import { NotificationContainer, NotificationProvider } from "./Notifications";
import routes from "./Routes";
import Breadcrumbs from "./components/Breadcrumbs";
import { WebSocketProvider } from "./Websockets";

function Layout() {
  const location = useLocation();

  return (
    <div className="App">
      <Header />
      <Container fluid>
        <Row>
          <Nav />
          <Col xl={10} lg={9} className="flex-lg-grow-1">
            <main className="py-3 sentry-mask">
              {routes === null ? null : (
                <Breadcrumbs routes={routes} location={location.pathname} />
              )}
              <NotificationProvider>
                <WebSocketProvider>
                  <Outlet />
                  <NotificationContainer />
                </WebSocketProvider>
              </NotificationProvider>
            </main>
            <Footer />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Layout;
