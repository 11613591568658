import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { Alert, Badge, Form, ListGroup, Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import type { Note } from "../../../api/src/notes/notes.service";
import Loading from "../Loading";
import { sortISODate } from "../lib/date";
import { useErrorMessage } from "./FormError";
import FormFieldError from "./FormFieldError";
import SubmitButton from "./SubmitButton";

export type NoteForm = {
  content?: string;
};

const schema: yup.ObjectSchema<NoteForm> = yup.object({
  content: yup.string(),
});

const Notes = ({
  notes,
  onCreate,
}: {
  notes: Note[];
  onCreate?: (data: NoteForm) => Promise<Note | null>;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<NoteForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { setError, resetError, errorMessage } = useErrorMessage();

  const onSubmit = useMutation({
    mutationFn: async (data: NoteForm) => {
      resetError();

      if (
        typeof onCreate !== "undefined" &&
        typeof data.content !== "undefined" &&
        data.content !== ""
      ) {
        try {
          await onCreate(data);
          reset({ content: "" });
        } catch (err) {
          const message = "Failed to save note";
          setError(message);
          console.error(message, err);
        }
      }
    },
  });

  return (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      <div>
        {typeof onCreate === "undefined" ? null : (
          <div className="mb-3">
            {errorMessage}
            <Form.Control as="textarea" rows={3} {...register("content")} />
            <FormFieldError field={errors.content} />
            <SubmitButton
              isSubmitting={isSubmitting}
              label="Create"
              className="mt-3"
            />
          </div>
        )}
        {notes.length <= 0 ? (
          <Alert>No notes</Alert>
        ) : (
          <ListGroup as="ul">
            {notes
              .sort((noteA, noteB) =>
                sortISODate(noteA.updatedAt, noteB.updatedAt),
              )
              .reverse()
              .map((note) => (
                <ListGroup.Item key={note.id} as="li">
                  <div dangerouslySetInnerHTML={{ __html: note.content }} />
                  <div className="mt-1">
                    <Badge bg="primary">
                      {new Date(note.updatedAt).toLocaleString()}
                    </Badge>
                  </div>
                </ListGroup.Item>
              ))}
          </ListGroup>
        )}
      </div>
    </Form>
  );
};

export default Notes;

export const NotesPane = ({
  showNotes,
  setShowNotes,
  notes,
  onCreate,
  isLoading = false,
  isError = false,
}: {
  showNotes: boolean;
  setShowNotes: (val: boolean) => void;
  notes: Note[];
  onCreate: (data: NoteForm) => Promise<Note | null>;
  isLoading?: boolean;
  isError?: boolean;
}) => {
  return (
    <Offcanvas
      show={showNotes}
      onHide={() => setShowNotes(false)}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Notes</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <Alert variant="danger">Failed to load notes</Alert>
        ) : (
          <Notes notes={notes ?? []} onCreate={onCreate} />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};
