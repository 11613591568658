import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { Button, ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import { NotificationContext } from "../../Notifications";
import Content from "../../components/Content";
import FormFieldError from "../../components/FormFieldError";
import SubmitButton from "../../components/SubmitButton";
import { useAuthenticatedMutationAsync } from "../../lib/api";
import { getSchemaFieldLabel } from "../../lib/forms";

const schema: yup.ObjectSchema<{ name: string }> = yup.object({
  name: yup.string().required().label("Name"),
});

const CreateHousehold = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{ name: string }>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const createHousehold = useAuthenticatedMutationAsync<
    UnpackResponse<HouseholdsController["create"]>
  >("/households", async (name: string) => {
    return {
      method: "POST",
      body: JSON.stringify({ name }),
    };
  });

  const notificationContext = useContext(NotificationContext);
  const navigate = useNavigate();

  const onSubmit = useMutation({
    mutationFn: async (name: string) => {
      try {
        const body = await createHousehold(name);
        notificationContext.pushNotification({
          id: `household-${body.data}`,
          header: "Household Created",
          body: `${name} created`,
          variant: "success",
        });
        navigate(`../${body.data}`);
      } catch (err) {
        console.error("Failed to create household", err);
        notificationContext.pushNotification({
          id: "household-new",
          header: "Failed to Create Household",
          body: `${name} was not created`,
          variant: "danger",
        });
      }
    },
  });

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data.name))}>
      <Row>
        <Col>
          <h1>Create Household</h1>
        </Col>
      </Row>
      <Content>
        <Row>
          <Col xxl={3} xl={4} md={6} xs={12}>
            <Form.Group className="mb-3" controlId="form-name">
              <Form.Label>{getSchemaFieldLabel(schema.fields.name)}</Form.Label>
              <Form.Control type="text" {...register("name")} />
              <FormFieldError field={errors.name} />
            </Form.Group>
            <ButtonToolbar>
              <SubmitButton
                label="Create"
                isSubmitting={isSubmitting}
                className="me-3"
              />
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

export default CreateHousehold;
