import { ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { InsightsController } from "../../../api/src/insights/insights.controller";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { useAuthenticatedQuery } from "../lib/api";

function useQueryNewAccountsInsight() {
  return useAuthenticatedQuery<
    UnpackResponse<InsightsController["newAccounts"]>,
    number[],
    [string, string]
  >({
    queryKey: ["insights", "new-accounts"],
    queryFn: async (newAccounts) => newAccounts.data,
  });
}

const NewAccounts = () => {
  const { isPending: isPendingNewAccounts, data: dataNewAccounts } =
    useQueryNewAccountsInsight();

  return isPendingNewAccounts ? (
    <ListGroupItem>
      <Loading />
    </ListGroupItem>
  ) : typeof dataNewAccounts === "undefined" ? null : (
    <ListGroupItem>
      {dataNewAccounts.length <= 0 ? (
        "0 new accounts"
      ) : (
        <Link
          to={`/clients/accounts?${dataNewAccounts
            .map((accountId) => `id=${accountId}`)
            .join("&")}`}
        >
          {dataNewAccounts.length} new accounts
        </Link>
      )}{" "}
      have been found in your firm today.
    </ListGroupItem>
  );
};

export default NewAccounts;
