import { useMemo } from "react";
import { Alert } from "react-bootstrap";
import { AxisOptions, UserSerie } from "react-charts";
import { useParams } from "react-router-dom";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import HistoricalChart, { TimeSeries } from "../../dashboard/HistoricalChart";
import { useAuthenticatedFetch } from "../../lib/api";
import { formatCurrency } from "../../lib/numbers";

type ValueType = "totalValue" | "cashValue";

const AccountHistoricalAumChart = ({
  valueType,
  customReportPeriodParams,
}: {
  valueType: ValueType;
  customReportPeriodParams: string;
}) => {
  const { accountId } = useParams();
  const {
    isPending: isPendingHistoricalAum,
    isError: isErrorHistoricalAum,
    data: dataHistoricalAum,
  } = useAuthenticatedFetch<
    UnpackResponse<AccountsController["getHistoricalAUM"]>
  >(`/accounts/${accountId}/aum/historical?${customReportPeriodParams}`);
  const historicalAUM = dataHistoricalAum?.data;

  const primaryAxis = useMemo(
    (): AxisOptions<TimeSeries> => ({
      getValue: (item) => item.date,
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<TimeSeries>[] => [
      {
        id: "value",
        getValue: (item) => item.value,
        elementType: "line",
        formatters: {
          scale: (value: number | null) =>
            value === null
              ? ""
              : formatCurrency(value, 1, {
                  notation: "compact",
                  compactDisplay: "short",
                }),
        },
      } as AxisOptions<TimeSeries>,
    ],
    [],
  );

  const aumDataSeries = useMemo(
    (): UserSerie<TimeSeries>[] => [
      {
        label: "AUM",
        axisFamily: "time",
        secondaryAxisId: "value",
        data: (historicalAUM ?? []).map((item) => ({
          value: item.aum[valueType],
          date: new Date(item.date),
        })),
      } as UserSerie<TimeSeries>,
    ],
    [historicalAUM, valueType],
  );

  return isPendingHistoricalAum ? (
    <Loading message="Chart" />
  ) : isErrorHistoricalAum ? (
    <Alert variant="danger">Failed to load historical AUM </Alert>
  ) : aumDataSeries[0].data.length === 0 ? (
    <Alert variant="info">No AUM data found</Alert>
  ) : (
    <HistoricalChart
      primaryAxis={primaryAxis}
      secondaryAxes={secondaryAxes}
      data={aumDataSeries}
    />
  );
};

export default AccountHistoricalAumChart;
