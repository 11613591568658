import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { ReportsController } from "../../../api/src/reports/reports.controller";
import type {
  Report,
  ReportBatch,
  ReportBatchForm,
  ReportTemplate,
  ReportTemplateForm,
} from "../../../api/src/reports/reports.service";
import {
  deserializeDate,
  useAuthenticatedMutationNew,
  useAuthenticatedQuery,
} from "../lib/api";

export function useQueryReportBatches() {
  return useAuthenticatedQuery<
    UnpackResponse<ReportsController["getAllReportBatches"]>,
    ReportBatch[],
    [string, string]
  >({
    queryKey: ["reports", "batch"],
    queryFn: async (batches) => batches.data.map(deserializeReportBatch),
  });
}

export function useQueryReportBatch(batchId: number) {
  return useAuthenticatedQuery<
    UnpackResponse<ReportsController["getReportBatch"]>,
    ReportBatch | undefined,
    [string, string, number]
  >({
    queryKey: ["reports", "batch", batchId],
    queryFn: async (batch) =>
      typeof batch === "undefined"
        ? undefined
        : deserializeReportBatch(batch.data),
  });
}

export function useCreateReportBatch() {
  return useAuthenticatedMutationNew<
    UnpackResponse<ReportsController["createReportBatch"]>,
    number,
    ReportBatchForm
  >({
    mutationKey: ["POST", "reports", "batch"],
    mutationFn: ({ data }: { data: number }) => data,
  });
}

export function useDeleteReportBatch(reportBatchId: number) {
  return useAuthenticatedMutationNew<
    UnpackResponse<ReportsController["deleteReportBatch"]>,
    void,
    void
  >({
    mutationKey: ["DELETE", "reports", "batch", reportBatchId],
    mutationFn: () => null,
  });
}

export function useQueryReportTemplates() {
  return useAuthenticatedQuery<
    UnpackResponse<ReportsController["getAllReportTemplates"]>,
    ReportTemplate[],
    [string, string]
  >({
    queryKey: ["reports", "templates"],
    queryFn: async (templates) => templates.data.map(deserializeReportTemplate),
  });
}

export function useQueryReportTemplate(
  templateId: number,
  { enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<ReportsController["getReportTemplate"]>,
    ReportTemplate | undefined,
    [string, string, number]
  >({
    queryKey: ["reports", "templates", templateId],
    queryFn: async (template) =>
      typeof template === "undefined"
        ? undefined
        : deserializeReportTemplate(template.data),
    queryOptions: { enabled },
  });
}

export function useCreateReportTemplate() {
  return useAuthenticatedMutationNew<
    UnpackResponse<ReportsController["createReportTemplate"]>,
    number,
    ReportTemplateForm
  >({
    mutationKey: ["POST", "reports", "templates"],
    mutationFn: ({ data }: { data: number }) => data,
  });
}

export function useUpdateReportTemplate(reportTemplateId: number) {
  return useAuthenticatedMutationNew<
    UnpackResponse<ReportsController["updateReportTemplate"]>,
    void,
    ReportTemplateForm
  >({
    mutationKey: ["PUT", "reports", "templates", reportTemplateId],
    mutationFn: () => null,
  });
}

export function useDeleteReportTemplate(reportTemplateId: number) {
  return useAuthenticatedMutationNew<
    UnpackResponse<ReportsController["deleteReportTemplate"]>,
    void,
    void
  >({
    mutationKey: ["DELETE", "reports", "templates", reportTemplateId],
    mutationFn: () => null,
  });
}

export function deserializeReportBatch(
  reportBatch: SerializedObject<ReportBatch>,
): ReportBatch {
  return {
    ...reportBatch,
    startDate: deserializeDate(reportBatch.startDate),
    endDate: deserializeDate(reportBatch.endDate),
    reportDate: deserializeDate(reportBatch.reportDate),
    createdTime: deserializeDate(reportBatch.createdTime),
    updatedTime: deserializeDate(reportBatch.updatedTime),
    reports: (reportBatch.reports ?? []).map(deserializeReport),
  };
}

export function deserializeReport(report: SerializedObject<Report>): Report {
  return {
    ...report,
    startDate: deserializeDate(report.startDate),
    endDate: deserializeDate(report.endDate),
    reportDate: deserializeDate(report.reportDate),
    createdTime: deserializeDate(report.createdTime),
    updatedTime: deserializeDate(report.updatedTime),
  };
}

export function deserializeReportTemplate(
  reportTemplate: SerializedObject<ReportTemplate>,
): ReportTemplate {
  return {
    ...reportTemplate,
    createdTime: deserializeDate(reportTemplate.createdTime),
    updatedTime: deserializeDate(reportTemplate.updatedTime),
  };
}
