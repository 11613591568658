import { Button, Form, Modal, Row } from "react-bootstrap";
import SubmitButton from "./SubmitButton";

const ModelAssignDialog = ({
  showModal,
  handleModalClose,
  rowSelection,
  selectedModelId,
  setModelId,
  modelOptions,
  isSaving,
  handleSaveChanges,
  title,
}: {
  showModal: boolean;
  handleModalClose: any;
  rowSelection: any;
  selectedModelId: number;
  setModelId: any;
  modelOptions: any;
  isSaving: boolean;
  handleSaveChanges: any;
  title: string;
}) => {
  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Assign Model</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="m-2">
          {Object.keys(rowSelection).length} {title} selected
        </Row>
        <Row className="m-2">
          <Form.Select
            placeholder="Select assigned model"
            value={selectedModelId}
            onChange={(e) => setModelId(+e.target.value)}
          >
            {modelOptions}
          </Form.Select>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleModalClose}
          disabled={isSaving}
        >
          Discard
        </Button>
        <SubmitButton
          onClick={handleSaveChanges}
          isSubmitting={isSaving}
          disabled={selectedModelId === 0}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModelAssignDialog;
