import { Col, Container, Image, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LoginButton from "./LoginButton";
import GlobalSearchBox from "./components/GlobalSearchBox";

const IconBox = ({ src, alt }: { src: string; alt: string }) => (
  <div className="sidebar-icon-box">
    <Image src={src} alt={alt} />
  </div>
);

const NavBar = () => (
  <Col lg={3} xl={2} className="sidebar-col gx-3 gx-lg-0 px-0">
    {/* 
      Use the gutter modifiers to keep the collapsed navbar items spaced from
      the edge and aligned. 
    */}
    <div className="sidebar border-end">
      <Navbar expand="lg" collapseOnSelect variant="dark" className="py-lg-0">
        <Container fluid className="px-lg-0">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <GlobalSearchBox className="d-lg-none" />
          <LoginButton variant="light" className="d-inline-block d-lg-none" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              variant="pills"
              as="ul"
              defaultActiveKey="/dashboard"
              className="flex-column w-100"
            >
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to="/dashboard">
                  <IconBox src="/icons/home.svg" alt="home icon" />
                  <span className="align-middle">Home</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to="/clients">
                  <IconBox src="/icons/client.svg" alt="client icon" />
                  <span className="align-middle">Clients</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to="/models">
                  <IconBox src="/icons/models.svg" alt="models icon" />
                  <span className="align-middle">Models</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to="/rebalances">
                  <IconBox src="/icons/trades.svg" alt="rebalancing icon" />
                  <span className="align-middle">Rebalancing</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to="/cash">
                  <IconBox src="/icons/cash.svg" alt="cash icon" />
                  <span className="align-middle">Cash</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to="/billing">
                  <IconBox src="/icons/billing.svg" alt="billing icon" />
                  <span className="align-middle">Billing</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to="/reports">
                  <IconBox src="/icons/manage.svg" alt="reporting icon" />
                  <span className="align-middle">Reporting</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  </Col>
);

export default NavBar;
