import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { ReportBatch } from "../../../api/src/reports/reports.service";
import ActionButton from "../components/ActionButton";
import TabContainerWithTabs from "../components/TabContainer";
import {
  dateFilterFn,
  DateRangeFilter,
  multiSelectIncludes,
  yesNoOptions,
  YesNoSingleFilter,
} from "../components/Table/filters";
import { Table, useTable } from "../components/Table/Table";
import Loading from "../Loading";
import { useQueryReportBatches } from "./lib";
import ReportsNav from "./ReportsNav";

const ReportBatches = () => {
  const columnHelper = useMemo(() => createColumnHelper<ReportBatch>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => (
          <Link to={`/reports/${info.row.original.id}`}>{info.getValue()}</Link>
        ),
        header: () => "Name",
      }),
      columnHelper.accessor("reportDate", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "Report Date",
        meta: {
          filterComponent: (props) => (
            <DateRangeFilter {...props} name="reportDate" />
          ),
        },
        filterFn: dateFilterFn,
      }),
      columnHelper.accessor(
        (row) => {
          const firmWide = yesNoOptions.find(
            (a) => a.label === (row.isFirmWideExecution ? "Yes" : "No"),
          );
          return firmWide?.value ?? "";
        },
        {
          id: "isFirmWideExecution",
          cell: (info) => {
            return info.getValue() ? "Yes" : "No";
          },
          filterFn: multiSelectIncludes,
          header: () => "Firm-Wide?",
          meta: {
            filterComponent: YesNoSingleFilter,
          },
        },
      ),
      columnHelper.accessor("startDate", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "Start Date",
        meta: {
          filterComponent: (props) => (
            <DateRangeFilter {...props} name="startDate" />
          ),
        },
        filterFn: dateFilterFn,
      }),
      columnHelper.accessor("endDate", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "End Date",
        meta: {
          filterComponent: (props) => (
            <DateRangeFilter {...props} name="endDate" />
          ),
        },
        filterFn: dateFilterFn,
      }),
      columnHelper.accessor("createdTime", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "Created",
        enableColumnFilter: false,
      }),
    ],
    [columnHelper],
  );

  const { isPending, data: reportBatches } = useQueryReportBatches();

  const { table } = useTable({
    columns,
    data: reportBatches ?? [],
    getRowId: (row) => row.id.toString(),
    initialState: {
      sorting: [{ id: "reportDate", desc: true }],
    },
  });

  return (
    <TabContainerWithTabs tabs={ReportsNav}>
      <Row>
        <Col xl={12}>
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Run Firm-Wide Report"
                icon="/icons/new.svg"
                className="btn-action_secondary_wide"
                as={Link}
                to="/reports/batches/new?type=standard"
              />
            </ButtonGroup>
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Run One-Off Report"
                icon="/icons/new.svg"
                className="btn-action_secondary_wide"
                as={Link}
                to="/reports/batches/new?type=custom"
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          {isPending ? <Loading message="reports" /> : <Table table={table} />}
        </Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default ReportBatches;
