export function formatCurrency(
  value: number,
  precision = 0,
  options?: {
    currency?: string;
    currencySign?: "standard" | "accounting";
    notation?: "standard" | "scientific" | "engineering" | "compact";
    compactDisplay?: "short" | "long";
  },
) {
  const { currency, currencySign, notation, compactDisplay } = {
    currency: "USD",
    ...options,
  };
  return value.toLocaleString(undefined, {
    maximumFractionDigits: precision,
    style: "currency",
    currency,
    currencySign,
    notation,
    compactDisplay,
  });
}

export function formatCurrencyComponent(
  value: number,
  precision = 0,
  options?: {
    currency?: string;
    currencySign?: "standard" | "accounting";
    notation?: "standard" | "scientific" | "engineering" | "compact";
    compactDisplay?: "short" | "long";
  },
) {
  const stringValue = formatCurrency(value, precision, {
    ...options,
    currencySign: options?.currencySign ?? "accounting",
  });

  return value >= 0 ? (
    stringValue
  ) : (
    <span className="text-danger">{stringValue}</span>
  );
}

export function formatPercent(value: number, precision = 0) {
  return value.toLocaleString(undefined, {
    maximumFractionDigits: precision,
    style: "percent",
  });
}
