import { Form } from "react-bootstrap";
import { CASH_SYMBOLS, SECURITY_INFO_URL } from "../lib/security";

const SecurityLink = ({
  symbol,
  description,
}: {
  symbol: string;
  description?: string;
}) =>
  CASH_SYMBOLS.includes(symbol) ? (
    "Cash"
  ) : (
    <>
      <a
        href={`${SECURITY_INFO_URL}${symbol}`}
        target="_blank"
        rel="noreferrer"
      >
        {symbol}
      </a>
      <br />
      <Form.Text>{description}</Form.Text>
    </>
  );

export default SecurityLink;
