import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import type { ContactsController } from "../../../../api/src/contacts/contacts.controller";
import type { Contact } from "../../../../api/src/contacts/lib";
import type { UnpackResponse } from "../../../../api/src/lib";
import type { TargetMappingData } from "../../../../api/src/lib/dataMapping";
import type { ProfileController } from "../../../../api/src/profile/profile.controller";
import DataMappingModal from "../../components/DataMapping";
import { useErrorMessage } from "../../components/FormError";
import SubmitButton from "../../components/SubmitButton";
import {
  useAuthenticatedFetch,
  useAuthenticatedMutation,
  useAuthenticatedMutationAsync,
} from "../../lib/api";

type LinkData = Record<string, Record<number, number>>;

const ContactMapping = () => {
  const {
    isPending: isPendingProfile,
    isError: isErrorProfile,
    data: dataProfile,
  } = useAuthenticatedFetch<UnpackResponse<ProfileController["getProfile"]>>(
    "/profile",
  );
  const user = dataProfile?.data;

  const providerOptions = [
    ...(!user?.connections.wealthbox ? [] : ["wealthbox"]),
    ...(!user?.connections.redtail ? [] : ["redtail"]),
  ];

  const { errorMessage, setError, resetError } = useErrorMessage();

  const {
    isPending: isPendingMapping,
    isError: isErrorMapping,
    data: dataMapping,
  } = useAuthenticatedFetch<
    UnpackResponse<ContactsController["getContactMappings"]>
  >("/contacts/data/mappings");

  const [service, setService] = useState(providerOptions[0] ?? "");

  const {
    isFetching: isFetchingSuggestions,
    data: dataSuggestions,
    refetch: fetchSuggestions,
  } = useAuthenticatedFetch<
    UnpackResponse<ContactsController["getContactMappingSuggestions"]>
  >(`/contacts/data/mappings/suggestions/${service}`, undefined, {
    enabled: false,
  });

  const onAutoSuggest = useCallback(async () => {
    await fetchSuggestions();
  }, [fetchSuggestions]);

  const mapContacts = useAuthenticatedMutationAsync<
    UnpackResponse<ContactsController["mapContacts"]>
  >("/contacts/data/mappings", async (data: LinkData) => ({
    method: "POST",
    body: JSON.stringify(data),
  }));

  const queryClient = useQueryClient();

  const onMap = useMutation({
    mutationFn: async (data: LinkData) => {
      resetError();

      try {
        const mappedContacts = await mapContacts(data);
        queryClient.setQueryData(["/contacts/data/mappings"], {
          data: mappedContacts.data,
        });
      } catch (err) {
        const message = "Failed to connect contacts";
        setError(message);
        console.error(message, err);
      }
    },
  });

  const handleLinkContacts = useCallback(
    async (newAssignedData: TargetMappingData<Contact>[]) => {
      await onMap.mutateAsync(
        newAssignedData.reduce(
          (result, mappedContact) => ({
            ...result,
            ...Object.entries(mappedContact.assigned).reduce(
              (assignedResult, [provider, records]) => ({
                ...assignedResult,
                ...(records.length <= 0
                  ? {}
                  : {
                      [provider]: {
                        ...result[provider],
                        ...assignedResult[provider],
                        [mappedContact.id]: records[0].id,
                      },
                    }),
              }),
              {} as LinkData,
            ),
          }),
          {} as LinkData,
        ),
      );
    },
    [onMap],
  );

  const mappingData = useMemo(
    () => ({
      ...(dataMapping?.data ?? {
        target: [],
        source: {},
      }),
      suggested: dataSuggestions?.data ?? {},
    }),
    [dataMapping, dataSuggestions],
  );

  const [isCreating, setIsCreating] = useState(false);

  const createNewContacts = useAuthenticatedMutation<
    UnpackResponse<ContactsController["createContactsFromUnmapped"]>
  >(`/contacts/data/mappings/create/${service}`, {
    method: "POST",
  });

  const onCreateNewContacts = useCallback(async () => {
    resetError();
    setIsCreating(true);

    try {
      const linkedHouseholds = await createNewContacts();
      queryClient.setQueryData(["/contacts/data/mappings"], {
        data: linkedHouseholds.data,
      });
    } catch (err) {
      const message = "Failed to create contacts";
      setError(message);
      console.error(message, err);
    } finally {
      setIsCreating(false);
    }
  }, [createNewContacts, setError, resetError, queryClient]);

  const createContactsButton = (
    <SubmitButton
      label="Create from Unlinked"
      onClick={onCreateNewContacts}
      isSubmitting={isCreating}
    />
  );

  return (
    <>
      {errorMessage}
      <DataMappingModal
        linkHeader="/clients/contacts/"
        title="Contact Data Connections"
        targetTitle="Contact"
        providerOptions={providerOptions}
        sourceTitle="Unlinked"
        isLoading={isPendingMapping || isPendingProfile}
        isError={isErrorMapping || isErrorProfile}
        mappingData={mappingData}
        isSubmitting={onMap.isPending}
        handleSubmit={handleLinkContacts}
        onAutoSuggest={onAutoSuggest}
        onSetService={setService}
        isLoadingSuggestions={isFetchingSuggestions}
        oneToMany={false}
        additionalButtons={createContactsButton}
        showNewRows={true}
      />
    </>
  );
};

export default ContactMapping;
