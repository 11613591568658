import IconBox from "./IconBox";

const SummaryPill = ({
  label,
  value,
  size,
  icon,
  className = "",
}: {
  label: string;
  value: string | number;
  size?: "lg" | "sm";
  icon?: string;
  className?: string;
}) => (
  <div
    className={`summary-pill rounded p-3 ${className} ${
      size === "lg" ? "summary-pill_lg" : size === "sm" ? "summary-pill_sm" : ""
    }`}
  >
    {!icon ? null : <IconBox src={icon} alt={label} />}
    <div className="summary-pill__text">
      <span className="summary-pill__value">{value}</span>
      <span className="summary-pill__label">{label}</span>
    </div>
  </div>
);

export default SummaryPill;
