import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const ManageNav = () => (
  <nav>
    <Nav variant="tabs" defaultActiveKey="/clients/households">
      <Nav.Item>
        <NavLink to="/clients/households" className="nav-link">
          Households
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/clients/accounts" className="nav-link">
          Accounts
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/clients/contacts" className="nav-link">
          Contacts
        </NavLink>
      </Nav.Item>
    </Nav>
  </nav>
);

export default ManageNav;
