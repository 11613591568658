import { Header, createColumnHelper } from "@tanstack/react-table";
import React, { useCallback, useMemo } from "react";
import { Alert, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { Report } from "../../../api/src/reports/reports.service";
import Loading from "../Loading";
import { useQueryAccounts } from "../clients/account/lib";
import { useQueryHouseholds } from "../clients/household/lib";
import {
  Table,
  metaDefault,
  useSkipper,
  useTable,
} from "../components/Table/Table";
import { DateRangeFilter, dateFilterFn } from "../components/Table/filters";
import { displayAccountName } from "../lib/display";

type ReportRow = Report & { householdName?: string; accountName?: string };

export const ReportTypeFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const value = (header.column.getFilterValue() ?? "") as string;

  const onChange = useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) => {
      header.column.setFilterValue(ev.target.value);
    },
    [header.column],
  );

  return (
    <Form.Select value={value} onChange={onChange} placeholder="All">
      <option value="">All</option>
      <option value="Household">Household</option>
      <option value="Account">Account</option>
    </Form.Select>
  );
};

const ReportsTable = ({ reports }: { reports: Report[] }) => {
  const columnHelper = useMemo(() => createColumnHelper<ReportRow>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.householdName ?? row.accountName, {
        id: "name",
        cell: (info) => (
          <Link
            to={`/reports/${info.row.original.id}`}
            rel="noreferrer"
            target="_blank"
          >
            {info.getValue()}
          </Link>
        ),
        header: () => "Name",
        minSize: 225,
      }),
      columnHelper.accessor(
        (row) => (row.accountId === null ? "Household" : "Account"),
        {
          id: "type",
          header: () => "Type",
          filterFn: "equalsString",
          meta: {
            filterComponent: ReportTypeFilter,
          },
        },
      ),
      columnHelper.accessor("reportDate", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "Report Date",
        meta: {
          filterComponent: (props) => (
            <DateRangeFilter {...props} name="reportDate" />
          ),
        },
        filterFn: dateFilterFn,
      }),
      columnHelper.accessor("startDate", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "Start Date",
      }),
      columnHelper.accessor("endDate", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "End Date",
      }),
      columnHelper.accessor("createdTime", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "Created",
        enableColumnFilter: false,
      }),
    ],
    [columnHelper],
  );

  const {
    isPending: isPendingHouseholds,
    isError: isErrorHouseholds,
    data: dataHouseholds,
  } = useQueryHouseholds();
  const households = dataHouseholds?.data;

  const {
    isPending: isPendingAccounts,
    isError: isErrorAccounts,
    data: dataAccounts,
  } = useQueryAccounts({ pageSize: 10000 });

  const accounts = dataAccounts?.data;

  const reportsWithHouseholdAccount = useMemo(
    () =>
      (reports ?? []).map((report) => {
        const account = (accounts ?? []).find(
          (account) => account.id === report.accountId,
        );

        return {
          ...report,
          householdName: (households ?? []).find(
            (household) => household.id === report.householdId,
          )?.name,
          accountName: displayAccountName(
            account?.displayName,
            account?.displayNumber,
          ),
        };
      }),
    [accounts, households, reports],
  );

  const [autoResetPageIndex] = useSkipper();

  const { table } = useTable({
    columns,
    data: reportsWithHouseholdAccount ?? [],
    getRowId: (row) => row.id.toString(),
    initialState: {
      sorting: [{ id: "createdTime", desc: true }],
    },
    autoResetPageIndex,
    meta: metaDefault,
  });

  return isPendingHouseholds || isPendingAccounts ? (
    <Loading />
  ) : isErrorHouseholds || isErrorAccounts ? (
    <Alert variant="danger">An error occurred</Alert>
  ) : reports.length <= 0 ? (
    <Alert>No reports found</Alert>
  ) : (
    <Table table={table} />
  );
};

export default ReportsTable;
