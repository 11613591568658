import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect } from "react";
import { ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type {
  PageOrientation,
  RateOfReturn,
  ReportPeriod,
  ReportSubType,
  ReportTemplateForm,
} from "../../../api/src/reports/reports.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import ActionButton from "../components/ActionButton";
import Content from "../components/Content";
import FormError from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import MultiSelector from "../components/MultiSelector";
import SubmitButton from "../components/SubmitButton";
import { capitalize } from "../lib/display";
import { getSchemaFieldLabel } from "../lib/forms";
import {
  useCreateReportTemplate,
  useDeleteReportTemplate,
  useQueryReportTemplate,
  useUpdateReportTemplate,
} from "./lib";

const subReportOptions: ReportSubType[] = [
  "account_summary",
  "portfolio_snapshot",
  "appraisals",
  "security_performance",
  "household_performance_attribution",
  "appraisals_wo_cost_basis",
  "performance_summary",
  "performance_chart",
  "net_investment_chart",
  "asset_allocation_top_holdings",
];

const schema: yup.ObjectSchema<ReportTemplateForm> = yup.object({
  name: yup.string().required().label("Name"),
  subReports: yup
    .array()
    .of<ReportSubType>(yup.string().required().oneOf(subReportOptions))
    .required()
    .label("Sections"),
  rateOfReturnType: yup
    .string()
    .required()
    .oneOf<RateOfReturn>(["time-weighted", "internal"])
    .default("internal")
    .label("Rate of Return"),
  orientation: yup
    .string()
    .required()
    .oneOf<PageOrientation>(["portrait", "landscape"])
    .default("portrait")
    .label("Page Orientation"),
  period: yup
    .string()
    .required()
    .oneOf<ReportPeriod>(["M", "Q"])
    .label("Period"),
});

const ReportTemplateInfo = () => {
  const { templateId } = useParams();
  const isNew = templateId === "new";

  const {
    isLoading,
    isError,
    data: reportTemplate,
  } = useQueryReportTemplate(parseInt(templateId ?? ""), { enabled: !isNew });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    watch,
  } = useForm<ReportTemplateForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      orientation: "portrait",
      period: "M",
      rateOfReturnType: "internal",
      subReports: ["account_summary"],
    },
  });

  useEffect(() => {
    if (typeof reportTemplate !== "undefined") {
      reset(reportTemplate);
    }
  }, [reportTemplate, reset]);

  const createReport = useCreateReportTemplate();
  const updateReport = useUpdateReportTemplate(parseInt(templateId ?? ""));

  const notificationContext = useContext(NotificationContext);
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (data: ReportTemplateForm) => {
      try {
        if (isNew) {
          const templateBody = await createReport.mutateAsync(data);
          notificationContext.pushNotification({
            id: `report-template-${templateBody}`,
            header: "Report Template Created",
            body: `${data.name} created successfully.`,
            variant: "success",
          });
          navigate(`../${templateBody}`);
        } else {
          await updateReport.mutateAsync(data);
          // reset(templateBody);
          notificationContext.pushNotification({
            id: `report-template-${templateId}`,
            header: "Report Template Updated",
            body: `${data.name} updated successfully.`,
            variant: "success",
          });
        }
      } catch {
        notificationContext.pushNotification({
          id: "report-template",
          header: "Failed to Create Report Template",
          body: `Report template ${data.name} not created`,
          variant: "danger",
        });
      }
    },
    [
      createReport,
      isNew,
      navigate,
      notificationContext,
      templateId,
      updateReport,
    ],
  );

  const subReports = watch("subReports");

  const deleteReportTemplate = useDeleteReportTemplate(
    parseInt(templateId ?? ""),
  );

  const onDelete = useCallback(async () => {
    await deleteReportTemplate.mutateAsync();
    notificationContext.pushNotification({
      id: `report-template-${templateId}`,
      header: "Report Template Deleted",
      body: `Report template ${reportTemplate?.name} deleted`,
      variant: "warning",
    });
    navigate("..");
  }, [
    deleteReportTemplate,
    navigate,
    notificationContext,
    reportTemplate?.name,
    templateId,
  ]);

  return isLoading ? (
    <Loading message="report template" />
  ) : isError ? (
    <FormError message="An error occurred" />
  ) : typeof reportTemplate === "undefined" && !isNew ? (
    <FormError message="Report Template not found" />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <h1>Report Template Details</h1>
        </Col>
        <Col md="auto">
          <ButtonToolbar className="mb-3">
            <ActionButton
              label="Save"
              icon="/icons/save.svg"
              type="submit"
              className="me-3"
              disabled={isSubmitting}
            />
            <ActionButton
              variant="secondary"
              label="Delete"
              icon="/icons/trash.svg"
              type="button"
              className="me-3"
              onClick={onDelete}
              disabled={deleteReportTemplate.isPending}
            />
            <ActionButton
              as={Link}
              to="/reports/templates"
              label="Exit"
              icon="/icons/exit.svg"
            />
          </ButtonToolbar>
        </Col>
      </Row>
      <Content>
        <Row>
          <Col xl={5} lg={10}>
            <Form.Group className="mb-3" controlId="form-name">
              <Form.Label>{getSchemaFieldLabel(schema.fields.name)}</Form.Label>
              <Form.Control type="text" {...register("name")} />
              <FormFieldError field={errors.name} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xxl={5} lg={10}>
            <Form.Group className="mb-3" controlId="form-subReports">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.subReports)}
              </Form.Label>
              <MultiSelector
                placeholder="Select included reports"
                options={subReportOptions.map((subReport) => ({
                  label: capitalize(subReport.replaceAll("_", " ")),
                  value: subReport,
                }))}
                setValue={(value: ReportSubType[]) =>
                  setValue("subReports", value)
                }
                value={subReports}
              />
              <FormFieldError field={errors.subReports} />
            </Form.Group>
          </Col>
          <Col xxl={5} lg={10}>
            <Form.Group className="mb-3" controlId="form-period">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.period)}
              </Form.Label>
              <Form.Select
                placeholder="Select report period"
                {...register("period")}
              >
                <option value="M">Last Month</option>
                <option value="Q">Last Quarter</option>
                <option value="Y">Last Year</option>
              </Form.Select>
              <FormFieldError field={errors.period} />
            </Form.Group>
          </Col>
          <Col lg={10}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-orientation">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.orientation)}
                  </Form.Label>
                  <Form.Select
                    placeholder="Select page orientation"
                    {...register("orientation")}
                  >
                    <option value="portrait">Portrait</option>
                    <option value="landscape">Landscape</option>
                  </Form.Select>
                  <FormFieldError field={errors.orientation} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-rateOfReturnType">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.rateOfReturnType)}
                  </Form.Label>
                  <Form.Select
                    placeholder="Select rate of return type"
                    {...register("rateOfReturnType")}
                  >
                    <option value="internal">Internal</option>
                    <option value="time-weighted">Time-Weighted</option>
                  </Form.Select>
                  <FormFieldError field={errors.rateOfReturnType} />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} />
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

export default ReportTemplateInfo;
