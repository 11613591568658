import { useCallback } from "react";
import { Offcanvas } from "react-bootstrap";
import type { EditableTask, Task } from "../../../api/src/tasks/tasks.service";
import CreateTask from "./CreateTask";

const CreateTaskPane = ({
  show,
  setShow,
  onCreate,
}: {
  show: boolean;
  setShow: (val: boolean) => void;
  onCreate: (data: EditableTask) => Promise<Task | null>;
}) => {
  const hidePane = useCallback(() => setShow(false), [setShow]);

  const onCreateInner = useCallback(
    async (data: EditableTask) => {
      const body = await onCreate(data);
      setShow(false);

      return body;
    },
    [onCreate, setShow],
  );

  return (
    <Offcanvas show={show} onHide={hidePane} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Create Task</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <CreateTask onCreate={onCreateInner} />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CreateTaskPane;
