import _ from "lodash";
import { useCallback, useMemo } from "react";
import { Button, ButtonGroup, Form, InputGroup, Table } from "react-bootstrap";
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
  Path,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";
import type {
  PrimarySecurity,
  SimpleSleeve,
} from "../../../api/src/models/models.service";
import FormFieldError from "../components/FormFieldError";
import SymbolField from "../components/SymbolField";
import { addRemoveLabel } from "../lib/display";
import { formatPercent } from "../lib/numbers";
import { useQuerySecurities } from "../lib/security";
import { sumModelWeights } from "./lib";

type PrimarySecurityField = PrimarySecurity & { _field_id: string };

const emptyPrimarySecurity: PrimarySecurityField = {
  symbol: "",
  weight: 0,
  _field_id: "",
};

/**
 * A multi-item form component for primary securities on an investing model. This component
 * displays an arbitrary number of rows for users to view and modify primary securities.
 */
function PrimarySecurities<
  TForm extends { primarySecurities: PrimarySecurity[] },
>({
  fields,
  replace,
  add,
  remove,
  register,
  control,
  getValues,
  errors,
  sleeves = [],
}: {
  /** The collection of primary securities for a model */
  fields: PrimarySecurityField[];
  /** The setter function for the fields prop */
  replace: (primarySecurities: PrimarySecurityField[]) => void;
  add: (primarySecurity: PrimarySecurityField) => void;
  remove: UseFieldArrayRemove;
  register: UseFormRegister<TForm>;
  control: Control<TForm>;
  getValues: UseFormGetValues<TForm>;
  errors?: Merge<
    FieldError,
    (Merge<FieldError, FieldErrorsImpl<PrimarySecurity>> | undefined)[]
  >;
  sleeves?: SimpleSleeve[];
}) {
  const addSecurity = useCallback(() => {
    add({ ...emptyPrimarySecurity });
  }, [add]);

  const removeSecurity = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove],
  );

  const { data: securities } = useQuerySecurities(
    fields.map((security) => security.symbol),
  );

  const symbolsMap = useMemo(
    () => _.keyBy(securities ?? [], "identifier"),
    [securities],
  );

  return (
    <Table className="form-table form-table-sm">
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Weight</th>
          <th>{addRemoveLabel}</th>
        </tr>
      </thead>
      <tbody>
        {fields.length <= 0 ? (
          <tr>
            <td>None</td>
            <td>{/* Empty */}</td>
            <td>
              <Button onClick={addSecurity}>+</Button>
            </td>
          </tr>
        ) : (
          fields.map((security, index) => (
            <tr key={security._field_id}>
              <td className="align-top">
                <SymbolField
                  fieldName={`primarySecurities.${index}.symbol` as Path<TForm>}
                  register={register}
                  control={control}
                  getValues={getValues}
                  description={symbolsMap[security.symbol]?.description}
                />
                <FormFieldError field={errors?.[index]?.symbol} />
              </td>
              <td className="align-top">
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      step={0.01}
                      {...register(
                        `primarySecurities.${index}.weight` as Path<TForm>,
                      )}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <FormFieldError field={errors?.[index]?.weight} />
                </Form.Group>
              </td>
              <td className="align-top">
                <ButtonGroup>
                  <Button onClick={removeSecurity(index)}>-</Button>
                  {index !== fields.length - 1 ? null : (
                    <Button onClick={addSecurity}>+</Button>
                  )}
                </ButtonGroup>
              </td>
            </tr>
          ))
        )}
      </tbody>
      <tfoot>
        <tr>
          <th>Total</th>
          <th>{formatPercent(sumModelWeights(fields, sleeves) / 100, 2)}</th>
          <th>{/* Empty */}</th>
        </tr>
      </tfoot>
    </Table>
  );
}

export default PrimarySecurities;
