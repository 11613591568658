import React, { useCallback, useState } from "react";
import { Form, Row, Modal, Button } from "react-bootstrap";
import SubmitButton from "./SubmitButton";

const ItemAssigningModal = ({
  showModal,
  handleModalClose,
  data,
  handleSubmit,
  isSubmitting,
  header,
  description,
  targetTitle,
  allowRemoval,
}: {
  showModal: boolean;
  handleModalClose: () => void;
  data: { id: number; name: string }[];
  isSubmitting: boolean;
  header: string;
  description: string;
  targetTitle: string;
} & (
  | { handleSubmit: (id: number) => void; allowRemoval: false }
  | {
      handleSubmit: (id: number | null) => void;
      allowRemoval: true;
    }
)) => {
  const [toAssignId, setToAssignId] = useState<number>();

  const handleChangeSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setToAssignId(
        e.target.value === "" ? undefined : parseInt(e.target.value),
      );
    },
    [setToAssignId],
  );

  const onSubmit = useCallback(() => {
    if (allowRemoval) {
      handleSubmit(toAssignId ?? null);
    } else if (typeof toAssignId !== "undefined") {
      handleSubmit(toAssignId);
    }
    setToAssignId(undefined);
  }, [allowRemoval, handleSubmit, toAssignId]);

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="m-2">{description}</Row>
        <Row className="m-2">
          <Form.Select
            placeholder={`Select ${targetTitle}`}
            value={toAssignId}
            onChange={handleChangeSelect}
          >
            <option key={null} value="">
              {allowRemoval ? "Unassigned" : `Select ${targetTitle}`}
            </option>
            {data.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleModalClose}
          disabled={isSubmitting}
        >
          Discard
        </Button>
        <SubmitButton
          onClick={onSubmit}
          isSubmitting={isSubmitting}
          disabled={!allowRemoval && typeof toAssignId === "undefined"}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ItemAssigningModal;
