import React from "react";
import { Col, Row } from "react-bootstrap";

/**
 * Record container with child content
 */
const Content = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => (
  <Row>
    <Col>
      <div className={`bg-body rounded p-3 ${className ?? ""}`}>{children}</div>
    </Col>
  </Row>
);

export default Content;
