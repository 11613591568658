import Spinner from "./components/Spinner";

const Loading = ({ message }: { message?: string }) => (
  <div>
    Loading
    {typeof message === "undefined" || message.trim() === ""
      ? ""
      : ` ${message}`}
    ... <Spinner size="sm" />
  </div>
);

export default Loading;
