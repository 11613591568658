import { useMemo } from "react";
import { Alert } from "react-bootstrap";
import { AxisOptions, UserSerie } from "react-charts";
import { useParams } from "react-router-dom";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import HistoricalChart, { TimeSeries } from "../../dashboard/HistoricalChart";
import { useAuthenticatedFetch } from "../../lib/api";
import { formatPercent } from "../../lib/numbers";

const AccountHistoricalPerformanceChart = ({
  customReportPeriodParams,
}: {
  customReportPeriodParams: string;
}) => {
  const { accountId } = useParams();
  const {
    isPending: isPendingHistoricalPerformance,
    isError: isErrorHistoricalPerformance,
    data: dataHistoricalPerformance,
  } = useAuthenticatedFetch<
    UnpackResponse<AccountsController["getHistoricalPerformance"]>
  >(
    `/accounts/${accountId}/performance/historical?${customReportPeriodParams}`,
  );
  const historicalPerformance = dataHistoricalPerformance?.data;

  const primaryAxis = useMemo(
    (): AxisOptions<TimeSeries> => ({
      getValue: (item) => item.date,
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<TimeSeries>[] => [
      {
        id: "value",
        getValue: (item) => item.value,
        elementType: "line",
        formatters: {
          scale: (value: number | null) =>
            value === null ? "" : formatPercent(value, 1),
        },
      } as AxisOptions<TimeSeries>,
    ],
    [],
  );

  const performanceDataSeries = useMemo(
    (): UserSerie<TimeSeries>[] => [
      {
        label: "Performance",
        axisFamily: "time",
        secondaryAxisId: "value",
        data: (historicalPerformance ?? []).map((item) => ({
          value: item.performance,
          date: new Date(item.date),
        })),
      } as UserSerie<TimeSeries>,
    ],
    [historicalPerformance],
  );

  return isPendingHistoricalPerformance ? (
    <Loading message="Chart" />
  ) : isErrorHistoricalPerformance ? (
    <Alert variant="danger">Failed to load historical Performance </Alert>
  ) : performanceDataSeries[0].data.length === 0 ? (
    <Alert variant="info">No performance data found</Alert>
  ) : (
    <HistoricalChart
      primaryAxis={primaryAxis}
      secondaryAxes={secondaryAxes}
      data={performanceDataSeries}
    />
  );
};

export default AccountHistoricalPerformanceChart;
