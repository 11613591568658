import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import { useContext, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import type { AccountWithMetrics } from "../../../../api/src/accounts/accounts.service";
import type { BillingController } from "../../../../api/src/billing/billing.controller";
import type { CashSettingsController } from "../../../../api/src/cash-settings/cash-settings.controller";
import type { AccountCashSetting } from "../../../../api/src/cash-settings/lib";
import type {
  EditableHousehold,
  FeeChargeLocation,
} from "../../../../api/src/households-base/households-base.service";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import { NotificationContext } from "../../Notifications";
import FormFieldError from "../../components/FormFieldError";
import SubmitButton from "../../components/SubmitButton";
import {
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../../lib/api";
import { InlineError, displayAccountName } from "../../lib/display";
import { getSchemaFieldLabel } from "../../lib/forms";
import { formatCurrency, formatPercent } from "../../lib/numbers";
import HouseholdCashBillingTable, {
  HouseholdCashBillingRow,
} from "./HouseholdCashBillingTable";
import { HouseholdContext } from "./HouseholdInfo";

const feeChargeLocations: FeeChargeLocation[] = [
  "Custodian Billed",
  "Direct Billed",
];

const HouseholdCashBilling = () => {
  const { household, schema } = useContext(HouseholdContext);
  const { householdId } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useFormContext<EditableHousehold>();

  const {
    isPending: isPendingFeeStructures,
    isError: isErrorFeeStructures,
    data: dataFeeStructures,
  } = useAuthenticatedFetch<
    UnpackResponse<BillingController["getAllFeeStructures"]>
  >("/billing/fee-structures");
  const feeStructures = dataFeeStructures?.data;

  const feeStructureOptions = [
    <option key={null} value="">
      None
    </option>,
    ...(feeStructures ?? [])
      .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
      .map((feeStructure) => (
        <option
          key={feeStructure.feeStructureId}
          value={feeStructure.feeStructureId}
        >
          {feeStructure.name}
        </option>
      )),
  ];

  const {
    isPending: isPendingBillingMinimums,
    isError: isErrorBillingMinimums,
    data: dataBillingMinimum,
  } = useAuthenticatedFetch<
    UnpackResponse<BillingController["getAllBillingMinimums"]>
  >("/billing/billing-minimums");

  const billingMinimumOptions = useMemo(
    () => [
      <option key={null} value="">
        None
      </option>,
      ...(dataBillingMinimum?.data ?? [])
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((billingMinimum) => (
          <option key={billingMinimum.id} value={billingMinimum.id}>
            {billingMinimum.name}
          </option>
        )),
    ],
    [dataBillingMinimum?.data],
  );

  const updateHousehold = useAuthenticatedMutationAsync<
    UnpackResponse<HouseholdsController["update"]>
  >(`/households/${householdId}`, async (household: EditableHousehold) => ({
    method: "PUT",
    body: JSON.stringify(household),
  }));

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: EditableHousehold) => {
      try {
        const householdBody = await updateHousehold(data);
        reset(householdBody.data);
        notificationContext.pushNotification({
          id: `household-${householdId}`,
          header: "Household Updated",
          body: `${household?.name} updated`,
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save household", err);
        notificationContext.pushNotification({
          id: `household-${householdId}`,
          header: "Failed to Save Household",
          body: `${household?.name} was not saved`,
          variant: "danger",
        });
      }
    },
  });

  const { isPending: isPendingFeeEstimates, data: dataFeeEstimates } =
    useAuthenticatedFetch<
      UnpackResponse<BillingController["getHouseholdFeeEstimates"]>
    >(`/billing/fee-estimates/${householdId}`);
  const feeEstimates = dataFeeEstimates?.data;

  const { isPending: isPendingCashSettings, data: dataCashSettings } =
    useAuthenticatedFetch<
      UnpackResponse<CashSettingsController["getAllAccountSettings"]>
    >("/cash-settings/accounts");
  const cashSettings = dataCashSettings?.data;

  const combinedCashSettingsAccountsHouseholds = useMemo(() => {
    const cashSettingsMap: Record<number, AccountCashSetting> = _.keyBy(
      cashSettings ?? [],
      "accountId",
    );
    const accountsMap: Record<number, AccountWithMetrics> = _.keyBy(
      household?.accounts ?? [],
      "id",
    );
    const feeStructuresMap = _.keyBy(feeStructures ?? [], "feeStructureId");

    return Object.entries(accountsMap).map(([accountId, account]) => {
      const cashSetting = cashSettingsMap[parseInt(accountId)];

      const feeStructureId =
        account.feeStructureId ?? household?.feeStructureId;

      // Catch cases where a relocated account is no longer active
      const feeLocationAccountId =
        typeof account.feeLocationAccountId === "undefined" ||
        typeof accountsMap[account.feeLocationAccountId] === "undefined"
          ? undefined
          : account.feeLocationAccountId;

      const result: HouseholdCashBillingRow = {
        ...account,
        ...(typeof cashSetting === "undefined" ? {} : cashSetting),
        cashSettingId: account.cashSettingId,
        feeStructureId: feeStructureId,
        feeStructureName:
          typeof feeStructureId === "undefined"
            ? undefined
            : feeStructuresMap[feeStructureId]?.name,
        isFeeStructureInherited:
          typeof feeStructureId !== "undefined" &&
          typeof account.feeStructureId === "undefined",
        feeLocation: account.feeLocation,
        feeLocationAccountId,
        feeLocationAccountName:
          account.feeLocation !== "A" ||
          typeof feeLocationAccountId === "undefined"
            ? undefined
            : displayAccountName(
                accountsMap[feeLocationAccountId].displayName,
                accountsMap[feeLocationAccountId].displayNumber,
              ),
      };

      return result;
    });
  }, [cashSettings, feeStructures, household]);

  return typeof register === "undefined" || typeof errors === "undefined" ? (
    <Loading />
  ) : (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      <Row>
        <Col>
          <h3>Billing Settings</h3>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xl={7}>
          {typeof household === "undefined" ||
          isPendingFeeStructures ||
          isPendingBillingMinimums ? (
            <Loading />
          ) : (
            <>
              <Row>
                <Col as="dt" xxl={4} md={6}>
                  {getSchemaFieldLabel(schema.fields.feeStructureId)}
                </Col>
                <Col as="dd" xxl={6} md={6}>
                  {isErrorFeeStructures ? (
                    <InlineError>
                      There was an error loading fee structures
                    </InlineError>
                  ) : (
                    <Form.Select
                      placeholder="Select assigned fee structure"
                      {...register("feeStructureId")}
                    >
                      {feeStructureOptions}
                    </Form.Select>
                  )}
                  <FormFieldError field={errors.feeStructureId} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" xxl={4} md={6}>
                  {getSchemaFieldLabel(schema.fields.billingMinimumId)}
                </Col>
                <Col as="dd" xxl={6} md={6}>
                  {isErrorBillingMinimums ? (
                    <InlineError>
                      There was an error loading billing minimums
                    </InlineError>
                  ) : (
                    <Form.Select
                      placeholder="Select assigned billing minimum"
                      {...register("billingMinimumId")}
                    >
                      {billingMinimumOptions}
                    </Form.Select>
                  )}
                  <FormFieldError field={errors.billingMinimumId} />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col as="dt" xxl={4} md={6}>
              {getSchemaFieldLabel(schema.fields.feeChargeLocation)}
            </Col>
            <Col as="dd" xxl={6} md={6}>
              <Form.Select
                {...register("feeChargeLocation")}
                placeholder="Select fee location"
              >
                {feeChargeLocations.map((feeLocation, idx) => (
                  <option key={idx} value={feeLocation}>
                    {feeLocation}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Col>
        <Col xl={5}>
          {isPendingFeeEstimates ? (
            <Loading message="Fee Estimates" />
          ) : (
            <>
              <Row>
                <Col as="dt" xxl={4} md={6}>
                  Est. Effective Fee Rate
                </Col>
                <Col as="dd" xxl={8} md={6}>
                  {formatPercent(feeEstimates?.estEffectiveFeeRate ?? 0, 2)}
                </Col>
              </Row>
              <Row>
                <Col as="dt" xxl={4} md={6}>
                  Est. Annual Fee
                </Col>
                <Col as="dd" xxl={8} md={6}>
                  {formatCurrency(feeEstimates?.totalAnnualBill ?? 0)}
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h3>Account Cash Settings</h3>
          {isPendingCashSettings ? (
            <Loading message="Accounts" />
          ) : (
            <HouseholdCashBillingTable
              accounts={combinedCashSettingsAccountsHouseholds}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <SubmitButton isSubmitting={isSubmitting} />
        </Col>
      </Row>
    </Form>
  );
};

export default HouseholdCashBilling;
