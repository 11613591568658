import React from "react";
import TabContainerWithTabs from "../components/TabContainer";
import { HouseholdsTable } from "../clients/household/Households";
import DashboardNav from "./DashboardNav";

const ClientSummary = () => (
  <TabContainerWithTabs tabs={DashboardNav}>
    <HouseholdsTable />
  </TabContainerWithTabs>
);

export default ClientSummary;
