import { keepPreviousData } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { RenderSuggestionParams } from "react-autosuggest";
import { Form } from "react-bootstrap";
import {
  Control,
  FieldValues,
  Path,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { SecuritiesController } from "../../../api/src/securities/securities.controller";
import { useAuthenticatedFetch } from "../lib/api";
import Autocomplete, {
  AutocompleteControlled,
  Suggestion,
} from "./Autocomplete";

type SymbolSuggestion = { symbol: string; description: string };

function SymbolField<TForm extends FieldValues>({
  fieldName,
  register,
  control,
  getValues,
  onSelect,
  description,
}: {
  fieldName?: Path<TForm>;
  register?: UseFormRegister<TForm>;
  control?: Control<TForm>;
  getValues?: UseFormGetValues<TForm>;
  onSelect?: (suggestion: SymbolSuggestion) => void;
  description?: string;
}) {
  const [name, setName] = useState("");
  const { data, refetch, isFetching } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<SecuritiesController["getTickers"]>>
  >(
    `/securities/tickers?name=${name}`,
    undefined,
    { enabled: false, placeholderData: keepPreviousData },
    ["/securities/tickers", name],
  );

  const loadSuggestions = useCallback(
    (value: string) => {
      setName(value);
    },
    [setName],
  );

  const getSuggestionValue = useCallback(
    (suggestion: SymbolSuggestion) => suggestion.symbol,
    [],
  );

  const renderSuggestion = useCallback(
    (suggestion: SymbolSuggestion, params: RenderSuggestionParams) => (
      <Suggestion active={params.isHighlighted} title={suggestion.description}>
        {suggestion.symbol}
      </Suggestion>
    ),
    [],
  );

  useEffect(() => {
    if (name.length > 0) refetch();
  }, [name, refetch]);

  return (
    <>
      {typeof control === "undefined" || typeof fieldName === "undefined" ? (
        <Autocomplete
          suggestions={
            (data?.data ?? []).filter((value) =>
              value.symbol.toLowerCase().includes(name.toLowerCase()),
            ) as SymbolSuggestion[]
          }
          isLoading={isFetching}
          loadSuggestions={loadSuggestions}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          name={fieldName}
          register={register}
          getValues={getValues}
          onSelect={onSelect}
        />
      ) : (
        <AutocompleteControlled
          suggestions={
            (data?.data ?? []).filter((value) =>
              value.symbol.toLowerCase().includes(name.toLowerCase()),
            ) as SymbolSuggestion[]
          }
          isLoading={isFetching}
          loadSuggestions={loadSuggestions}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          name={fieldName}
          control={control}
          onSelect={onSelect}
        />
      )}
      <Form.Text>{description}</Form.Text>
    </>
  );
}

export default SymbolField;
