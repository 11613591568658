import { useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LabelValuePair, {
  OptionalLabelValuePair,
} from "../../components/LabelValuePair";
import { capitalize, formatAddress, newlinesToJSX } from "../../lib/display";
import { HouseholdContext } from "./HouseholdInfo";

const HouseholdProfile = () => {
  const { household } = useContext(HouseholdContext);

  return (
    <Row>
      <Col xl={6} lg={8} xs={12}>
        <h3>Contact Info</h3>
        {typeof household?.contactInfo.phoneNumber === "undefined" ||
        household?.contactInfo.phoneNumber === "" ? null : (
          <LabelValuePair
            label="Primary Phone"
            value={
              <a href={`tel:${household?.contactInfo.phoneNumber}`}>
                {household?.contactInfo.phoneNumber}
              </a>
            }
            orientation="horizontal"
            widths={{ label: { xxl: 4, md: 6 } }}
            plaintext
          />
        )}
        {typeof household?.contactInfo.email === "undefined" ||
        household?.contactInfo.email === "" ? null : (
          <LabelValuePair
            label="Primary Email"
            value={
              <a href={`mailto:${household?.contactInfo.email}`}>
                {household?.contactInfo.email}
              </a>
            }
            orientation="horizontal"
            widths={{ label: { xxl: 4, md: 6 } }}
            plaintext
          />
        )}
        <LabelValuePair
          label="Primary Address"
          value={newlinesToJSX(formatAddress(household?.contactInfo ?? {}))}
          orientation="horizontal"
          widths={{ label: { xxl: 4, md: 6 } }}
          plaintext
        />
        <OptionalLabelValuePair
          label="Background Info"
          value={household?.backgroundInfo}
          orientation="horizontal"
          widths={{ label: { xxl: 4, md: 6 } }}
          plaintext
        />
        {Object.entries(household?.additionalFields ?? {}).map(
          ([field, value]) => (
            <OptionalLabelValuePair
              label={field}
              value={value}
              orientation="horizontal"
              widths={{ label: { xxl: 4, md: 6 } }}
              plaintext
            />
          ),
        )}
      </Col>
      {(household?.members ?? []).length <= 0 ? null : (
        <Col xl={6} lg={4} xs={12}>
          <h3>Members</h3>
          {(household?.members ?? []).map((member) => (
            <Card key={member.id} className="mb-3">
              <Card.Header as="h5">
                <Link to={`/clients/contacts/${member.id}`}>{member.name}</Link>
              </Card.Header>
              <Card.Body>
                <OptionalLabelValuePair
                  label="Title"
                  value={capitalize(member.title ?? "")}
                  orientation="horizontal"
                  plaintext
                />
                {typeof member.phoneNumber === "undefined" ||
                member.phoneNumber === "" ? null : (
                  <LabelValuePair
                    label="Phone Number"
                    value={
                      <a href={`tel:${member.phoneNumber}`}>
                        {member.phoneNumber}
                      </a>
                    }
                    orientation="horizontal"
                    plaintext
                  />
                )}
                {typeof member.email === "undefined" ||
                member.email === "" ? null : (
                  <OptionalLabelValuePair
                    label="Email"
                    value={
                      <a href={`mailto:${member.email}`}>{member.email}</a>
                    }
                    orientation="horizontal"
                    plaintext
                  />
                )}
                <OptionalLabelValuePair
                  label="Birth Date"
                  value={member.birthDate?.toLocaleDateString()}
                  orientation="horizontal"
                  plaintext
                />
                <OptionalLabelValuePair
                  label="Retirement Date"
                  value={member.retirementDate?.toLocaleDateString()}
                  orientation="horizontal"
                  plaintext
                />
                <OptionalLabelValuePair
                  label="Date of Death"
                  value={member.dateOfDeath?.toLocaleDateString()}
                  orientation="horizontal"
                  plaintext
                />
                <OptionalLabelValuePair
                  label="Background Info"
                  value={member.backgroundInfo}
                  orientation="horizontal"
                  plaintext
                />
                {Object.entries(member.additionalFields ?? {}).map(
                  ([field, value]) => (
                    <OptionalLabelValuePair
                      label={field}
                      value={value}
                      orientation="horizontal"
                      plaintext
                    />
                  ),
                )}
              </Card.Body>
            </Card>
          ))}
        </Col>
      )}
    </Row>
  );
};

export default HouseholdProfile;
