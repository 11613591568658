import { useEffect } from "react";
import {
  Col,
  Form,
  InputGroup,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { Control, useController, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import type {
  AccountCashSetting,
  CashTargetCondition,
  CashTargetType,
} from "../../../api/src/cash-settings/lib";
import Loading from "../Loading";
import { useQueryAccount } from "../clients/account/lib";
import FormError from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import { getSchemaFieldLabel, nullableNumberSchema } from "../lib/forms";
import { formatCurrency, formatPercent } from "../lib/numbers";
import { useQueryAccountCashSetting, useQueryCashSettings } from "./lib";

export type AccountCashSettingFormState = Omit<AccountCashSetting, "accountId">;

const cashConditionSchema = yup
  .string()
  .oneOf<CashTargetCondition>(["and", "or"])
  .default("and")
  .required();

export const schema: yup.ObjectSchema<AccountCashSettingFormState> = yup.object(
  {
    cashSettingId: nullableNumberSchema.label("Cash Setting"),
    cashTargetType: yup
      .string()
      .oneOf<CashTargetType>(["absolute", "percent"])
      .default("percent")
      .required()
      .label("Target Type"),
    cashTargetAbsolute: nullableNumberSchema.min(0).label("Cash Target"),
    cashTargetPercent: nullableNumberSchema
      .min(0)
      .max(100)
      .label("Percent Target"),
    cashTargetMinCondition: cashConditionSchema.label("Minimum Condition"),
    cashTargetMinAbsolute: nullableNumberSchema
      .min(0)
      .label("Minimum Cash Trigger"),
    cashTargetMinPercent: nullableNumberSchema
      .min(0)
      .max(100)
      .label("Minimum Percent Trigger"),
    cashTargetMaxCondition: cashConditionSchema.label("Maximum Condition"),
    cashTargetMaxAbsolute: nullableNumberSchema
      .min(0)
      .label("Maximum Cash Trigger"),
    cashTargetMaxPercent: nullableNumberSchema
      .min(0)
      .max(100)
      .label("Maximum Percent Trigger"),
  },
);

// ToggleButtonGroup/ToggleButton have issues with refs from react-hook-form
// having a race condition. If the initial form render is too quick, the
// ToggleButton inputs display as unselected, even if an initial value is passed
// to the form in the 'reset' function. Use controlled form components to work
// around this issue.

const AbsolutePercentToggle = ({
  name,
  control,
}: {
  name: keyof AccountCashSettingFormState;
  control: Control<AccountCashSettingFormState>;
}) => {
  const {
    field: { onChange, onBlur, value, ref, name: fieldName },
  } = useController({
    name,
    control,
  });

  return (
    <ToggleButtonGroup
      type="radio"
      name={fieldName}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      <ToggleButton value="percent" id={`${fieldName}-percent`} inputRef={ref}>
        %
      </ToggleButton>
      <ToggleButton
        value="absolute"
        id={`${fieldName}-absolute`}
        inputRef={ref}
      >
        $
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const ConditionToggle = ({
  name,
  control,
}: {
  name: keyof AccountCashSettingFormState;
  control: Control<AccountCashSettingFormState>;
}) => {
  const {
    field: { onChange, onBlur, value, ref, name: fieldName },
  } = useController({
    name,
    control,
  });

  return (
    <ToggleButtonGroup
      type="radio"
      name={fieldName}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      <ToggleButton value="and" id={`${fieldName}-and`} inputRef={ref}>
        AND
      </ToggleButton>
      <ToggleButton value="or" id={`${fieldName}-or`} inputRef={ref}>
        OR
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const AccountCashInfo = () => {
  const {
    register,
    reset,
    watch,
    control,
    formState: { errors },
    getValues,
  } = useFormContext<AccountCashSettingFormState>();

  const { accountId: accountIdStr } = useParams();
  const accountId =
    typeof accountIdStr === "undefined" ? undefined : parseInt(accountIdStr);

  const {
    isPending: isPendingAccountCashSettings,
    isError: isErrorAccountCashSettings,
    data: accountCashSetting,
  } = useQueryAccountCashSetting(accountId ?? -1, {
    enabled: typeof accountId !== "undefined",
  });

  const {
    isPending: isPendingAccount,
    isError: isErrorAccount,
    data: account,
  } = useQueryAccount(accountId ?? -1, {
    includeBalances: true,
    enabled:
      !isPendingAccountCashSettings &&
      typeof accountCashSetting !== "undefined",
  });

  const {
    isPending: isPendingCashSettings,
    isError: isErrorCashSettings,
    data: dataCashSettings,
  } = useQueryCashSettings();

  const cashSettingOptions = isPendingCashSettings
    ? [
        <option key={null} value="">
          Loading...
        </option>,
      ]
    : isErrorCashSettings
      ? [
          <option key={null} value="">
            There was an error loading cash settings
          </option>,
        ]
      : [
          <option key="" value="">
            None
          </option>,
          ...dataCashSettings.data
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
            .map((cashSetting) => (
              <option key={cashSetting.id} value={cashSetting.id}>
                {cashSetting.name}
              </option>
            )),
        ];

  useEffect(() => {
    if (typeof accountCashSetting !== "undefined") {
      reset({ ...getValues(), ...accountCashSetting });
    }
  }, [accountCashSetting, reset, register, getValues]);

  const cashTargetType = watch("cashTargetType");
  const cashSettingId = watch("cashSettingId", null) as string | null;

  return isPendingAccountCashSettings || isPendingAccount ? (
    <Loading />
  ) : isErrorAccountCashSettings || isErrorAccount ? (
    <FormError message="Failed to load account cash settings" />
  ) : !accountCashSetting ? (
    <FormError message="Account cash settings not found" />
  ) : (
    <>
      <Row className="mb-2">
        <Col xl={6} md={12} sm={6}>
          <Row>
            <Col as="dt" sm={2}>
              AUM
            </Col>
            <Col as="dd" sm={10}>
              {formatCurrency(account?.accountBalance ?? 0, 2)}
            </Col>
          </Row>
        </Col>
        <Col xl={6} md={12} sm={6}>
          <Row>
            <Col as="dt" sm={2}>
              Cash
            </Col>
            <Col as="dd" sm={10}>
              {formatCurrency(account?.cashBalance ?? 0, 2)} (
              {formatPercent(
                (account?.cashBalance ?? 0) /
                  (account?.accountBalance ?? account?.cashBalance ?? 1),
                2,
              )}
              )
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="mb-3">
            <Col>
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.cashSettingId)}
              </Form.Label>
              <Form.Select {...register("cashSettingId")}>
                {cashSettingOptions}
              </Form.Select>
            </Col>
          </Row>
          {cashSettingId !== "" && cashSettingId !== null ? null : (
            <>
              <Row className="mb-3">
                <Col>
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.cashTargetAbsolute)}
                  </Form.Label>
                  <InputGroup>
                    {cashTargetType !== "absolute" ? null : (
                      <>
                        <Form.Control
                          type="number"
                          min={0}
                          step={0.01}
                          {...register("cashTargetAbsolute")}
                        />
                        <FormFieldError field={errors.cashTargetAbsolute} />
                      </>
                    )}
                    {cashTargetType !== "percent" ? null : (
                      <>
                        <Form.Control
                          type="number"
                          min={0}
                          max={100}
                          step={0.01}
                          {...register("cashTargetPercent")}
                        />
                        <FormFieldError field={errors.cashTargetPercent} />
                      </>
                    )}
                    <AbsolutePercentToggle
                      // {...register("cashTargetType")}
                      name="cashTargetType"
                      control={control}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.cashTargetMinAbsolute)}
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      min={0}
                      step={0.01}
                      {...register("cashTargetMinAbsolute")}
                    />
                    <ConditionToggle
                      name="cashTargetMinCondition"
                      control={control}
                    />
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      step={0.01}
                      {...register("cashTargetMinPercent")}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <FormFieldError field={errors.cashTargetMinCondition} />
                  <FormFieldError field={errors.cashTargetMinAbsolute} />
                  <FormFieldError field={errors.cashTargetMinPercent} />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.cashTargetMaxAbsolute)}
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      min={0}
                      step={0.01}
                      {...register("cashTargetMaxAbsolute")}
                    />
                    <ConditionToggle
                      name="cashTargetMaxCondition"
                      control={control}
                    />
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      step={0.01}
                      {...register("cashTargetMaxPercent")}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <FormFieldError field={errors.cashTargetMaxCondition} />
                  <FormFieldError field={errors.cashTargetMaxAbsolute} />
                  <FormFieldError field={errors.cashTargetMaxPercent} />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default AccountCashInfo;
