import { useCallback, useContext, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "../../Loading";
import AccountNumber from "../../components/AccountNumber";
import PerformancePeriodSelector from "../../components/PerformancePeriodSelector";
import { custodianCodeToDisplayName } from "../../lib/custodian";
import { boolToYesNo, unassignedLabel } from "../../lib/display";
import { useQueryHousehold } from "../household/lib";
import AccountHistoricalAumChart from "./AccountHistoricalAumChart";
import AccountHistoricalPerformanceChart from "./AccountHistoricalPerformanceChart";
import { AccountContext } from "./AccountInfo";
import AccountPerformance from "./AccountPerformance";
import AccountTaxSummary from "./AccountTaxSummary";

const AccountOverview = () => {
  const { account } = useContext(AccountContext);

  const {
    isFetching: isFetchingHousehold,
    isError: isErrorHousehold,
    data: household,
  } = useQueryHousehold(account?.householdId ?? 0, {
    enabled: typeof account?.householdId !== "undefined",
  });

  const reportTypes = ["AUM", "Cash", "Performance"];
  const [reportType, setReportType] = useState("AUM");
  const [customAccountReportPeriodParams, setCustomAccountReportPeriodParams] =
    useState("");

  const onChange = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    setReportType(ev.currentTarget.value);
  }, []);

  return typeof account === "undefined" ? null : (
    <>
      <Row>
        <Col xxl={4} xl={6} className="mb-3">
          <dl>
            <Row>
              <Col as="dt" sm={4}>
                Type
              </Col>
              <Col as="dd">{account.type}</Col>
            </Row>
            <Row>
              <Col as="dt" sm={4}>
                Taxable
              </Col>
              <Col as="dd">{boolToYesNo(account.isTaxable)}</Col>
            </Row>
            <Row>
              <Col as="dt" sm={4}>
                Tax Deferred
              </Col>
              <Col as="dd">{boolToYesNo(account.isTaxDeferred)}</Col>
            </Row>
            <Row>
              <Col as="dt" sm={4}>
                Account Number
              </Col>
              <Col as="dd">
                <AccountNumber
                  fullNumber={account.number}
                  displayNumber={account.displayNumber}
                />
              </Col>
            </Row>
            <Row>
              <Col as="dt" sm={4}>
                Custodian
              </Col>
              <Col as="dd">{custodianCodeToDisplayName(account.custodian)}</Col>
            </Row>
            {account.closeDate === null ? null : (
              <Row>
                <Col as="dt" sm={4}>
                  Closing Date
                </Col>
                <Col as="dd">
                  {new Date(account.closeDate).toLocaleDateString()}
                </Col>
              </Row>
            )}
            {account.archiveDate && (
              <Row>
                <Col as="dt" sm={4}>
                  Archive Date
                </Col>
                <Col as="dd">{account.archiveDate.toLocaleDateString()}</Col>
              </Row>
            )}
            <Row>
              <Col as="dt" sm={4}>
                Household
              </Col>
              <Col as="dd">
                {isFetchingHousehold ? (
                  <Loading />
                ) : isErrorHousehold ? (
                  <span className="text-danger">Failed to load household</span>
                ) : typeof household === "undefined" ? (
                  unassignedLabel
                ) : (
                  <Link to={`/clients/households/${account.householdId}`}>
                    {household.name}
                  </Link>
                )}
              </Col>
            </Row>
          </dl>
        </Col>
        <Col xl={4} className="mb-3">
          <AccountPerformance />
        </Col>
        {!account.isTaxable ? null : (
          <Col xl={4} className="mb-3">
            <AccountTaxSummary />
          </Col>
        )}
      </Row>
      <Row>
        <Col xxl={5} xl={6} lg={3} md={4}>
          <h5>Reports</h5>
        </Col>
        <Col xl={4} sm={4}>
          <Form.Select value={reportType} onChange={onChange}>
            {reportTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <PerformancePeriodSelector
            setCustomPeriodParams={setCustomAccountReportPeriodParams}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {reportType === "AUM" && (
            <AccountHistoricalAumChart
              valueType="totalValue"
              customReportPeriodParams={customAccountReportPeriodParams}
            />
          )}
          {reportType === "Cash" && (
            <AccountHistoricalAumChart
              valueType="cashValue"
              customReportPeriodParams={customAccountReportPeriodParams}
            />
          )}
          {reportType === "Performance" && (
            <AccountHistoricalPerformanceChart
              customReportPeriodParams={customAccountReportPeriodParams}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default AccountOverview;
