import { useMutation } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import type { AccountsController } from "../../../api/src/accounts/accounts.controller";
import type { AccountWithMetrics } from "../../../api/src/accounts/accounts.service";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type {
  BillingReportAccount,
  FeeStructure,
} from "../../../api/src/billing/lib";
import type { HouseholdsController } from "../../../api/src/households/households.controller";
import type { HouseholdWithMetrics } from "../../../api/src/households/households.service";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import {
  deserializeAccountWithMetrics,
  useQueryAccounts,
} from "../clients/account/lib";
import {
  deserializeHouseholdWithMetrics,
  useQueryHouseholds,
} from "../clients/household/lib";
import ActionButton from "../components/ActionButton";
import { useErrorMessage } from "../components/FormError";
import IndeterminateCheckbox from "../components/IndeterminateCheckbox";
import InfoTooltip from "../components/InfoTooltip";
import ItemAssigningModal from "../components/ItemAssigningModal";
import TabContainerWithTabs from "../components/TabContainer";
import { Table, useTable } from "../components/Table/Table";
import {
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import { parseISODateNaive } from "../lib/date";
import { displayAccountName, naLabel } from "../lib/display";
import { formatCurrency, formatPercent } from "../lib/numbers";
import BillingNav from "./BillingNav";
import { feeLocationDisplay } from "./lib";

type BillingHouseholdRow = {
  id: number;
  name: string;
  noOfAccounts: number;
  totalAUM: number;
  feeStructureId?: number;
  feeStructureName?: string;
  estEffectiveFeeRate: number;
  estFee: number;
  estAnnualFee: number;
};

const householdColumnHelper = createColumnHelper<BillingHouseholdRow>();

const BillingSummaryHouseholdTable = ({
  billingSummary,
  households,
  accounts,
  feeStructures,
  viewMode,
  showModal,
  setShowModal,
  handleModalClose,
  setError,
  resetError,
  refetchSummary,
  viewModeSelector,
}: {
  billingSummary?: UnpackResponse<
    BillingController["getBillingSummary"]
  >["data"];
  households?: HouseholdWithMetrics[];
  accounts?: AccountWithMetrics[];
  feeStructures?: FeeStructure[];
  viewMode: string;
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  handleModalClose: () => void;
  setError: (message: string) => void;
  resetError: () => void;
  refetchSummary: () => void;
  viewModeSelector: React.ReactNode;
}) => {
  const columns = useMemo(
    () => [
      householdColumnHelper.display({
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      }),
      householdColumnHelper.accessor((row) => row.name, {
        id: "household",
        cell: (info) => (
          <Link to={`/clients/households/${info.row.original.id}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => "Household",
      }),
      householdColumnHelper.accessor("noOfAccounts", {
        header: () => "# of Accounts",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      householdColumnHelper.accessor("totalAUM", {
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "Total Assets",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      householdColumnHelper.accessor((row) => row.feeStructureName ?? "", {
        id: "feeStructure",
        cell: (info) => (
          <Link
            to={`/billing/fee-structure/${info.row.original.feeStructureId}`}
          >
            {info.getValue()}
          </Link>
        ),
        header: () => "Fee Structure",
      }),
      householdColumnHelper.accessor("estEffectiveFeeRate", {
        cell: (info) =>
          typeof billingSummary === "undefined" ? (
            <Loading />
          ) : billingSummary === null ? (
            naLabel
          ) : (
            formatPercent(info.getValue(), 2)
          ),
        header: () => "Est. Effective Fee %",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      householdColumnHelper.accessor("estFee", {
        cell: (info) =>
          typeof billingSummary === "undefined" ? (
            <Loading />
          ) : billingSummary === null ? (
            naLabel
          ) : (
            formatCurrency(info.getValue())
          ),
        header: () => "Est. Effective Fee $",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      householdColumnHelper.accessor("estAnnualFee", {
        cell: (info) =>
          typeof billingSummary === "undefined" ? (
            <Loading />
          ) : billingSummary === null ? (
            naLabel
          ) : (
            formatCurrency(info.getValue())
          ),
        header: () => "Est. Annual Fee $",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
    ],
    [billingSummary],
  );

  const rows = useMemo((): BillingHouseholdRow[] => {
    const feeStructuresMap = _.keyBy(feeStructures, "feeStructureId");

    if (typeof billingSummary === "undefined" || billingSummary === null) {
      const accountsMap = _.groupBy(accounts, "householdId");

      return (households ?? []).map((household) => {
        const householdAccounts = accountsMap[household.id] ?? [];
        const totalAUM = householdAccounts.reduce(
          (result, account) => result + (account.accountBalance ?? 0),
          0,
        );

        return {
          id: household.id,
          name: household.name,
          noOfAccounts: householdAccounts.length,
          totalAUM,
          estEffectiveFeeRate: 0,
          estAnnualFee: 0,
          estFee: 0,
          feeStructureId: household.feeStructureId,
          feeStructureName:
            typeof household.feeStructureId === "undefined"
              ? undefined
              : feeStructuresMap[household.feeStructureId]?.name,
        };
      });
    } else {
      const accountsMap = _.groupBy(billingSummary.accounts, "householdId");

      return billingSummary.households.map((household) => {
        const householdAccounts = accountsMap[household.id] ?? [];

        return {
          id: household.id,
          name: household.name,
          noOfAccounts: householdAccounts.length,
          totalAUM: household.totalAUM,
          estEffectiveFeeRate: household.estEffectiveFeeRate,
          estFee: household.estFee,
          estAnnualFee: household.estAnnualFee,
          feeStructureId: household.feeStructureId,
          feeStructureName:
            typeof household.feeStructureId !== "undefined"
              ? feeStructuresMap[household.feeStructureId].name
              : "",
        };
      });
    }
  }, [accounts, billingSummary, feeStructures, households]);

  const { table, rowSelection, setRowSelection } = useTable({
    columns,
    data: rows,
    getRowId: (row) => row.id.toString(),
  });

  useEffect(() => {
    setRowSelection({});
  }, [setRowSelection, viewMode]);

  const assignFeeStructureToHouseholds = useAuthenticatedMutationAsync<
    UnpackResponse<HouseholdsController["assignFeeStructureToHouseholds"]>
  >(
    `/households/fee-structure/assign`,
    async (data: { feeStructureId: number; householdIds: number[] }) => ({
      method: "POST",
      body: JSON.stringify(data),
    }),
  );

  const onSubmit = useMutation({
    mutationFn: async (data: {
      selectedId: number | null;
      sourceIds: number[];
    }) => {
      resetError();

      try {
        if (actionMode === 1) {
          await assignFeeStructureToHouseholds({
            feeStructureId: data.selectedId,
            householdsIds: data.sourceIds,
          });
        }

        setShowModal(false);
        refetchSummary();
      } catch (err) {
        const message = "Failed to assign to households";
        setError(message);
        console.error(message, err);
      }
    },
  });

  const handleSubmit = useCallback(
    (toAssignId: number | null) => {
      const sourceIds = Object.keys(
        table.getFilteredSelectedRowModel().rowsById,
      ).map((idStr) => parseInt(idStr));
      onSubmit.mutateAsync({ selectedId: toAssignId, sourceIds });
    },
    [table, onSubmit],
  );

  const [actionMode, setActionMode] = useState(0);
  const handleActionItemClick = (action: number) => {
    setActionMode(action);
    setShowModal(true);
  };

  return (
    <>
      <Col>
        <Row>
          {viewModeSelector}
          <Col className="mb-3 d-flex justify-content-end">
            <DropdownButton title="Actions">
              <Dropdown.Item as="button" disabled>
                Export to Excel
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => handleActionItemClick(1)}
                disabled={Object.keys(rowSelection).length === 0}
              >
                Assign Fee Structures
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
      </Col>
      <Table table={table} />
      <ItemAssigningModal
        showModal={showModal}
        handleModalClose={handleModalClose}
        data={(feeStructures ?? []).map((feeStructure) => ({
          id: feeStructure.feeStructureId,
          name: feeStructure.name,
        }))}
        header="Assign Fee Structure to Households"
        description={`${Object.keys(rowSelection).length} Households Selected`}
        targetTitle="Fee Structure"
        isSubmitting={onSubmit.isPending}
        handleSubmit={handleSubmit}
        allowRemoval
      />
    </>
  );
};

type BillingAccountRow = {
  id: number;
  name: string;
  householdId?: number;
  householdName?: string;
  aum: number;
  cash: number;
  feeStructureId?: number;
  feeStructureName?: string;
  isFeeStructureInherited: boolean;
  feeLocation?: "S" | "G" | "A";
  feeLocationAccountId?: number;
  feeLocationAccountName?: string;
  cashOrNextFee?: number;
};

const accountColumnHelper = createColumnHelper<BillingAccountRow>();

const BillingSummaryAccountTable = ({
  billingSummary,
  households,
  accounts,
  feeStructures,
  viewMode,
  showModal,
  setShowModal,
  handleModalClose,
  setError,
  resetError,
  refetchSummary,
  viewModeSelector,
}: {
  billingSummary?: UnpackResponse<
    BillingController["getBillingSummary"]
  >["data"];
  households?: HouseholdWithMetrics[];
  accounts?: AccountWithMetrics[];
  feeStructures?: FeeStructure[];
  viewMode: string;
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  handleModalClose: () => void;
  setError: (message: string) => void;
  resetError: () => void;
  refetchSummary: () => void;
  viewModeSelector: React.ReactNode;
}) => {
  const columns = useMemo(
    () => [
      accountColumnHelper.display({
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      }),
      accountColumnHelper.accessor((row) => row.name, {
        id: "account",
        cell: (info) => (
          <Link to={`/clients/accounts/${info.row.original.id}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => "Account",
      }),
      accountColumnHelper.accessor((row) => row.householdName ?? "", {
        id: "household",
        cell: (info) => (
          <Link to={`/clients/households/${info.row.original.householdId}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => "Household",
      }),
      accountColumnHelper.accessor((row) => row.aum, {
        id: "aum",
        cell: (info) => formatCurrency(+info.getValue()),
        header: () => "AUM",
        enableColumnFilter: false,
      }),
      accountColumnHelper.accessor((row) => row.cash, {
        id: "cash",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Cash %",
        enableColumnFilter: false,
      }),
      accountColumnHelper.accessor((row) => row.feeStructureName ?? "", {
        id: "feeStructure",
        cell: (info) => (
          <Link
            to={`/billing/fee-structure/${info.row.original.feeStructureId}`}
          >
            {info.row.original.isFeeStructureInherited ? (
              <em>{info.getValue()}</em>
            ) : (
              info.getValue()
            )}
          </Link>
        ),
        header: () => "Fee Structure",
      }),
      accountColumnHelper.accessor(
        (row) =>
          feeLocationDisplay(
            row.feeLocation ?? "S",
            row.feeLocationAccountName,
          ),
        {
          id: "feeLocation",
          cell: (info) =>
            info.row.original.feeLocation !== "A" ? (
              info.getValue()
            ) : (
              <Link
                to={`/clients/accounts/${info.row.original.feeLocationAccountId}`}
              >
                {info.getValue()}
              </Link>
            ),
          header: () => "Fee Location",
          enableColumnFilter: false,
        },
      ),
    ],
    [],
  );

  const rows = useMemo((): BillingAccountRow[] => {
    const householdsMap = _.keyBy(households, "id");
    const accountsMap = _.keyBy(accounts, "id");
    const feeStructuresMap = _.keyBy(feeStructures, "feeStructureId");

    const result: BillingAccountRow[] = (
      billingSummary?.accounts ??
      accounts ??
      []
    ).map(
      (
        account: AccountWithMetrics &
          Partial<{
            billing: BillingReportAccount & { billableAUM: number };
          }>,
      ) => {
        const accountHousehold =
          typeof account.householdId === "undefined"
            ? undefined
            : householdsMap[account.householdId];
        const feeStructureId =
          account.feeStructureId ?? accountHousehold?.feeStructureId;

        // Catch cases where a relocated account is no longer active
        const feeLocationAccountId =
          typeof account.feeLocationAccountId === "undefined" ||
          typeof accountsMap[account.feeLocationAccountId] === "undefined"
            ? undefined
            : account.feeLocationAccountId;

        return {
          id: account.id,
          name: displayAccountName(account.displayName, account.displayNumber),
          householdId: account.householdId,
          householdName: accountHousehold?.name,
          aum: account.accountBalance ?? 0,
          cash:
            (account.accountBalance ?? 0) === 0
              ? 0
              : (account.cashBalance ?? 0) / (account.accountBalance ?? 0),
          feeStructureId: feeStructureId,
          feeStructureName:
            typeof feeStructureId === "undefined"
              ? undefined
              : feeStructuresMap[feeStructureId].name,
          isFeeStructureInherited:
            typeof feeStructureId !== "undefined" &&
            typeof account.feeStructureId === "undefined",
          cashOrNextFee:
            typeof billingSummary === "undefined"
              ? undefined
              : typeof account.billing?.totalDebited === "undefined" ||
                  account.billing.totalDebited === 0
                ? undefined
                : (account.cashBalance ?? 0) / account.billing.totalDebited,
          feeLocation: account.feeLocation,
          feeLocationAccountId,
          feeLocationAccountName:
            account.feeLocation !== "A" ||
            typeof feeLocationAccountId === "undefined"
              ? undefined
              : displayAccountName(
                  accountsMap[feeLocationAccountId].displayName,
                  accountsMap[feeLocationAccountId].displayNumber,
                ),
        };
      },
    );

    const accountsPayingForOthers = result
      .filter((account) => typeof account.feeLocationAccountId !== "undefined")
      .map((account) => account.feeLocationAccountId as number);

    return result.filter(
      (account) =>
        viewMode === "Account" ||
        (typeof account.householdId === "undefined" &&
          viewMode === "Accounts without households") ||
        (typeof account.feeStructureId === "undefined" &&
          viewMode === "Accounts without fee structure") ||
        (viewMode === "Accounts paying for other accounts" &&
          accountsPayingForOthers.includes(account.id)) ||
        (viewMode === "Accounts with relocated fees" &&
          typeof account.feeLocationAccountId !== "undefined"),
    );
  }, [households, feeStructures, billingSummary, accounts, viewMode]);

  const { table, setRowSelection } = useTable({
    columns,
    data: rows,
    getRowId: (row) => row.id.toString(),
  });

  useEffect(() => {
    setRowSelection({});
  }, [setRowSelection, viewMode]);

  const assignHouseholdToAccounts = useAuthenticatedMutationAsync<
    UnpackResponse<HouseholdsController["mapAccountsToHouseholds"]>
  >(`/households/accounts/assign`, async (data: Record<number, number[]>) => ({
    method: "POST",
    body: JSON.stringify(data),
  }));

  const assignFeeStructureToAccounts = useAuthenticatedMutationAsync<
    UnpackResponse<AccountsController["assignFeeStructureToAccounts"]>
  >(
    `/accounts/fee-structure/assign`,
    async (data: { feeStructureId: number; accountsIds: number[] }) => ({
      method: "POST",
      body: JSON.stringify(data),
    }),
  );

  const onSubmit = useMutation({
    mutationFn: async (data: {
      selectedId: number | null;
      sourceIds: number[];
    }) => {
      resetError();

      try {
        if (actionMode === 1) {
          await assignHouseholdToAccounts(
            data.selectedId === null
              ? { null: data.sourceIds }
              : {
                  [data.selectedId]: data.sourceIds,
                },
          );
        } else if (actionMode === 2) {
          await assignFeeStructureToAccounts({
            feeStructureId: data.selectedId,
            accountsIds: data.sourceIds,
          });
        }

        setShowModal(false);
        refetchSummary();
      } catch (err) {
        const message = "Failed to assign to accounts";
        setError(message);
        console.error(message, err);
      }
    },
  });

  const handleSubmit = useCallback(
    (toAssignId: number | null) => {
      const sourceIds = Object.keys(
        table.getFilteredSelectedRowModel().rowsById,
      ).map((idStr) => parseInt(idStr));
      onSubmit.mutateAsync({ selectedId: toAssignId, sourceIds });
    },
    [onSubmit, table],
  );

  const [actionMode, setActionMode] = useState(0);
  const handleActionItemClick = (action: number) => {
    setActionMode(action);
    setShowModal(true);
  };

  return (
    <>
      <Col>
        <Row>
          {viewModeSelector}
          <Col className="mb-3 d-flex justify-content-end">
            <DropdownButton title="Actions">
              <Dropdown.Item as="button" disabled>
                Export to Excel
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => handleActionItemClick(1)}
                disabled={
                  Object.keys(table.getFilteredSelectedRowModel().rowsById)
                    .length === 0
                }
              >
                Assign Accounts to Households
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => handleActionItemClick(2)}
                disabled={
                  Object.keys(table.getFilteredSelectedRowModel().rowsById)
                    .length === 0
                }
              >
                Assign Fee Structures to Accounts
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
      </Col>
      <Table table={table} />
      <ItemAssigningModal
        showModal={showModal}
        handleModalClose={handleModalClose}
        data={
          actionMode === 1
            ? (billingSummary?.households ?? [])
            : (feeStructures ?? []).map((feeStructure) => ({
                id: feeStructure.feeStructureId,
                name: feeStructure.name,
              }))
        }
        header={
          actionMode === 1
            ? "Assign Accounts to Household"
            : "Assign Fee Structure to Accounts"
        }
        description={`${
          Object.keys(table.getFilteredSelectedRowModel().rowsById).length
        } Accounts Selected`}
        targetTitle={actionMode === 1 ? "Household" : "Fee Structure"}
        isSubmitting={onSubmit.isPending}
        handleSubmit={handleSubmit}
        allowRemoval
      />
    </>
  );
};

const ViewModeSelector = ({
  viewMode,
  setViewMode,
}: {
  viewMode: string;
  setViewMode: (val: string) => void;
}) => {
  const handleChangeViewMode = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setViewMode(e.target.value);
    },
    [setViewMode],
  );

  return (
    <Col sm={8} md={6} xl={4} xxl={3} className="mb-3">
      <Form.Group controlId="form-calculation-type">
        <Form.Label>View by</Form.Label>
        <Form.Select value={viewMode} onChange={handleChangeViewMode}>
          {[
            "Household",
            "Account",
            "Accounts without households",
            "Accounts without fee structure",
            "Accounts paying for other accounts",
            "Accounts with relocated fees",
          ].map((mode) => (
            <option key={mode} value={mode}>
              {mode}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>
  );
};

const BillingSummary = () => {
  const {
    isPending: isPendingSummary,
    isError: isErrorSummary,
    data: summaryBody,
    refetch: refetchSummary,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<BillingController["getBillingSummary"]>>
  >(`/billing/billing-summary`);

  const billingSummary = useMemo(
    () =>
      typeof summaryBody?.data === "undefined"
        ? undefined
        : summaryBody.data === null
          ? null
          : {
              ...summaryBody.data,
              asOfDate: parseISODateNaive(summaryBody.data.asOfDate),
              accounts: summaryBody.data.accounts.map((account) => ({
                ...deserializeAccountWithMetrics(account),
                billing:
                  typeof account.billing === "undefined"
                    ? undefined
                    : {
                        ...account.billing,
                        startDate: parseISODateNaive(account.billing.startDate),
                        endDate: parseISODateNaive(account.billing.endDate),
                      },
              })),
              households: summaryBody.data.households.map(
                deserializeHouseholdWithMetrics,
              ),
            },
    [summaryBody?.data],
  );

  const {
    isPending: isPendingHouseholds,
    isError: isErrorHouseholds,
    data: households,
  } = useQueryHouseholds();

  const {
    isPending: isPendingAccounts,
    isError: isErrorAccounts,
    data: accountsBody,
  } = useQueryAccounts({ includeBalances: true, pageSize: 10000 });
  const accounts = accountsBody?.data ?? [];

  const { isPending: isPendingFeeStructures, data: feeStructuresBody } =
    useAuthenticatedFetch<
      SerializedObject<UnpackResponse<BillingController["getAllFeeStructures"]>>
    >("/billing/fee-structures");
  const feeStructures = feeStructuresBody?.data;

  const { setError, resetError, errorMessage } = useErrorMessage();

  const [viewMode, setViewMode] = useState("Household");
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const viewModeSelector = (
    <ViewModeSelector viewMode={viewMode} setViewMode={setViewMode} />
  );

  return (
    <TabContainerWithTabs tabs={BillingNav}>
      {
        <>
          {errorMessage}
          <Row>
            <Col xl={12}>
              <ButtonToolbar className="mb-3">
                <ButtonGroup className="me-3">
                  <ActionButton
                    variant="secondary"
                    label="Run Firm-Wide Billing"
                    icon="/icons/new.svg"
                    className="btn-action_secondary_wide"
                    as={Link}
                    to="/billing/reports/run?type=standard"
                  />
                </ButtonGroup>
                <ButtonGroup>
                  <ActionButton
                    variant="secondary"
                    label="Run One-Off Billing"
                    icon="/icons/new.svg"
                    className="btn-action_secondary_wide"
                    as={Link}
                    to="/billing/reports/run?type=custom"
                  />
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Billing Summary</h3>
              {isPendingSummary ? (
                <Loading />
              ) : isErrorSummary ? (
                <Alert variant="danger">An error occurred</Alert>
              ) : billingSummary === null ? (
                <Alert>
                  Please run a billing report to see a summary of your firm's
                  billing.
                </Alert>
              ) : (
                <>
                  <small>
                    *Based on latest billing report from{" "}
                    {billingSummary?.asOfDate.toLocaleDateString()}
                  </small>
                  <Row>
                    <Col xxl={3} md={6}>
                      <Row className="mb-2">
                        <Col>
                          <div className="text-center item-card">
                            <Row className="h-100 align-items-center">
                              <Col as="dt" sm={6}>
                                AUM with Fees
                              </Col>
                              <Col as="dd">
                                {formatCurrency(
                                  billingSummary?.aumWithFees ?? 0,
                                )}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          <div className="text-center item-card">
                            <Row className="h-100 align-items-center">
                              <Col as="dt" sm={6}>
                                AUM with 0% Fee
                              </Col>
                              <Col as="dd">
                                {formatCurrency(
                                  billingSummary?.unbilledAUM ?? 0,
                                )}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xxl={3} md={6}>
                      <Row className="mb-2">
                        <Col>
                          <div className="text-center item-card">
                            <Row className="h-100 align-items-center">
                              <Col as="dt" sm={6}>
                                <span className="align-middle me-1">
                                  Effective Fee %
                                </span>
                                <InfoTooltip tooltip="The effective fee in parentheses includes any accounts with 0% fee" />
                              </Col>
                              <Col as="dd">
                                {formatPercent(
                                  billingSummary?.effectiveFeeRateBillableAUM ??
                                    0,
                                  2,
                                )}{" "}
                                (
                                {formatPercent(
                                  billingSummary?.effectiveFeeRateTotalAUM ?? 0,
                                  2,
                                )}
                                )
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          <div className="text-center item-card">
                            <Row className="h-100 align-items-center">
                              <Col as="dt" sm={6}>
                                Est. Annual Revenue
                              </Col>
                              <Col as="dd">
                                {formatCurrency(
                                  billingSummary?.annualRevenue ?? 0,
                                )}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xxl={3} md={6}>
                      <Row className="mb-2">
                        <Col>
                          <div className="text-center item-card">
                            <Row className="h-100 align-items-center">
                              <Col as="dt" sm={6}>
                                Households
                              </Col>
                              <Col as="dd">
                                <Link to={`/clients/households`}>
                                  {billingSummary?.households.length.toLocaleString()}
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          <div className="text-center item-card">
                            <Row className="h-100 align-items-center">
                              <Col as="dt" sm={6}>
                                Accounts
                              </Col>
                              <Col as="dd">
                                <Link to={`/clients/accounts`}>
                                  {billingSummary?.accounts.length.toLocaleString()}
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xxl={3} md={6}>
                      <Row className="mb-2">
                        <Col>
                          <div className="text-center item-card">
                            <Row className="h-100 align-items-center">
                              <Col as="dt" sm={5}>
                                Accounts w/o Fee Structure
                              </Col>
                              <Col as="dd">
                                <Link
                                  to={`/clients/accounts?${billingSummary?.accountsWithoutFeeStructures
                                    .map((id) => `id=${id}`)
                                    .join("&")}`}
                                >
                                  {billingSummary?.accountsWithoutFeeStructures.length.toLocaleString()}
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          <div className="text-center item-card">
                            <Row className="h-100 align-items-center">
                              <Col as="dt" sm={5}>
                                Accounts w/o Household
                              </Col>
                              <Col as="dd">
                                <Link
                                  to={`/clients/accounts?${billingSummary?.accountsWithoutHouseholds
                                    .map((id) => `id=${id}`)
                                    .join("&")}`}
                                >
                                  {billingSummary?.accountsWithoutHouseholds.length.toLocaleString()}
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <Row className="mt-3">
            {isPendingHouseholds ||
            isPendingAccounts ||
            isPendingFeeStructures ? (
              <Loading />
            ) : isErrorHouseholds || isErrorAccounts ? (
              <Alert variant="danger">Failed to load households/accounts</Alert>
            ) : viewMode.includes("Account") ? (
              <BillingSummaryAccountTable
                billingSummary={billingSummary}
                households={households.data}
                accounts={accounts}
                feeStructures={feeStructures}
                viewMode={viewMode}
                showModal={showModal}
                setShowModal={setShowModal}
                handleModalClose={handleModalClose}
                setError={setError}
                resetError={resetError}
                refetchSummary={refetchSummary}
                viewModeSelector={viewModeSelector}
              />
            ) : (
              <BillingSummaryHouseholdTable
                billingSummary={billingSummary}
                households={households.data}
                accounts={accounts}
                feeStructures={feeStructures}
                viewMode={viewMode}
                showModal={showModal}
                setShowModal={setShowModal}
                handleModalClose={handleModalClose}
                setError={setError}
                resetError={resetError}
                refetchSummary={refetchSummary}
                viewModeSelector={viewModeSelector}
              />
            )}
          </Row>
        </>
      }
    </TabContainerWithTabs>
  );
};

export default BillingSummary;
