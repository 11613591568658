const Footer = () => (
  <footer className="d-flex justify-content-center py-1 small">
    <nav>
      <a
        href="https://help.advicecloudft.com"
        target="_blank"
        rel="noreferrer"
        className="me-3"
      >
        Help
      </a>
      <a href="mailto:support@advicecloudft.com" className="me-3">
        Support
      </a>
      <a
        href="https://www.advicecloudft.com/terms_and_conditions"
        target="_blank"
        rel="noreferrer"
        className="me-3"
      >
        Terms and Conditions
      </a>
      <a
        href="https://www.advicecloudft.com/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
    </nav>
  </footer>
);

export default Footer;
