import _ from "lodash";
import { useCallback, useMemo } from "react";
import { Button, ButtonGroup, Form, Table } from "react-bootstrap";
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
  Path,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";
import type { SecurityRestriction } from "../../../api/src/models/models.service";
import { addRemoveLabel } from "../lib/display";
import { useQuerySecurities } from "../lib/security";
import FormFieldError from "./FormFieldError";
import SymbolField from "./SymbolField";

type SecurityRestrictionField = SecurityRestriction & { _field_id: string };

const emptySecurityRestriction: SecurityRestrictionField = {
  symbol: "",
  treatment: "hold_only",
  _field_id: "",
};

/**
 * A multi-item form component for portfolio security restrictions. This component
 * displays an arbitrary number of rows for users to view and modify security restrictions.
 */
function SecurityRestrictions<
  TForm extends { securityRestrictions: SecurityRestriction[] },
>({
  fields,
  replace,
  add,
  remove,
  register,
  control,
  getValues,
  errors,
}: {
  /** The collection of primary securities for a model */
  fields: SecurityRestrictionField[];
  /** The setter function for the fields prop */
  replace: (securityRestrictions: SecurityRestrictionField[]) => void;
  add: (securityRestrictions: SecurityRestrictionField) => void;
  remove: UseFieldArrayRemove;
  register: UseFormRegister<TForm>;
  control: Control<TForm>;
  getValues: UseFormGetValues<TForm>;
  errors?: Merge<
    FieldError,
    (Merge<FieldError, FieldErrorsImpl<SecurityRestriction>> | undefined)[]
  >;
}) {
  const addSecurity = useCallback(() => {
    add({ ...emptySecurityRestriction });
  }, [add]);

  const removeSecurity = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove],
  );

  const { data: securities } = useQuerySecurities(
    fields.map((security) => security.symbol),
  );

  const symbolsMap = useMemo(
    () => _.keyBy(securities ?? [], "identifier"),
    [securities],
  );

  return (
    <Table className="form-table form-table-sm">
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Treatment</th>
          <th>{addRemoveLabel}</th>
        </tr>
      </thead>
      <tbody>
        {fields.length <= 0 ? (
          <tr>
            <td>None</td>
            <td>{/* Empty */}</td>
            <td>
              <Button onClick={addSecurity}>+</Button>
            </td>
          </tr>
        ) : (
          fields.map((security, index) => (
            <tr key={security._field_id}>
              <td className="align-top">
                <SymbolField
                  fieldName={
                    `securityRestrictions.${index}.symbol` as Path<TForm>
                  }
                  register={register}
                  control={control}
                  getValues={getValues}
                  description={symbolsMap[security.symbol]?.description}
                />
                <FormFieldError field={errors?.[index]?.symbol} />
              </td>
              <td className="align-top">
                <Form.Group>
                  <Form.Select
                    {...register(
                      `securityRestrictions.${index}.treatment` as Path<TForm>,
                    )}
                  >
                    <option value="hold_only">Hold only</option>
                    <option value="sell_no_tax">
                      Sell if no tax consequences
                    </option>
                  </Form.Select>
                  <FormFieldError field={errors?.[index]?.treatment} />
                </Form.Group>
              </td>
              <td className="align-top">
                <ButtonGroup>
                  <Button onClick={removeSecurity(index)}>-</Button>
                  {index !== fields.length - 1 ? null : (
                    <Button onClick={addSecurity}>+</Button>
                  )}
                </ButtonGroup>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
}

export default SecurityRestrictions;
