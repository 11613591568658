import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import type { UnpackResponse } from "../../../api/src/lib";
import type { TransfersController } from "../../../api/src/transfers/transfers.controller";
import type {
  ForecastedTransfer,
  RecurringTransferFrequency,
  TransferType,
} from "../../../api/src/transfers/transfers.service";
import { NotificationContext } from "../Notifications";
import { useQueryAccounts } from "../clients/account/lib";
import Content from "../components/Content";
import FormFieldError from "../components/FormFieldError";
import SubmitButton from "../components/SubmitButton";
import {
  processEmptyResponse,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import { toISODateNoTimezone } from "../lib/date";
import { displayAccountName } from "../lib/display";
import { getSchemaFieldLabel } from "../lib/forms";

type ForecastedTransferForm = Omit<
  ForecastedTransfer,
  "id" | "householdId" | "createdTime" | "updatedTime" | "status"
>;

const directions = ["DEP", "WITH", "TLO", "TLC"];
const frequencies = [
  "first",
  "biweekly",
  "monthly",
  "quarterly",
  "semiannually",
  "annually",
  "other",
];

const schema: yup.ObjectSchema<ForecastedTransferForm> = yup.object({
  accountId: yup.number().required().label("Acoount"),
  description: yup.string().required().label("Description"),
  direction: yup
    .string()
    .oneOf(directions as TransferType[])
    .required()
    .label("Direction"),
  amount: yup.number().required().min(0).label("Amount"),
  frequency: yup
    .string()
    .oneOf(frequencies as RecurringTransferFrequency[])
    .required()
    .label("Frequency"),
  startDate: yup
    .date()
    .required()
    .min(toISODateNoTimezone(new Date()))
    .label("Start Date"),
});

const CreateForecastedTransfer = () => {
  const [params] = useSearchParams();
  const accountId = params.get("accountId");

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForecastedTransferForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      accountId:
        accountId === null || accountId === ""
          ? undefined
          : parseInt(accountId),
    },
  });

  const navigate = useNavigate();

  const {
    isPending: isPendingAccounts,
    isError: isErrorAccounts,
    data: dataAccounts,
  } = useQueryAccounts({
    includeBalances: true,
    pageSize: 10000,
  });

  const accountOptions = isPendingAccounts
    ? [
        <option key={null} value="">
          Loading...
        </option>,
      ]
    : isErrorAccounts
      ? [
          <option key={null} value="">
            There was an error loading accounts
          </option>,
        ]
      : [
          <option key={null} value="">
            Select a account
          </option>,
          ...(dataAccounts?.data ?? [])
            .sort((a, b) =>
              a.displayName < b.displayName
                ? -1
                : a.displayName > b.displayName
                  ? 1
                  : 0,
            )
            .map((account) => (
              <option key={account.id} value={account.id}>
                {displayAccountName(account.displayName, account.displayNumber)}
              </option>
            )),
        ];

  const createForecastedTransfer = useAuthenticatedMutationAsync<
    UnpackResponse<TransfersController["createForecastedTransfer"]>
  >(
    "/transfers/forecast",
    async (transfer: ForecastedTransferForm) => ({
      method: "POST",
      body: JSON.stringify(transfer),
    }),
    processEmptyResponse,
  );

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: ForecastedTransferForm) => {
      try {
        const body = await createForecastedTransfer(data);
        notificationContext.pushNotification({
          id: `forecasted-transfer-${body}`,
          header: "Forecasted Transfer Created",
          body: `Forecasted transfer "${data.description}" created`,
          variant: "success",
        });
        navigate("..");
      } catch (err) {
        console.error("Failed to save forecasted transfer", err);
        notificationContext.pushNotification({
          id: "forecasted-transfer",
          header: "Failed to Create Forecasted Transfer",
          body: `Forecasted transfer "${data.description}" was not saved`,
          variant: "danger",
        });
      }
    },
  });

  return (
    <>
      <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
        <Row>
          <Col>
            <h1>Forecasted Transfer</h1>
          </Col>
        </Row>
        <Content>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group controlId="form-accountId">
                <Form.Label>
                  {getSchemaFieldLabel(schema.fields.accountId)}
                </Form.Label>
                <Form.Select
                  placeholder="Select account"
                  {...(isPendingAccounts ? {} : register("accountId"))}
                >
                  {accountOptions}
                </Form.Select>
                <FormFieldError field={errors.accountId} />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="form-direction">
                <Form.Label>
                  {getSchemaFieldLabel(schema.fields.direction)}
                </Form.Label>
                <Form.Select
                  placeholder="Select direction"
                  {...register("direction")}
                >
                  {directions.map((direction, idx) => (
                    <option key={idx} value={direction}>
                      {direction}
                    </option>
                  ))}
                </Form.Select>
                <FormFieldError field={errors.direction} />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>
                  {getSchemaFieldLabel(schema.fields.amount)}
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    {...register("amount")}
                    defaultValue={0}
                  />
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup>
                <FormFieldError field={errors.amount} />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="form-frequency">
                <Form.Label>
                  {getSchemaFieldLabel(schema.fields.frequency)}
                </Form.Label>
                <Form.Select
                  placeholder="Select frequency"
                  {...register("frequency")}
                >
                  {frequencies.map((frequency, idx) => (
                    <option key={idx} value={frequency}>
                      {frequency.charAt(0).toUpperCase() +
                        frequency.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
                <FormFieldError field={errors.direction} />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>
                  {getSchemaFieldLabel(schema.fields.startDate)}
                </Form.Label>
                <Form.Control
                  type="date"
                  {...register("startDate")}
                  defaultValue={toISODateNoTimezone(new Date())}
                />
                <FormFieldError field={errors.startDate} />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>
                  {getSchemaFieldLabel(schema.fields.description)}
                </Form.Label>
                <Form.Control type="text" {...register("description")} />
                <FormFieldError field={errors.description} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <SubmitButton isSubmitting={isSubmitting} />
            </Col>
          </Row>
        </Content>
      </Form>
    </>
  );
};

export default CreateForecastedTransfer;
