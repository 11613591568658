import { useContext, useMemo } from "react";
import {
  dateIsUpcoming,
  dateIsUpcomingOrOverdue,
  sortISODate,
} from "../../lib/date";
import { naLabel } from "../../lib/display";
import { HouseholdContext } from "./HouseholdInfo";

const sevenDaysInSeconds = 6.048e5;

const HouseholdEvents = () => {
  const { household } = useContext(HouseholdContext);

  const upcomingTask = useMemo(
    () =>
      household?.tasks
        .sort((a, b) => sortISODate(a.dueDate ?? null, b.dueDate ?? null))
        .find((task) =>
          dateIsUpcomingOrOverdue(task.dueDate ?? null, sevenDaysInSeconds),
        ),
    [household?.tasks],
  );

  const upcomingEvent = useMemo(
    () =>
      household?.events
        .sort((a, b) => sortISODate(a.starts, b.starts))
        .find((event) => dateIsUpcoming(event.starts, sevenDaysInSeconds)),
    [household?.events],
  );

  const upcomingWorkflow = useMemo(
    () =>
      household?.workflows
        .sort((a, b) => sortISODate(a.startsAt, b.startsAt))
        .find((workflow) =>
          dateIsUpcoming(workflow.startsAt, sevenDaysInSeconds),
        ),
    [household?.workflows],
  );

  const upcomingBirthdate = useMemo(
    () =>
      household?.memberDates
        .sort((a, b) => sortISODate(a.birthDate, b.birthDate))
        .find(
          (member) =>
            member.birthDate !== null &&
            dateIsUpcoming(member.birthDate, sevenDaysInSeconds),
        ),
    [household?.memberDates],
  );

  const upcomingRetirement = useMemo(
    () =>
      household?.memberDates
        .sort((a, b) => sortISODate(a.retirementDate, b.retirementDate))
        .find(
          (member) =>
            member.retirementDate !== null &&
            dateIsUpcoming(member.retirementDate, sevenDaysInSeconds),
        ),
    [household?.memberDates],
  );

  const upcomingAnniversary = useMemo(
    () =>
      household?.memberDates
        .sort((a, b) => sortISODate(a.anniversary, b.anniversary))
        .find(
          (member) =>
            member.anniversary !== null &&
            dateIsUpcoming(member.anniversary, sevenDaysInSeconds),
        ),
    [household?.memberDates],
  );

  const upcomingClientAnniversary = useMemo(
    () =>
      household?.memberDates
        .sort((a, b) => sortISODate(a.clientSinceDate, b.clientSinceDate))
        .find(
          (member) =>
            member.clientSinceDate !== null &&
            dateIsUpcoming(member.clientSinceDate, sevenDaysInSeconds),
        ),
    [household?.memberDates],
  );

  return (
    <dl>
      <dt>Upcoming Task</dt>
      <dd>
        {typeof upcomingTask === "undefined" ? (
          naLabel
        ) : (
          <span>
            <a
              href={`${process.env.REACT_APP_WEALTHBOX_APP_ROOT}/tasks/${upcomingTask.id}`}
              rel="noreferrer"
              target="_blank"
            >
              {upcomingTask.name}
            </a>
            :{" "}
            {typeof upcomingTask.dueDate === "undefined"
              ? "No due date"
              : upcomingTask.dueDate.toLocaleString()}
          </span>
        )}
      </dd>
      <dt>Upcoming Event</dt>
      <dd>
        {typeof upcomingEvent === "undefined" ? (
          naLabel
        ) : (
          <span>
            <a
              href={`${process.env.REACT_APP_WEALTHBOX_APP_ROOT}/events/${upcomingEvent.id}`}
              rel="noreferrer"
              target="_blank"
            >
              {upcomingEvent.title}
            </a>
            :{" "}
            {upcomingEvent.starts === null
              ? "No event date"
              : upcomingEvent.starts.toLocaleString()}
          </span>
        )}
      </dd>
      <dt>Upcoming Workflow</dt>
      <dd>
        {typeof upcomingWorkflow === "undefined" ? (
          naLabel
        ) : (
          <span>
            <a
              href={`${process.env.REACT_APP_WEALTHBOX_APP_ROOT}/workflows/${upcomingWorkflow.id}`}
              rel="noreferrer"
              target="_blank"
            >
              {upcomingWorkflow.label}
            </a>
            : {upcomingWorkflow.startsAt.toLocaleString()}
          </span>
        )}
      </dd>
      {typeof upcomingBirthdate === "undefined" ? null : (
        <>
          <dt>Upcoming Birthday</dt>
          <dd>
            {upcomingBirthdate.name}:{" "}
            {(upcomingBirthdate.birthDate as Date).toLocaleDateString()}
          </dd>
        </>
      )}
      {typeof upcomingRetirement === "undefined" ? null : (
        <>
          <dt>Upcoming Retirement</dt>
          <dd>
            {upcomingRetirement.name}:{" "}
            {(upcomingRetirement.retirementDate as Date).toLocaleDateString()}
          </dd>
        </>
      )}
      {typeof upcomingAnniversary === "undefined" ? null : (
        <>
          <dt>Upcoming Anniversary</dt>
          <dd>
            {upcomingAnniversary.name}:{" "}
            {(upcomingAnniversary.anniversary as Date).toLocaleDateString()}
          </dd>
        </>
      )}
      {typeof upcomingClientAnniversary === "undefined" ? null : (
        <>
          <dt>Upcoming Client Anniversary</dt>
          <dd>
            {upcomingClientAnniversary.name}:{" "}
            {(
              upcomingClientAnniversary.clientSinceDate as Date
            ).toLocaleDateString()}
          </dd>
        </>
      )}
    </dl>
  );
};

export default HouseholdEvents;
