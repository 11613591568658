import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { RenderSuggestionParams } from "react-autosuggest";
import { useNavigate } from "react-router-dom";
import type { AppController } from "../../../api/src/app/app.controller";
import type { UnpackResponse } from "../../../api/src/lib";
import { useAuthenticatedFetch } from "../lib/api";
import { useDebounce } from "../lib/forms";
import Autocomplete, { Suggestion } from "./Autocomplete";

type GlobalSuggestion = {
  id?: number | string;
  name: string;
  description?: string;
  score?: number;
  source?: string;
  isLast?: boolean;
};

type sources =
  | "model"
  | "approved_list"
  | "benchmark"
  | "household"
  | "household_active"
  | "account"
  | "account_active"
  | "contact";

const urlMap: Record<sources, string> = {
  model: "/models/models/",
  approved_list: "/models/sleeves/",
  benchmark: "/models/benchmarks/",
  household: "/clients/households/",
  household_active: "/clients/households/",
  account: "/clients/accounts/",
  account_active: "/clients/accounts/",
  contact: "/clients/contacts/",
};

const sourceNameMap: Record<sources, string> = {
  model: "Model",
  approved_list: "Sleeve",
  benchmark: "Benchmark",
  household: "Household",
  household_active: "Household",
  account: "Account",
  account_active: "Account",
  contact: "Contact",
};

const GlobalSearchBox = ({ className = "" }: { className?: string }) => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [suggestions, setSuggestions] = useState<GlobalSuggestion[]>([]);

  const { data, refetch, isFetching } = useAuthenticatedFetch<
    UnpackResponse<AppController["globalSearch"]>
  >(`/search?keyword=${keyword}`, undefined, { enabled: false }, [
    "/search",
    keyword,
  ]);

  const [search] = useDebounce(refetch, 1000);

  const onSelect = useCallback(
    (value: GlobalSuggestion) => {
      if (
        typeof value.source !== "undefined" &&
        typeof value.id !== "undefined"
      ) {
        navigate(`${urlMap[value.source as sources]}${value.id}`);
      }
    },
    [navigate],
  );

  const loadSuggestions = useCallback(
    (value: string) => {
      setKeyword(value);
    },
    [setKeyword],
  );

  const getSuggestionValue = useCallback((suggestion: GlobalSuggestion) => {
    return suggestion.name;
  }, []);

  const renderSuggestion = useCallback(
    (suggestion: GlobalSuggestion, params: RenderSuggestionParams) => (
      <Suggestion
        active={params.isHighlighted}
        title={[
          sourceNameMap[suggestion.source as sources],
          ...(suggestion.description ? [suggestion.description] : []),
        ].join(" - ")}
        divider={suggestion.isLast}
      >
        {suggestion.name}
      </Suggestion>
    ),
    [],
  );

  useEffect(
    () => {
      if (keyword.length > 0) search();
    },
    // `search` is not a stable function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keyword],
  );

  useEffect(() => {
    if (!isFetching && typeof data?.data !== "undefined") {
      const group = _.groupBy(data.data, "source");

      setSuggestions(
        Object.keys(group)
          .map((key) => {
            const samplesOfGroup = group[key].slice(0, 3);
            const avgScore =
              samplesOfGroup.reduce(
                (result, suggestion) => result + suggestion.score,
                0,
              ) / samplesOfGroup.length;

            return {
              key,
              score: avgScore,
            };
          })
          .sort((a, b) => b.score - a.score)
          .map((score, idx) => [
            ...group[score.key].slice(0, -1),
            {
              ...group[score.key].at(-1)!,
              isLast: idx < Object.keys(group).length - 1,
            },
          ])
          .flat(),
      );
    }
  }, [data?.data, isFetching, setSuggestions]);

  return (
    <div className={`global-search-box-container ${className}`}>
      <Autocomplete
        suggestions={suggestions}
        isLoading={isFetching}
        loadSuggestions={loadSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSelect={onSelect}
        showSearchIcon={true}
        placeholder="Search"
      />
    </div>
  );
};

export default GlobalSearchBox;
