import React from "react";
import { Button, Modal } from "react-bootstrap";
import SubmitButton from "./SubmitButton";

const ConfirmationDialog = ({
  title,
  message,
  errorMessage,
  showModal,
  isConfirming,
  handleConfirm,
  handleClose,
}: {
  title: string;
  message: React.ReactNode;
  errorMessage?: React.ReactNode;
  showModal: boolean;
  isConfirming: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}) => (
  <Modal show={showModal} onHide={handleClose} centered>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {errorMessage}
      <p>{message}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose} disabled={isConfirming}>
        Cancel
      </Button>
      <SubmitButton
        variant="danger"
        label="Retire"
        onClick={handleConfirm}
        isSubmitting={isConfirming}
      />
    </Modal.Footer>
  </Modal>
);

export default ConfirmationDialog;
