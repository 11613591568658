import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type {
  Annual,
  BalanceType,
  BillingCycle,
  BillingFrequency,
  BillingPartition,
  CalculationType,
  CollectionType,
  FeeStructureForm,
  Quarter,
} from "../../../api/src/billing/lib";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import Content from "../components/Content";
import FormError from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import HelpTooltip from "../components/HelpTooltip";
import SubmitButton from "../components/SubmitButton";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import { capitalize } from "../lib/display";
import {
  getSchemaFieldLabel,
  optionalStringValueTransform,
  requiredNumberSchema,
} from "../lib/forms";
import FeeStructureInfoActionButtons from "./FeeStructureInfoActionButton";
import FeeTiers from "./FeeTiers";
import {
  feeStructureCycleHelpMessage,
  periodPartitionHelpMessage,
} from "./lib";

const calculationTypes: CalculationType[] = [
  "Flat Rate",
  "Flat Account Fee",
  "Flat Group Fee",
  "Drop Through",
  "Tiered",
  "No Fee",
];

const collectionTypes: CollectionType[] = [
  "Advance",
  "Arrears",
  "Advance with Proration",
];

const balanceTypes: BalanceType[] = [
  "Ending Period Balance",
  "Average Period Balance",
  "Ending Period Balance with Adjustment for Flows",
];

const billingFrequencies: BillingFrequency[] = [
  "Monthly",
  "Quarterly",
  "Annual",
];

const annuals: Annual[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const quarters: Quarter[] = [
  "Jan - Apr - Jul - Oct",
  "Feb - May - Aug - Nov",
  "Mar - Jun - Sep - Dec",
];

const partitionOptions: BillingPartition[] = ["daily", "period"];

const schema: yup.ObjectSchema<FeeStructureForm> = yup.object({
  name: yup.string().required().label("Name"),
  calculationType: yup
    .string()
    .oneOf<CalculationType>(calculationTypes)
    .required()
    .label("Calculation Type"),
  collectionType: yup
    .string()
    .oneOf<CollectionType>(collectionTypes)
    .required()
    .label("Collection Type"),
  balanceType: yup
    .string()
    .oneOf<BalanceType>(balanceTypes)
    .required()
    .label("Balance Type"),
  billingFrequency: yup
    .string()
    .oneOf<BillingFrequency>(billingFrequencies)
    .required()
    .label("Billing Frequency"),
  billingCycle: yup
    .string()
    .oneOf<BillingCycle>([...annuals, ...quarters])
    .label("Billing Cycle")
    .test(
      "billing-frequency-cycle",
      "A billing cycle is required for quarterly or annual frequency.",
      (item, testContext) =>
        testContext.parent.billingFrequency === "Monthly" ||
        (typeof item !== "undefined" &&
          ((testContext.parent.billingFrequency === "Quarterly" &&
            quarters.includes(item as Quarter)) ||
            (testContext.parent.billingFrequency === "Annual" &&
              annuals.includes(item as Annual)))),
    ),
  partition: yup
    .string()
    .oneOf<BillingPartition>(partitionOptions)
    .required()
    .label("Period Partition"),
  tiers: yup
    .array()
    .of(
      yup.object({
        min: requiredNumberSchema.label("Minimum"),
        max: requiredNumberSchema.label("Maximum"),
        feeRate: requiredNumberSchema.label("Fee Rate"),
      }),
    )
    .required(),
  feeValue: yup.number().default(0).transform(optionalStringValueTransform),
});

const FeeStructureInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
    reset,
  } = useForm<FeeStructureForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    fields: tierFields,
    append: appendTiers,
    remove: removeTiers,
    replace: replaceTiers,
  } = useFieldArray({
    name: "tiers",
    control,
    keyName: "_field_id",
  });

  const watchTiersArray = watch("tiers", []);
  const controlledTierFields = tierFields.map((field, index) => ({
    ...field,
    ...watchTiersArray[index],
  }));

  const { feeStructureId } = useParams();
  const isNew = feeStructureId === "new";

  const {
    isLoading,
    isError,
    data: feeStructureBody,
  } = useAuthenticatedFetch<
    UnpackResponse<BillingController["getFeeStructure"]>
  >(`/billing/fee-structures/${feeStructureId}`, undefined, {
    enabled: !isNew,
  });

  const createFeeStructure = useAuthenticatedMutationAsync<
    UnpackResponse<BillingController["createFeeStructure"]>
  >(
    "/billing/fee-structures",
    async (feeStructure: FeeStructureForm) => ({
      method: "POST",
      body: JSON.stringify(feeStructure),
    }),
    processEmptyResponse,
  );

  const updateFeeStructure = useAuthenticatedMutationAsync<
    UnpackResponse<BillingController["updateFeeStructure"]>
  >(
    `/billing/fee-structures/${feeStructureId}`,
    async (feeStructure: FeeStructureForm) => ({
      method: "PUT",
      body: JSON.stringify(feeStructure),
    }),
    processEmptyResponse,
  );

  const navigate = useNavigate();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: FeeStructureForm) => {
      try {
        if (isNew) {
          await createFeeStructure(data);
          notificationContext.pushNotification({
            id: `fee-structure-${feeStructureId}`,
            header: "Fee Structure Created",
            body: `Fee  ${data.name} created`,
            variant: "success",
          });
          navigate("..");
        } else {
          await updateFeeStructure(data);
          notificationContext.pushNotification({
            id: `fee-structure-${feeStructureId}`,
            header: "Fee Structure Updated",
            body: `Fee structure ${data.name} updated`,
            variant: "success",
          });
        }
      } catch (err) {
        console.error("Failed to save fee structure", err);
        notificationContext.pushNotification({
          id: `fee-structure-${feeStructureId}`,
          header: "Failed to Save Fee Structure",
          body: `Fee structure ${data.name} was not saved`,
          variant: "success",
        });
      }
    },
  });

  const calculationType = useWatch({
    control,
    name: "calculationType",
    defaultValue: "Flat Rate",
  });

  const billingFrequency = useWatch({
    control,
    name: "billingFrequency",
    defaultValue: "Monthly",
  });

  useEffect(() => {
    if (typeof feeStructureBody !== "undefined") {
      const { tiers, ...feeStructure } = feeStructureBody.data;
      reset({
        name: feeStructure.name,
        calculationType: feeStructure.calculationType,
        collectionType: feeStructure.collectionType,
        billingFrequency: feeStructure.frequency,
        billingCycle: feeStructure.annualCycle ?? feeStructure.quarterCycle,
        balanceType: feeStructure.balanceType,
        partition: feeStructure.partition,
        feeValue:
          feeStructure.calculationType === "Flat Rate"
            ? feeStructure.flatRate * 100
            : feeStructure.calculationType === "Flat Account Fee" ||
                feeStructure.calculationType === "Flat Group Fee"
              ? feeStructure.flatDollarFee
              : 0,
      });
      replaceTiers(tiers);
    }
  }, [feeStructureBody, reset, replaceTiers]);

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <FormError message="An error occurred" />
  ) : !feeStructureBody && !isNew ? (
    <FormError message="Fee Structure not found" />
  ) : (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      <Row>
        <Col>
          <h1>Fee Structure Details</h1>
        </Col>
        <Col md="auto">
          <FeeStructureInfoActionButtons />
        </Col>
      </Row>
      <Content>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-name">
              <Form.Label>{getSchemaFieldLabel(schema.fields.name)}</Form.Label>
              <Form.Control type="text" {...register("name")} />
              <FormFieldError field={errors.name} />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-calculation-type">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.calculationType)}
              </Form.Label>
              <Form.Select
                placeholder="Select Calculation Type"
                {...register("calculationType")}
                defaultValue={"Flat Rate"}
              >
                {calculationTypes.map((calculationType, idx) => (
                  <option key={idx} value={calculationType}>
                    {calculationType}
                  </option>
                ))}
              </Form.Select>
              <FormFieldError field={errors.calculationType} />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-collection-type">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.collectionType)}
              </Form.Label>
              <Form.Select
                placeholder="Select Collection Type"
                {...register("collectionType")}
                defaultValue={"Advance"}
              >
                {collectionTypes.map((collectionType, idx) => (
                  <option key={idx} value={collectionType}>
                    {collectionType}
                  </option>
                ))}
              </Form.Select>
              <FormFieldError field={errors.collectionType} />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-balance-type">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.balanceType)}
              </Form.Label>
              <Form.Select
                placeholder="Select Balance Type"
                {...register("balanceType")}
                defaultValue={"Ending Period Balance"}
              >
                {balanceTypes.map((balanceType, idx) => (
                  <option key={idx} value={balanceType}>
                    {balanceType}
                  </option>
                ))}
              </Form.Select>
              <FormFieldError field={errors.balanceType} />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Row>
              <Col xs={11}>
                <Form.Group controlId="form-partition">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.partition)}
                  </Form.Label>
                  <Form.Select
                    placeholder="Select Period Partition"
                    {...register("partition")}
                    defaultValue="daily"
                  >
                    {partitionOptions.map((partition) => (
                      <option key={partition} value={partition}>
                        {capitalize(partition)}
                      </option>
                    ))}
                  </Form.Select>
                  <FormFieldError field={errors.partition} />
                </Form.Group>
              </Col>
              <Col xs={1} className="d-flex align-items-end py-2">
                <HelpTooltip tooltip={periodPartitionHelpMessage} />
              </Col>
            </Row>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-billing-frequency">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.billingFrequency)}
              </Form.Label>
              <Form.Select
                placeholder="Select Billing Frequency"
                {...register("billingFrequency")}
                defaultValue={"Monthly"}
              >
                {billingFrequencies.map((billingFrequency, idx) => (
                  <option key={idx} value={billingFrequency}>
                    {billingFrequency}
                  </option>
                ))}
              </Form.Select>
              <FormFieldError field={errors.billingFrequency} />
            </Form.Group>
          </Col>
          {billingFrequency !== "Monthly" ? (
            <Col md={6} className="mb-3">
              <Row>
                <Col xs={11}>
                  <Form.Group controlId="form-billing-cycle">
                    <Form.Label>
                      {getSchemaFieldLabel(schema.fields.billingCycle)}
                    </Form.Label>
                    <Form.Select
                      placeholder="Select Billing Cycle"
                      {...register("billingCycle")}
                    >
                      {(billingFrequency === "Annual" ? annuals : quarters).map(
                        (item, idx) => (
                          <option key={idx} value={item}>
                            {item}
                          </option>
                        ),
                      )}
                    </Form.Select>
                    <FormFieldError field={errors.billingCycle} />
                  </Form.Group>
                </Col>
                <Col xs={1} className="d-flex align-items-end py-2">
                  <HelpTooltip tooltip={feeStructureCycleHelpMessage} />
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
        {calculationType !== "No Fee" ? (
          <Row className="mb-3">
            <h4>Fee Schedule</h4>
            {calculationType === "Flat Rate" ? (
              <Col xl={2} lg={3} md={4} className="mb-3">
                <Form.Group controlId="form-feeValue">
                  <Form.Label>Fee Rate</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      {...register("feeValue")}
                      defaultValue={0}
                      step={0.01}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <FormFieldError field={errors.feeValue} />
                </Form.Group>
              </Col>
            ) : calculationType === "Flat Account Fee" ||
              calculationType === "Flat Group Fee" ? (
              <Col xl={3} lg={4} md={6} className="mb-3">
                <Form.Group controlId="form-feeValue">
                  <Form.Label>Annual Amount</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      {...register("feeValue")}
                      defaultValue={0}
                      step={1}
                    />
                  </InputGroup>
                  <FormFieldError field={errors.feeValue} />
                </Form.Group>
              </Col>
            ) : calculationType === "Drop Through" ||
              calculationType === "Tiered" ? (
              <Col>
                <FeeTiers
                  fields={controlledTierFields}
                  errors={errors.tiers}
                  register={register}
                  add={appendTiers}
                  remove={removeTiers}
                />
              </Col>
            ) : null}
          </Row>
        ) : null}
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} />
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

export default FeeStructureInfo;
