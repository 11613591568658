import React, { useCallback, useEffect, useState } from "react";
import Autosuggest, {
  GetSuggestionValue,
  RenderSuggestion,
  RenderSuggestionParams,
  RenderSuggestionsContainer,
} from "react-autosuggest";
import { Dropdown, Form, Image, InputGroup } from "react-bootstrap";
import {
  Control,
  FieldValues,
  Path,
  UseFormGetValues,
  UseFormRegister,
  useController,
} from "react-hook-form";
import Spinner from "./Spinner";

export function Suggestion({
  children,
  active = false,
  title,
  divider = false,
}: {
  children: React.ReactNode;
  active?: boolean;
  title?: string;
  divider?: boolean;
}) {
  return (
    <>
      <Dropdown.Item
        as="button"
        className="auto-suggestion-header"
        active={active}
        title={title}
      >
        {children}
        <p className="auto-suggestion-title mt-0 mb-0">{title}</p>
      </Dropdown.Item>
      {divider ? <Dropdown.Divider /> : null}
    </>
  );
}

function defaultGetSuggestionValue<TSuggestion>(suggestion: TSuggestion) {
  return String(suggestion).toString();
}

function defaultRenderSuggestion<TSuggestion>(
  suggestion: TSuggestion,
  params: RenderSuggestionParams,
) {
  return (
    <Suggestion active={params.isHighlighted}>
      {String(suggestion).toString()}
    </Suggestion>
  );
}

const defaultRenderSuggestionsContainer: RenderSuggestionsContainer = ({
  containerProps: { key, ...containerProps },
  children,
}) => (
  <Dropdown.Menu
    {...containerProps}
    key={key}
    className={`${containerProps.className} w-100`}
    show={containerProps.className
      .split(" ")
      .includes("react-autosuggest__suggestions-container--open")}
  >
    {children}
  </Dropdown.Menu>
);

const loadingRenderSuggestionsContainer: RenderSuggestionsContainer = ({
  containerProps: { key, ...containerProps },
}) => (
  <Dropdown.Menu
    {...containerProps}
    key={key}
    className={`${containerProps.className} w-100`}
    show={containerProps.className
      .split(" ")
      .includes("react-autosuggest__suggestions-container--open")}
  >
    <div className="text-center">
      <Spinner size="sm" />
    </div>
  </Dropdown.Menu>
);

function Autocomplete<TSuggestion, TForm extends FieldValues>({
  suggestions,
  loadSuggestions,
  isLoading = false,
  onSelect = () => null,
  getSuggestionValue = defaultGetSuggestionValue,
  renderSuggestion = defaultRenderSuggestion,
  renderSuggestionsContainer = defaultRenderSuggestionsContainer,
  placeholder = "",
  name,
  register,
  getValues,
  showSearchIcon = false,
}: {
  suggestions: TSuggestion[];
  loadSuggestions: (val: string) => void;
  isLoading?: boolean;
  onSelect?: (suggestion: TSuggestion) => void;
  getSuggestionValue?: GetSuggestionValue<TSuggestion>;
  renderSuggestion?: RenderSuggestion<TSuggestion>;
  renderSuggestionsContainer?: RenderSuggestionsContainer;
  placeholder?: string;
  name?: Path<TForm>;
  register?: UseFormRegister<TForm>;
  getValues?: UseFormGetValues<TForm>;
  showSearchIcon?: boolean;
}) {
  const { ref, onChange, onBlur } =
    typeof name !== "undefined" && typeof register === "function"
      ? register(name)
      : {
          ref: undefined,
          onChange: undefined,
          onBlur: undefined,
        };

  const [value, setValue] = useState("");

  const onChangeInner = useCallback(
    (event: React.FormEvent, { newValue }: Autosuggest.ChangeEvent) => {
      setValue(newValue);
      if (typeof name !== "undefined" && typeof onChange === "function") {
        onChange(event);
      }
    },
    [name, onChange],
  );

  const onBlurInner = useCallback(
    (event: React.FormEvent) => {
      if (typeof name !== "undefined" && typeof onBlur === "function") {
        onBlur(event);
      }
    },
    [name, onBlur],
  );

  const onSuggestionSelected = useCallback(
    (event: React.FormEvent, { suggestion }: { suggestion: TSuggestion }) => {
      onSelect(suggestion);
      if (typeof name === "undefined") {
        setValue("");
      }
    },
    [name, onSelect],
  );

  const onSuggestionsFetchRequested = useCallback(
    ({ value }: { value: string }) => {
      loadSuggestions(value);
    },
    [loadSuggestions],
  );

  const onSuggestionsClearRequested = useCallback(() => {
    loadSuggestions("");
  }, [loadSuggestions]);

  useEffect(() => {
    if (typeof getValues !== "undefined" && typeof name !== "undefined") {
      const initialValue = getValues(name);
      if (typeof initialValue !== "undefined" && initialValue !== null) {
        setValue(initialValue);
      }
    }
  }, [getValues, name]);

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={
        isLoading
          ? loadingRenderSuggestionsContainer
          : renderSuggestionsContainer
      }
      // @ts-expect-error The type definitions do not include the key prop
      renderInputComponent={({ key, ...inputProps }) => (
        <InputGroup>
          <Form.Control key={key} {...inputProps} size={undefined} />
          {showSearchIcon ? (
            <InputGroup.Text>
              <Image src="/icons/search.svg" className="svg" />
            </InputGroup.Text>
          ) : null}
        </InputGroup>
      )}
      inputProps={{
        ref,
        placeholder,
        value:
          typeof getValues !== "function"
            ? (value ?? "")
            : typeof name === "undefined"
              ? ""
              : (getValues(name) ?? ""),
        name,
        onChange: onChangeInner,
        onBlur: onBlurInner,
      }}
      containerProps={{
        className: "react-autosuggest__container position-relative",
      }}
    />
  );
}

export default Autocomplete;

/**
 * react-hook-form does not work with the uncontrolled input component within
 * Autosuggest. We need to pass in the form controller to manage the value in
 * the Autosuggest input field.
 * @param props The React component props
 * @returns The JSX element
 */
export function AutocompleteControlled<TSuggestion, TForm extends FieldValues>({
  suggestions,
  loadSuggestions,
  isLoading = false,
  onSelect = () => null,
  getSuggestionValue = defaultGetSuggestionValue,
  renderSuggestion = defaultRenderSuggestion,
  renderSuggestionsContainer = defaultRenderSuggestionsContainer,
  placeholder = "",
  name,
  control,
  showSearchIcon = false,
}: {
  suggestions: TSuggestion[];
  loadSuggestions: (val: string) => void;
  isLoading?: boolean;
  onSelect?: (suggestion: TSuggestion) => void;
  getSuggestionValue?: GetSuggestionValue<TSuggestion>;
  renderSuggestion?: RenderSuggestion<TSuggestion>;
  renderSuggestionsContainer?: RenderSuggestionsContainer;
  placeholder?: string;
  name: Path<TForm>;
  control: Control<TForm>;
  showSearchIcon?: boolean;
}) {
  const {
    field: { onChange, onBlur, value, ref, name: fieldName },
  } = useController({
    name,
    control,
  });

  const onChangeInner = useCallback(
    (event: React.FormEvent<HTMLElement>, params: Autosuggest.ChangeEvent) => {
      if (typeof name !== "undefined" && typeof onChange === "function") {
        onChange(params.newValue);
      }
    },
    [name, onChange],
  );

  const onBlurInner = useCallback(() => {
    if (typeof name !== "undefined" && typeof onBlur === "function") {
      onBlur();
    }
  }, [name, onBlur]);

  const onSuggestionSelected = useCallback(
    (
      event: React.FormEvent<HTMLSelectElement>,
      { suggestion }: { suggestion: TSuggestion },
    ) => {
      onSelect(suggestion);
    },
    [onSelect],
  );

  const onSuggestionsFetchRequested = useCallback(
    ({ value }: { value: string }) => {
      loadSuggestions(value);
    },
    [loadSuggestions],
  );

  const onSuggestionsClearRequested = useCallback(() => {
    loadSuggestions("");
  }, [loadSuggestions]);

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={
        isLoading
          ? loadingRenderSuggestionsContainer
          : renderSuggestionsContainer
      }
      // @ts-expect-error The type definitions do not include the key prop
      renderInputComponent={({ key, ...inputProps }) => (
        <InputGroup>
          <Form.Control key={key} {...inputProps} size={undefined} />
          {showSearchIcon ? (
            <InputGroup.Text>
              <Image src="/icons/search.svg" className="svg" />
            </InputGroup.Text>
          ) : null}
        </InputGroup>
      )}
      inputProps={{
        ref,
        placeholder,
        value,
        name: fieldName,
        onChange: onChangeInner,
        onBlur: onBlurInner,
      }}
      containerProps={{
        className: "react-autosuggest__container position-relative",
      }}
    />
  );
}
