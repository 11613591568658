import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { AccountHoldingResponse } from "../../../../api/src/accounts/accounts.service";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import SecurityLink from "../../components/SecurityLink";
import { Table, useTable } from "../../components/Table/Table";
import { useAuthenticatedFetch } from "../../lib/api";
import { formatCurrencyComponent, formatPercent } from "../../lib/numbers";

type AccountHoldingRow = {
  symbol: string;
  income: number;
  incomeYield: number;
} & Pick<
  AccountHoldingResponse,
  "value" | "security" | "unrealizedGainLoss" | "realizedGainLoss"
>;

function AccountTaxTable({
  customTaxPeriodParams,
}: {
  customTaxPeriodParams: string;
}) {
  const { accountId } = useParams();

  const columnHelper = useMemo(
    () => createColumnHelper<AccountHoldingRow>(),
    [],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("symbol", {
        header: "Type",
        cell: (info) => (
          <SecurityLink
            symbol={info.getValue()}
            description={info.row.original.security?.description}
          />
        ),
        size: 180,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("value", {
        header: () => "Value",
        cell: (info) => formatCurrencyComponent(info.getValue(), 2),
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("income", {
        header: () => "Total Div./Int. (% Yield)",
        cell: (info) =>
          `${formatCurrencyComponent(info.getValue(), 2)} (${formatPercent(
            info.row.original.incomeYield,
            2,
          )})`,
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.realizedGainLoss ?? 0, {
        id: "realizedGainLoss",
        header: () => "Realized Gain/Loss",
        cell: (info) => formatCurrencyComponent(info.getValue(), 2),
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("unrealizedGainLoss", {
        cell: (info) => formatCurrencyComponent(info.getValue(), 2),
        header: () => "Unrealized Gain/Loss",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
    ],
    [columnHelper],
  );

  const {
    data: dataHoldings,
    isPending: isPendingHoldings,
    isError: isErrorHoldings,
  } = useAuthenticatedFetch<
    UnpackResponse<AccountsController["getAccountHoldings"]>
  >(`/accounts/${accountId}/holdings?${customTaxPeriodParams}`);

  const holdings: AccountHoldingRow[] = useMemo(() => {
    return (dataHoldings?.data ?? []).map((holding) => ({
      ...holding,
      symbol: holding.security?.identifier ?? "",
      income: holding.income,
      incomeYield:
        holding.value - holding.income === 0
          ? 0
          : holding.income / (holding.value - holding.income),
    }));
  }, [dataHoldings?.data]);

  const { table } = useTable({
    columns,
    data: holdings,
    initialState: {
      sorting: [{ id: "symbol", desc: false }],
      pagination: {
        pageSize: 9999,
      },
    },
    manualPagination: true,
    getRowId: (row) => row.symbol,
  });

  return isPendingHoldings ? (
    <Loading message="Holdings" />
  ) : isErrorHoldings ? (
    <Alert variant="danger">Failed to load holdings</Alert>
  ) : holdings.length <= 0 ? (
    <Alert>No holdings found</Alert>
  ) : (
    <Table table={table} disablePagination isDisableGrouping isFixedGrouping />
  );
}

export default AccountTaxTable;
