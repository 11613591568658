import React from "react";
import { Alert, Badge, ListGroup } from "react-bootstrap";
import type {
  AuditResponse,
  EntityAudit,
} from "../../../api/src/lib/auditTrail";
import Loading from "../Loading";

function EntityAuditTrail<TItem extends EntityAudit>({
  history,
  itemDisplay,
  itemDisplayName = "Record",
  isLoading = false,
  isError = false,
  errorMessage = "An error occurred",
}: {
  history: AuditResponse<TItem>;
  itemDisplay: (item: TItem) => React.ReactNode;
  itemDisplayName?: string;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
}) {
  return isLoading ? (
    <Loading />
  ) : isError ? (
    <Alert variant="danger">{errorMessage}</Alert>
  ) : (
    <ListGroup as="ol">
      {history.items.map((item, index) => (
        <ListGroup.Item key={new Date(item.updatedTime).toUTCString()} as="li">
          <div className="me-auto">
            {item.createdTime === item.updatedTime ||
            index === history.items.length - 1
              ? `${itemDisplayName} Created`
              : itemDisplay(item)}
          </div>
          <div className="d-flex justify-content-between">
            <div className="text-secondary">
              {item.userId === "sa"
                ? "System"
                : (history.users[item.userId]?.name ?? item.userId)}
            </div>
            <Badge bg="primary">
              {new Date(item.updatedTime).toLocaleString()}
            </Badge>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default EntityAuditTrail;
