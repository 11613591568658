import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { TasksController } from "../../../api/src/tasks/tasks.controller";
import type {
  EditableWorkflow,
  Workflow,
} from "../../../api/src/tasks/tasks.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import { useAuthenticatedFetch } from "../lib/api";
import { InlineError } from "../lib/display";
import { dateSchema, getSchemaFieldLabel } from "../lib/forms";
import { useErrorMessage } from "./FormError";
import FormFieldError from "./FormFieldError";
import SubmitButton from "./SubmitButton";

const schema: yup.ObjectSchema<EditableWorkflow> = yup.object({
  label: yup.string().required().label("Label"),
  startsAt: dateSchema.required().label("Start Date"),
  template: yup
    .mixed<string | number>()
    .required()
    .transform((val: string) => {
      const valInt = parseInt(val);
      return isNaN(valInt) ? val : valInt;
    })
    .label("Template"),
  category: yup.number().label("Category"),
});

const CreateWorkflow = ({
  onCreate,
}: {
  onCreate: (data: EditableWorkflow) => Promise<Workflow | null>;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    reset,
  } = useForm<EditableWorkflow>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { setError, resetError, errorMessage } = useErrorMessage();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: EditableWorkflow) => {
      resetError();

      try {
        const body = await onCreate(data);
        if (body !== null) {
          notificationContext.pushNotification({
            id: `workflow-${body.id}`,
            header: "Workflow Created",
            body: `${body.name} created`,
            variant: "success",
          });
        }
        reset();
      } catch (err) {
        const message = "Failed to save workflow";
        console.error(message, err);
        setError(message);
      }
    },
  });

  const {
    isPending: isPendingTemplates,
    isError: isErrorTemplates,
    isFetched: isFetchedTemplates,
    data: dataTemplates,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<TasksController["getWorkflowTemplates"]>>
  >("/tasks/workflows/templates");

  const templateOptions = useMemo(
    () =>
      (dataTemplates?.data ?? [])
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((template) => (
          <option key={template.id} value={template.id}>
            {template.name}
          </option>
        )),
    [dataTemplates?.data],
  );

  useEffect(() => {
    const template = getValues().template;

    if (
      isFetchedTemplates &&
      (dataTemplates?.data ?? []).length > 0 &&
      typeof template !== "undefined" &&
      (typeof template === "string" || isNaN(template))
    ) {
      setValue("template", (dataTemplates?.data ?? [])[0].id);
    }
  }, [isFetchedTemplates, dataTemplates?.data, setValue, getValues]);

  const {
    isPending: isPendingCategories,
    isError: isErrorCategories,
    isFetched: isFetchedCategories,
    data: dataCategories,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<TasksController["getWorkflowCategories"]>>
  >("/tasks/workflows/categories");

  const categoryOptions = useMemo(
    () =>
      (dataCategories?.data ?? [])
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        )),
    [dataCategories?.data],
  );

  useEffect(() => {
    const category = getValues().category;

    if (
      isFetchedCategories &&
      (dataCategories?.data ?? []).length > 0 &&
      typeof category !== "undefined"
    ) {
      setValue("category", (dataCategories?.data ?? [])[0].id);
    }
  }, [setValue, getValues, isFetchedCategories, dataCategories?.data]);

  return isPendingTemplates || isPendingCategories ? (
    <Loading />
  ) : (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      {errorMessage}
      <Form.Group className="mb-3" controlId="form-label">
        <Form.Label>{getSchemaFieldLabel(schema.fields.label)}</Form.Label>
        <Form.Control type="text" {...register("label")} />
        <FormFieldError field={errors.label} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="form-startsAt">
        <Form.Label>{getSchemaFieldLabel(schema.fields.startsAt)}</Form.Label>
        <Form.Control type="date" {...register("startsAt")} />
        <FormFieldError field={errors.startsAt} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="form-template">
        <Form.Label>{getSchemaFieldLabel(schema.fields.template)}</Form.Label>
        {isErrorTemplates ? (
          <InlineError>There was an error loading templates</InlineError>
        ) : (
          <Form.Select {...register("template")}>{templateOptions}</Form.Select>
        )}
        <FormFieldError field={errors.template} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="form-category">
        <Form.Label>{getSchemaFieldLabel(schema.fields.category)}</Form.Label>
        {isErrorCategories ? (
          <InlineError>There was an error loading categories</InlineError>
        ) : categoryOptions.length <= 0 ? null : (
          <Form.Select {...register("category")}>{categoryOptions}</Form.Select>
        )}
        <FormFieldError field={errors.category} />
      </Form.Group>
      <SubmitButton isSubmitting={isSubmitting} label="Create" />
    </Form>
  );
};

export default CreateWorkflow;
