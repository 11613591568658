import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Header, createColumnHelper } from "@tanstack/react-table";
import { useCallback } from "react";
import { ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type { BillingMinimum } from "../../../api/src/billing/lib";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import ActionButton from "../components/ActionButton";
import IndeterminateCheckbox from "../components/IndeterminateCheckbox";
import MultiSelector from "../components/MultiSelector";
import TabContainerWithTabs from "../components/TabContainer";
import { Table, useTable } from "../components/Table/Table";
import { multiSelectIncludes } from "../components/Table/filters";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutation,
} from "../lib/api";
import { formatCurrency, formatPercent } from "../lib/numbers";
import BillingNav from "./BillingNav";

type BillingMinimumRow = BillingMinimum;

const columnHelper = createColumnHelper<BillingMinimumRow>();

function valueTypeDisplay(valueType: "F" | "P") {
  return valueType === "F" ? "Flat dollar amount" : "Percentage of AUM";
}

export const valueTypeFilterOptions = [
  { label: "Flat dollar amount", value: 1 },
  { label: "Percentage of AUM", value: 2 },
];

const ValueTypeFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const onChange = useCallback(
    (values: number[]) => {
      header.column.setFilterValue(values);
    },
    [header.column],
  );

  return (
    <MultiSelector
      options={valueTypeFilterOptions}
      placeholder="All"
      setValue={onChange}
    />
  );
};

const columns = [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor((row) => row.name, {
    id: "name",
    cell: (info) => (
      <Link to={`/billing/billing-minimums/${info.row.original.id}`}>
        {info.getValue()}
      </Link>
    ),
    header: () => "Name",
  }),
  columnHelper.accessor((row) => row.value, {
    id: "value",
    cell: (info) =>
      info.row.original.valueType === "P"
        ? formatPercent(+info.getValue() / 100, 2)
        : formatCurrency(+info.getValue()),
    header: () => "Value",
    enableColumnFilter: false,
  }),
  columnHelper.accessor(
    (row) => {
      const option = valueTypeFilterOptions.find(
        (a) => a.label === valueTypeDisplay(row.valueType ?? ""),
      );
      return option?.value.toString() ?? "";
    },
    {
      id: "valueType",
      cell: (info) => {
        const option = valueTypeFilterOptions.find(
          (a) => a.value.toString() === info.getValue(),
        );
        return option?.label ?? "";
      },
      header: () => "Value Type",
      filterFn: multiSelectIncludes,
      meta: {
        filterComponent: ValueTypeFilter,
      },
    },
  ),
];

const BillingMinimumTable = () => {
  const { isPending, data } = useAuthenticatedFetch<
    UnpackResponse<BillingController["getAllBillingMinimums"]>
  >("/billing/billing-minimums");

  const { table, rowSelection, setRowSelection } = useTable({
    columns,
    data: data?.data ?? [],
  });

  const removeBillingMinimums = useAuthenticatedMutation<
    UnpackResponse<BillingController["deleteBillingMinimums"]>
  >(
    `/billing/billing-minimums/delete-many`,
    {
      method: "POST",
      body: JSON.stringify(
        data?.data.reduce((result, billingMinimum, idx) => {
          return Object.keys(rowSelection).includes(`${idx}`)
            ? [...result, billingMinimum.id]
            : result;
        }, [] as number[]),
      ),
    },
    processEmptyResponse,
  );

  const queryClient = useQueryClient();

  const del = useMutation({
    mutationFn: async () => {
      if (Object.keys(rowSelection).length === 0) return;
      try {
        await removeBillingMinimums();
        queryClient.setQueryData(["/billing/billing-minimums"], {
          data: data?.data.filter(
            (billingMinimum, idx) =>
              !Object.keys(rowSelection).includes(`${idx}`),
          ),
        });
        setRowSelection({});
      } catch (err) {
        const message = "Failed to delete billing minimums";
        console.error(message, err);
      }
    },
  });

  return (
    <TabContainerWithTabs tabs={BillingNav}>
      <Row>
        <Col className="d-flex justify-content-between mb-3">
          <ButtonToolbar className="gap-3">
            <ButtonGroup>
              <ActionButton
                as={Link}
                to="new"
                variant="secondary"
                label="Create"
                icon="/icons/new.svg"
              />
            </ButtonGroup>
            <ButtonGroup>
              <ActionButton
                variant="secondary"
                label="Delete"
                icon="/icons/trash.svg"
                onClick={() => del.mutate()}
                disabled={
                  del.isPending || Object.keys(rowSelection).length === 0
                }
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>{isPending ? <Loading /> : <Table table={table} />}</Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default BillingMinimumTable;
