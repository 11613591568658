import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Badge, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import * as yup from "yup";
import type {
  Contact,
  EditablePerson,
  Gender,
  Job,
  MaritalStatus,
  Prefix,
  StreetAddress,
  Suffix,
} from "../../../../api/src/contacts/lib";
import type {
  SerializedObject,
  WrappedApiResponse,
} from "../../../../api/src/lib";
import { NotificationContext } from "../../Notifications";
import Avatar from "../../components/Avatar";
import Content from "../../components/Content";
import DateInput from "../../components/DateInput";
import FormFieldError from "../../components/FormFieldError";
import SubmitButton from "../../components/SubmitButton";
import { useAuthenticatedMutationAsync } from "../../lib/api";
import { capitalize } from "../../lib/display";
import {
  additionalNameSchema,
  birthDateSchema,
  dateSchema,
  emailSchema,
  familyNameSchema,
  getSchemaFieldLabel,
  givenNameSchema,
  householdTitleSchema,
  optionalStringValueTransform,
  phoneNumberSchema,
} from "../../lib/forms";
import GenerateForms from "./GenerateForms";
import {
  GENDERS,
  HOUSEHOLD_TITLES,
  MARITAL_STATUSES,
  PREFIXES,
  SUFFIXES,
  deserializeContact,
} from "./lib";

const schema: yup.ObjectSchema<EditablePerson> = yup.object({
  name: yup.string().required(),
  givenName: givenNameSchema.required(),
  familyName: familyNameSchema.required(),
  additionalName: additionalNameSchema,
  title: householdTitleSchema,
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  birthDate: birthDateSchema,
  streetAddress: yup.object({
    id: yup.number(),
    streetLine1: yup.string().label("Street Line 1"),
    streetLine2: yup.string().label("Street Line 2"),
    city: yup.string().label("City"),
    state: yup.string().label("State"),
    country: yup.string().label("Country"),
    zipCode: yup.string().label("ZipCode"),
  }),
  prefix: yup
    .string()
    .oneOf<Prefix | "">([...PREFIXES, ""])
    .label("Prefix"),
  suffix: yup
    .string()
    .oneOf<Suffix | "">([...SUFFIXES, ""])
    .label("Suffix"),
  nickName: yup.string().label("Nick Name"),
  gender: yup
    .string()
    .oneOf<Gender>(GENDERS)
    .transform(optionalStringValueTransform)
    .label("Gender"),
  maritalStatus: yup
    .string()
    .oneOf<MaritalStatus>(MARITAL_STATUSES)
    .transform(optionalStringValueTransform)
    .label("Marital Status"),
  dateOfDeath: dateSchema.label("Date of Death"),
  retirementDate: dateSchema.label("Retirement Date"),
  anniversary: dateSchema.label("Anniversary Date"),
  clientSince: dateSchema.label("Client Since Date"),
  backgroundInfo: yup.string().label("Background Info"),
  job: yup.object({
    title: yup.string().label("Employer Title"),
    employer: yup.string().label("Employer"),
  }),
});

const PersonForm = ({
  contact,
}: {
  contact?: Contact & { linkedHousehold?: { id?: number; name?: string } };
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<EditablePerson>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { contactId } = useParams();

  useEffect(() => {
    if (typeof contact !== "undefined") {
      reset(contact);
    }
  }, [contact, reset]);

  const updateClient = useAuthenticatedMutationAsync<
    SerializedObject<WrappedApiResponse<Contact>>
  >(`/contacts/${contactId}`, async (data: EditablePerson) => ({
    method: "PUT",
    body: JSON.stringify(data),
  }));

  const queryClient = useQueryClient();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: EditablePerson) => {
      try {
        const body = await updateClient(data);

        if (typeof body !== "undefined") {
          queryClient.setQueryData([`/contacts/${contactId}`], {
            data: body?.data,
          });
          reset(deserializeContact(body.data));
        }

        notificationContext.pushNotification({
          id: `contact-${contactId}`,
          header: "Contact Updated",
          body: `${data.givenName} ${data.familyName} updated`,
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save contact details", err);
        notificationContext.pushNotification({
          id: `contact-${contactId}`,
          header: "Failed to Save Contact",
          body: `${data.givenName} ${data.familyName} was not saved`,
          variant: "danger",
        });
      }
    },
  });

  return typeof contact === "undefined" ? null : (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      <Row>
        <Col xxl={4} md={6} className="mb-3">
          <h3>Identity</h3>
          <Content>
            <dl>
              <Row>
                {!contact.image ? null : (
                  <Col lg={3} sm={4}>
                    <Form.Group className="mb-3" controlId="form-image">
                      <Avatar src={contact.image} alt="contact avatar" />
                    </Form.Group>
                  </Col>
                )}
                <Col>
                  <Row>
                    <Col as="dt" sm={3} md={4} xl={4}>
                      {getSchemaFieldLabel(schema.fields.givenName)}
                    </Col>
                    <Col as="dd">
                      <Form.Control type="text" {...register("givenName")} />
                      <FormFieldError field={errors.givenName} />
                    </Col>
                  </Row>
                  <Row>
                    <Col as="dt" sm={3} md={4} xl={4}>
                      {getSchemaFieldLabel(schema.fields.additionalName)}
                    </Col>
                    <Col as="dd">
                      <Form.Control
                        type="text"
                        {...register("additionalName")}
                      />
                      <FormFieldError field={errors.additionalName} />
                    </Col>
                  </Row>
                  <Row>
                    <Col as="dt" sm={3} md={4} xl={4}>
                      {getSchemaFieldLabel(schema.fields.familyName)}
                    </Col>
                    <Col as="dd">
                      <Form.Control type="text" {...register("familyName")} />
                      <FormFieldError field={errors.familyName} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Row>
                  <Col as="dt" sm={3} md={4} xl={4}>
                    Tags
                  </Col>
                  <Col as="dd">
                    {contact.tags?.map((tag) => (
                      <Badge key={tag.id} bg="secondary" pill className="me-1">
                        {tag.name}
                      </Badge>
                    ))}
                  </Col>
                </Row>
                <Col as="dt" sm={3} md={4} xl={4}>
                  {getSchemaFieldLabel(schema.fields.prefix)}
                </Col>
                <Col as="dd">
                  <Form.Select
                    {...register("prefix")}
                    placeholder="Select a prefix"
                  >
                    <option value="" />
                    {PREFIXES.map((prefix) => (
                      <option key={prefix} value={prefix}>
                        {prefix}
                      </option>
                    ))}
                  </Form.Select>
                  <FormFieldError field={errors.prefix} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" sm={3} md={4} xl={4}>
                  {getSchemaFieldLabel(schema.fields.nickName)}
                </Col>
                <Col as="dd">
                  <Form.Control type="text" {...register("nickName")} />
                  <FormFieldError field={errors.nickName} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" sm={3} md={4} xl={4}>
                  {getSchemaFieldLabel(schema.fields.suffix)}
                </Col>
                <Col as="dd">
                  <Form.Select
                    {...register("suffix")}
                    placeholder="Select a suffix"
                  >
                    <option value="" />
                    {SUFFIXES.map((suffix) => (
                      <option key={suffix} value={suffix}>
                        {suffix}
                      </option>
                    ))}
                  </Form.Select>
                  <FormFieldError field={errors.suffix} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" sm={3} md={4} xl={4}>
                  {getSchemaFieldLabel(schema.fields.gender)}
                </Col>
                <Col as="dd">
                  <Form.Select
                    {...register("gender")}
                    placeholder="Select a gender"
                  >
                    <option value="" />
                    {GENDERS.map((gender) => (
                      <option key={gender} value={gender}>
                        {capitalize(gender)}
                      </option>
                    ))}
                  </Form.Select>
                  <FormFieldError field={errors.gender} />
                </Col>
              </Row>
            </dl>
          </Content>
        </Col>
        <Col xxl={4} md={6} className="mb-3">
          <h3>Background</h3>
          <Content>
            <dl>
              <Row>
                <Col as="dt" md={4} sm={3} xl={4}>
                  Type
                </Col>
                <Col as="dd">{capitalize(contact.type)}</Col>
              </Row>
              {!contact.household?.id ? null : (
                <Row>
                  <Col as="dt" md={4}>
                    Household
                  </Col>
                  <Col as="dd">
                    <Link to={`/clients/households/${contact.household.id}`}>
                      {contact.household.name}
                    </Link>
                  </Col>
                </Row>
              )}
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(schema.fields.title)}
                </Col>
                <Col as="dd">
                  <Form.Select
                    {...register("title")}
                    placeholder="Select a title"
                  >
                    <option value="" />
                    {HOUSEHOLD_TITLES.map((title) => (
                      <option key={title} value={title}>
                        {capitalize(title)}
                      </option>
                    ))}
                  </Form.Select>
                  <FormFieldError field={errors.title} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(schema.fields.maritalStatus)}
                </Col>
                <Col as="dd">
                  <Form.Select
                    {...register("maritalStatus")}
                    placeholder="Select a marital status"
                  >
                    <option value="" />
                    {MARITAL_STATUSES.map((status) => (
                      <option key={status} value={status}>
                        {capitalize(status)}
                      </option>
                    ))}
                  </Form.Select>
                  <FormFieldError field={errors.maritalStatus} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (schema.fields.job as yup.ObjectSchema<Job>).fields
                      .employer,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control type="text" {...register("job.employer")} />
                  <FormFieldError field={errors.job?.employer} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (schema.fields.job as yup.ObjectSchema<Job>).fields.title,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control type="text" {...register("job.title")} />
                  <FormFieldError field={errors.job?.title} />
                </Col>
              </Row>
              {(contact.websites ?? []).length <= 0 ? null : (
                <Row>
                  <Col as="dt" md={4}>
                    Website
                  </Col>
                  <Col as="dd">
                    {contact.websites?.map((site) => (
                      <a key={site.url} href={`${site.url}`}>
                        {site.name}
                      </a>
                    ))}
                  </Col>
                </Row>
              )}
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(schema.fields.backgroundInfo)}
                </Col>
                <Col as="dd">
                  <Form.Control as="textarea" {...register("backgroundInfo")} />
                  <FormFieldError field={errors.backgroundInfo} />
                </Col>
              </Row>
            </dl>
          </Content>
        </Col>
        <Col xxl={4} md={6} className="mb-3">
          <h3>Dates</h3>
          <Content>
            <dl>
              <Row>
                <Col as="dt" md={6}>
                  {getSchemaFieldLabel(schema.fields.birthDate)}
                </Col>
                <Col as="dd">
                  <DateInput name="birthDate" control={control} />
                  <FormFieldError field={errors.birthDate} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={6}>
                  {getSchemaFieldLabel(schema.fields.clientSince)}
                </Col>
                <Col as="dd">
                  <DateInput name="clientSince" control={control} />
                  <FormFieldError field={errors.clientSince} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={6}>
                  {getSchemaFieldLabel(schema.fields.anniversary)}
                </Col>
                <Col as="dd">
                  <DateInput name="anniversary" control={control} />
                  <FormFieldError field={errors.anniversary} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={6}>
                  {getSchemaFieldLabel(schema.fields.dateOfDeath)}
                </Col>
                <Col as="dd">
                  <DateInput name="dateOfDeath" control={control} />
                  <FormFieldError field={errors.dateOfDeath} />
                </Col>
              </Row>
            </dl>
          </Content>
        </Col>
        <Col xxl={4} md={6} className="mb-3">
          <h3>Contact Information</h3>
          <Content>
            <dl>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (
                      schema.fields
                        .streetAddress as yup.ObjectSchema<StreetAddress>
                    ).fields.streetLine1,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="text"
                    {...register("streetAddress.streetLine1")}
                  />
                  <FormFieldError field={errors.streetAddress?.streetLine1} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (
                      schema.fields
                        .streetAddress as yup.ObjectSchema<StreetAddress>
                    ).fields.streetLine2,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="text"
                    {...register("streetAddress.streetLine2")}
                  />
                  <FormFieldError field={errors.streetAddress?.streetLine2} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (
                      schema.fields
                        .streetAddress as yup.ObjectSchema<StreetAddress>
                    ).fields.city,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="text"
                    {...register("streetAddress.city")}
                  />
                  <FormFieldError field={errors.streetAddress?.city} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (
                      schema.fields
                        .streetAddress as yup.ObjectSchema<StreetAddress>
                    ).fields.state,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="text"
                    {...register("streetAddress.state")}
                  />
                  <FormFieldError field={errors.streetAddress?.state} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (
                      schema.fields
                        .streetAddress as yup.ObjectSchema<StreetAddress>
                    ).fields.country,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="text"
                    {...register("streetAddress.country")}
                  />
                  <FormFieldError field={errors.streetAddress?.country} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (
                      schema.fields
                        .streetAddress as yup.ObjectSchema<StreetAddress>
                    ).fields.zipCode,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="text"
                    {...register("streetAddress.zipCode")}
                  />
                  <FormFieldError field={errors.streetAddress?.zipCode} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(schema.fields.email)}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="email"
                    placeholder="example@gmail.com"
                    {...register("email")}
                  />
                  <FormFieldError field={errors.email} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(schema.fields.phoneNumber)}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="tel"
                    placeholder="+1-(xxx)-xxx-xxxx"
                    {...register("phoneNumber")}
                  />
                  <FormFieldError field={errors.phoneNumber} />
                </Col>
              </Row>
            </dl>
          </Content>
        </Col>
      </Row>
      <Row>
        <Col>
          <SubmitButton isSubmitting={isSubmitting} className="me-3" />
          <GenerateForms />
        </Col>
      </Row>
    </Form>
  );
};

export default PersonForm;
