import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const HouseholdNav = () => (
  <nav>
    <Nav variant="tabs" defaultActiveKey="overview">
      <Nav.Item>
        <NavLink to="overview" className="nav-link">
          Overview
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="portfolio" className="nav-link">
          Portfolio
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="model-trading" className="nav-link">
          Model / Trading
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="tax-rmd" className="nav-link">
          Tax
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="profile" className="nav-link">
          Profile
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="cash-billing" className="nav-link">
          Cash & Billing
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="transactions" className="nav-link">
          Transactions
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="todos" className="nav-link">
          To-Dos
        </NavLink>
      </Nav.Item>
    </Nav>
  </nav>
);

export default HouseholdNav;
