import { useMemo } from "react";
import { ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { CashSettingsController } from "../../../api/src/cash-settings/cash-settings.controller";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { useAuthenticatedFetch } from "../lib/api";

const UnassignedCashSettings = () => {
  const { isPending: isPendingCashSettings, data: dataCashSettings } =
    useAuthenticatedFetch<
      UnpackResponse<CashSettingsController["getAllAccountSettings"]>
    >("/cash-settings/accounts");
  const cashSettings = dataCashSettings?.data;

  const accountIdsWithoutCashSettings = useMemo(
    () =>
      (cashSettings ?? [])
        .filter(
          (account) =>
            account.cashTargetAbsolute === null &&
            account.cashTargetPercent === null &&
            account.cashTargetMinAbsolute === null &&
            account.cashTargetMinPercent === null &&
            account.cashTargetMaxAbsolute === null &&
            account.cashTargetMaxPercent === null,
        )
        .map((account) => account.accountId),
    [cashSettings],
  );

  return isPendingCashSettings ? (
    <ListGroupItem>
      <Loading />
    </ListGroupItem>
  ) : typeof cashSettings === "undefined" ? null : (
    <ListGroupItem>
      You have{" "}
      {accountIdsWithoutCashSettings.length <= 0 ? (
        "0 Accounts"
      ) : (
        <Link
          to={`/clients/accounts?${accountIdsWithoutCashSettings
            .map((id) => `id=${id}`)
            .join("&")}`}
        >
          {accountIdsWithoutCashSettings.length} Accounts
        </Link>
      )}{" "}
      without cash settings.
    </ListGroupItem>
  );
};

export default UnassignedCashSettings;
