import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import type { EditableHousehold } from "../../../../api/src/households-base/households-base.service";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import { NotificationContext } from "../../Notifications";
import SubmitButton from "../../components/SubmitButton";
import { useAuthenticatedMutationAsync } from "../../lib/api";
import { formatCurrency, formatPercent } from "../../lib/numbers";
import HouseholdAccounts, { AccountItem } from "./HouseholdAccounts";
import HouseholdHoldings from "./HouseholdHoldings";
import { HouseholdContext } from "./HouseholdInfo";
import HouseholdPerformance from "./HouseholdPerformance";
import HouseholdTaxSummary from "./HouseholdTaxSummary";

const HouseholdPortfolio = () => {
  const { household } = useContext(HouseholdContext);
  const { householdId } = useParams();
  const [accounts, setAccounts] = useState<AccountItem[]>(
    household?.accounts ?? [],
  );

  const { handleSubmit } = useFormContext<EditableHousehold>();

  const updateHouseholdAccounts = useAuthenticatedMutationAsync<
    UnpackResponse<HouseholdsController["updateAccounts"]>
  >(`/households/${householdId}/accounts`, async (accountIds: number[]) => ({
    method: "PUT",
    body: JSON.stringify(accountIds),
  }));

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async () => {
      try {
        await updateHouseholdAccounts(accounts.map((account) => account.id));
        notificationContext.pushNotification({
          id: `household-${householdId}-portfolio`,
          header: "Household Accounts Updated",
          body: `${household?.name} updated`,
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save household accounts", err);
        notificationContext.pushNotification({
          id: `household-${householdId}-portfolio`,
          header: "Failed to Save Household Accounts",
          body: `${household?.name} was not saved`,
          variant: "danger",
        });
      }
    },
  });

  const [customTaxPeriodParams, setCustomTaxPeriodParams] = useState("");
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const householdTotalsCurrent = (household?.accounts ?? [])
    .filter((account) => rowSelection[account.id] === true)
    .reduce(
      (sums, account) => ({
        aum: sums.aum + (account.accountBalance ?? 0),
        cash: sums.cash + (account.cashBalance ?? 0),
        unrealizedGains:
          sums.unrealizedGains + (account.unrealizedGainLoss ?? 0),
      }),
      {
        aum: 0,
        cash: 0,
        unrealizedGains: 0,
      },
    );

  return (
    <Form onSubmit={handleSubmit(() => onSubmit.mutate())}>
      <Row>
        <Col xl={7} className="mb-3">
          <Row>
            <Col>
              <h4>Accounts</h4>
              <HouseholdAccounts
                accounts={accounts}
                setAccounts={setAccounts}
                setRowSelection={setRowSelection}
              />
              <SubmitButton
                isSubmitting={onSubmit.isPending}
                className="mt-2"
              />
            </Col>
          </Row>
        </Col>
        <Col xxl={{ span: 4, offset: 1 }} xl={5} className="mb-3">
          <h4>Account Summary</h4>
          <dl className="mb-3">
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                AUM
              </Col>
              <Col as="dd">{formatCurrency(householdTotalsCurrent.aum, 2)}</Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Cash
              </Col>
              <Col as="dd">
                {formatCurrency(householdTotalsCurrent.cash, 2)} (
                {formatPercent(
                  householdTotalsCurrent.aum === 0
                    ? Infinity
                    : householdTotalsCurrent.cash / householdTotalsCurrent.aum,
                  2,
                )}
                )
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <HouseholdPerformance rowSelection={rowSelection} />
              </Col>
            </Row>
          </dl>
          <HouseholdTaxSummary
            rowSelection={rowSelection}
            customTaxPeriodParams={customTaxPeriodParams}
            setCustomTaxPeriodParams={setCustomTaxPeriodParams}
          />
        </Col>
        <Col xs={12}>
          <h4>Holdings</h4>
          <HouseholdHoldings
            accountSelection={rowSelection}
            customTaxPeriodParams={customTaxPeriodParams}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default HouseholdPortfolio;
