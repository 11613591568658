import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

/**
 * Login page; this currently redirects to Auth0's Universal Login.
 */
const Login = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        navigate("/");
      } else {
        loginWithRedirect();
      }
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, navigate]);

  return <p>Logging in...</p>;
};

export default Login;
