import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { Alert, ButtonToolbar, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import type { ContactsController } from "../../../../api/src/contacts/contacts.controller";
import type { Contact } from "../../../../api/src/contacts/lib";
import type {
  SerializedObject,
  UnpackResponse,
  WrappedApiResponse,
} from "../../../../api/src/lib";
import type {
  EditableTask,
  EditableWorkflow,
} from "../../../../api/src/tasks/tasks.service";
import Loading from "../../Loading";
import ActionButton from "../../components/ActionButton";
import CreateTaskPane from "../../components/CreateTaskPane";
import CreateWorkflowPane from "../../components/CreateWorkflowPane";
import FormError from "../../components/FormError";
import { NoteForm, NotesPane } from "../../components/Notes";
import {
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../../lib/api";
import { deserializeNote } from "../../lib/notes";
import { deserializeTask, deserializeWorkflow } from "../../tasks/lib";
import ContactArchiveDialog from "./ContactArchiveDialog";
import PersonForm from "./PersonForm";
import TrustForm from "./TrustForm";

const ContactInfo = () => {
  const { contactId } = useParams();

  const { isPending, data: contactBody } = useAuthenticatedFetch<
    WrappedApiResponse<
      Contact & { linkedHousehold?: { id?: number; name?: string } }
    >
  >(`/contacts/${contactId}`);
  const contact = contactBody?.data;

  const linkedToWealthbox =
    typeof contact?.idExternal.wealthbox !== "undefined";
  const linkedToRedtail = typeof contact?.idExternal.redtail !== "undefined";
  const linkedToCrm = linkedToWealthbox || linkedToRedtail;

  const queryClient = useQueryClient();

  const [showNotes, setShowNotes] = useState(false);
  const onShowNotes = useCallback(() => {
    setShowNotes(!showNotes);
  }, [showNotes]);

  const createNote = useAuthenticatedMutationAsync<
    SerializedObject<UnpackResponse<ContactsController["createNote"]>>
  >(`/contacts/${contactId}/notes`, async (data: NoteForm) => ({
    method: "POST",
    body: JSON.stringify(data),
  }));

  const onCreateNote = useCallback(
    async (data: NoteForm) => {
      const body = await createNote(data);
      queryClient.setQueryData([`/contacts/${contactId}`], {
        data: { ...contact, notes: [...(contact?.notes ?? []), body.data] },
      });
      return body.data === null ? null : deserializeNote(body.data);
    },
    [contact, contactId, createNote, queryClient],
  );

  const [isShowCreateTask, setIsShowCreateTask] = useState(false);
  const onShowCreateTask = useCallback(() => {
    setIsShowCreateTask(!isShowCreateTask);
  }, [isShowCreateTask]);

  const createTaskMutation = useAuthenticatedMutationAsync<
    SerializedObject<UnpackResponse<ContactsController["createTask"]>>
  >(`/contacts/${contactId}/tasks`, async (data: EditableTask) => ({
    method: "POST",
    body: JSON.stringify(data),
  }));

  const onCreateTask = useCallback(
    async (data: EditableTask) => {
      const body = await createTaskMutation(data);

      if (body !== null) {
        queryClient.setQueryData([`/contacts/${contactId}`], {
          data: { ...contact, tasks: [...(contact?.tasks ?? []), body.data] },
        });
      }

      return body.data === null ? null : deserializeTask(body.data);
    },
    [contact, contactId, createTaskMutation, queryClient],
  );

  const [isShowCreateWorkflow, setIsShowCreateWorkflow] = useState(false);
  const showCreateWorkflow = useCallback(() => {
    setIsShowCreateWorkflow(!isShowCreateWorkflow);
  }, [isShowCreateWorkflow]);

  const createWorkflow = useAuthenticatedMutationAsync<
    SerializedObject<UnpackResponse<ContactsController["createWorkflow"]>>
  >(`/contacts/${contactId}/workflows`, async (data: EditableWorkflow) => ({
    method: "POST",
    body: JSON.stringify(data),
  }));

  const onCreateWorkflow = useCallback(
    async (data: EditableWorkflow) => {
      const body = await createWorkflow(data);

      if (body.data === null) {
        return null;
      } else {
        queryClient.setQueryData([`/contacts/${contactId}`], {
          data: {
            ...contact,
            workflows: [...(contact?.workflows ?? []), body.data],
          },
        });

        return deserializeWorkflow(body.data);
      }
    },
    [createWorkflow, queryClient, contactId, contact],
  );

  const [showModal, setShowModal] = useState(false);
  const handleArchiveClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const externalURLWealthbox =
    typeof contact?.idExternal.wealthbox === "undefined"
      ? undefined
      : `${process.env.REACT_APP_WEALTHBOX_APP_ROOT}/contacts/${contact?.idExternal.wealthbox}`;
  const externalURLRedtail =
    typeof contact?.idExternal.redtail === "undefined"
      ? undefined
      : `${process.env.REACT_APP_REDTAIL_APP_ROOT}/contacts/${contact?.idExternal.redtail}`;

  return isPending ? (
    <Loading />
  ) : !contact ? (
    <FormError message="Failed to load contact details" />
  ) : (
    <>
      <Row>
        <Col className="d-flex justify-content-between">
          <h1>Contact Details</h1>
          {typeof externalURLWealthbox === "undefined" ? null : (
            <a
              className="mt-auto mb-auto"
              href={externalURLWealthbox}
              rel="noreferrer"
              target="_blank"
            >
              View in Wealthbox
            </a>
          )}
          {typeof externalURLRedtail === "undefined" ? null : (
            <a
              className="mt-auto mb-auto"
              href={externalURLRedtail}
              rel="noreferrer"
              target="_blank"
            >
              View in Redtail
            </a>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonToolbar className="mb-3 justify-content-end">
            {!linkedToCrm ? null : (
              <>
                <ActionButton
                  variant="secondary"
                  icon="/icons/tasks.svg"
                  label="Create Task"
                  type="button"
                  onClick={onShowCreateTask}
                  className="me-2"
                />
                <ActionButton
                  variant="secondary"
                  icon="/icons/flowchart.svg"
                  label="Create Workflow"
                  type="button"
                  onClick={showCreateWorkflow}
                  className="me-2"
                />
                <ActionButton
                  variant="secondary"
                  icon="/icons/folded-list.svg"
                  label="Notes"
                  type="button"
                  onClick={onShowNotes}
                  className="me-2"
                />
              </>
            )}
            <ActionButton
              type="button"
              label="Archive"
              icon="/icons/trash.svg"
              onClick={handleArchiveClick}
            />
          </ButtonToolbar>
        </Col>
        <ContactArchiveDialog
          showModal={showModal}
          setShowModal={setShowModal}
          archiveContactId={contactId ? Number(contactId) : 0}
          archiveContactName={contact?.name ?? ""}
          title="archive-contact"
        />
      </Row>
      {contact.type === "person" ? (
        <PersonForm contact={contact} />
      ) : contact.type === "trust" || contact.type === "organization" ? (
        <TrustForm contact={contact} />
      ) : (
        <Alert variant="warning">Unable to display contact</Alert>
      )}
      <CreateTaskPane
        show={isShowCreateTask}
        setShow={setIsShowCreateTask}
        onCreate={onCreateTask}
      />
      <CreateWorkflowPane
        show={isShowCreateWorkflow}
        setShow={setIsShowCreateWorkflow}
        onCreate={onCreateWorkflow}
      />
      <NotesPane
        showNotes={showNotes}
        setShowNotes={setShowNotes}
        notes={contact?.notes ?? []}
        onCreate={onCreateNote}
      />
    </>
  );
};

export default ContactInfo;
