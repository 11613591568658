import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { ModelsController } from "../../../api/src/models/models.controller";
import type {
  Model,
  PrimarySecurity,
  SimpleSleeve,
} from "../../../api/src/models/models.service";
import { deserializeDate, useAuthenticatedQuery } from "../lib/api";

export function sumModelWeights(
  primarySecurities: PrimarySecurity[],
  sleeves: SimpleSleeve[] = [],
) {
  return sumWeights(primarySecurities) + sumWeights(sleeves);
}

function sumWeights(arr: { weight: number | string }[]) {
  return arr.reduce((sum, list) => {
    const weightStr = list.weight as unknown as string;
    return sum + parseFloat(weightStr === "" ? "0" : weightStr);
  }, 0);
}

export const primarySecurityHelpMessage =
  "Priority securities to have in a portfolio";
export const secondarySecurityHelpMessage =
  "Securities that can be used in place of primary securities in a portfolio";
export const sleeveHelpMessage =
  "Sub-models that can be combined to form an assignable model";
export const securityRestrictionHelpMessage =
  "Securities that should remain held but that have no alternative security";

export const ModelsHelp = () => (
  <section>
    <p>
      Models are target portfolios that can be assigned to households and
      accounts. Models dictate what trades are proposed when generating
      household and account rebalances.
    </p>
    <h3>Models</h3>
    <p>
      <strong>Models</strong> are a combination of Primary Securities and
      Sleeves. Each Primary Security and Sleeve is given a target weight within
      the portfolio. <strong>Models</strong> can also contain Secondary
      Securities, which can be used in place of one or more Primary Securities.
      Primary Securities and Sleeves are given priority over Secondary
      Securities when generating rebalances and proposing trades. Secondary
      Securities have various uses, such as tax-loss harvesting or facilitating
      a gradual migration from an old to a new Model without generating a large
      amount of trades immediately.
    </p>
    <h3>Sleeves</h3>
    <p>
      <strong>Sleeves</strong> are sub-models that can be reused within multiple
      Models. They consist of Primary and Secondary Securities, just like
      Models. <strong>Sleeves</strong> can not be directly assigned to a
      household or account. AdviceCloud does not allow the use of{" "}
      <strong>Sleeves</strong> within other <strong>Sleeves</strong>. The Asset
      Category and Region are optional labels that can help categorize{" "}
      <strong>Sleeves</strong>.
    </p>
    <h3>Benchmarks</h3>
    <p>
      <strong>Benchmarks</strong> are an optional feature of Models that allow
      comparison of a Model to another portfolio, such as one or more ETFs.
      Assigning a <strong>Benchmark</strong> to a Model is useful for indicating
      the intended theme of a Model. Benchmarks have no effect on rebalancing
      and are purely for informational purposes.
    </p>
  </section>
);

export function useQueryModels({
  includeMetrics = false,
  page = 1,
  enabled = true,
} = {}) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getAllModels"]>,
    { data: Model[]; page: number },
    [
      string,
      string,
      {
        includeMetrics: boolean;
      },
      number,
    ]
  >({
    queryKey: ["models", "models", { includeMetrics }, page],
    queryFn: async (models) => ({
      data: models.data.map(deserializeModel),
      page,
    }),
    urlBuilderFn: (key) =>
      `/${key[0]}/${key[1]}?metrics=${key[2].includeMetrics}&page=${key[3]}`,
    queryOptions: { enabled },
  });
}

export function deserializeModel<TModel extends Model>(
  model: SerializedObject<TModel>,
) {
  return {
    ...model,
    createdTime: deserializeDate(model.createdTime),
  } as TModel;
}
