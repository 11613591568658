import { ColumnFiltersState, SortingState } from "@tanstack/react-table";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type {
  Account,
  AccountWithMetrics,
} from "../../../../api/src/accounts/accounts.service";
import type { CurrentAndTargetRiskAllocation } from "../../../../api/src/households/households.service";
import type {
  PaginationResponseConfig,
  SerializedObject,
  UnpackResponse,
} from "../../../../api/src/lib";
import {
  deserializeDate,
  useAuthenticatedMutationNew,
  useAuthenticatedQuery,
} from "../../lib/api";
import { processFileResponse } from "../../lib/file";

export function useQueryAccounts(
  {
    includeBalances = false,
    includeInactive = false,
    filters = [] as ColumnFiltersState,
    sorting = [] as SortingState,

    pageSize = 10,
    pageIndex = 0, // front-end pageIndex is 0 based vs backend pageNumber which is 1 based index
    page = 1,
    enabled = true,
  } = {},
  accountIds?: number[],
) {
  return useAuthenticatedQuery<
    UnpackResponse<AccountsController["getAllAccounts"]>,
    {
      data: AccountWithMetrics[];
      config: PaginationResponseConfig;
      page: number;
    },
    [
      string,
      {
        includeBalances: boolean;
        includeInactive: boolean;
        filters: ColumnFiltersState;
        sorting: SortingState;
        pageSize: number;
        pageIndex: number;
        accountIds?: number[];
      },
      number,
    ]
  >({
    queryKey: [
      "accounts",
      {
        includeBalances,
        includeInactive,
        filters,
        sorting,
        pageSize,
        pageIndex,
        accountIds,
      },
      page,
    ],
    queryFn: async (accounts) => ({
      data: accounts.data.map(deserializeAccountWithMetrics),
      config: {
        ...accounts?.config,
        pageIndex: accounts?.config.pageIndex ?? 0,
        pageSize: accounts?.config.pageSize ?? 0,
      },
      page,
    }),
    urlBuilderFn: (key) => {
      let url = `/${key[0]}?balances=${key[1].includeBalances}&includeInactive=${key[1].includeInactive}&filters=${JSON.stringify(key[1].filters ?? [])}&sorting=${JSON.stringify(key[1].sorting ?? [])}`;

      if (key[1].pageSize) {
        url += `&pageSize=${key[1].pageSize}`;
      }

      if (key[1].pageIndex) {
        url += `&pageNumber=${key[1].pageIndex + 1}`;
      }

      url += `&page=${key[2]}`;

      if ((key[1].accountIds ?? []).length > 0) {
        url += "&" + key[1].accountIds?.map((id) => `id=${id}`).join("&");
      }

      return url;
    },
    queryOptions: { enabled },
  });
}

export function useQueryAccount(
  id: number,
  { includeBalances = false, enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<AccountsController["getOne"]>,
    AccountWithMetrics | undefined,
    [
      string,
      number,
      {
        includeBalances: boolean;
      },
    ]
  >({
    queryKey: ["accounts", id, { includeBalances }],
    queryFn: async (account) =>
      typeof account?.data === "undefined"
        ? undefined
        : deserializeAccountWithMetrics(account.data),
    urlBuilderFn: (key) =>
      `/${key[0]}/${key[1]}/?balances=${key[2].includeBalances}`,
    queryOptions: { enabled },
  });
}

export function useUpdateAccountsModel() {
  return useAuthenticatedMutationNew<
    UnpackResponse<AccountsController["bulkAssignModelToAccounts"]>,
    {
      account_id: number;
      model_id: number;
    }[],
    {
      modelId: number;
      accounts: Record<number, boolean>;
    }
  >({
    mutationKey: ["PUT", "accounts", "model", "assign"],
    mutationFn: ({
      data,
    }: {
      data: {
        account_id: number;
        model_id: number;
      }[];
    }) => data,
  });
}

export function displayAccountStatus(account: Pick<Account, "isActive">) {
  return account.isActive ? "Active" : "Closed";
}

export function deserializeAccountWithMetrics<
  TAccount extends AccountWithMetrics,
>(account: SerializedObject<TAccount>) {
  return {
    ...account,
    billingStartDate: deserializeDate(account.billingStartDate),
    inceptionDate: deserializeDate(account.inceptionDate),
    closeDate: deserializeDate(account.closeDate),
    holdings: (account.holdings ?? []).map((holding) => ({
      ...holding,
      asOfDate: deserializeDate(holding.asOfDate),
      firstOpenDate: deserializeDate(holding.firstOpenDate),
      firstOriginationDate: deserializeDate(holding.firstOriginationDate),
      latestOpenDate: deserializeDate(holding.latestOpenDate),
    })),
    archiveDate: deserializeDate(account.archiveDate),
  } as TAccount;
}

export function deserializeAccountWithMetricsAndAddRiskAllocation<
  TAccount extends AccountWithMetrics,
>(
  account: SerializedObject<TAccount>,
  riskAllocation?: Record<number, CurrentAndTargetRiskAllocation>,
) {
  return {
    ...deserializeAccountWithMetrics(account),
    riskAllocation: riskAllocation ?? account.riskAllocation,
  } as TAccount;
}

export function useQueryExportAccounts() {
  return useAuthenticatedQuery<
    UnpackResponse<AccountsController["exportAccounts"]>,
    void,
    [string, string]
  >({
    queryKey: ["accounts", "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}
