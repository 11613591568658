import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Badge, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import * as yup from "yup";
import type {
  Contact,
  EditableTrust,
  StreetAddress,
} from "../../../../api/src/contacts/lib";
import type { WrappedApiResponse } from "../../../../api/src/lib";
import { NotificationContext } from "../../Notifications";
import Avatar from "../../components/Avatar";
import Content from "../../components/Content";
import FormFieldError from "../../components/FormFieldError";
import SubmitButton from "../../components/SubmitButton";
import { useAuthenticatedMutationAsync } from "../../lib/api";
import { capitalize } from "../../lib/display";
import {
  emailSchema,
  getSchemaFieldLabel,
  phoneNumberSchema,
} from "../../lib/forms";
import GenerateForms from "./GenerateForms";

const schema: yup.ObjectSchema<EditableTrust> = yup.object({
  name: yup.string().required().label("Name"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  streetAddress: yup.object({
    id: yup.number(),
    streetLine1: yup.string().label("Street Line 1"),
    streetLine2: yup.string().label("Street Line 2"),
    city: yup.string().label("City"),
    state: yup.string().label("State"),
    country: yup.string().label("Country"),
    zipCode: yup.string().label("ZipCode"),
  }),
});

const TrustForm = ({
  contact,
}: {
  contact?: Contact & { linkedHousehold?: { id?: number; name?: string } };
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<EditableTrust>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { contactId } = useParams();

  useEffect(() => {
    if (typeof contact !== "undefined") {
      reset(contact);
    }
  }, [contact, reset]);

  const updateClient = useAuthenticatedMutationAsync<
    WrappedApiResponse<Contact>
  >(`/contacts/${contactId}`, async (data: EditableTrust) => ({
    method: "PUT",
    body: JSON.stringify(data),
  }));

  const queryClient = useQueryClient();
  const notificationContext = useContext(NotificationContext);

  const onSubmitInner = useMutation({
    mutationFn: async (data: EditableTrust) => {
      try {
        const body = await updateClient(data);

        if (typeof body !== "undefined") {
          queryClient.setQueryData([`/contacts/${contactId}`], {
            data: body?.data,
          });
          reset(body.data);
        }

        notificationContext.pushNotification({
          id: `contact-${contactId}`,
          header: "Contact Updated",
          body: `${data.name} updated`,
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save contact details", err);
        notificationContext.pushNotification({
          id: `contact-${contactId}`,
          header: "Failed to Save Contact",
          body: `${data.name} was not saved`,
          variant: "danger",
        });
      }
    },
  });

  return typeof contact === "undefined" ? null : (
    <Form onSubmit={handleSubmit((data) => onSubmitInner.mutateAsync(data))}>
      <Row>
        <Col xxl={4} md={6} className="mb-3">
          <h3>Identity</h3>
          <Content>
            <dl>
              <Row>
                {!contact.image ? null : (
                  <Col lg={3} sm={4}>
                    <Form.Group className="mb-3" controlId="form-image">
                      <Avatar src={contact.image} alt="contact avatar" />
                    </Form.Group>
                  </Col>
                )}
                <Col lg={9} md={8}>
                  <Row>
                    <Col as="dt" md={4} sm={3} xl={4}>
                      {getSchemaFieldLabel(schema.fields.name)}
                    </Col>
                    <Col as="dd">
                      <Form.Control type="text" {...register("name")} />
                      <FormFieldError field={errors.name} />
                    </Col>
                  </Row>
                  <Row>
                    <Col as="dt" md={4} sm={3} xl={4}>
                      Type
                    </Col>
                    <Col as="dd">{capitalize(contact.type)}</Col>
                  </Row>
                  {!contact.household?.id ? null : (
                    <Row>
                      <Col as="dt" md={4}>
                        Household
                      </Col>
                      <Col as="dd">
                        <Link
                          to={`/clients/households/${contact.household?.id}`}
                        >
                          {contact.household?.name}
                        </Link>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col as="dt" md={4}>
                      {getSchemaFieldLabel(schema.fields.email)}
                    </Col>
                    <Col as="dd">
                      <Form.Control
                        type="email"
                        placeholder="example@gmail.com"
                        {...register("email")}
                      />
                      <FormFieldError field={errors.email} />
                    </Col>
                  </Row>
                  <Row>
                    <Col as="dt" sm={3} md={4} xl={4}>
                      Tags
                    </Col>
                    <Col as="dd">
                      {contact.tags?.map((tag) => (
                        <Badge
                          key={tag.id}
                          bg="secondary"
                          pill
                          className="me-1"
                        >
                          {tag.name}
                        </Badge>
                      ))}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </dl>
          </Content>
        </Col>
        <Col xxl={4} md={6} className="mb-3">
          <h3>Contact Information</h3>
          <Content>
            <dl>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (
                      schema.fields
                        .streetAddress as yup.ObjectSchema<StreetAddress>
                    ).fields.streetLine1,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="text"
                    {...register("streetAddress.streetLine1")}
                  />
                  <FormFieldError field={errors.streetAddress?.streetLine1} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (
                      schema.fields
                        .streetAddress as yup.ObjectSchema<StreetAddress>
                    ).fields.streetLine2,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="text"
                    {...register("streetAddress.streetLine2")}
                  />
                  <FormFieldError field={errors.streetAddress?.streetLine2} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (
                      schema.fields
                        .streetAddress as yup.ObjectSchema<StreetAddress>
                    ).fields.city,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="text"
                    {...register("streetAddress.city")}
                  />
                  <FormFieldError field={errors.streetAddress?.city} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (
                      schema.fields
                        .streetAddress as yup.ObjectSchema<StreetAddress>
                    ).fields.state,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="text"
                    {...register("streetAddress.state")}
                  />
                  <FormFieldError field={errors.streetAddress?.state} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (
                      schema.fields
                        .streetAddress as yup.ObjectSchema<StreetAddress>
                    ).fields.country,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="text"
                    {...register("streetAddress.country")}
                  />
                  <FormFieldError field={errors.streetAddress?.country} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(
                    (
                      schema.fields
                        .streetAddress as yup.ObjectSchema<StreetAddress>
                    ).fields.zipCode,
                  )}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="text"
                    {...register("streetAddress.zipCode")}
                  />
                  <FormFieldError field={errors.streetAddress?.zipCode} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(schema.fields.email)}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="email"
                    placeholder="example@gmail.com"
                    {...register("email")}
                  />
                  <FormFieldError field={errors.email} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={4}>
                  {getSchemaFieldLabel(schema.fields.phoneNumber)}
                </Col>
                <Col as="dd">
                  <Form.Control
                    type="tel"
                    placeholder="+1-(xxx)-xxx-xxxx"
                    {...register("phoneNumber")}
                  />
                  <FormFieldError field={errors.phoneNumber} />
                </Col>
              </Row>
            </dl>
          </Content>
        </Col>
      </Row>
      <Row>
        <Col>
          <SubmitButton isSubmitting={isSubmitting} className="me-3" />
          <GenerateForms />
        </Col>
      </Row>
    </Form>
  );
};

export default TrustForm;
