import React, { createContext, useCallback, useContext, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";

export type Notification = {
  id: string;
  header: React.ReactNode;
  body: React.ReactNode;
  variant?: Variant;
};

const NotificationComp = ({
  header,
  body,
  variant,
}: Omit<Notification, "id">) => {
  const [show, setShow] = useState(true);
  const onClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Toast
      onClose={onClose}
      show={show}
      bg={`${variant?.toLowerCase()}-subtle`}
      autohide
    >
      <Toast.Header>
        <strong className="me-auto">{header}</strong>
      </Toast.Header>
      <Toast.Body>{body}</Toast.Body>
    </Toast>
  );
};

export const NotificationContainer = () => {
  const context = useContext(NotificationContext);

  return (
    <ToastContainer
      position="top-end"
      className="notification-container p-3 position-fixed"
      style={{ top: "65px !important" }}
    >
      {context.notifications.map((notification) => (
        <NotificationComp
          key={notification.id}
          header={notification.header}
          body={notification.body}
          variant={notification.variant}
        />
      ))}
    </ToastContainer>
  );
};

export const NotificationContext = createContext<{
  notifications: Notification[];
  setNotifications: (notifications: Notification[]) => void;
  pushNotification: (notification: Notification) => void;
}>({
  notifications: [],
  setNotifications: () => null,
  pushNotification: () => null,
});

export const NotificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const context = {
    notifications,
    setNotifications,
    pushNotification: (notification: Notification) => {
      setNotifications([...notifications, notification]);
    },
  };

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
};
