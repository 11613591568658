import { User, useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Dropdown, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import type {
  Auth0User,
  Auth0UserRole,
} from "../../../api/src/auth0/auth0.service";
import type { UnpackResponse } from "../../../api/src/lib";
import type { UsersController } from "../../../api/src/users/users.controller";
import Loading from "../Loading";
import ActionButton from "../components/ActionButton";
import FormError, { useErrorMessage } from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import Spinner from "../components/Spinner";
import SubmitButton from "../components/SubmitButton";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutation,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import { emailSchema } from "../lib/forms";

type InviteUser = { email: string };

const schema: yup.ObjectSchema<InviteUser> = yup.object({
  email: emailSchema.required(),
});

type Role = {
  id?: string | undefined;
  name?: string | undefined;
  description?: string | undefined;
};

type ChangeUserRole = {
  userId: string;
  roleId: string;
  currentRole: string[];
};

type UserRole = {
  role: Auth0UserRole[];
};

const UserRow = ({
  user,
  owner,
  users,
  roles,
  setUsers,
  handleChangePermission,
}: {
  user: Auth0User & UserRole;
  owner?: User;
  users: (Auth0User & UserRole)[];
  roles: Role[];
  setUsers: React.Dispatch<React.SetStateAction<(Auth0User & UserRole)[]>>;
  handleChangePermission: (changedRole: ChangeUserRole) => Promise<void>;
}) => {
  const removeUser = useAuthenticatedMutation<
    UnpackResponse<UsersController["deleteOne"]>
  >(
    `/users/${user.user_id}`,
    {
      method: "DELETE",
    },
    processEmptyResponse,
  );

  const del = useMutation({
    mutationFn: async () => {
      await removeUser();
      const remainingUsers = users.filter((u) => u.user_id !== user.user_id);
      setUsers(remainingUsers);
    },
  });

  const [isChanging, setChanging] = useState(false);

  const onSelectHandler = useCallback(
    async (roleId: string | null) => {
      setChanging(true);
      await handleChangePermission({
        currentRole: user.role.reduce(
          (result, sub) =>
            typeof sub.role_id !== "undefined"
              ? [...result, sub.role_id]
              : result,
          [] as string[],
        ),
        userId: user.user_id,
        roleId: roleId ?? "",
      });
      setChanging(false);
    },
    [handleChangePermission, user.role, user.user_id],
  );

  return (
    <tr key={user.user_id}>
      <td>
        <Link to={`/users/${user.user_id}`}>
          {user.given_name} {user.family_name}
        </Link>
      </td>
      <td>{user.email}</td>
      <td>
        <Dropdown onSelect={onSelectHandler}>
          <Dropdown.Toggle
            className="w-100"
            variant="secondary"
            size="sm"
            disabled={isChanging}
          >
            {user.role.map((sub) => sub.role_name).join(",")}
            {isChanging ? <Spinner className="ms-1" size="sm" /> : null}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {roles
              .filter(
                (role) =>
                  !user.role.some((userRole) => userRole.role_id === role.id),
              )
              .map((role) => (
                <Dropdown.Item key={role.id} eventKey={role.id}>
                  {role.name}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <td className="text-end">
        {typeof owner !== "undefined" &&
        owner.email !== "undefined" &&
        owner.email === user.email ? null : (
          <ActionButton
            variant="icon"
            icon="/icons/trash.svg"
            label="Remove"
            onClick={() => del.mutate()}
            disabled={del.isPending}
            type="button"
          />
        )}
      </td>
    </tr>
  );
};

const UsersManager = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<InviteUser>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { user: owner } = useAuth0();
  const { setError, resetError, errorMessage } = useErrorMessage();

  const [users, setUsers] = useState<(Auth0User & UserRole)[]>([]);

  const {
    isPending: isPendingUsers,
    isError: isErrorUsers,
    data: dataUsers,
  } = useAuthenticatedFetch<UnpackResponse<UsersController["getAll"]>>(
    "/users",
  );

  const {
    isPending: isPendingRoles,
    isError: isErrorRoles,
    data: dataRoles,
  } = useAuthenticatedFetch<UnpackResponse<UsersController["getAllRoles"]>>(
    "/users/roles",
  );

  useEffect(() => {
    if (typeof dataUsers !== "undefined") {
      setUsers(dataUsers.data);
    }
  }, [dataUsers]);

  const inviteUserRequest = useAuthenticatedMutationAsync<
    UnpackResponse<UsersController["inviteUser"]>
  >("/users/invite", async (data: InviteUser) => ({
    method: "POST",
    body: JSON.stringify({
      userEmail: data.email,
    }),
  }));

  const changeUserRoleRequest = useAuthenticatedMutationAsync<
    UnpackResponse<UsersController["changeUserRole"]>
  >("/users/update-role", async (data: ChangeUserRole) => ({
    method: "POST",
    body: JSON.stringify({
      userId: data.userId,
      roleId: data.roleId,
      currentRole: data.currentRole,
    }),
  }));

  const queryClient = useQueryClient();

  const changeUserRole = useMutation({
    mutationFn: async (data: ChangeUserRole) => {
      resetError();

      try {
        await changeUserRoleRequest(data);
        queryClient.setQueryData(["/users"], {
          data: users.map((user) =>
            user.user_id === data.userId
              ? {
                  ...user,
                  role: [
                    {
                      user_id: data.userId,
                      role_id: data.roleId,
                      role_name: dataRoles?.data.find(
                        (role) => role.id === data.roleId,
                      )?.name,
                    },
                  ],
                }
              : user,
          ),
        });
      } catch (err) {
        const message = "Failed to change user role";
        setError(message);
        console.error(message, err);
      }
    },
  });

  const inviteUser = useMutation({
    mutationFn: async (data: InviteUser) => {
      resetError();

      try {
        const newUser = await inviteUserRequest(data);
        queryClient.setQueryData(["/users"], {
          data: (dataUsers?.data ?? []).concat(newUser.data),
        });
        reset({ email: "" });
      } catch (err) {
        const message = "Failed to add user";
        setError(message);
        console.error(message, err);
      }
    },
  });

  const handleChangeUserPermission = useCallback(
    async (changedRole: ChangeUserRole) => {
      if (
        typeof changedRole.userId === "undefined" ||
        changedRole.currentRole.length === 0 ||
        changedRole.roleId === null
      )
        return;
      await changeUserRole.mutateAsync({
        userId: changedRole.userId,
        roleId: changedRole.roleId,
        currentRole: changedRole.currentRole,
      });
    },
    [changeUserRole],
  );

  return isPendingUsers || isPendingRoles ? (
    <Loading />
  ) : typeof users === "undefined" || isErrorUsers || isErrorRoles ? (
    <FormError message="Failed to load users" />
  ) : (
    <>
      <Row>
        <Col md={12} xs={12}>
          <h2>Users</h2>
          {errorMessage}
          <Form.Group className="mb-3" controlId="form-invite-email">
            <Form.Label>Invite User</Form.Label>
            <InputGroup>
              <Form.Control type="email" {...register("email")} />
              <SubmitButton
                type="button"
                isSubmitting={isSubmitting}
                label="Invite"
                onClick={handleSubmit((data) => inviteUser.mutateAsync(data))}
              />
            </InputGroup>
            <FormFieldError field={errors.email} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>{/* Empty */}</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <UserRow
                  key={user.user_id}
                  owner={owner}
                  user={user}
                  users={users}
                  roles={dataRoles?.data ?? []}
                  setUsers={setUsers}
                  handleChangePermission={handleChangeUserPermission}
                />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default UsersManager;
