import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext } from "react";
import { Button, ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import type { ContactType } from "../../../../api/src/contacts/lib";
import { NotificationContext } from "../../Notifications";
import Content from "../../components/Content";
import FormFieldError from "../../components/FormFieldError";
import SubmitButton from "../../components/SubmitButton";
import { capitalize } from "../../lib/display";
import {
  familyNameSchema,
  getSchemaFieldLabel,
  givenNameSchema,
} from "../../lib/forms";
import { CONTACT_TYPES, useCreateContact } from "./lib";

// Yup does not have a sane way to do union object types. Create a new
// form type that we will manipulate before sending to the API.
type NewContactForm = {
  type: ContactType;
  name: string;
  givenName?: string;
  familyName?: string;
};

const schema: yup.ObjectSchema<NewContactForm> = yup
  .object({
    type: yup
      .string()
      .required()
      .oneOf<ContactType>(CONTACT_TYPES)
      .label("Type"),
    name: yup.string().required().label("Name"),
    givenName: givenNameSchema.when("type", {
      is: "person",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
    familyName: familyNameSchema.when("type", {
      is: "person",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
  })
  .transform((value: NewContactForm) => {
    const name =
      value.type !== "person"
        ? value.name
        : `${value.givenName ?? ""} ${value.familyName ?? ""}`.trim();

    return { ...value, name };
  });

const CreateContact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<NewContactForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: { type: "person" },
  });

  const createContact = useCreateContact();

  const notificationContext = useContext(NotificationContext);
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (newContact: NewContactForm) => {
      try {
        const body = await createContact.mutateAsync(newContact);
        notificationContext.pushNotification({
          id: `contact-${body}`,
          header: "Contact Created",
          body: `${newContact.name} created`,
          variant: "success",
        });
        navigate(`../${body}`);
      } catch (err) {
        console.error("Failed to create contact", err);
        notificationContext.pushNotification({
          id: "contact-new",
          header: "Failed to Create Contact",
          body: `${newContact.name} was not created`,
          variant: "danger",
        });
      }
    },
    [createContact, navigate, notificationContext],
  );

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const type = watch("type");

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <h1>Create Contact</h1>
        </Col>
      </Row>
      <Content>
        <Row>
          <Col xxl={3} xl={4} md={6} xs={12}>
            <Form.Group className="mb-3" controlId="form-type">
              <Form.Label>{getSchemaFieldLabel(schema.fields.type)}</Form.Label>
              <Form.Select {...register("type")}>
                {CONTACT_TYPES.map((type) => (
                  <option key={type} value={type}>
                    {capitalize(type)}
                  </option>
                ))}
              </Form.Select>
              <FormFieldError field={errors.type} />
            </Form.Group>
            {type !== "person" ? (
              <Form.Group className="mb-3" controlId="form-name">
                <Form.Label>
                  {getSchemaFieldLabel(schema.fields.name)}
                </Form.Label>
                <Form.Control type="text" {...register("name")} />
                <FormFieldError field={errors.name} />
              </Form.Group>
            ) : (
              <>
                <Form.Group className="mb-3" controlId="form-givenName">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.givenName)}
                  </Form.Label>
                  <Form.Control type="text" {...register("givenName")} />
                  <FormFieldError field={errors.givenName} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="form-familyName">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.familyName)}
                  </Form.Label>
                  <Form.Control type="text" {...register("familyName")} />
                  <FormFieldError field={errors.familyName} />
                </Form.Group>
              </>
            )}
            <ButtonToolbar>
              <SubmitButton
                label="Create"
                isSubmitting={isSubmitting}
                className="me-3"
              />
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

export default CreateContact;
