import { ListGroup } from "react-bootstrap";
import CashActivity from "./CashActivity";
import NewAccounts from "./NewAccounts";
import RebalanceOpportunities from "./RebalanceOpportunities";
import UnassignedAccounts from "./UnassignedAccounts";
import UnassignedCashSettings from "./UnassignedCashSettings";
import UnassignedFeeStructuresModels from "./UnassignedFeeStructuresModels";

const InsightsList = () => (
  <ListGroup>
    <NewAccounts />
    <UnassignedAccounts />
    <CashActivity />
    <RebalanceOpportunities />
    <UnassignedFeeStructuresModels />
    <UnassignedCashSettings />
  </ListGroup>
);

export default InsightsList;
