import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { EditableAccountTrading } from "../../../../api/src/accounts/accounts.service";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import { NotificationContext } from "../../Notifications";
import FormFieldError from "../../components/FormFieldError";
import SecurityRestrictions from "../../components/SecurityRestrictions";
import SubmitButton from "../../components/SubmitButton";
import { useAuthenticatedMutationAsync } from "../../lib/api";
import { InlineError, displayAccountName } from "../../lib/display";
import {
  getSchemaFieldLabel,
  idOptionalSelectorSchema,
  riskScoreSchema,
  securityRestrictionSchema,
} from "../../lib/forms";
import { formatCurrencyComponent } from "../../lib/numbers";
import { useQueryModels } from "../../models/lib";
import AccountAssetAllocationChart from "./AccountAssetAllocationChart";
import { AccountContext } from "./AccountInfo";

export const schema: yup.ObjectSchema<EditableAccountTrading> = yup.object({
  assignedModelId: idOptionalSelectorSchema.label("Assigned Model"),
  riskScore: riskScoreSchema,
  capitalGainsBudgetST: idOptionalSelectorSchema
    .min(0)
    .label("Capital Gains Budget (Short-Term)"),
  capitalGainsBudgetLT: idOptionalSelectorSchema
    .min(0)
    .label("Capital Gains Budget (Long-Term)"),
  securityRestrictions: securityRestrictionSchema.required(),
  // holdings: yup
  //   .array()
  //   .required()
  //   .of(
  //     yup.object({
  //       restrictSecurity: yup.bool().required(),
  //       symbol: yup.string().required(),
  //       treatment: securityRestrictionTreatmentSchema,
  //     }),
  //   ),
});

const AccountModelTrading = () => {
  const { account } = useContext(AccountContext);
  const { accountId } = useParams();
  const form = useForm<EditableAccountTrading>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    control,
    getValues,
    watch,
  } = form;

  useEffect(() => {
    if (typeof account !== "undefined") {
      reset({
        assignedModelId: account.assignedModelId,
        riskScore: account.riskScore,
        capitalGainsBudgetST: account.capitalGainsBudgetST,
        capitalGainsBudgetLT: account.capitalGainsBudgetLT,
        securityRestrictions: account.securityRestrictions,
        // holdings: holdings.map((holding) => {
        //   const symbol = holding.security?.identifier ?? "";
        //   const securityRestriction = account?.securityRestrictions.find(
        //     (restriction) => restriction.symbol === symbol,
        //   );
        //   const restrictSecurity = typeof securityRestriction !== "undefined";

        //   return {
        //     ...holding,
        //     symbol,
        //     restrictSecurity,
        //     treatment: securityRestriction?.treatment ?? "sell_no_tax",
        //   };
        // }),
      });
    }
  }, [account, reset]);

  const {
    fields: securityRestrictionFields,
    append: appendSecurityRestriction,
    remove: removeSecurityRestriction,
    replace: replaceSecurityRestriction,
  } = useFieldArray({
    name: "securityRestrictions",
    control,
    keyName: "_field_id",
  });

  const {
    isPending: isPendingModels,
    isError: isErrorModels,
    data: dataModels,
  } = useQueryModels();

  const modelOptions = useMemo(
    () => [
      <option key={null} value="">
        None
      </option>,
      ...(dataModels?.data ?? [])
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((model) => (
          <option key={model.id} value={model.id}>
            {model.name}
          </option>
        )),
    ],
    [dataModels?.data],
  );

  const updateAccount = useAuthenticatedMutationAsync<
    UnpackResponse<AccountsController["updateTradingSettings"]>
  >(
    `/accounts/${accountId}/trading`,
    async (household: EditableAccountTrading) => ({
      method: "PUT",
      body: JSON.stringify(household),
    }),
  );

  const queryClient = useQueryClient();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: EditableAccountTrading) => {
      try {
        await updateAccount(data);
        queryClient.invalidateQueries({
          queryKey: [
            "accounts",
            parseInt(accountId ?? ""),
            { includeBalances: true },
          ],
        });
        notificationContext.pushNotification({
          id: `account-${accountId}`,
          header: "Account Updated",
          body: `${displayAccountName(
            account?.displayName,
            account?.displayNumber,
          )} updated`,
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save account", err);
        notificationContext.pushNotification({
          id: `account-${accountId}`,
          header: "Failed to Save Account",
          body: `${displayAccountName(
            account?.displayName,
            account?.displayNumber,
          )} was not saved`,
          variant: "danger",
        });
      }
    },
  });

  const watchSecurityRestrictionsArray = watch("securityRestrictions", []);
  const controlledSecurityRestrictionFields = securityRestrictionFields.map(
    (field, index) => ({
      ...field,
      ...watchSecurityRestrictionsArray[index],
    }),
  );

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
        <Row>
          <Col xl={6} className="mb-3">
            <h3>Model Details</h3>
            <dl>
              <Row>
                <Col as="dt" lg={6}>
                  {getSchemaFieldLabel(schema.fields.assignedModelId)}
                </Col>
                <Col as="dd" lg={6}>
                  {isPendingModels ? (
                    <Loading />
                  ) : isErrorModels ? (
                    <InlineError>There was an error loading models</InlineError>
                  ) : (
                    <Form.Select
                      placeholder="Select assigned model"
                      {...register("assignedModelId")}
                    >
                      {modelOptions}
                    </Form.Select>
                  )}
                  <FormFieldError field={errors.assignedModelId} />
                </Col>
              </Row>

              <Row>
                <Col as="dt" lg={6}>
                  {getSchemaFieldLabel(schema.fields.capitalGainsBudgetST)}
                </Col>
                <Col as="dd" lg={6}>
                  <Form.Control
                    type="number"
                    min={0}
                    {...register("capitalGainsBudgetST")}
                  />
                  <FormFieldError field={errors.capitalGainsBudgetST} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" lg={6}>
                  {getSchemaFieldLabel(schema.fields.capitalGainsBudgetLT)}
                </Col>
                <Col as="dd" lg={6}>
                  <Form.Control
                    type="number"
                    min={0}
                    {...register("capitalGainsBudgetLT")}
                  />
                  <FormFieldError field={errors.capitalGainsBudgetLT} />
                </Col>
              </Row>
              <Row>
                <Col as="dt" lg={6}>
                  Realized Gains YTD
                </Col>
                <Col as="dd">
                  {formatCurrencyComponent(
                    account?.performanceYTD?.realizedGainLoss ?? 0,
                  )}
                </Col>
              </Row>
              {/* <Row>
                <Col as="dt" lg={6}>
                  Last Rebalance Date
                </Col>
                <Col as="dd">
                  {typeof account?.lastRebalanceDate === "undefined" ||
                  account.lastRebalanceDate === null
                    ? naLabel
                    : account.lastRebalanceDate.toLocaleDateString()}
                </Col>
              </Row>
              <Row>
                <Col as="dt" lg={6}>
                  Pending Rebalance
                </Col>
                <Col as="dd">
                  {typeof account?.latestRebalanceDate === "undefined" ||
                  account.latestRebalanceDate === null ? (
                    naLabel
                  ) : (
                    <Link to={`/rebalances/${account.latestRebalanceId}`}>
                      {account.latestRebalanceDate.toLocaleDateString()}
                    </Link>
                  )}
                </Col>
              </Row> */}
            </dl>
          </Col>
          <Col>
            <h3>Allocation Details</h3>
            <AccountAssetAllocationChart />
          </Col>
        </Row>
        {/* <Row>
          <Col className="mb-3">
            <h3>Holdings</h3>
            <HouseholdTradingHoldingsTable
              holdings={holdings}
              accounts={accounts}
            />
          </Col>
        </Row> */}
        <Row>
          <Col>
            <h3>Security Restrictions</h3>
            <SecurityRestrictions
              fields={controlledSecurityRestrictionFields}
              replace={replaceSecurityRestriction}
              add={appendSecurityRestriction}
              remove={removeSecurityRestriction}
              register={register}
              control={control}
              getValues={getValues}
              errors={errors.securityRestrictions}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default AccountModelTrading;
