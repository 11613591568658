import { Button, ButtonGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAuthenticatedFileFetch } from "../../lib/api";

const GenerateForms = () => {
  const { contactId } = useParams();

  const { refetch: downloadSchwabIra } = useAuthenticatedFileFetch(
    `/custodian-forms/schwab-ira/${contactId}`,
    undefined,
    {
      enabled: false,
    },
  );

  const { refetch: downloadSchwabTaxable } = useAuthenticatedFileFetch(
    `/custodian-forms/schwab-taxable/${contactId}`,
    undefined,
    {
      enabled: false,
    },
  );

  const { refetch: downloadSchwabToa } = useAuthenticatedFileFetch(
    `/custodian-forms/schwab-toa/${contactId}`,
    undefined,
    {
      enabled: false,
    },
  );

  return (
    <ButtonGroup>
      <Button onClick={() => downloadSchwabIra()}>Generate IRA Form</Button>
      <Button onClick={() => downloadSchwabTaxable()}>
        Generate Taxable Account Form
      </Button>
      <Button onClick={() => downloadSchwabToa()}>Generate TOA Form</Button>
    </ButtonGroup>
  );
};

export default GenerateForms;
