import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { Outlet, useParams } from "react-router-dom";
import * as yup from "yup";
import type {
  EditableHousehold,
  FeeChargeLocation,
} from "../../../../api/src/households-base/households-base.service";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { HouseholdWithAccounts } from "../../../../api/src/households/households.service";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import FormError from "../../components/FormError";
import TabContainerWithTabs from "../../components/TabContainer";
import { useAuthenticatedFetch } from "../../lib/api";
import {
  idOptionalSelectorSchema,
  optionalStringValueTransform,
  securityRestrictionSchema,
} from "../../lib/forms";
import HouseholdHeader from "./HouseholdHeader";
import HouseholdNav from "./HouseholdNav";
import { useQueryHousehold } from "./lib";

const feeChargeLocations: FeeChargeLocation[] = [
  "Custodian Billed",
  "Direct Billed",
];

const schema: yup.ObjectSchema<EditableHousehold> = yup.object({
  assignedModelId: idOptionalSelectorSchema.label("Assigned Model"),
  rebalanceAllocationThreshold: yup
    .number()
    .min(0)
    .max(100)
    .label("Rebalance Allocation Threshold"),
  rebalanceWeightLimit: yup
    .number()
    .min(0)
    .max(100)
    .label("Rebalance Weight Limit"),
  rebalancePeriod: yup
    .string()
    .required()
    .oneOf(["d", "w", "m", "q", "y"])
    .label("Rebalance Recurrence"),
  rebalancePeriodStartDate: yup
    .date()
    .required()
    .label("Rebalance Recurrence Start Date"),
  feeStructureId: idOptionalSelectorSchema.label("Assigned Fee Structure"),
  billingMinimumId: idOptionalSelectorSchema.label("Assigned Billing Minimum"),
  feeChargeLocation: yup
    .string()
    .oneOf<FeeChargeLocation>(feeChargeLocations)
    .transform(optionalStringValueTransform)
    .label("Fee Charge Location"),
  securityRestrictions: securityRestrictionSchema.required(),
  capitalGainsBudgetST: idOptionalSelectorSchema.label(
    "Capital Gains Budget (Short-Term)",
  ),
  capitalGainsBudgetLT: idOptionalSelectorSchema.label(
    "Capital Gains Budget (Long-Term)",
  ),
});

export const HouseholdContext = React.createContext<{
  household?: HouseholdWithAccounts;
  schema: yup.ObjectSchema<EditableHousehold>;
}>({ schema });

const HouseholdInfo = () => {
  const { householdId } = useParams();

  const {
    isPending: isPendingHousehold,
    isError: isErrorHousehold,
    data: dataHousehold,
  } = useQueryHousehold(parseInt(householdId ?? ""), {
    includeAccounts: true,
    includeCRM: true,
  });

  const { data: householdRiskAllocationData } = useAuthenticatedFetch<
    UnpackResponse<HouseholdsController["riskAllocation"]>
  >(`/households/${householdId}/risk-allocation`);

  const household: HouseholdWithAccounts | undefined = useMemo(
    () =>
      typeof dataHousehold === "undefined"
        ? undefined
        : {
            ...dataHousehold,
            householdBalance: dataHousehold.householdBalance || 0, // provide a default value
            accounts: (dataHousehold.accounts ?? []).map((account) => ({
              ...account,
              riskAllocation:
                householdRiskAllocationData?.data.accounts[account.id] ??
                account.riskAllocation,
            })),
            riskAllocation: householdRiskAllocationData?.data
              ?.riskAllocation ?? {
              current: { growth: 0, defensive: 0, unknown: 0 },
              target: { growth: 0, defensive: 0, unknown: 0 },
            },
          },
    [dataHousehold, householdRiskAllocationData],
  );

  const form = useForm<EditableHousehold>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (typeof household !== "undefined" && !isPendingHousehold) {
      form.reset(household);
    }
  }, [household, isPendingHousehold, form]);

  return isPendingHousehold ? (
    <Loading />
  ) : isErrorHousehold ? (
    <FormError message="Failed to load household" />
  ) : !household ? (
    <FormError message="Household not found" />
  ) : (
    <HouseholdContext.Provider value={{ household, schema }}>
      <HouseholdHeader />
      <FormProvider {...form}>
        <Row>
          <Col>
            <TabContainerWithTabs tabs={HouseholdNav}>
              <Col>
                <Outlet />
              </Col>
            </TabContainerWithTabs>
          </Col>
        </Row>
      </FormProvider>
    </HouseholdContext.Provider>
  );
};

export default HouseholdInfo;
