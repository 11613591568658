import { Row, Table } from "@tanstack/react-table";
import { Button } from "react-bootstrap";

function TableExpander({
  getToggleExpandedHandler,
  getIsExpanded,
}: {
  getToggleExpandedHandler: () => (event?: unknown) => void;
  getIsExpanded: () => boolean;
}) {
  return (
    <Button
      onClick={getToggleExpandedHandler()}
      className="me-2 p-1 lh-1 align-baseline"
      size="sm"
    >
      {getIsExpanded() ? "–" : "+"}
    </Button>
  );
}

export function AllRowsExpander<TData>({ table }: { table: Table<TData> }) {
  return (
    <TableExpander
      getToggleExpandedHandler={table.getToggleAllRowsExpandedHandler}
      getIsExpanded={table.getIsAllRowsExpanded}
    />
  );
}

export function RowExpander<TRow>({ row }: { row: Row<TRow> }) {
  return (
    <TableExpander
      getToggleExpandedHandler={row.getToggleExpandedHandler}
      getIsExpanded={row.getIsExpanded}
    />
  );
}
