import { flexRender, Row as TanTableRow } from "@tanstack/react-table";
import { useState, useCallback } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import ActionButton from "../ActionButton";

export type EditableRowExtension = Partial<{ _isNew: boolean }>;

/**
 * A table row component that is designed for use in the {@link TableBase} component
 * as a row component override. This component allows for rows that have two states:
 * read-only and write. When in read-only mode, render the default cells in the given
 * table row. When in write mode, render the editable field (if defined) for each
 * table cell.
 *
 * Optionally allow rows to be added and removed from the table. Rows can be added or
 * removed at any time, not just in edit mode.
 * @param props
 * @returns A JSX element
 */
function EditableTableRow<TRow extends EditableRowExtension>({
  row,
  onSave,
  onEdit = () => null,
  onDiscard = () => null,
  editComp = () => null,
  enableAddRemove = false,
  onAdd = () => null,
  onRemove = () => null,
}: {
  row: TanTableRow<TRow>;
  onSave: (row: TanTableRow<TRow>) => void;
  onEdit?: (row: TanTableRow<TRow>) => void;
  onDiscard?: (row: TanTableRow<TRow>) => void;
  editComp?: (row: TanTableRow<TRow>) => React.ReactNode;
  enableAddRemove?: boolean;
  onAdd?: (row: TanTableRow<TRow>) => void;
  onRemove?: (row: TanTableRow<TRow>) => void;
}) {
  const [isEditing, setIsEditing] = useState(row.original._isNew ?? false);

  const handleEnableEdit = useCallback(() => {
    onEdit(row);
    setIsEditing(true);
  }, [onEdit, row]);

  const handleSave = useCallback(() => {
    if (isEditing) {
      onSave(row);
      setIsEditing(false);
    }
  }, [isEditing, onSave, row]);

  const handleDiscard = useCallback(() => {
    if (isEditing) {
      onDiscard(row);
      setIsEditing(false);
    }
  }, [isEditing, onDiscard, row]);

  const handleAdd = useCallback(() => {
    onAdd(row);
  }, [onAdd, row]);

  const handleRemove = useCallback(() => {
    onRemove(row);
  }, [onRemove, row]);

  const cells = row.getVisibleCells();
  const lastCellIndex = cells.length - (enableAddRemove ? 2 : 1);
  const lastCell = cells[lastCellIndex];

  return (
    <tr>
      {cells.slice(0, lastCellIndex).map((cell) => (
        <td
          key={cell.id}
          id={cell.id}
          className={`p-2 ${isEditing ? "align-top" : ""}`}
        >
          {!isEditing ||
          typeof cell.column.columnDef.meta?.editableComponent === "undefined"
            ? flexRender(cell.column.columnDef.cell, cell.getContext())
            : cell.column.columnDef.meta.editableComponent(cell)}
        </td>
      ))}
      <td
        style={{ width: lastCell.column.columnDef.size }}
        className={`text-end ${isEditing ? "align-top" : ""}`}
      >
        {isEditing ? (
          <>
            <ActionButton
              type="button"
              onClick={handleSave}
              variant="icon"
              label="Confirm"
              icon="/icons/save.svg"
              className="me-1"
            />
            <ActionButton
              type="button"
              onClick={handleDiscard}
              variant="icon"
              label="Discard"
              icon="/icons/exit.svg"
            />
          </>
        ) : (
          <>
            <ActionButton
              type="button"
              onClick={handleEnableEdit}
              variant="icon"
              label="Edit"
              icon="/icons/edit.svg"
              className="me-1"
            />
            {editComp(row)}
          </>
        )}
      </td>
      {!enableAddRemove ? null : (
        <td
          style={{ width: "120px" }}
          className={`text-end ${isEditing ? "align-top" : ""}`}
        >
          <ButtonGroup>
            <Button onClick={handleRemove}>-</Button>
            <Button onClick={handleAdd}>+</Button>
          </ButtonGroup>
        </td>
      )}
    </tr>
  );
}

export default EditableTableRow;

export const EmptyEditableTableRow = ({
  onAdd = () => null,
}: {
  onAdd?: () => void;
}) => {
  const handleAdd = useCallback(() => {
    onAdd();
  }, [onAdd]);

  return (
    <tr>
      {/* Push to the right, no matter how many columns exist */}
      <td colSpan={1000} className="text-end">
        <ButtonGroup>
          <Button onClick={handleAdd}>+</Button>
        </ButtonGroup>
      </td>
    </tr>
  );
};
