import _ from "lodash";
import { useContext, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import type { AccountHoldingResponse } from "../../../api/src/accounts/accounts.service";
import type { HouseholdsController } from "../../../api/src/households/households.controller";
import type { UnpackResponse } from "../../../api/src/lib";
import HoldingsByAccountTable, {
  HoldingsFieldMode,
} from "../components/HoldingsByAccountTable";
import { useAuthenticatedFetch } from "../lib/api";
import { useQuerySecurities } from "../lib/security";
import { RebalanceContext } from "./RebalanceInfo";

type HoldingsMode = "current" | "target" | "post-rebalance";

const RebalanceHoldings = () => {
  const { household, rebalance } = useContext(RebalanceContext);

  const [holdingsMode, setHoldingsMode] = useState<HoldingsMode>("current");
  const [fieldMode, setFieldMode] = useState<HoldingsFieldMode>("value");

  const { data: dataHoldings, isPending: isPendingHoldings } =
    useAuthenticatedFetch<
      UnpackResponse<HouseholdsController["getHouseholdHoldings"]>
    >(`/households/${household?.id}/holdings`, undefined, {
      enabled: typeof household !== "undefined",
    });

  const { data: dataModelSummary, isPending: isPendingModelSummary } =
    useAuthenticatedFetch<
      UnpackResponse<HouseholdsController["getHouseholdModelSummary"]>
    >(`/households/${household?.id}/model-summary`);

  const modelSecurities = (dataModelSummary?.data ?? []).flatMap((account) =>
    Object.keys(account.model),
  );

  const { data: securities, isPending: isPendingRebalanceSecurities } =
    useQuerySecurities(
      _.uniq([
        ...(rebalance?.postRebalanceHoldings ?? []).map(
          (holding) => holding.security?.identifier ?? "",
        ),
        ...modelSecurities,
      ]).filter((symbol) => symbol !== ""),
      { enabled: typeof rebalance !== "undefined" },
    );

  const securitiesMap = useMemo(
    () => _.keyBy(securities ?? [], "identifier"),
    [securities],
  );

  const holdings: AccountHoldingResponse[] = useMemo(
    () =>
      holdingsMode === "current"
        ? (dataHoldings?.data.accounts ?? []).flatMap(
            (account) => account.holdings,
          )
        : holdingsMode === "post-rebalance" && typeof securities !== "undefined"
          ? (rebalance?.accounts ?? []).flatMap((account) =>
              (account.postRebalanceHoldings ?? []).map(
                (holding): AccountHoldingResponse => {
                  const security =
                    securitiesMap[holding.security?.identifier ?? ""];

                  return {
                    ...holding,
                    accountId: account.id,
                    securityId: holding.securityId,
                    security: security ?? {
                      ...holding.security,
                      assetClass: "other",
                      riskAllocation: {
                        growth: 0,
                        defensive: 0,
                        unknown: 1,
                      },
                    },
                    income: 0,
                    unrealizedGainLoss: 0,
                  };
                },
              ),
            )
          : (dataModelSummary?.data ?? []).flatMap((account) => {
              return Object.entries(account.model).map(
                ([symbol, weight]): AccountHoldingResponse => {
                  const security = securitiesMap[symbol];

                  return {
                    accountId: account.accountId,
                    securityId: security.id,
                    security: security ?? {
                      assetClass: "other",
                      riskAllocation: {
                        growth: 0,
                        defensive: 0,
                        unknown: 1,
                      },
                    },
                    appraisedUnitPrice: 0,
                    units: 0,
                    value: weight * (household?.householdBalance ?? 0),
                    income: 0,
                    unrealizedGainLoss: 0,
                  };
                },
              );
            }),
    [
      dataHoldings?.data.accounts,
      dataModelSummary?.data,
      securities,
      holdingsMode,
      household?.householdBalance,
      rebalance?.accounts,
      securitiesMap,
    ],
  );

  return (
    <>
      <Row>
        <Col xxl={2} md={4} className="mb-3">
          <Form.Select
            value={holdingsMode}
            onChange={(ev) =>
              setHoldingsMode(ev.currentTarget.value as HoldingsMode)
            }
          >
            <option value="current">Current</option>
            <option value="target">Target</option>
            <option value="post-rebalance">Post-Rebalance</option>
          </Form.Select>
        </Col>
        <Col xxl={3} xl={4} md={6} className="mb-3">
          <Form.Select
            value={fieldMode}
            onChange={(ev) =>
              setFieldMode(ev.currentTarget.value as HoldingsFieldMode)
            }
          >
            <option value="value">Value</option>
            <option value="weight">Weight</option>
            <option value="modelWeight">Model Weight</option>
            <option value="modelWeightDifference">
              Current - Model Weight
            </option>
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <HoldingsByAccountTable
            holdings={holdings}
            accounts={household?.accounts ?? []}
            transactions={[]}
            modelSummaries={dataModelSummary?.data ?? []}
            fieldMode={fieldMode}
            isLoading={
              isPendingHoldings ||
              isPendingModelSummary ||
              isPendingRebalanceSecurities
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default RebalanceHoldings;
