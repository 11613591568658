import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { TasksController } from "../../../api/src/tasks/tasks.controller";
import type { Task } from "../../../api/src/tasks/tasks.service";
import Loading from "../Loading";
import { Table, useTable } from "../components/Table/Table";
import {
  AssignedToFilter,
  CategoryFilter,
  multiSelectIncludes,
  PriorityFilter,
  priorityOptions,
} from "../components/Table/filters";
import { useQueryCrmUsers } from "../firm/lib";
import { useAuthenticatedFetch } from "../lib/api";

const TaskList = ({
  tasks,
  hideAssignee = false,
}: {
  tasks: Task[];
  hideAssignee?: boolean;
}) => {
  const {
    isPending: isPendingAssignees,
    isError: isErrorAssignees,
    data: dataAssignees,
  } = useQueryCrmUsers({ enabled: !hideAssignee });

  const {
    isPending: isPendingCategories,
    isError: isErrorCategories,
    data: dataCategories,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<TasksController["getCategories"]>>
  >("/tasks/categories");

  const tasksWithAssignedUsers = useMemo(
    () =>
      tasks.map((task) => ({
        ...task,
        assignedUser: dataAssignees?.data.find(
          (assignee) => assignee.id === task.assignedTo,
        ),
      })),
    [dataAssignees?.data, tasks],
  );

  const columnHelper = useMemo(() => createColumnHelper<Task>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => (
          <a
            href={
              info.row.original.service === "wealthbox"
                ? `${process.env.REACT_APP_WEALTHBOX_APP_ROOT}/tasks/${info.row.original.id}`
                : `${process.env.REACT_APP_REDTAIL_APP_ROOT}/activities/${info.row.original.id}`
            }
            rel="noreferrer"
            target="_blank"
          >
            {info.getValue()}
          </a>
        ),
        header: () => "Name",
      }),
      columnHelper.accessor("description", {
        cell: (info) => (
          <a
            href={`${process.env.REACT_APP_WEALTHBOX_APP_ROOT}/tasks/${info.row.original.id}`}
            rel="noreferrer"
            target="_blank"
          >
            {info.getValue()}
          </a>
        ),
        header: () => "Description",
      }),
      columnHelper.accessor(
        (row) => {
          const category = dataCategories?.data.find(
            (a) => a.id === row.category,
          );

          return category?.name ?? "";
        },
        {
          id: "category",
          cell: (info) =>
            isPendingCategories ? (
              <Loading />
            ) : isErrorCategories ? (
              <span className="text-danger">Error</span>
            ) : (
              info.getValue()
            ),
          header: () => "Category",
          filterFn: multiSelectIncludes,
          meta: {
            filterComponent: CategoryFilter,
          },
        },
      ),
      ...(hideAssignee
        ? []
        : [
            columnHelper.accessor(
              (row) => row.assignedUser?.id.toString() ?? "",
              {
                id: "assignedTo",
                cell: (info) =>
                  isPendingAssignees ? (
                    <Loading />
                  ) : isErrorAssignees ? (
                    <span className="text-danger">Error</span>
                  ) : (
                    (info.row.original.assignedUser?.name ?? "")
                  ),
                header: () => "Assignee",
                filterFn: multiSelectIncludes,
                meta: {
                  filterComponent: AssignedToFilter,
                },
              },
            ),
          ]),
      columnHelper.accessor(
        (row) => {
          const priority = priorityOptions.find(
            (a) => a.label === row.priority,
          );
          return priority?.value.toString() ?? "";
        },
        {
          id: "priority",
          cell: (info) => {
            const priority = priorityOptions.find(
              (a) => a.value.toString() === info.getValue(),
            );
            return priority?.label ?? "";
          },
          header: () => "Priority",
          filterFn: multiSelectIncludes,
          meta: {
            filterComponent: PriorityFilter,
          },
        },
      ),
      columnHelper.accessor("dueDate", {
        cell: (info) => info.getValue()?.toLocaleDateString() ?? "",
        header: () => "Due Date",
        enableColumnFilter: false,
      }),
    ],
    [
      dataCategories?.data,
      columnHelper,
      hideAssignee,
      isErrorAssignees,
      isErrorCategories,
      isPendingAssignees,
      isPendingCategories,
    ],
  );

  const { table } = useTable({
    columns,
    data: tasksWithAssignedUsers,
    getRowId: (row) => row.id.toString(),
    initialState: {
      sorting: [{ id: "dueDate", desc: false }],
    },
  });

  return <Table table={table} />;
};

export default TaskList;
