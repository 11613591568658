import { Col, Row } from "react-bootstrap";
import TabContainerWithTabs from "../components/TabContainer";
import InsightsList from "../insights/InsightsList";
import DashboardNav from "./DashboardNav";
import FirmSummaryTotals from "./FirmSummaryTotals";
import HistoricalAumAccountsChart from "./HistoricalAumAccountsChart";
import HistoricalTransactionsChart from "./HistoricalTransactionsChart";
import TopHouseholds from "./TopHouseholds";
import TopHoldings from "./TopHoldings";

const FirmSummary = () => {
  return (
    <TabContainerWithTabs tabs={DashboardNav}>
      <Row>
        <Col>
          <Row>
            <Col>
              <FirmSummaryTotals />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <h3>Insights</h3>
              <InsightsList />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <h3>Historical Trends</h3>
              <div className="mb-3">
                <HistoricalAumAccountsChart />
              </div>
              <HistoricalTransactionsChart />
            </Col>
          </Row>
          <Row>
            <Col xxl={6}>
              <h3>Top Households</h3>
              <TopHouseholds />
            </Col>
            <Col xxl={6}>
              <h3>Top Holdings</h3>
              <TopHoldings />
            </Col>
          </Row>
        </Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default FirmSummary;
