import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Header, createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type { FeeStructure } from "../../../api/src/billing/lib";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { useQueryAccounts } from "../clients/account/lib";
import { useQueryHouseholds } from "../clients/household/lib";
import ActionButton from "../components/ActionButton";
import IndeterminateCheckbox from "../components/IndeterminateCheckbox";
import MultiSelector from "../components/MultiSelector";
import TabContainerWithTabs from "../components/TabContainer";
import { Table, useTable } from "../components/Table/Table";
import { multiSelectIncludes } from "../components/Table/filters";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutation,
} from "../lib/api";
import BillingNav from "./BillingNav";

type FeeStructureRow = Omit<FeeStructure, "firmId"> & {
  assignedAccounts: number[];
};

const columnHelper = createColumnHelper<FeeStructureRow>();

export const frequencyFilterOptions = [
  { label: "Monthly", value: 1 },
  { label: "Quarterly", value: 2 },
  { label: "Annual", value: 3 },
];
const FrequencyFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const onChange = useCallback(
    (values: number[]) => {
      header.column.setFilterValue(values);
    },
    [header.column],
  );

  return (
    <MultiSelector
      options={frequencyFilterOptions}
      placeholder="All"
      setValue={onChange}
    />
  );
};

const FeeStructureTable = () => {
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.name, {
        id: "name",
        cell: (info) => (
          <Link
            to={`/billing/fee-structure/${info.row.original.feeStructureId}`}
          >
            {info.getValue()}
          </Link>
        ),
        header: () => "Name",
      }),
      columnHelper.accessor(
        (row): string => {
          const option = frequencyFilterOptions.find(
            (a) => a.label === row.frequency,
          );
          return option?.value.toString() ?? "";
        },
        {
          id: "frequency",
          header: () => "Frequency",
          cell: (info) => {
            const option = frequencyFilterOptions.find(
              (a) => a.value.toString() === info.getValue(),
            );
            return option?.label ?? "";
          },
          filterFn: multiSelectIncludes,
          meta: {
            filterComponent: FrequencyFilter,
          },
        },
      ),
      columnHelper.accessor((row) => row.calculationType, {
        id: "calculation",
        header: () => "Collection",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.collectionType, {
        id: "collection",
        header: () => "Structure",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.balanceType, {
        id: "valuation",
        header: () => "Valuation Method",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.assignedAccounts.length, {
        id: "assignedAccounts",
        cell: (info) => (
          <Link
            to={`/clients/accounts?${info.row.original.assignedAccounts
              .map((id) => `id=${id}`)
              .join("&")}`}
          >
            {info.getValue()}
          </Link>
        ),
        header: () => "Assigned Accounts",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
    ],
    [],
  );

  const { isPending: isPendingFeeStructures, data } = useAuthenticatedFetch<
    UnpackResponse<BillingController["getAllFeeStructures"]>
  >("/billing/fee-structures");

  const { isPending: isPendingAccounts, data: dataAccounts } = useQueryAccounts(
    { pageSize: 10000 },
  );
  const accounts = dataAccounts?.data;

  const { isPending: isPendingHouseholds, data: dataHouseholds } =
    useQueryHouseholds();
  const households = dataHouseholds?.data;

  const [feeStructures, setFeeStructures] = useState<FeeStructureRow[]>([]);

  useEffect(() => {
    if (!isPendingAccounts && !isPendingHouseholds) {
      const mapHouseholds = _.keyBy(households, "id");
      const mapAccountsFeeStructures = _.groupBy(
        accounts?.map((account) => ({
          id: account.id,
          feeStructureId:
            typeof account.feeStructureId !== "undefined"
              ? account.feeStructureId
              : typeof account.householdId !== "undefined"
                ? mapHouseholds[account.householdId].feeStructureId
                : undefined,
        })),
        "feeStructureId",
      );

      setFeeStructures(
        data?.data.map((feeStructure) => ({
          ...feeStructure,
          assignedAccounts:
            typeof feeStructure.feeStructureId !== "undefined"
              ? typeof mapAccountsFeeStructures[feeStructure.feeStructureId] ===
                "undefined"
                ? []
                : mapAccountsFeeStructures[feeStructure.feeStructureId].map(
                    (item) => item.id,
                  )
              : [],
        })) ?? [],
      );
    }
  }, [
    data?.data,
    setFeeStructures,
    accounts,
    households,
    isPendingAccounts,
    isPendingHouseholds,
  ]);

  const { table, rowSelection, setRowSelection } = useTable({
    columns,
    data: feeStructures,
  });

  const removeFeeStructures = useAuthenticatedMutation<
    UnpackResponse<BillingController["deleteFeeStructures"]>
  >(
    `/billing/fee-structures/delete`,
    {
      method: "POST",
      body: JSON.stringify(
        data?.data.reduce((result, feeStructure, idx) => {
          return Object.keys(rowSelection).includes(`${idx}`)
            ? [...result, feeStructure.feeStructureId]
            : result;
        }, [] as number[]),
      ),
    },
    processEmptyResponse,
  );

  const queryClient = useQueryClient();

  const del = useMutation({
    mutationFn: async () => {
      if (Object.keys(rowSelection).length === 0) return;
      try {
        await removeFeeStructures();
        queryClient.setQueryData(["/billing/fee-structures"], {
          data: data?.data.filter(
            (feeStructure, idx) =>
              !Object.keys(rowSelection).includes(`${idx}`),
          ),
        });
        setRowSelection({});
      } catch (err) {
        const message = "Failed to delete fee structure";
        console.error(message, err);
      }
    },
  });

  return (
    <TabContainerWithTabs tabs={BillingNav}>
      <Row>
        <Col className="d-flex justify-content-between mb-3">
          <ButtonToolbar className="gap-3">
            <ButtonGroup>
              <ActionButton
                as={Link}
                to="new"
                variant="secondary"
                label="Create"
                icon="/icons/new.svg"
              />
            </ButtonGroup>
            <ButtonGroup>
              <ActionButton
                variant="secondary"
                label="Delete"
                icon="/icons/trash.svg"
                onClick={() => del.mutate()}
                disabled={
                  del.isPending || Object.keys(rowSelection).length === 0
                }
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          {isPendingFeeStructures ||
          isPendingAccounts ||
          isPendingHouseholds ? (
            <Loading />
          ) : (
            <Table table={table} />
          )}
        </Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default FeeStructureTable;
