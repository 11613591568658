import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ModelsController } from "../../../api/src/models/models.controller";
import type { ExtendedBenchmark } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import ActionButton from "../components/ActionButton";
import HelpButton from "../components/HelpButton";
import TabContainerWithTabs from "../components/TabContainer";
import { Table, useTable } from "../components/Table/Table";
import {
  onColumnFiltersChange,
  onPaginationChange,
  onSortingChange,
  useTableSettings,
} from "../components/Table/tableSettings";
import { deserializeDate, useAuthenticatedFetch } from "../lib/api";
import { formatCurrency, formatPercent } from "../lib/numbers";
import { getRiskAllocationDisplay } from "../rebalances/lib";
import BenchmarkDeleteDialog from "./BenchmarkDeleteDialog";
import ModelsNav from "./ModelsNav";
import { ModelsHelp } from "./lib";

type BenchmarkRow = Required<ExtendedBenchmark>;

const columnHelper = createColumnHelper<BenchmarkRow>();

const Benchmarks = () => {
  const [showModal, setShowModal] = useState(false);
  const [deleteBenchmarkId, setDeleteBenchmarkId] = useState(0);
  const handleDeleteBenchmarkClick = useCallback(
    (deleteBenchmarkId: number) => {
      setDeleteBenchmarkId(deleteBenchmarkId);
      setShowModal(true);
    },
    [],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        id: "name",
        cell: (info) => (
          <Link to={info.row.original.id.toString()}>{info.getValue()}</Link>
        ),
        header: () => "Name",
        minSize: 275,
      }),
      columnHelper.accessor((row) => row.aum, {
        id: "aum",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "AUM",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.noOfAccounts, {
        id: "usageCount",
        header: () => "Number of Accounts",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.riskAllocations, {
        id: "riskAllocation",
        cell: (info) => getRiskAllocationDisplay(info.getValue()),
        header: () => "Allocation",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.expenseRatio, {
        id: "weightedExpenseRatio",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Weighted Expense Ratio",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.returns.oneYear, {
        id: "performanceOneYear",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Performance 1 Year",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.returns.threeYear, {
        id: "performanceThreeYear",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Performance 3 Year",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.returns.fiveYear, {
        id: "performanceFiveYear",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Performance 5 Year",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.updatedTime, {
        id: "updatedTime",
        cell: (info) => new Date(info.getValue()).toLocaleDateString(),
        header: () => "Last Modified",
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: "deleteBenchmark",
        cell: (info) => (
          <ActionButton
            onClick={() => {
              handleDeleteBenchmarkClick(info.row.original.id);
            }}
            variant="icon"
            icon="/icons/trash.svg"
            label="Delete Benchmark"
            type="button"
          />
        ),
        header: () => "",
        enableColumnFilter: false,
      }),
    ],
    [handleDeleteBenchmarkClick],
  );

  const { isPending: isPendingBenchmarks, data: dataBenchmarks } =
    useAuthenticatedFetch<UnpackResponse<ModelsController["getAllBenchmarks"]>>(
      "/models/benchmarks?metrics=true",
    );
  const benchmarks = useMemo(
    () =>
      dataBenchmarks?.data.map((benchmark) => {
        const result: ExtendedBenchmark = {
          ...benchmark,
          updatedTime: deserializeDate(benchmark.updatedTime),
        };

        return result as Required<ExtendedBenchmark>;
      }),
    [dataBenchmarks?.data],
  );

  const [tableSettings, setTableSettings] = useTableSettings("benchmarks");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    tableSettings.filters,
  );
  const [sorting, setSorting] = useState<SortingState>(tableSettings.sorting);
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: tableSettings.pageSize,
    pageIndex: 0,
  });

  const { table } = useTable({
    columns,
    data: benchmarks ?? [],
    getRowId: (row) => row.id.toString(),
    state: { columnFilters, sorting, pagination },
    onColumnFiltersChange: onColumnFiltersChange(
      columnFilters,
      setColumnFilters,
      tableSettings,
      setTableSettings,
    ),
    onSortingChange: onSortingChange(
      sorting,
      setSorting,
      tableSettings,
      setTableSettings,
    ),
    onPaginationChange: onPaginationChange(
      pagination,
      setPagination,
      tableSettings,
      setTableSettings,
    ),
  });

  return (
    <TabContainerWithTabs tabs={ModelsNav}>
      <Row>
        <Col className="d-flex justify-content-between">
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                as={Link}
                to="new"
                variant="secondary"
                label="New"
                icon="/icons/new.svg"
              />
            </ButtonGroup>
          </ButtonToolbar>
          <HelpButton
            title="Help – Models"
            body={<ModelsHelp />}
            buttonProps={{ className: "mb-3" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>{isPendingBenchmarks ? <Loading /> : <Table table={table} />}</Col>
        <BenchmarkDeleteDialog
          showModal={showModal}
          setShowModal={setShowModal}
          deleteBenchmarkId={deleteBenchmarkId}
          setDeleteBenchmarkId={setDeleteBenchmarkId}
          title="delete-benchmark"
        />
      </Row>
    </TabContainerWithTabs>
  );
};

export default Benchmarks;
