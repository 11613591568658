import { useParams } from "react-router-dom";
import type { UnpackResponse } from "../../../../api/src/lib";
import type { Transaction } from "../../../../api/src/transactions/lib";
import type { TransactionsController } from "../../../../api/src/transactions/transactions.controller";
import TransactionsTable, {
  deserializeTransaction,
} from "../../components/TransactionsTable";
import { useAuthenticatedQuery } from "../../lib/api";
import { processFileResponse } from "../../lib/file";

export function useQueryAccountTransactions(accountId: number) {
  return useAuthenticatedQuery<
    UnpackResponse<TransactionsController["getTransactionsForAccount"]>,
    Transaction[],
    [string, string, number]
  >({
    queryKey: ["transactions", "account", accountId],
    queryFn: async (transactions) =>
      (transactions?.data ?? []).map(deserializeTransaction),
  });
}

export function useQueryExportAccountTransactions(accountId: number) {
  return useAuthenticatedQuery<
    UnpackResponse<TransactionsController["exportAccountTransactions"]>,
    void,
    [string, string, number, string]
  >({
    queryKey: ["transactions", "account", accountId, "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}

const AccountTransactions = () => {
  const { accountId: accountIdStr } = useParams();
  const accountId = parseInt(accountIdStr ?? "");

  const {
    isPending: isPendingTransactions,
    isError: isErrorTransactions,
    data: transactions,
  } = useQueryAccountTransactions(accountId);

  const queryExport = useQueryExportAccountTransactions(accountId);

  return (
    <TransactionsTable
      isLoading={isPendingTransactions}
      isError={isErrorTransactions}
      transactions={transactions ?? []}
      queryExport={queryExport}
    />
  );
};

export default AccountTransactions;
