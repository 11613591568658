import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type { AssetExclusionForm } from "../../../api/src/billing/lib";
import type { UnpackResponse } from "../../../api/src/lib";
import type { SecuritiesController } from "../../../api/src/securities/securities.controller";
import type { Security } from "../../../api/src/securities/securities.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import ActionButton from "../components/ActionButton";
import Content from "../components/Content";
import FormError from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import SubmitButton from "../components/SubmitButton";
import SymbolField from "../components/SymbolField";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import { getSchemaFieldLabel, modelSecurityWeightSchema } from "../lib/forms";
import AssetExclusionInfoActionButtons from "./AssetExclusionInfoActionButtons";

const Scopes: ("Firm" | "Account")[] = ["Firm", "Account"];

const schema: yup.ObjectSchema<AssetExclusionForm> = yup.object({
  name: yup.string().required().label("Name"),
  weight: modelSecurityWeightSchema.required().label("Weight"),
  level: yup
    .string()
    .oneOf<"Firm" | "Account">(Scopes)
    .required()
    .label("Scope"),
  securities: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        symbol: yup.string().required().label("Symbol"),
        description: yup.string().label("Description"),
        cusip: yup.string().label("CUSIP"),
      }),
    )
    .required(),
});

const SecurityRow = ({
  security,
  onDelete,
}: {
  security: Partial<Security>;
  onDelete: (id: number) => void;
}) => {
  return (
    <tr>
      <td>{security.symbol}</td>
      <td>{security.description}</td>
      <td>{security.cusip}</td>
      <td className="text-end">
        <ActionButton
          variant="icon"
          icon="/icons/trash.svg"
          label="Remove"
          type="button"
          onClick={() => onDelete(security.id ?? -1)}
        />
      </td>
    </tr>
  );
};

const AssetExclusionInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    reset,
  } = useForm<AssetExclusionForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const watchSecuritiesArray = watch("securities", []);

  const { assetExclusionId } = useParams();
  const isNew = assetExclusionId === "new";

  const {
    isLoading,
    isError,
    data: dataAssetExclusion,
  } = useAuthenticatedFetch<
    UnpackResponse<BillingController["getAssetExclusion"]>
  >(`/billing/asset-exclusions/${assetExclusionId}`, undefined, {
    enabled: !isNew,
  });

  const createAssetExclusion = useAuthenticatedMutationAsync<
    UnpackResponse<BillingController["createAssetExclusion"]>
  >(
    "/billing/asset-exclusions",
    async (assetExclusion: AssetExclusionForm) => ({
      method: "POST",
      body: JSON.stringify(assetExclusion),
    }),
    processEmptyResponse,
  );

  const updateAssetExclusion = useAuthenticatedMutationAsync<
    UnpackResponse<BillingController["updateAssetExclusion"]>
  >(
    `/billing/asset-exclusions/${assetExclusionId}`,
    async (assetExclusion: AssetExclusionForm) => ({
      method: "PUT",
      body: JSON.stringify(assetExclusion),
    }),
    processEmptyResponse,
  );

  const navigate = useNavigate();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: AssetExclusionForm) => {
      try {
        if (isNew) {
          await createAssetExclusion(data);
          notificationContext.pushNotification({
            id: "asset-exclusion-",
            header: "Asset Exclusion Created",
            body: `Asset exclusion ${data.name} created`,
            variant: "success",
          });
          navigate("..");
        } else {
          await updateAssetExclusion(data);
          notificationContext.pushNotification({
            id: `asset-exclusion-${assetExclusionId}`,
            header: "Asset Exclusion Updated",
            body: `Asset exclusion ${data.name} updated`,
            variant: "success",
          });
        }
      } catch (err) {
        console.error("Failed to save asset exclusion", err);
        notificationContext.pushNotification({
          id: `asset-exclusion-${assetExclusionId}`,
          header: "Failed to Save Asset Exclusion",
          body: `Asset exclusion ${data.name} was not saved`,
          variant: "danger",
        });
      }
    },
  });

  useEffect(() => {
    if (typeof dataAssetExclusion !== "undefined") {
      reset({
        name: dataAssetExclusion.data.name,
        weight: dataAssetExclusion.data.weight,
        level: dataAssetExclusion.data.level,
        securities: dataAssetExclusion.data.securities ?? [],
      });
    }
  }, [dataAssetExclusion, reset]);

  const { data: dataCash, isPending: isPendingCash } = useAuthenticatedFetch<
    UnpackResponse<SecuritiesController["getTickers"]>
  >(`/securities/tickers?name=CASH`);

  const handleSelectSecurity = useCallback(
    (suggestion: Partial<Security>) => {
      if (
        typeof watchSecuritiesArray === "undefined" ||
        !watchSecuritiesArray.some((security) => security.id === suggestion.id)
      )
        setValue("securities", [...(watchSecuritiesArray ?? []), suggestion]);
    },
    [watchSecuritiesArray, setValue],
  );

  const handleClickDelete = useCallback(
    (id: number) => {
      if (id !== -1 && typeof watchSecuritiesArray !== "undefined") {
        setValue(
          "securities",
          watchSecuritiesArray.filter((security) => security.id !== id),
        );
      }
    },
    [watchSecuritiesArray, setValue],
  );

  const handleClickAddCash = useCallback(() => {
    setValue("securities", [
      ...(watchSecuritiesArray ?? []),
      ...(dataCash?.data ?? []).filter(
        (cash) =>
          !(watchSecuritiesArray ?? []).some(
            (security) => security.id === cash.id,
          ),
      ),
    ]);
  }, [setValue, watchSecuritiesArray, dataCash?.data]);

  const handleClickRemoveCash = useCallback(() => {
    setValue("securities", [
      ...(watchSecuritiesArray ?? []).filter(
        (security) => !dataCash?.data.some((cash) => cash.id === security.id),
      ),
    ]);
  }, [setValue, watchSecuritiesArray, dataCash?.data]);

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <FormError message="An error occurred" />
  ) : !dataAssetExclusion && !isNew ? (
    <FormError message="Asset Exclusion not found" />
  ) : (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      <Row>
        <Col>
          <h1>Asset Exclusion Details</h1>
        </Col>
        <Col md="auto">
          <AssetExclusionInfoActionButtons />
        </Col>
      </Row>
      <Content>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-name">
              <Form.Label>{getSchemaFieldLabel(schema.fields.name)}</Form.Label>
              <Form.Control
                type="text"
                {...register("name")}
                placeholder={`e.g. Standard Fee`}
              />
              <FormFieldError field={errors.name} />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-level">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.level)}
              </Form.Label>
              <Form.Select {...register("level")} placeholder="Select a scope">
                {Scopes.map((scope, idx) => (
                  <option key={idx} value={scope}>
                    {scope}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="mt-0 mb-0" muted>
                Firm-level scope means the adjustment will be applied for all
                accounts, both those that exist today and all future opened
                accounts. The account-level scope allows you limit the
                application of the adjustment to a subset of accounts.
              </Form.Text>
              <FormFieldError field={errors.level} />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-weight">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.weight)}
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  {...register("weight")}
                  min={0}
                  defaultValue={100}
                  max={100}
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
              <FormFieldError field={errors.weight} />
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Row className="mb-3 d-flex">
              <Form.Label>Add Securities</Form.Label>
              <Col md={6}>
                <SymbolField onSelect={handleSelectSecurity} />
              </Col>
              <Col md={6} className="d-flex justify-content-end">
                {!(watchSecuritiesArray ?? []).some((security) =>
                  security.symbol?.includes("CASH"),
                ) ? (
                  <Button
                    variant="secondary"
                    onClick={handleClickAddCash}
                    disabled={
                      isPendingCash ||
                      typeof dataCash?.data === "undefined" ||
                      dataCash.data.length <= 0
                    }
                  >
                    Add Cash (USD)
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    onClick={handleClickRemoveCash}
                    disabled={
                      isPendingCash ||
                      typeof dataCash?.data === "undefined" ||
                      dataCash.data.length <= 0
                    }
                  >
                    Remove Cash (USD)
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>Symbol</th>
                      <th>Description</th>
                      <th>CUSIP</th>
                      <th>{/* Empty */}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {typeof watchSecuritiesArray !== "undefined"
                      ? watchSecuritiesArray.map((security, idx) => (
                          <SecurityRow
                            key={idx}
                            security={security}
                            onDelete={handleClickDelete}
                          />
                        ))
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} />
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

export default AssetExclusionInfo;
