import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ModelsController } from "../../../api/src/models/models.controller";
import type { SleeveRequest } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import Content from "../components/Content";
import FormError, { useErrorMessage } from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import HelpTooltip from "../components/HelpTooltip";
import SubmitButton from "../components/SubmitButton";
import {
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import {
  getSchemaFieldLabel,
  modelAssetCategorySchema,
  modelRegionSchema,
  primarySecuritiesSchema,
  secondarySecuritiesSchema,
} from "../lib/forms";
import ModelInfoActionButtons from "./ModelInfoActionButtons";
import ModelPerformance from "./ModelPerformance";
import PrimarySecurities from "./PrimarySecurities";
import SecondarySecurities from "./SecondarySecurities";
import SleeveDeleteDialog from "./SleeveDeleteDialog";
import {
  primarySecurityHelpMessage,
  secondarySecurityHelpMessage,
  sumModelWeights,
} from "./lib";

type SleeveForm = Omit<SleeveRequest, "id">;

const schema: yup.ObjectSchema<SleeveForm> = yup
  .object({
    name: yup.string().required().label("Sleeve Name"),
    description: yup.string().label("Description"),
    assetCategory: modelAssetCategorySchema.required().label("Asset Category"),
    region: modelRegionSchema.required().label("Region"),
    primarySecurities: primarySecuritiesSchema.required(),
    secondarySecurities: secondarySecuritiesSchema.required(),
  })
  .test({
    name: "weights-sum-100",
    test: (value) => sumModelWeights(value.primarySecurities) === 100,
    message: "The sum of all weights must equal 100%",
  });

const SleeveInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValidating },
    reset,
    control,
    watch,
    getValues,
  } = useForm<SleeveForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    fields: primarySecurityFields,
    append: appendPrimarySecurity,
    remove: removePrimarySecurity,
    replace: replacePrimarySecurities,
  } = useFieldArray({
    name: "primarySecurities",
    control,
    keyName: "_field_id",
  });
  const {
    fields: secondarySecurityFields,
    append: appendSecondarySecurity,
    remove: removeSecondarySecurity,
    replace: replaceSecondarySecurities,
  } = useFieldArray({
    name: "secondarySecurities",
    control,
    keyName: "_field_id",
  });

  const { setError, resetError, errorMessage } = useErrorMessage();

  const { sleeveId } = useParams();
  const isNew = sleeveId === "new";

  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    data: dataSleeve,
  } = useAuthenticatedFetch<UnpackResponse<ModelsController["getSleeve"]>>(
    `/models/sleeves/${sleeveId}`,
    undefined,
    {
      enabled: !isNew,
    },
  );

  useEffect(() => {
    if (typeof dataSleeve !== "undefined") {
      const { primarySecurities, secondarySecurities, ...sleeve } =
        dataSleeve.data;
      reset(sleeve);
      replacePrimarySecurities(primarySecurities);
      replaceSecondarySecurities(secondarySecurities);
    }
  }, [dataSleeve, reset, replacePrimarySecurities, replaceSecondarySecurities]);

  const createSleeve = useAuthenticatedMutationAsync<
    UnpackResponse<ModelsController["createSleeve"]>
  >(`/models/sleeves/${isNew ? "" : sleeveId}`, async (sleeve: SleeveForm) => ({
    method: "POST",
    body: JSON.stringify({
      ...sleeve,
      primarySecurities: sleeve.primarySecurities,
      // Filter out empty secondary security row
      secondarySecurities: sleeve.secondarySecurities.filter(
        (security) => security.symbol !== "" && security.secondaryTo !== "",
      ),
    }),
  }));

  const updateSleeve = useAuthenticatedMutationAsync<
    UnpackResponse<ModelsController["updateSleeve"]>
  >(`/models/sleeves/${isNew ? "" : sleeveId}`, async (sleeve: SleeveForm) => ({
    method: "PUT",
    body: JSON.stringify({
      ...sleeve,
      primarySecurities: sleeve.primarySecurities,
      // Filter out empty secondary security row
      secondarySecurities: sleeve.secondarySecurities.filter(
        (security) => security.symbol !== "" && security.secondaryTo !== "",
      ),
    }),
  }));

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: SleeveForm) => {
      try {
        if (isNew) {
          const body = await createSleeve(data);
          notificationContext.pushNotification({
            id: `sleeve-${body.data}`,
            header: "Sleeve Created",
            body: `${data.name} sleeve created`,
            variant: "success",
          });
          navigate(`../${body.data}`);
        } else {
          const sleeveBody = await updateSleeve(data);
          reset(sleeveBody.data);
          notificationContext.pushNotification({
            id: `sleeve-${sleeveId}`,
            header: "Sleeve Updated",
            body: `${data.name} sleeve updated`,
            variant: "success",
          });
        }
      } catch (err) {
        console.error("Failed to save sleeve", err);
        notificationContext.pushNotification({
          id: `sleeve-${sleeveId}`,
          header: "Failed to Save Sleeve",
          body: `Sleeve ${data.name} was not saved`,
          variant: "danger",
        });
      }
    },
  });

  const watchPrimarySecurityArray = watch("primarySecurities");
  const controlledPrimarySecurityFields = primarySecurityFields.map(
    (field, index) => ({
      ...field,
      ...watchPrimarySecurityArray[index],
    }),
  );

  const watchSecondarySecurityArray = watch("secondarySecurities", []);
  const controlledSecondarySecurityFields = secondarySecurityFields.map(
    (field, index) => ({
      ...field,
      ...watchSecondarySecurityArray[index],
    }),
  );

  // react-hook-form does not have form-level validation,
  // so we must perform it manually.
  useEffect(() => {
    if (isValidating) {
      const sum = sumModelWeights(watchPrimarySecurityArray);

      if (sum === 100) {
        resetError();
      } else {
        setError("The sum of all weights must equal 100");
      }
    }
  }, [watchPrimarySecurityArray, setError, resetError, isValidating]);

  const [showModal, setShowModal] = useState(false);
  const [deleteSleeveId, setDeleteSleeveId] = useState(0);
  const handleDeleteSleeveClick = useCallback(() => {
    setDeleteSleeveId(sleeveId ? Number(sleeveId) : 0);
    setShowModal(true);
  }, [sleeveId]);

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <FormError message="An error occurred" />
  ) : !dataSleeve && !isNew ? (
    <FormError message="Sleeve not found" />
  ) : (
    <>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
            <Row>
              <Col>
                <h1>Sleeve Details</h1>
              </Col>
              <Col md="auto">
                <ModelInfoActionButtons
                  deleteHandler={handleDeleteSleeveClick}
                />
              </Col>
            </Row>
            <Content>
              {errorMessage}
              <Row>
                <Col xxl={10} md={8}>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="form-name">
                        <Form.Label>
                          {getSchemaFieldLabel(schema.fields.name)}
                        </Form.Label>
                        <Form.Control type="text" {...register("name")} />
                        <FormFieldError field={errors.name} />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="form-description">
                        <Form.Label>
                          {getSchemaFieldLabel(schema.fields.description)}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          {...register("description")}
                        />
                        <FormFieldError field={errors.description} />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="form-assetCategory">
                        <Form.Label>
                          {getSchemaFieldLabel(schema.fields.assetCategory)}
                        </Form.Label>
                        <Form.Select
                          placeholder="Select an asset category"
                          {...register("assetCategory")}
                        >
                          <option value="stock">Stock</option>
                          <option value="bond">Bond</option>
                          <option value="other">Other</option>
                          <option value="cash">Cash</option>
                        </Form.Select>
                        <FormFieldError field={errors.assetCategory} />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="formRegion">
                        <Form.Label>
                          {getSchemaFieldLabel(schema.fields.region)}
                        </Form.Label>
                        <Form.Select
                          placeholder="Select a region"
                          {...register("region")}
                        >
                          <option value="US">US</option>
                          <option value="global">Global</option>
                        </Form.Select>
                        <FormFieldError field={errors.region} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex">
                    <h2 className="me-1">Primary Securities</h2>
                    <HelpTooltip tooltip={primarySecurityHelpMessage} />
                  </div>
                  <PrimarySecurities
                    fields={controlledPrimarySecurityFields}
                    replace={replacePrimarySecurities}
                    add={appendPrimarySecurity}
                    remove={removePrimarySecurity}
                    register={register}
                    control={control}
                    getValues={getValues}
                    errors={errors.primarySecurities}
                  />
                  <div className="d-flex">
                    <h2 className="me-1">Secondary Securities</h2>
                    <HelpTooltip tooltip={secondarySecurityHelpMessage} />
                  </div>
                  <SecondarySecurities
                    fields={controlledSecondarySecurityFields}
                    replace={replaceSecondarySecurities}
                    add={appendSecondarySecurity}
                    remove={removeSecondarySecurity}
                    register={register}
                    control={control}
                    getValues={getValues}
                    errors={errors.secondarySecurities}
                    primarySecurities={controlledPrimarySecurityFields}
                  />
                  <Row>
                    <Col>
                      <SubmitButton isSubmitting={isSubmitting} />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <ModelPerformance model={getValues()} isLoading={isLoading} />
                </Col>
                <SleeveDeleteDialog
                  showModal={showModal}
                  setShowModal={setShowModal}
                  deleteSleeveId={deleteSleeveId ? Number(deleteSleeveId) : 0}
                  setDeleteSleeveId={setDeleteSleeveId}
                  title="delete-sleeve"
                />
              </Row>
            </Content>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default SleeveInfo;
