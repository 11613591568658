import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type { BillingMinimumForm } from "../../../api/src/billing/lib";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import Content from "../components/Content";
import FormError from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import SubmitButton from "../components/SubmitButton";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import { getSchemaFieldLabel, requiredNumberSchema } from "../lib/forms";
import BillingMinimumInfoActionButtons from "./BillingMinimumInfoActionButton";

const schema: yup.ObjectSchema<BillingMinimumForm> = yup
  .object({
    name: yup.string().required().label("Name"),
    value: requiredNumberSchema.min(0).label("Value"),
    valueType: yup
      .string()
      .oneOf<"P" | "F">(["P", "F"])
      .required()
      .label("Value Type"),
  })
  .transform((value) =>
    value.name === ""
      ? {
          ...value,
          name: `${
            value.valueType === "F" ? `$${value.value}` : `${value.value}%`
          } per year`,
        }
      : value,
  );

const BillingMinimumInfo = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<BillingMinimumForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { billingMinimumId } = useParams();
  const isNew = billingMinimumId === "new";

  const {
    isLoading,
    isError,
    data: billingMinimumBody,
  } = useAuthenticatedFetch<
    UnpackResponse<BillingController["getBillingMinimum"]>
  >(`/billing/billing-minimums/${billingMinimumId}`, undefined, {
    enabled: !isNew,
  });

  const createBillingMinimum = useAuthenticatedMutationAsync<
    UnpackResponse<BillingController["createBillingMinimum"]>
  >(
    "/billing/billing-minimums",
    async (billingMinimum: BillingMinimumForm) => ({
      method: "POST",
      body: JSON.stringify(billingMinimum),
    }),
    processEmptyResponse,
  );

  const updateBillingMinimum = useAuthenticatedMutationAsync<
    UnpackResponse<BillingController["updateBillingMinimum"]>
  >(
    `/billing/billing-minimums/${billingMinimumId}`,
    async (billingMinimum: BillingMinimumForm) => ({
      method: "PUT",
      body: JSON.stringify(billingMinimum),
    }),
    processEmptyResponse,
  );

  const navigate = useNavigate();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: BillingMinimumForm) => {
      try {
        if (isNew) {
          await createBillingMinimum(data);
          notificationContext.pushNotification({
            id: `billing-minimum-${billingMinimumId}`,
            header: "Billing Minimum Created",
            body: `Billing minimum ${data.name} created`,
            variant: "success",
          });
          navigate("..");
        } else {
          await updateBillingMinimum(data);
          notificationContext.pushNotification({
            id: `billing-minimum-${billingMinimumId}`,
            header: "Billing Minimum Updated",
            body: `Billing minimum ${data.name} updated`,
            variant: "success",
          });
        }
      } catch (err) {
        console.error("Failed to save billing minimum", err);
        notificationContext.pushNotification({
          id: `billing-minimum-${billingMinimumId}`,
          header: "Failed to Save Billing Minimum",
          body: `Billing minimum ${data.name} was not saved`,
          variant: "danger",
        });
      }
    },
  });

  useEffect(() => {
    if (typeof billingMinimumBody !== "undefined") {
      reset({
        name: billingMinimumBody.data.name,
        value: billingMinimumBody.data.value,
        valueType: billingMinimumBody.data.valueType,
      });
    }
  }, [billingMinimumBody, reset]);

  const valueType = useWatch({
    control,
    name: "valueType",
    defaultValue: "F",
  });

  const value = useWatch({
    control,
    name: "value",
    defaultValue: 0,
  });

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <FormError message="An error occurred" />
  ) : !billingMinimumBody && !isNew ? (
    <FormError message="Billing Minimum not found" />
  ) : (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      <Row>
        <Col>
          <h1>Billing Minimum Details</h1>
        </Col>
        <Col md="auto">
          <BillingMinimumInfoActionButtons />
        </Col>
      </Row>
      <Content>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-value-type">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.valueType)}
              </Form.Label>
              <Form.Select
                {...register("valueType")}
                placeholder="Select a value type"
              >
                <option value="F">Flat dollar amount</option>
                <option value="P">Percentage of AUM</option>
              </Form.Select>
              <FormFieldError field={errors.valueType} />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-percentage">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.value)}
              </Form.Label>
              <InputGroup>
                {valueType !== "F" ? null : (
                  <InputGroup.Text>$</InputGroup.Text>
                )}
                <Form.Control
                  type="number"
                  {...register("value")}
                  step={0.01}
                  min={0}
                />
                {valueType !== "P" ? null : (
                  <InputGroup.Text>%</InputGroup.Text>
                )}
              </InputGroup>
              <Form.Text muted>
                Annual minimum value to be billed on each account assigned to
                it.
              </Form.Text>
              <FormFieldError field={errors.value} />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-name">
              <Form.Label>{getSchemaFieldLabel(schema.fields.name)}</Form.Label>
              <Form.Control
                type="text"
                {...register("name")}
                placeholder={`e.g. ${
                  valueType === "F" ? `$${value}` : `${value}%`
                } per year`}
              />
              <Form.Text muted>
                Optional name of this minimum for easy reference. If unspecified
                a name will be automatically created based on the value and
                type.
              </Form.Text>
              <FormFieldError field={errors.name} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} />
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

export default BillingMinimumInfo;
