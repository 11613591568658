import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ModelsController } from "../../../api/src/models/models.controller";
import SubmitButton from "../components/SubmitButton";
import {
  processEmptyResponse,
  useAuthenticatedMutationAsync,
} from "../lib/api";

const BenchmarkDeleteDialog = ({
  showModal,
  setShowModal,
  deleteBenchmarkId,
  setDeleteBenchmarkId,
  title,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteBenchmarkId: number;
  setDeleteBenchmarkId: React.Dispatch<React.SetStateAction<number>>;
  title: string;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Delete action
  const handleModalClose = useCallback(() => {
    setDeleteBenchmarkId(0);
    setShowModal(false);
  }, [setDeleteBenchmarkId, setShowModal]);

  const deleteBenchmark = useAuthenticatedMutationAsync<
    UnpackResponse<ModelsController["deleteBenchmark"]>
  >(
    `/models/Benchmarks/${deleteBenchmarkId}`,
    async () => ({
      method: "DELETE",
    }),
    processEmptyResponse,
  );

  const handleDeleteBenchmark = useMutation({
    mutationFn: () => deleteBenchmark(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["/models/benchmarks?metrics=true"],
      });
      setShowModal(false);
      navigate("/models/benchmarks");
    },
  });

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Delete Benchmark</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="m-2">
          This benchmark will be un-attached from all models using it. Are you
          sure you would like to delete this benchmark?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Discard
        </Button>
        <SubmitButton
          variant="danger"
          label="Delete"
          onClick={() => handleDeleteBenchmark.mutate()}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default BenchmarkDeleteDialog;
