declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blob: Blob) => void;
  }
}

function getFileNameFromContentDispostionHeader(header?: string | null) {
  let fileName = "downloaded.pdf";

  if (typeof header === "undefined" || header === null) {
    return fileName;
  }

  const contentDispostion = header.split(";");
  const fileNameToken = "filename=";

  for (const thisValue of contentDispostion) {
    if (thisValue.trim().indexOf(fileNameToken) === 0) {
      fileName = decodeURIComponent(
        thisValue.trim().replace(fileNameToken, ""),
      );
      break;
    }
  }

  return fileName;
}

export async function processFileResponse(response: Response) {
  try {
    const resObj = {
      filename: getFileNameFromContentDispostionHeader(
        response.headers.get("content-disposition"),
      ),
      blob: await response.blob(),
    };

    // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
    const newBlob = new Blob([resObj.blob], {
      type: response.headers.get("content-type") ?? "application/pdf",
    });

    // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
    } else {
      // For other browsers: create a link pointing to the ObjectURL containing the blob.
      const objUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement("a");
      link.href = objUrl;
      link.download = resObj.filename;
      link.click();

      // For Firefox it is necessary to delay revoking the ObjectURL.
      setTimeout(() => {
        window.URL.revokeObjectURL(objUrl);
      }, 250);
    }
  } catch (err) {
    console.error("DOWNLOAD ERROR", err);
  }
}
