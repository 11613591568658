import { useContext, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import type { Transaction } from "../../../../api/src/transactions/lib";
import type { TransactionsController } from "../../../../api/src/transactions/transactions.controller";
import { useAuthenticatedFetch } from "../../lib/api";
import { formatCurrency } from "../../lib/numbers";
import AccountHoldingsTable from "./AccountHoldingsTable";
import { AccountContext } from "./AccountInfo";
import AccountPerformance from "./AccountPerformance";
import AccountTaxSummary from "./AccountTaxSummary";

const AccountPortfolio = () => {
  const { account } = useContext(AccountContext);

  const { data: dataModelSummary } = useAuthenticatedFetch<
    UnpackResponse<HouseholdsController["getHouseholdModelSummary"]>
  >(`/accounts/${account?.id}/model-summary`);

  const { data: dataTransactions } = useAuthenticatedFetch<
    UnpackResponse<TransactionsController["getTransactionsForHousehold"]>
  >(`/transactions/account/${account?.id}`);

  const transactions: Transaction[] = useMemo(
    () =>
      (dataTransactions?.data ?? []).filter(
        (transaction) =>
          transaction.type === "Income" || transaction.type === "Expense",
      ),
    [dataTransactions?.data],
  );

  return typeof account === "undefined" ? null : (
    <>
      <Row>
        <Col xxl={4} xl={6} className="mb-3">
          <dl>
            <Row>
              <Col as="dt" xl={2} sm={4}>
                AUM
              </Col>
              <Col as="dd">
                {formatCurrency(account?.accountBalance ?? 0, 2)}
              </Col>
            </Row>
            <Row>
              <Col as="dt" xl={2} sm={4}>
                Cash
              </Col>
              <Col as="dd">{formatCurrency(account?.cashBalance ?? 0, 2)}</Col>
            </Row>
          </dl>
        </Col>
        <Col xl={4} className="mb-3">
          <AccountPerformance />
        </Col>
        {!account.isTaxable ? null : (
          <Col xl={4} className="mb-3">
            <AccountTaxSummary />
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12}>
          <AccountHoldingsTable
            holdings={account?.holdings ?? []}
            modelSummaries={dataModelSummary?.data ?? []}
            transactions={transactions}
          />
        </Col>
      </Row>
    </>
  );
};

export default AccountPortfolio;
