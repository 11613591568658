import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type { BillingSplitForm } from "../../../api/src/billing/lib";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import Content from "../components/Content";
import FormError from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import SubmitButton from "../components/SubmitButton";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import { getSchemaFieldLabel, requiredNumberSchema } from "../lib/forms";
import BillingSplitsInfoActionButtons from "./BillingSplitsInfoActionButton";

const schema: yup.ObjectSchema<BillingSplitForm> = yup.object({
  name: yup.string().required().label("Name"),
  splitterName: yup.string().required().label("Splitter"),
  percentage: requiredNumberSchema.default(0).min(0).label("Percentage"),
});

const BillingSplitsInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<BillingSplitForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { billingSplitId } = useParams();
  const isNew = billingSplitId === "new";

  const {
    isLoading,
    isError,
    data: dataBillingSplit,
  } = useAuthenticatedFetch<
    UnpackResponse<BillingController["getBillingSplit"]>
  >(`/billing/billing-splits/${billingSplitId}`, undefined, {
    enabled: !isNew,
  });

  const createBillingSplit = useAuthenticatedMutationAsync<
    UnpackResponse<BillingController["createBillingSplit"]>
  >(
    "/billing/billing-splits",
    async (billingSplit: BillingSplitForm) => ({
      method: "POST",
      body: JSON.stringify(billingSplit),
    }),
    processEmptyResponse,
  );

  const updateBillingSplit = useAuthenticatedMutationAsync<
    UnpackResponse<BillingController["updateBillingSplit"]>
  >(
    `/billing/billing-splits/${billingSplitId}`,
    async (billingSplit: BillingSplitForm) => ({
      method: "PUT",
      body: JSON.stringify(billingSplit),
    }),
    processEmptyResponse,
  );

  const navigate = useNavigate();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: BillingSplitForm) => {
      try {
        if (isNew) {
          await createBillingSplit(data);
          notificationContext.pushNotification({
            id: `billing-split-${billingSplitId}`,
            header: "Billing Split Created",
            body: `Billing split ${data.name} created`,
            variant: "success",
          });
          navigate("..");
        } else {
          await updateBillingSplit(data);
          notificationContext.pushNotification({
            id: `billing-split-${billingSplitId}`,
            header: "Billing Split Updated",
            body: `Billing split ${data.name} updated`,
            variant: "success",
          });
        }
      } catch (err) {
        console.error("Failed to save billing split", err);
        notificationContext.pushNotification({
          id: `billing-split-${billingSplitId}`,
          header: "Failed to Save Billing Split",
          body: `Billing split ${data.name} was not saved`,
          variant: "success",
        });
      }
    },
  });

  useEffect(() => {
    if (typeof dataBillingSplit !== "undefined") {
      reset({
        name: dataBillingSplit.data.name,
        percentage: dataBillingSplit.data.percentage,
        splitterName: dataBillingSplit.data.splitterName,
      });
    }
  }, [dataBillingSplit, reset]);

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <FormError message="An error occurred" />
  ) : !dataBillingSplit && !isNew ? (
    <FormError message="Billing Split not found" />
  ) : (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      <Row>
        <Col>
          <h1>Fee Splits Details</h1>
        </Col>
        <Col md="auto">
          <BillingSplitsInfoActionButtons />
        </Col>
      </Row>
      <Content>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-splitter-name">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.splitterName)}
              </Form.Label>
              <Form.Control
                type="text"
                {...register("splitterName")}
                placeholder="e.g. Jane Smith"
              />
              <FormFieldError field={errors.splitterName} />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-percentage">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.percentage)}
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  {...register("percentage")}
                  step={0.01}
                  min={0}
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
              <Form.Text className="mt-0 mb-0" muted>
                The percentage of fees collected that should be directed to the
                splitter.
              </Form.Text>
              <FormFieldError field={errors.percentage} />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group controlId="form-name">
              <Form.Label>{getSchemaFieldLabel(schema.fields.name)}</Form.Label>
              <Form.Control
                type="text"
                {...register("name")}
                placeholder="e.g. Jane Smith: 10%"
              />
              <Form.Text className="mt-0 mb-0" muted>
                <b>Optional. </b>An automatically generated (or your own) tag
                that refers to this split.
              </Form.Text>
              <FormFieldError field={errors.name} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} />
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

export default BillingSplitsInfo;
