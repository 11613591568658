import type { Event } from "../../../api/src/events/events.service";
import type { SerializedObject } from "../../../api/src/lib";
import type { Task, Workflow } from "../../../api/src/tasks/tasks.service";
import { deserializeDate } from "../lib/api";

export function deserializeTask(task: SerializedObject<Task>): Task {
  return {
    ...task,
    dueDate: deserializeDate(task.dueDate),
    createdAt: deserializeDate(task.createdAt),
    updatedAt: deserializeDate(task.updatedAt),
  };
}

export function deserializeWorkflow(
  workflow: SerializedObject<Workflow>,
): Workflow {
  return {
    ...workflow,
    startedAt: deserializeDate(workflow.startedAt),
    startsAt: deserializeDate(workflow.startsAt),
    createdAt: deserializeDate(workflow.createdAt),
    updatedAt: deserializeDate(workflow.updatedAt),
  };
}

export function deserializeEvent(event: SerializedObject<Event>): Event {
  return {
    ...event,
    starts: deserializeDate(event.starts),
    ends: deserializeDate(event.ends),
    created: deserializeDate(event.created),
    updated: deserializeDate(event.updated),
  };
}
