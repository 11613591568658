import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type { BillingSplit } from "../../../api/src/billing/lib";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import ActionButton from "../components/ActionButton";
import IndeterminateCheckbox from "../components/IndeterminateCheckbox";
import TabContainerWithTabs from "../components/TabContainer";
import { Table, useTable } from "../components/Table/Table";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutation,
} from "../lib/api";
import { formatPercent } from "../lib/numbers";
import BillingNav from "./BillingNav";

type BillingSplitsRow = BillingSplit;

const columnHelper = createColumnHelper<BillingSplitsRow>();

const columns = [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor((row) => row.name, {
    id: "name",
    cell: (info) => (
      <Link to={`/billing/billing-splits/${info.row.original.id}`}>
        {info.getValue()}
      </Link>
    ),
    header: () => "Name",
  }),
  columnHelper.accessor((row) => row.splitterName, {
    id: "splitter",
    header: () => "Splitter",
  }),
  columnHelper.accessor((row) => row.percentage, {
    id: "percentage",
    cell: (info) => formatPercent(info.getValue() / 100, 4),
    header: () => "Split Percentage",
    enableColumnFilter: false,
    meta: {
      className: "text-end",
      headerClassName: "text-end",
    },
  }),
];

const BillingSplitsTable = () => {
  const { isPending, data } = useAuthenticatedFetch<
    UnpackResponse<BillingController["getAllBillingSplits"]>
  >("/billing/billing-splits");

  const { table, rowSelection, setRowSelection } = useTable({
    columns,
    data: data?.data ?? [],
  });

  const removeBillingSplits = useAuthenticatedMutation<
    UnpackResponse<BillingController["deleteBulkBillingSplits"]>
  >(
    `/billing/billing-splits/delete-many`,
    {
      method: "POST",
      body: JSON.stringify(
        data?.data.reduce((result, billingSplit, idx) => {
          return Object.keys(rowSelection).includes(`${idx}`)
            ? [...result, billingSplit.id]
            : result;
        }, [] as number[]),
      ),
    },
    processEmptyResponse,
  );

  const queryClient = useQueryClient();

  const del = useMutation({
    mutationFn: async () => {
      if (Object.keys(rowSelection).length === 0) return;
      try {
        await removeBillingSplits();
        queryClient.setQueryData(["/billing/billing-splits"], {
          data: data?.data.filter(
            (billingSplit, idx) =>
              !Object.keys(rowSelection).includes(`${idx}`),
          ),
        });
        setRowSelection({});
      } catch (err) {
        const message = "Failed to delete billing splits";
        console.error(message, err);
      }
    },
  });

  return (
    <TabContainerWithTabs tabs={BillingNav}>
      <Row>
        <Col className="d-flex justify-content-between mb-3">
          <ButtonToolbar className="gap-3">
            <ButtonGroup>
              <ActionButton
                as={Link}
                to="new"
                variant="secondary"
                label="Create"
                icon="/icons/new.svg"
              />
            </ButtonGroup>
            <ButtonGroup>
              <ActionButton
                variant="secondary"
                label="Delete"
                icon="/icons/trash.svg"
                onClick={() => del.mutate()}
                disabled={
                  del.isPending || Object.keys(rowSelection).length === 0
                }
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>{isPending ? <Loading /> : <Table table={table} />}</Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default BillingSplitsTable;
