import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const CashNav = () => (
  <nav>
    <Nav variant="tabs" defaultActiveKey="/cash/settings">
      <Nav.Item>
        <NavLink to="/cash/accounts" className="nav-link">
          Accounts
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/cash/settings" className="nav-link">
          Cash Settings
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/cash/predictions" className="nav-link">
          Recurring Cash Predictions
        </NavLink>
      </Nav.Item>
    </Nav>
  </nav>
);

export default CashNav;
