import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import type { AccountWithMetrics } from "../../../api/src/accounts/accounts.service";
import type { AccountCashSetting } from "../../../api/src/cash-settings/lib";
import type { Household } from "../../../api/src/households-base/households-base.service";
import { Table, useTable } from "../components/Table/Table";
import { displayAccountName } from "../lib/display";
import { formatCurrency, formatPercent } from "../lib/numbers";
import {
  displayCashTarget,
  displayMinMaxCashTarget,
  getCashTargetPercentage,
} from "./lib";

export type AccountCashSettingRow = AccountWithMetrics &
  Partial<AccountCashSetting> & {
    household: Household | null;
    cashSettingName?: string;
  };

const columnHelper = createColumnHelper<AccountCashSettingRow>();

const AccountCashSettingsTable = ({
  accountCashSettings,
}: {
  accountCashSettings: AccountCashSettingRow[];
}) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) => displayAccountName(row.displayName, row.displayNumber),
        {
          id: "name",
          cell: (info) => (
            <Link to={`/clients/accounts/${info.row.original.id}`}>
              {info.getValue()}
            </Link>
          ),
          header: () => "Account",
          minSize: 225,
        },
      ),
      columnHelper.accessor((row) => row.household?.name ?? "", {
        id: "household",
        cell: (info) => {
          const householdId = info.row.original.householdId;

          return typeof householdId === "undefined" || householdId === null ? (
            ""
          ) : (
            <Link to={`/clients/households/${householdId}`}>
              {info.getValue() ?? "<no name>"}
            </Link>
          );
        },
        header: () => "Household",
        minSize: 225,
      }),
      columnHelper.accessor((row) => row.cashSettingName ?? "", {
        id: "cashSettingName",
        cell: (info) => {
          const cashSettingId = info.row.original.cashSettingId;

          return typeof cashSettingId === "undefined" ||
            cashSettingId === null ? (
            ""
          ) : (
            <Link to={`/cash/settings/${cashSettingId}`}>
              {info.getValue()}
            </Link>
          );
        },
        header: () => "Cash Setting",
        minSize: 225,
      }),
      columnHelper.accessor((row) => row.accountBalance ?? 0, {
        id: "accountBalance",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "Balance",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.cashBalance ?? 0, {
        id: "cashBalance",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "Cash",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor(
        (row) =>
          row.accountBalance === 0
            ? 0
            : (row.cashBalance ?? 0) /
              (row.accountBalance ?? row.cashBalance ?? 1),
        {
          id: "cashWeight",
          cell: (info) => formatPercent(info.getValue(), 2),
          header: () => "Cash %",
          enableColumnFilter: false,
          meta: {
            className: "text-end",
            headerClassName: "text-end",
          },
        },
      ),
      columnHelper.accessor(
        (row) =>
          getCashTargetPercentage(
            row.accountBalance ?? 0,
            "percent",
            row.cashTargetMinAbsolute,
            row.cashTargetMinPercent,
          ),
        {
          id: "cashTargetMin",
          cell: (info) =>
            displayMinMaxCashTarget(
              info.row.original.accountBalance ?? 0,
              info.row.original.cashTargetMinCondition ?? "and",
              info.row.original.cashTargetMinAbsolute,
              info.row.original.cashTargetMinPercent,
            ),
          header: () => "Minimum Cash Trigger",
          enableColumnFilter: false,
        },
      ),
      columnHelper.accessor(
        (row) =>
          getCashTargetPercentage(
            row.accountBalance ?? 0,
            row.cashTargetType ?? "absolute",
            row.cashTargetAbsolute,
            row.cashTargetPercent,
          ),
        {
          id: "cashTarget",
          cell: (info) =>
            displayCashTarget(
              info.row.original.accountBalance ?? 0,
              info.row.original.cashTargetType ?? "absolute",
              info.row.original.cashTargetAbsolute,
              info.row.original.cashTargetPercent,
            ),
          header: () => "Target Cash",
          enableColumnFilter: false,
        },
      ),
      columnHelper.accessor(
        (row) =>
          getCashTargetPercentage(
            row.accountBalance ?? 0,
            "percent",
            row.cashTargetMaxAbsolute,
            row.cashTargetMaxPercent,
          ),
        {
          id: "cashTargetMax",
          cell: (info) =>
            displayMinMaxCashTarget(
              info.row.original.accountBalance ?? 0,
              info.row.original.cashTargetMaxCondition ?? "and",
              info.row.original.cashTargetMaxAbsolute,
              info.row.original.cashTargetMaxPercent,
            ),
          header: () => "Maximum Cash Trigger",
          enableColumnFilter: false,
        },
      ),
    ],
    [],
  );

  const { table } = useTable({
    columns,
    data: accountCashSettings,
    getRowId: (row) => row.id.toString(),
  });

  return <Table table={table} />;
};

export default AccountCashSettingsTable;
