import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ProfileController } from "../../../api/src/profile/profile.controller";
import Avatar from "../components/Avatar";
import { useAuthenticatedFetch } from "../lib/api";

const ProfilePicture = ({
  avatar,
  width = 120,
  height = 120,
  roundedCircle = false,
  setIsOriginal,
}: {
  avatar?: string;
  width?: number;
  height?: number;
  roundedCircle?: boolean;
  setIsOriginal?: (val: boolean) => void;
}) => {
  const { user } = useAuth0();

  const { data: dataAvatar, isPending } =
    useAuthenticatedFetch<UnpackResponse<ProfileController["getUserAvatar"]>>(
      "/profile/avatar",
    );

  useEffect(() => {
    if (setIsOriginal && (isPending || !dataAvatar?.data)) {
      setIsOriginal(true);
    }
  }, [isPending, dataAvatar?.data, setIsOriginal]);

  return (
    <Avatar
      src={
        avatar
          ? avatar === "Original"
            ? user?.picture
            : avatar
          : !isPending && dataAvatar?.data
            ? `data:${dataAvatar?.data?.mimetype};base64,${dataAvatar?.data?.image}`
            : user?.picture
      }
      alt="User profile picture"
      width={width}
      height={height}
      roundedCircle={roundedCircle}
    />
  );
};

export default ProfilePicture;
