import { Image, ImageProps } from "react-bootstrap";

const Avatar = ({ src, ...props }: ImageProps) => (
  <Image
    src={src}
    width={props.width ?? 120}
    height={props.height ?? 120}
    roundedCircle={props.roundedCircle ?? false}
    fluid
    alt="This record's avatar"
    {...props}
    className={`sentry-block ${props.className ?? ""}`}
  />
);

export default Avatar;
