import { flexRender, Header } from "@tanstack/react-table";
import { InputGroup } from "react-bootstrap";
import { TextFilter } from "./filters";

const ColumnSearchBox = <TRow, TValue>({
  header,
  FilterComp = TextFilter,
}: {
  header: Header<TRow, TValue>;
  FilterComp?: React.ComponentType<{
    header: Header<TRow, TValue>;
  }>;
}) => {
  return (
    <InputGroup size="sm">
      <InputGroup.Text>
        {flexRender(header.column.columnDef.header, header.getContext())}
      </InputGroup.Text>
      <FilterComp header={header} />
    </InputGroup>
  );
};

export default ColumnSearchBox;
