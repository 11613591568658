import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "./lib/display";

function App() {
  const { isLoading, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (isAuthenticated) {
      Sentry.setUser({
        id: user?.sub,
        email: user?.email,
      });
    }

    setTheme(user?.user_metadata.theme ?? "system");
  }, [isAuthenticated, user, setTheme]);

  useEffect(() => {
    if (
      isAuthenticated &&
      location.pathname !== "/profile/create" &&
      !isLoading &&
      (typeof user?.given_name === "undefined" ||
        typeof user?.family_name === "undefined")
    ) {
      navigate("/profile/create");
    }
  }, [isAuthenticated, isLoading, user, navigate, location.pathname]);

  return <Outlet />;
}

export default App;
