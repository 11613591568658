import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import type { AuthController } from "../../api/src/auth/auth.controller";
import type { UnpackResponse } from "../../api/src/lib";
import { useAuthenticatedFetch } from "./lib/api";

const Auth = () => {
  const { service } = useParams();
  const navigate = useNavigate();

  const isAuth0 = service === "auth0";

  useEffect(() => {
    if (!isAuth0 && (window.opener === null || window.opener === window)) {
      navigate("/");
    }
  }, [isAuth0, navigate]);

  const [params] = useSearchParams();
  const code = params.get("code");

  const { isSuccess } = useAuthenticatedFetch<
    UnpackResponse<AuthController["authorizeService"]>
  >(
    "/auth/authorize",
    {
      method: "POST",
      body: JSON.stringify({
        service: service,
        code,
      }),
    },
    {
      enabled: typeof service !== "undefined" && !isAuth0,
    },
    ["/auth/authorize", service, code],
  );

  useEffect(() => {
    if (isSuccess) {
      window.opener.location.reload();
      window.opener?.console.debug(`Authorized Service: ${service}`);
      window.close();
    }
  }, [isSuccess, service]);

  return <p>Authorizing...</p>;
};

export default Auth;
