import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { Household } from "../../../../api/src/households-base/households-base.service";
import type { UnpackResponse } from "../../../../api/src/lib";
import {
  DataMapping,
  TargetMappingData,
} from "../../../../api/src/lib/dataMapping";
import DataMappingModal from "../../components/DataMapping";
import { useErrorMessage } from "../../components/FormError";
import {
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../../lib/api";

type LinkData = Record<number, number[]>;

const AccountsHouseholdsMapping = () => {
  const { errorMessage, setError, resetError } = useErrorMessage();

  const {
    isPending: isPendingMapping,
    isError: isErrorMapping,
    data: householdAccountMappings,
  } = useAuthenticatedFetch<
    UnpackResponse<HouseholdsController["getAccountHouseholdMappings"]>
  >("/households/accounts/mappings");

  const {
    isFetching: isFetchingSuggestions,
    data: suggestions,
    refetch: fetchSuggestions,
  } = useAuthenticatedFetch<
    UnpackResponse<
      HouseholdsController["getAccountHouseholdMappingSuggestions"]
    >
  >("/households/accounts/mappings/suggestions", undefined, { enabled: false });

  const onAutoSuggest = useCallback(async () => {
    await fetchSuggestions();
  }, [fetchSuggestions]);

  const linkAccountHouseholds = useAuthenticatedMutationAsync<
    UnpackResponse<HouseholdsController["mapAccountsToHouseholds"]>
  >("/households/accounts/mappings", async (data: LinkData) => ({
    method: "POST",
    body: JSON.stringify(data),
  }));

  const queryClient = useQueryClient();

  const onLink = useMutation({
    mutationFn: async (data: LinkData) => {
      resetError();

      try {
        const linkedAccountHouseholds = await linkAccountHouseholds(data);
        queryClient.setQueryData(["/households/accounts/mappings"], {
          data: linkedAccountHouseholds.data,
        });
      } catch (err) {
        const message = "Failed to assign accounts to households";
        setError(message);
        console.error(message, err);
      }
    },
  });

  const handleSyncHouseholds = useCallback(
    async (newAssignedData: TargetMappingData<Household>[]) => {
      await onLink.mutateAsync(
        newAssignedData.reduce(
          (result, household) => ({
            ...result,
            [household.id]: household.assigned.accounts.map(
              (assignedItem) => assignedItem.id,
            ),
          }),
          {} as LinkData,
        ),
      );
    },
    [onLink],
  );

  const mappingData = useMemo(
    (): DataMapping<Household> => ({
      ...(householdAccountMappings?.data ?? {
        target: [],
        source: {},
      }),
      suggested: suggestions?.data ?? {},
    }),
    [householdAccountMappings, suggestions],
  );

  return (
    <>
      {errorMessage}
      <DataMappingModal
        linkHeader="/clients/households/"
        title="Assign Accounts to Households"
        targetTitle="Households"
        mappedTitle="Accounts"
        sourceTitle="Unassigned Accounts"
        isLoading={isPendingMapping}
        isError={isErrorMapping}
        mappingData={mappingData}
        isSubmitting={onLink.isPending}
        handleSubmit={handleSyncHouseholds}
        onAutoSuggest={onAutoSuggest}
        isLoadingSuggestions={isFetchingSuggestions}
      />
    </>
  );
};

export default AccountsHouseholdsMapping;
