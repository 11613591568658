import React, { useState } from "react";
import { Alert } from "react-bootstrap";

export const useErrorMessage = () => {
  const [err, setError] = useState<any>();

  return {
    setError,
    resetError: () => setError(undefined),
    errorMessage: (
      <FormError hasError={typeof err !== "undefined"} message={String(err)} />
    ),
  };
};

const FormError = ({
  hasError = true,
  message = "",
}: {
  hasError?: boolean;
  message?: any;
}) => (!hasError ? null : <Alert variant="danger">{message}</Alert>);

export default FormError;
