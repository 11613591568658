import _ from "lodash";
import { useMemo } from "react";
import { ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "../Loading";
import { useQueryAccounts } from "../clients/account/lib";
import { useQueryHouseholds } from "../clients/household/lib";

const UnassignedFeeStructuresModels = () => {
  const { isPending: isPendingHouseholds, data: dataHouseholds } =
    useQueryHouseholds();
  const households = dataHouseholds?.data;

  const { isPending: isPendingAccounts, data: dataAccounts } = useQueryAccounts(
    { pageSize: 10000 },
  );
  const accounts = dataAccounts?.data;

  const householdIdsWithoutFeeStructure = useMemo(
    () =>
      (households ?? [])
        .filter((household) => typeof household.feeStructureId === "undefined")
        .map((household) => household.id),
    [households],
  );

  const householdIdsWithoutModel = useMemo(
    () =>
      (households ?? [])
        .filter((household) => typeof household.assignedModelId === "undefined")
        .map((household) => household.id),
    [households],
  );

  const mapHouseholds = useMemo(() => _.keyBy(households, "id"), [households]);

  const accountIdsWithoutFeeStructure = useMemo(
    () =>
      (accounts ?? [])
        .filter(
          (account) =>
            typeof account.feeStructureId === "undefined" &&
            (typeof account.householdId === "undefined" ||
              typeof mapHouseholds[account.householdId] === "undefined" ||
              typeof mapHouseholds[account.householdId].feeStructureId ===
                "undefined"),
        )
        .map((account) => account.id),
    [accounts, mapHouseholds],
  );

  const accountIdsWithoutModel = useMemo(
    () =>
      (accounts ?? [])
        .filter(
          (account) =>
            typeof account.assignedModelId === "undefined" &&
            (typeof account.householdId === "undefined" ||
              typeof mapHouseholds[account.householdId] === "undefined" ||
              typeof mapHouseholds[account.householdId].assignedModelId ===
                "undefined"),
        )
        .map((account) => account.id),
    [accounts, mapHouseholds],
  );

  return isPendingHouseholds || isPendingAccounts ? (
    <ListGroupItem>
      <Loading />
    </ListGroupItem>
  ) : typeof households === "undefined" ||
    typeof accounts === "undefined" ? null : (
    <>
      <ListGroupItem>
        You have{" "}
        {householdIdsWithoutFeeStructure.length <= 0 ? (
          "0 Households"
        ) : (
          <Link
            to={`/clients/households?${householdIdsWithoutFeeStructure
              .map((id) => `id=${id}`)
              .join("&")}`}
          >
            {householdIdsWithoutFeeStructure.length} Households
          </Link>
        )}{" "}
        and{" "}
        {accountIdsWithoutFeeStructure.length <= 0 ? (
          "0 Accounts"
        ) : (
          <Link
            to={`/clients/accounts?${accountIdsWithoutFeeStructure
              .map((id) => `id=${id}`)
              .join("&")}`}
          >
            {accountIdsWithoutFeeStructure.length} Accounts
          </Link>
        )}{" "}
        without fee structures.
      </ListGroupItem>
      <ListGroupItem>
        You have{" "}
        {householdIdsWithoutModel.length <= 0 ? (
          "0 Households"
        ) : (
          <Link
            to={`/clients/households?${householdIdsWithoutModel
              .map((id) => `id=${id}`)
              .join("&")}`}
          >
            {householdIdsWithoutModel.length} Households
          </Link>
        )}{" "}
        and{" "}
        {accountIdsWithoutModel.length <= 0 ? (
          "0 Accounts"
        ) : (
          <Link
            to={`/clients/accounts?${accountIdsWithoutModel
              .map((id) => `id=${id}`)
              .join("&")}`}
          >
            {accountIdsWithoutModel.length} Accounts
          </Link>
        )}{" "}
        without models assigned.
      </ListGroupItem>
    </>
  );
};

export default UnassignedFeeStructuresModels;
