import { useMemo } from "react";
import { Alert } from "react-bootstrap";
import { AxisOptions, UserSerie } from "react-charts";
import type { DashboardController } from "../../../api/src/dashboard/dashboard.controller";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { useAuthenticatedFetch } from "../lib/api";
import { formatCurrency } from "../lib/numbers";
import HistoricalChart, { TimeSeries } from "./HistoricalChart";

const HistoricalTransactionsChart = () => {
  const {
    isPending: isPendingHistoricalNetTransactions,
    isError: isErrorHistoricalNetTransactions,
    data: dataHistoricalNetTransactions,
  } = useAuthenticatedFetch<
    UnpackResponse<DashboardController["getHistoricalNetTransactions"]>
  >("/dashboard/transactions/historical");
  const historicalNetTransactions = dataHistoricalNetTransactions?.data;

  const primaryAxis = useMemo(
    (): AxisOptions<TimeSeries> => ({
      getValue: (item) => item.date,
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<TimeSeries>[] => [
      {
        id: "value",
        getValue: (item) => item.value,
        elementType: "line",
        formatters: {
          scale: (value: number | null) =>
            value === null
              ? ""
              : formatCurrency(value, 1, {
                  notation: "compact",
                  compactDisplay: "short",
                }),
        },
      } as AxisOptions<TimeSeries>,
    ],
    [],
  );

  const data = useMemo(
    (): UserSerie<TimeSeries>[] => [
      {
        label: "Net Transfers",
        axisFamily: "time",
        secondaryAxisId: "value",
        data: (historicalNetTransactions ?? []).map((item) => ({
          value: item.value,
          date: new Date(item.date),
        })),
      } as UserSerie<TimeSeries>,
    ],
    [historicalNetTransactions],
  );

  return isPendingHistoricalNetTransactions ? (
    <Loading message="Transactions Chart" />
  ) : isErrorHistoricalNetTransactions ? (
    <Alert variant="danger">Failed to load historical transfers</Alert>
  ) : (
    <HistoricalChart
      primaryAxis={primaryAxis}
      secondaryAxes={secondaryAxes}
      data={data}
    />
  );
};

export default HistoricalTransactionsChart;
