import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useMemo } from "react";
import { ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { CashSettingsController } from "../../../api/src/cash-settings/cash-settings.controller";
import type { CashSetting } from "../../../api/src/cash-settings/lib";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import ActionButton from "../components/ActionButton";
import HelpButton from "../components/HelpButton";
import TabContainerWithTabs from "../components/TabContainer";
import { Table, useTable } from "../components/Table/Table";
import { useAuthenticatedFetch, useAuthenticatedQuery } from "../lib/api";
import { processFileResponse } from "../lib/file";
import { formatCurrency, formatPercent } from "../lib/numbers";
import CashNav from "./CashNav";
import { CashSettingsHelp } from "./lib";

function useQueryExportCashSettings() {
  return useAuthenticatedQuery<
    UnpackResponse<CashSettingsController["exportCashSettings"]>,
    void
  >({
    queryKey: ["cash-settings", "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}

const CashSettings = () => {
  const { isPending: isPendingCashSettings, data: cashSettingsBody } =
    useAuthenticatedFetch<UnpackResponse<CashSettingsController["getAll"]>>(
      "/cash-settings",
    );
  const cashSettings = cashSettingsBody?.data ?? [];

  const columnHelper = useMemo(() => createColumnHelper<CashSetting>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => (
          <Link to={`/cash/settings/${info.row.original.id}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => "Name",
        minSize: 225,
      }),
      columnHelper.accessor("cashTargetAbsolute", {
        cell: (info) => {
          const val = info.getValue();
          return val === null ? "" : formatCurrency(val, 2);
        },
        header: () => "Target Cash",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("cashTargetPercent", {
        cell: (info) => {
          const val = info.getValue();
          return val === null ? "" : formatPercent(val, 2);
        },
        header: () => "Target Weight",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("cashTargetMinAbsolute", {
        cell: (info) => {
          const val = info.getValue();
          return val === null ? "" : formatCurrency(val, 2);
        },
        header: () => "Min Cash",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("cashTargetMinPercent", {
        cell: (info) => {
          const val = info.getValue();
          return val === null ? "" : formatPercent(val, 2);
        },
        header: () => "Min Weight",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("cashTargetMaxAbsolute", {
        cell: (info) => {
          const val = info.getValue();
          return val === null ? "" : formatCurrency(val, 2);
        },
        header: () => "Max Cash",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("cashTargetMaxPercent", {
        cell: (info) => {
          const val = info.getValue();
          return val === null ? "" : formatPercent(val, 2);
        },
        header: () => "Max Weight",
        enableColumnFilter: false,
      }),
    ],
    [columnHelper],
  );

  const { table } = useTable({
    columns,
    data: cashSettings,
    getRowId: (row) => row.id.toString(),
  });

  const { isLoading: isLoadingExport, refetch: refetchExport } =
    useQueryExportCashSettings();

  const onExport = useCallback(async () => {
    await refetchExport();
  }, [refetchExport]);

  return (
    <TabContainerWithTabs tabs={CashNav}>
      <Row>
        <Col className="d-flex justify-content-between">
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                as={Link}
                to="new"
                variant="secondary"
                label="New"
                icon="/icons/new.svg"
              />
            </ButtonGroup>
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Export"
                icon="/icons/folded-list.svg"
                onClick={onExport}
                className="me-2"
                disabled={isLoadingExport}
              />
            </ButtonGroup>
          </ButtonToolbar>
          <HelpButton
            title="Help – Cash Settings"
            body={<CashSettingsHelp />}
            buttonProps={{ className: "mb-3" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {isPendingCashSettings ? <Loading /> : <Table table={table} />}
        </Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default CashSettings;
