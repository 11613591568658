import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { DashboardController } from "../../../api/src/dashboard/dashboard.controller";
import type { HouseholdWithMetrics } from "../../../api/src/households/households.service";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { deserializeHouseholdWithMetrics } from "../clients/household/lib";
import { Table, useTable } from "../components/Table/Table";
import { useAuthenticatedFetch } from "../lib/api";
import { formatCurrency } from "../lib/numbers";

type HouseholdRow = Pick<
  HouseholdWithMetrics,
  "id" | "name" | "householdBalance" | "cashBalance"
>;

const TopHouseholds = () => {
  const columnHelper = useMemo(() => createColumnHelper<HouseholdRow>(), []);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "rank",
        cell: (info) => `${info.row.index + 1}.`,
      }),
      columnHelper.accessor("name", {
        cell: (info) => (
          <Link to={`/clients/households/${info.row.original.id}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => "Name",
        minSize: 225,
      }),
      columnHelper.accessor((row) => row.householdBalance ?? 0, {
        id: "householdBalance",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "AUM",
        enableColumnFilter: false,
        meta: {
          headerClassName: "text-end",
          className: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.cashBalance ?? 0, {
        id: "cashBalance",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "Cash",
        enableColumnFilter: false,
        meta: {
          headerClassName: "text-end",
          className: "text-end",
        },
      }),
    ],
    [columnHelper],
  );

  const { isPending, isError, data } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<DashboardController["getTopHouseholds"]>>
  >("/dashboard/households/top?count=10");
  const households = useMemo(
    () => (data?.data ?? []).map(deserializeHouseholdWithMetrics),
    [data?.data],
  );

  const { table } = useTable({
    columns,
    data: households ?? [],
    initialState: {
      sorting: [{ id: "householdBalance", desc: true }],
    },
    manualPagination: true,
    getRowId: (row) => row.id.toString(),
    enableFilters: false,
  });

  return isPending ? (
    <Loading message="Top Households" />
  ) : isError ? (
    <Alert variant="danger">Failed to load top households</Alert>
  ) : (
    <Table table={table} disablePagination />
  );
};

export default TopHouseholds;
