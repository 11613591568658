import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const FirmNav = () => (
  <nav>
    <Nav variant="tabs" defaultActiveKey="/firm/info">
      <Nav.Item>
        <NavLink to="/firm/info" className="nav-link">
          Settings
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/firm/asset-class-risk-allocations" className="nav-link">
          Asset Class to Risk Mapping
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/firm/security-category-asset-class" className="nav-link">
          Category to Asset Class Mapping
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/firm/securities/asset-categories" className="nav-link">
          Security Category Mapping
        </NavLink>
      </Nav.Item>
    </Nav>
  </nav>
);

export default FirmNav;
