import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ModelsController } from "../../../api/src/models/models.controller";
import type { ExtendedModelSleeve } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import ActionButton from "../components/ActionButton";
import HelpButton from "../components/HelpButton";
import TabContainerWithTabs from "../components/TabContainer";
import { Table, useTable } from "../components/Table/Table";
import {
  onColumnFiltersChange,
  onPaginationChange,
  onSortingChange,
  useTableSettings,
} from "../components/Table/tableSettings";
import { deserializeDate, useAuthenticatedFetch } from "../lib/api";
import { formatPercent } from "../lib/numbers";
import ModelsNav from "./ModelsNav";
import SleeveDeleteDialog from "./SleeveDeleteDialog";
import { ModelsHelp } from "./lib";

type ModelSleeveListRow = Required<ExtendedModelSleeve>;

const columnHelper = createColumnHelper<ModelSleeveListRow>();

const ModelSleeves = () => {
  const [showModal, setShowModal] = useState(false);
  const [deleteSleeveId, setDeleteSleeveId] = useState(0);
  const handleDeleteSleeveClick = useCallback((deleteSleeveId: number) => {
    setDeleteSleeveId(deleteSleeveId);
    setShowModal(true);
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        id: "name",
        cell: (info) => (
          <Link to={info.row.original.id.toString()}>{info.getValue()}</Link>
        ),
        header: () => "Name",
        minSize: 275,
      }),
      columnHelper.accessor((row) => row.modelsInUse, {
        id: "usageCount",
        header: () => "Models Used In",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.primarySecurities.length, {
        id: "primarySecurities",
        header: () => "Primary Securities",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.secondarySecurities.length, {
        id: "secondarySecurities",
        header: () => "Secondary Securities",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.expenseRatio, {
        id: "expenseRatio",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Expense Ratio",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.returns.oneYear, {
        id: "performanceOneYear",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Performance 1 Year",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.returns.threeYear, {
        id: "performanceThreeYear",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Performance 3 Year",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.returns.fiveYear, {
        id: "performanceFiveYear",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Performance 5 Year",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.updatedTime, {
        id: "updatedTime",
        cell: (info) => new Date(info.getValue()).toLocaleDateString(),
        header: () => "Last Modified",
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: "deleteSleeve",
        cell: (info) => (
          <ActionButton
            onClick={() => {
              handleDeleteSleeveClick(info.row.original.id);
            }}
            variant="icon"
            icon="/icons/trash.svg"
            label="Delete Sleeve"
            type="button"
          />
        ),
        header: () => "",
        enableColumnFilter: false,
      }),
    ],
    [handleDeleteSleeveClick],
  );

  const { isPending: isPendingSleeves, data: dataSleeves } =
    useAuthenticatedFetch<UnpackResponse<ModelsController["getAllSleeves"]>>(
      "/models/sleeves?metrics=true",
    );
  const sleeves = useMemo(
    () =>
      dataSleeves?.data.map((sleeve) => {
        const result: ExtendedModelSleeve = {
          ...sleeve,
          updatedTime: deserializeDate(sleeve.updatedTime),
        };

        return result as Required<ExtendedModelSleeve>;
      }),
    [dataSleeves?.data],
  );

  const [tableSettings, setTableSettings] = useTableSettings("sleeves");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    tableSettings.filters,
  );
  const [sorting, setSorting] = useState<SortingState>(tableSettings.sorting);
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: tableSettings.pageSize,
    pageIndex: 0,
  });

  const { table } = useTable({
    columns,
    data: sleeves ?? [],
    getRowId: (row) => row.id.toString(),
    state: { columnFilters, sorting, pagination },
    onColumnFiltersChange: onColumnFiltersChange(
      columnFilters,
      setColumnFilters,
      tableSettings,
      setTableSettings,
    ),
    onSortingChange: onSortingChange(
      sorting,
      setSorting,
      tableSettings,
      setTableSettings,
    ),
    onPaginationChange: onPaginationChange(
      pagination,
      setPagination,
      tableSettings,
      setTableSettings,
    ),
  });

  return (
    <TabContainerWithTabs tabs={ModelsNav}>
      <Row>
        <Col className="d-flex justify-content-between">
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                as={Link}
                to="new"
                variant="secondary"
                label="New"
                icon="/icons/new.svg"
              />
            </ButtonGroup>
          </ButtonToolbar>
          <HelpButton
            title="Help – Models"
            body={<ModelsHelp />}
            buttonProps={{ className: "mb-3" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>{isPendingSleeves ? <Loading /> : <Table table={table} />}</Col>
        <SleeveDeleteDialog
          showModal={showModal}
          setShowModal={setShowModal}
          deleteSleeveId={deleteSleeveId}
          setDeleteSleeveId={setDeleteSleeveId}
          title="delete-sleeve"
        />
      </Row>
    </TabContainerWithTabs>
  );
};

export default ModelSleeves;
