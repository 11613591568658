import React, { useContext, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import TaxPerformancePeriodSelector from "../../components/TaxPerformancePeriodSelector";
import { useAuthenticatedFetch } from "../../lib/api";
import { formatCurrencyComponent } from "../../lib/numbers";
import { AccountContext } from "./AccountInfo";

const AccountTaxSummary = ({
  customTaxPeriodParams,
  setCustomTaxPeriodParams,
}: {
  rowSelection?: Record<string, boolean>;
  useall?: boolean;
  customTaxPeriodParams?: string;
  setCustomTaxPeriodParams?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { account } = useContext(AccountContext);
  const { accountId } = useParams();
  const [
    customAccountTaxPeriodParamsInner,
    setCustomAccountTaxPeriodParamsInner,
  ] = useState("");

  const {
    data: dataAccountTaxPerformance,
    isPending: isPendingAccountTaxPerformance,
    isError: isErrorAccountTaxPerformance,
  } = useAuthenticatedFetch<
    UnpackResponse<AccountsController["getAccountPerformance"]>
  >(
    `/accounts/${accountId}/performance?${
      customTaxPeriodParams ?? customAccountTaxPeriodParamsInner
    }`,
  );
  const accountTaxPerformance = dataAccountTaxPerformance?.data;

  return (
    <Row className="mb-2">
      <Col>
        <h4>Tax Summary</h4>
        <Row>
          <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
            Unrealized Gain
          </Col>
          <Col as="dd">
            {formatCurrencyComponent(account?.unrealizedGain ?? 0, 2)}
          </Col>
        </Row>
        <Row>
          <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
            Unrealized Loss
          </Col>
          <Col as="dd">
            {formatCurrencyComponent(account?.unrealizedLoss ?? 0, 2)}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xl={4} md={2} sm={4}>
            <TaxPerformancePeriodSelector
              inceptionDate={account?.inceptionDate}
              setCustomPeriodParams={
                setCustomTaxPeriodParams ?? setCustomAccountTaxPeriodParamsInner
              }
            />
          </Col>
        </Row>
        {isPendingAccountTaxPerformance ? (
          <Loading message="Tax Details" />
        ) : isErrorAccountTaxPerformance ? (
          <Alert variant="danger">Failed to load tax summary</Alert>
        ) : (
          <dl>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Realized LT G/L
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(
                  accountTaxPerformance?.realizedGainLossLT ?? 0,
                  2,
                )}
              </Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Realized ST G/L
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(
                  accountTaxPerformance?.realizedGainLossST ?? 0,
                  2,
                )}
              </Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Taxabale Div./Int.
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(
                  account?.isTaxable ? (accountTaxPerformance?.income ?? 0) : 0,
                  2,
                )}
              </Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Capital Gain Distributions
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(
                  account?.isTaxable
                    ? (accountTaxPerformance?.capitalGainDistributions ?? 0)
                    : 0,
                  2,
                )}
              </Col>
            </Row>
          </dl>
        )}
      </Col>
    </Row>
  );
};

export default AccountTaxSummary;
