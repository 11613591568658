import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SubmitButton from "../components/SubmitButton";
import {
  useDeleteCashSetting,
  useQueryCashSettingAssignmentCounts,
} from "./lib";

const CashSettingDeleteDialog = ({
  showModal,
  setShowModal,
  deleteCashSettingId,
  setDeleteCashSettingId,
  selectedCashSettingId,
  setCashSettingId,
  cashSettingOptions,
  title,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteCashSettingId: number | null;
  setDeleteCashSettingId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedCashSettingId: number | null;
  setCashSettingId: React.Dispatch<React.SetStateAction<number | null>>;
  cashSettingOptions: JSX.Element[];
  title: string;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading: isLoadingAssignmentCount, data: assignmentCount } =
    useQueryCashSettingAssignmentCounts(deleteCashSettingId ?? -1, {
      enabled: deleteCashSettingId !== null,
    });

  const handleModalClose = useCallback(() => {
    setCashSettingId(null);
    setShowModal(false);
  }, [setCashSettingId, setShowModal]);

  const replaceAccountCashSetting = useDeleteCashSetting(
    deleteCashSettingId ?? -1,
  );

  const handleSaveChanges = useCallback(async () => {
    if (deleteCashSettingId !== null) {
      await replaceAccountCashSetting.mutateAsync({
        newCashSettingId: selectedCashSettingId,
      });
      queryClient.invalidateQueries({
        queryKey: ["cash-settings"],
      });
      setShowModal(false);
      setCashSettingId(null);
      navigate("/cash/settings");
    }
  }, [
    deleteCashSettingId,
    navigate,
    queryClient,
    replaceAccountCashSetting,
    selectedCashSettingId,
    setCashSettingId,
    setShowModal,
  ]);

  return (
    <Modal
      show={showModal && !isLoadingAssignmentCount}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Delete Cash Setting</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(assignmentCount ?? 0) === 0 ? (
          <Row className="m-2">
            Are you sure you would like to delete this cash setting?
          </Row>
        ) : (
          <>
            <Row className="m-2">
              This cash setting is assigned to {assignmentCount ?? 0}{" "}
              account(s). Which cash setting would you instead like to assign to
              these account(s)?
            </Row>
            <Row className="m-2">
              <Form.Select
                placeholder="Select assigned cash setting"
                value={selectedCashSettingId ?? undefined}
                onChange={(e) =>
                  setCashSettingId(
                    e.target.value === "" ? null : +e.target.value,
                  )
                }
              >
                {cashSettingOptions}
              </Form.Select>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Discard
        </Button>
        <SubmitButton
          variant="danger"
          label="Delete"
          onClick={() => handleSaveChanges()}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default CashSettingDeleteDialog;
