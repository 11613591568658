import { yupResolver } from "@hookform/resolvers/yup";
import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { Alert, ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import type { HouseholdWithMetrics } from "../../../api/src/households/households.service";
import type {
  ReportBatchForm,
  ReportPeriod,
  ReportSubType,
} from "../../../api/src/reports/reports.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import {
  displayHouseholdStatus,
  useQueryHouseholds,
} from "../clients/household/lib";
import ActionButton from "../components/ActionButton";
import Content from "../components/Content";
import FormFieldError from "../components/FormFieldError";
import IndeterminateCheckbox from "../components/IndeterminateCheckbox";
import MultiSelector from "../components/MultiSelector";
import SubmitButton from "../components/SubmitButton";
import { Table, useTable } from "../components/Table/Table";
import { StatusFilter } from "../components/Table/filters";
import { capitalize, InlineError } from "../lib/display";
import { dateSchema, getSchemaFieldLabel } from "../lib/forms";
import { formatCurrency } from "../lib/numbers";
import { useCreateReportBatch, useQueryReportTemplates } from "./lib";

const subReportOptions: ReportSubType[] = [
  "account_summary",
  "portfolio_snapshot",
  "appraisals",
  "security_performance",
  "household_performance_attribution",
  "appraisals_wo_cost_basis",
  "performance_summary",
  "performance_chart",
  "net_investment_chart",
  "asset_allocation_top_holdings",
];

type ReportOptionsForm = Omit<
  ReportBatchForm,
  "householdIds" | "isFirmWideExecution"
>;

const schema: yup.ObjectSchema<ReportOptionsForm> = yup.object({
  name: yup.string().required().label("Name"),
  subReports: yup
    .array()
    .of<ReportSubType>(yup.string().required().oneOf(subReportOptions))
    .default(["account_summary"])
    .label("Sections"),
  rateOfReturnType: yup
    .string()
    .required()
    .oneOf(["time-weighted", "internal"])
    .default("internal")
    .label("Rate of Return"),
  orientation: yup
    .string()
    .required()
    .oneOf(["portrait", "landscape"])
    .default("portrait")
    .label("Page Orientation"),
  period: yup
    .string()
    .defined()
    .nullable()
    .oneOf<ReportPeriod>(["M", "Q", "Y"])
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .default("M")
    .label("Period"),
  startDate: dateSchema
    .when("period", {
      is: null,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    })
    .label("Start Date"),
  endDate: dateSchema
    .when("period", {
      is: null,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    })
    .label("End Date"),
});

type HouseholdRow = Pick<
  HouseholdWithMetrics,
  "id" | "name" | "householdBalance" | "cashBalance" | "isActive"
>;

const CreateReport = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm<ReportOptionsForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const [params] = useSearchParams();

  const householdIds = params.getAll("household_id");
  const isFirmWideBatch = params.get("type") === "standard";

  const {
    isLoading: isLoadingHouseholds,
    isError: isErrorHouseholds,
    data: households,
  } = useQueryHouseholds({ includeBalances: true, enabled: !isFirmWideBatch });

  const columnHelper = useMemo(() => createColumnHelper<HouseholdRow>(), []);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.name, {
        id: "name",
        cell: (info) => (
          <Link to={`/clients/households/${info.row.original.id}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => "Name",
        minSize: 225,
      }),
      columnHelper.accessor((row) => row.householdBalance ?? 0, {
        id: "householdBalance",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "AUM",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.cashBalance ?? 0, {
        id: "cashBalance",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "Cash",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor(displayHouseholdStatus, {
        id: "isActive",
        header: () => "Status",
        filterFn: "equalsString",
        meta: {
          filterComponent: StatusFilter,
        },
      }),
    ],
    [columnHelper],
  );

  const { table, rowSelection, setRowSelection } = useTable({
    data: households?.data ?? [],
    columns,
    getRowId: (row) => row.id.toString(),
    initialState: {
      sorting: [{ id: "name", desc: false }],
      columnFilters: [
        {
          id: "status",
          value: "Active",
        },
      ],
    },
  });

  // initialeState row selection does not work for asynchronously loaded data;
  // initialize it manually on first render.
  useEffect(
    () => {
      setRowSelection(
        householdIds.reduce((acc, id) => ({ ...acc, [id]: true }), {}),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const {
    isLoading: isLoadingReportTemplates,
    isError: isErrorReportTemplates,
    data: reportTemplates,
  } = useQueryReportTemplates();

  const createReport = useCreateReportBatch();

  const notificationContext = useContext(NotificationContext);
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (report: ReportOptionsForm) => {
      const now = new Date();
      const startDate = report.startDate ?? new Date(now);
      const endDate = report.endDate ?? new Date(now);

      if (report.period === "M") {
        endDate.setDate(0);
        startDate.setMonth(now.getMonth() - 1);
        startDate.setDate(1);
      } else if (report.period === "Q") {
        const monthDifference = now.getMonth() % 3;
        endDate.setMonth(now.getMonth() - monthDifference);
        endDate.setDate(0);
        startDate.setMonth(now.getMonth() - monthDifference - 1);
        startDate.setDate(1);
      } else if (report.period === "Y") {
        endDate.setMonth(0);
        endDate.setDate(0);
        startDate.setFullYear(now.getFullYear() - 1);
        startDate.setMonth(0);
        startDate.setDate(1);
      }

      try {
        await createReport.mutateAsync({
          ...report,
          isFirmWideExecution: params.get("type") === "standard",
          startDate,
          endDate,
          householdIds: isFirmWideBatch
            ? []
            : Object.keys(rowSelection).map((idStr) => parseInt(idStr)),
        });
        notificationContext.pushNotification({
          id: "report",
          header: "Report Created",
          body: "Generating report... We will notify you when the report is ready.",
          variant: "success",
        });
        navigate(-1);
      } catch {
        notificationContext.pushNotification({
          id: "report",
          header: "Failed to Create Report",
          body: "Report not created",
          variant: "danger",
        });
      }
    },
    [
      createReport,
      isFirmWideBatch,
      navigate,
      notificationContext,
      params,
      rowSelection,
    ],
  );

  const period = watch("period") as ReportPeriod | "";
  const subReports = watch("subReports");

  const onTemplateChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const templateId = parseInt(event.target.value);
      const template = reportTemplates?.find((t) => t.id === templateId);

      if (typeof template !== "undefined") {
        setValue("period", template.period);
        setValue("subReports", template.subReports);
        setValue("rateOfReturnType", template.rateOfReturnType);
        setValue("orientation", template.orientation);
      }
    },
    [reportTemplates, setValue],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <h1>Create Report</h1>
        </Col>
        <Col md="auto">
          <ButtonToolbar className="mb-3">
            <ActionButton
              label="Save"
              icon="/icons/save.svg"
              type="submit"
              className="me-4"
            />
            <ActionButton
              as={Link}
              to="/reports"
              label="Exit"
              icon="/icons/exit.svg"
            />
          </ButtonToolbar>
        </Col>
      </Row>
      <Content>
        <Row>
          <Col xxl={5} lg={10}>
            <Form.Group className="mb-3" controlId="form-name">
              <Form.Label>{getSchemaFieldLabel(schema.fields.name)}</Form.Label>
              <Form.Control {...register("name")} />
              <FormFieldError field={errors.name} />
            </Form.Group>
          </Col>
          <Col xxl={5} lg={10}>
            <Form.Group className="mb-3" controlId="form-templateId">
              <Form.Label>Template</Form.Label>
              {isLoadingReportTemplates ? (
                <Loading />
              ) : isErrorReportTemplates ? (
                <InlineError>An error occurred</InlineError>
              ) : (
                <Form.Select
                  placeholder="Select report template"
                  onChange={onTemplateChange}
                >
                  <option value="">None</option>
                  {(reportTemplates ?? []).map((template) => (
                    <option key={template.id} value={template.id}>
                      {template.name}
                    </option>
                  ))}
                </Form.Select>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xxl={5} lg={10}>
            <Form.Group className="mb-3" controlId="form-subReports">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.subReports)}
              </Form.Label>
              <MultiSelector
                placeholder="Select included reports"
                {...register("subReports")}
                options={subReportOptions.map((subReport) => ({
                  label: capitalize(subReport.replaceAll("_", " ")),
                  value: subReport,
                }))}
                setValue={(value: ReportSubType[]) =>
                  setValue("subReports", value)
                }
                value={subReports}
              />
              <FormFieldError field={errors.subReports} />
            </Form.Group>
          </Col>
          <Col xxl={5} lg={10}>
            <Form.Group className="mb-3" controlId="form-period">
              <Form.Label>
                {getSchemaFieldLabel(schema.fields.period)}
              </Form.Label>
              <Form.Select
                placeholder="Select report period"
                {...register("period")}
                defaultValue="M"
              >
                <option value="M">Last Month</option>
                <option value="Q">Last Quarter</option>
                <option value="Y">Last Year</option>
                <option value="">Custom</option>
              </Form.Select>
              <FormFieldError field={errors.period} />
            </Form.Group>
            {period !== "" ? null : (
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="form-startDate">
                    <Form.Label>
                      {getSchemaFieldLabel(schema.fields.startDate)}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      {...register("startDate")}
                      max={new Date().toISOString().substring(0, 10)}
                    />
                    <FormFieldError field={errors.startDate} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="form-endDate">
                    <Form.Label>
                      {getSchemaFieldLabel(schema.fields.endDate)}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      {...register("endDate")}
                      max={new Date().toISOString().substring(0, 10)}
                    />
                    <FormFieldError field={errors.endDate} />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Col>
          <Col lg={10}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-orientation">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.orientation)}
                  </Form.Label>
                  <Form.Select
                    placeholder="Select page orientation"
                    {...register("orientation")}
                    defaultValue="portrait"
                  >
                    <option value="portrait">Portrait</option>
                    <option value="landscape">Landscape</option>
                  </Form.Select>
                  <FormFieldError field={errors.orientation} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-rateOfReturnType">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.rateOfReturnType)}
                  </Form.Label>
                  <Form.Select
                    placeholder="Select rate of return type"
                    {...register("rateOfReturnType")}
                    defaultValue="internal"
                  >
                    <option value="internal">Internal</option>
                    <option value="time-weighted">Time-Weighted</option>
                  </Form.Select>
                  <FormFieldError field={errors.rateOfReturnType} />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        {isFirmWideBatch ? null : isLoadingHouseholds ? (
          <Loading message="Households" />
        ) : isErrorHouseholds ? (
          <Alert variant="danger">Failed to load households</Alert>
        ) : (
          <Row className="mb-3">
            <Col>
              <h2>Select Households</h2>
              <Table table={table} />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} />
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

export default CreateReport;
