import { createColumnHelper } from "@tanstack/react-table";
import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import type { UnpackResponse } from "../../../../api/src/lib";
import type { Transaction } from "../../../../api/src/transactions/lib";
import type { TransactionsController } from "../../../../api/src/transactions/transactions.controller";
import TransactionsTable, {
  deserializeTransaction,
} from "../../components/TransactionsTable";
import { useAuthenticatedQuery } from "../../lib/api";
import { processFileResponse } from "../../lib/file";
import { HouseholdContext } from "./HouseholdInfo";

export function useQueryHouseholdTransactions(householdId: number) {
  return useAuthenticatedQuery<
    UnpackResponse<TransactionsController["getTransactionsForHousehold"]>,
    Transaction[],
    [string, string, number]
  >({
    queryKey: ["transactions", "household", householdId],
    queryFn: async (transactions) =>
      (transactions?.data ?? []).map(deserializeTransaction),
  });
}

export function useQueryExportHouseholdTransactions(householdId: number) {
  return useAuthenticatedQuery<
    UnpackResponse<TransactionsController["exportHouseholdTransactions"]>,
    void,
    [string, string, number, string]
  >({
    queryKey: ["transactions", "household", householdId, "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}

type TransactionRow = Transaction;

const HouseholdTransactions = () => {
  const columnHelper = useMemo(() => createColumnHelper<TransactionRow>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("accountNumber", {
        cell: (info) => (
          <Link to={`/clients/accounts/${info.row.original.accountId}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => "Account Number",
        enableColumnFilter: false,
      }),
    ],
    [columnHelper],
  );

  const { household } = useContext(HouseholdContext);

  const {
    isPending: isPendingTransactions,
    isError: isErrorTransactions,
    data: transactions,
  } = useQueryHouseholdTransactions(household?.id ?? -1);

  const queryExport = useQueryExportHouseholdTransactions(household?.id ?? -1);

  return (
    <TransactionsTable
      additionalColumns={columns}
      isLoading={isPendingTransactions}
      isError={isErrorTransactions}
      transactions={transactions ?? []}
      queryExport={queryExport}
    />
  );
};

export default HouseholdTransactions;
