import { ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import ActionButton from "../components/ActionButton";

const ModelInfoActionButtons = ({
  deleteHandler,
}: {
  deleteHandler: React.MouseEventHandler;
}) => (
  <ButtonToolbar className="mb-3">
    <ButtonGroup className="me-4">
      <ActionButton label="Save" icon="/icons/save.svg" type="submit" />
    </ButtonGroup>
    <ButtonGroup className="me-4">
      <ActionButton
        type="button"
        label="Delete"
        icon="/icons/trash.svg"
        onClick={deleteHandler}
      />
    </ButtonGroup>
    <ButtonGroup>
      <ActionButton
        as={Link}
        to="/models"
        label="Exit"
        icon="/icons/exit.svg"
      />
    </ButtonGroup>
  </ButtonToolbar>
);

export default ModelInfoActionButtons;
