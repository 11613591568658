import { useMutation } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ButtonGroup, ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { Account } from "../../../api/src/accounts/accounts.service";
import type { Household } from "../../../api/src/households-base/households-base.service";
import type { UnpackResponse } from "../../../api/src/lib";
import type { TransfersController } from "../../../api/src/transfers/transfers.controller";
import type {
  ForecastedTransfer,
  ForecastedTransferStatus,
  RecurringTransferFrequency,
} from "../../../api/src/transfers/transfers.service";
import Loading from "../Loading";
import { useQueryAccounts } from "../clients/account/lib";
import { useQueryHouseholds } from "../clients/household/lib";
import ActionButton from "../components/ActionButton";
import { Table, useTable } from "../components/Table/Table";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from "../lib/api";
import { parseISODateNaive, toISODateNoTimezone } from "../lib/date";
import { displayAccountName, unassignedLabel } from "../lib/display";
import { processFileResponse } from "../lib/file";
import { formatCurrency } from "../lib/numbers";
import { displayForecastedTransferFrequency } from "./lib";

function useQueryExport() {
  return useAuthenticatedQuery<
    UnpackResponse<TransfersController["exportForecast"]>,
    void
  >({
    queryKey: ["transfers", "forecast", "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}

type ForecastedTransferRow = ForecastedTransfer & {
  account: Account;
  household: Household;
};

const columnHelper = createColumnHelper<ForecastedTransferRow>();

const ForecastedTransfers = ({
  status,
}: {
  status: ForecastedTransferStatus;
}) => {
  const [transfers, setTransfers] = useState<ForecastedTransferRow[]>([]);
  const [editedRows, setEditedRows] = useState<
    Record<number, ForecastedTransferRow>
  >({});

  const { isPending: isPendingAccounts, data: dataAccounts } = useQueryAccounts(
    { pageSize: 10000 },
  );
  const accounts = dataAccounts?.data;

  const { isPending: isPendingHouseholds, data: dataHouseholds } =
    useQueryHouseholds();
  const households = dataHouseholds?.data;

  const { isPending: isPendingTransfers, data: transfersBody } =
    useAuthenticatedFetch<
      UnpackResponse<TransfersController["getAllForecasted"]>
    >(`/transfers/forecast?status=${status}`);

  useEffect(() => {
    if (
      typeof transfersBody !== "undefined" &&
      typeof accounts !== "undefined" &&
      typeof households !== "undefined"
    ) {
      const transfersInner = transfersBody.data;

      const accountsMap: Record<number, Account> = (accounts ?? []).reduce(
        (result, account) => ({
          ...result,
          [account.id]: account,
        }),
        {},
      );

      const householdsMap: Record<number, Household> = (
        households ?? []
      ).reduce(
        (result, household) => ({
          ...result,
          [household.id]: household,
        }),
        {},
      );

      const combinedTransfersAccountsHouseholds = transfersInner
        ?.map((transfer) => {
          const account = accountsMap[transfer.accountId];

          if (typeof account === "undefined") {
            return null;
          }

          const household =
            typeof transfer.householdId === "undefined"
              ? null
              : householdsMap[transfer.householdId];

          return {
            ...transfer,
            account,
            household,
          };
        })
        .filter((transfer) => transfer !== null) as ForecastedTransferRow[];

      setTransfers(combinedTransfersAccountsHouseholds);
    }
  }, [transfersBody, accounts, households]);

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) =>
          typeof row.household !== "undefined" && row.household !== null
            ? row.household.name
            : "Unassigned",
        {
          id: "householdName",
          cell: (info) =>
            info.getValue() !== "Unassigned" ? (
              <Link
                to={`/clients/households/${
                  info.row.original.householdId ?? ""
                }`}
              >
                {info.getValue()}
              </Link>
            ) : (
              unassignedLabel
            ),
          header: () => "Household",
        },
      ),
      columnHelper.accessor(
        (row) =>
          displayAccountName(
            row.account.displayName,
            row.account.displayNumber,
          ),
        {
          id: "accountName",
          cell: (info) => (
            <Link to={`/clients/accounts/${info.row.original.accountId}`}>
              {info.getValue()}
            </Link>
          ),
          header: () => "Account",
          enableColumnFilter: false,
        },
      ),
      columnHelper.accessor((row) => row.amount ?? 0, {
        id: "amount",
        cell: (info) =>
          typeof info.table.options.meta?.editedRows[info.row.original.id] ===
          "undefined" ? (
            formatCurrency(info.getValue())
          ) : (
            <Form.Control
              type="number"
              value={
                info.table.options.meta?.editedRows[info.row.original.id].amount
              }
              onChange={(ev) => {
                info.table.options.meta?.setEditedRows({
                  ...info.table.options.meta?.editedRows,
                  [info.row.original.id]: {
                    ...info.table.options.meta?.editedRows[
                      info.row.original.id
                    ],
                    amount: parseFloat(ev.target.value),
                  },
                });
              }}
            />
          ),
        header: () => "Amount",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.frequency, {
        id: "frequency",
        cell: (info) =>
          typeof info.table.options.meta?.editedRows[info.row.original.id] ===
          "undefined" ? (
            displayForecastedTransferFrequency(info.getValue())
          ) : (
            <Form.Select
              placeholder="Select frequency"
              value={
                info.table.options.meta?.editedRows[info.row.original.id]
                  .frequency
              }
              onChange={(ev) =>
                info.table.options.meta?.setEditedRows({
                  ...info.table.options.meta?.editedRows,
                  [info.row.original.id]: {
                    ...info.table.options.meta?.editedRows[
                      info.row.original.id
                    ],
                    frequency: ev.target.value as RecurringTransferFrequency,
                  },
                })
              }
            >
              {(
                [
                  "first",
                  "biweekly",
                  "monthly",
                  "quarterly",
                  "semiannually",
                  "annually",
                  "other",
                ] as RecurringTransferFrequency[]
              ).map((freq) => (
                <option key={freq} value={freq}>
                  {displayForecastedTransferFrequency(freq)}
                </option>
              ))}
            </Form.Select>
          ),
        header: () => "Frequency",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.startDate, {
        id: "startDate",
        cell: (info) =>
          typeof info.table.options.meta?.editedRows[info.row.original.id] ===
          "undefined" ? (
            new Date(info.getValue()).toLocaleDateString()
          ) : (
            <Form.Control
              type="date"
              value={toISODateNoTimezone(
                info.table.options.meta?.editedRows[info.row.original.id]
                  .startDate,
              )}
              onChange={(ev) => {
                info.table.options.meta?.setEditedRows({
                  ...info.table.options.meta?.editedRows,
                  [info.row.original.id]: {
                    ...info.table.options.meta?.editedRows[
                      info.row.original.id
                    ],
                    startDate: parseISODateNaive(ev.target.value),
                  },
                });
              }}
            />
          ),
        header: () => "Next Date",
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: "actions",
        cell: (info) => {
          const transfer = info.row.original;
          const setEditedRows =
            info.table.options.meta?.setEditedRows ?? (() => null);
          const editedRows = info.table.options.meta?.editedRows ?? {};
          const editedTransfer = editedRows[transfer.id];
          const isEditing = typeof editedTransfer !== "undefined";

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const approveForecastedTransfer = useAuthenticatedMutation<
            UnpackResponse<
              TransfersController["changeForecastedTransferStatus"]
            >
          >(
            "/transfers/forecast",
            {
              method: "PATCH",
              body: JSON.stringify({
                status: "approved",
                transferIds: [transfer.id],
              }),
            },
            processEmptyResponse,
          );

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const deleteForecastedTransfer = useAuthenticatedMutation<
            UnpackResponse<
              TransfersController["changeForecastedTransferStatus"]
            >
          >(
            "/transfers/forecast",
            {
              method: "PATCH",
              body: JSON.stringify({
                status: "rejected",
                transferIds: [transfer.id],
              }),
            },
            processEmptyResponse,
          );

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const updateForecastedTransfer = useAuthenticatedMutation<
            UnpackResponse<TransfersController["updateForecastedTransfer"]>
          >(
            `/transfers/forecast/${transfer.id}`,
            {
              method: "PUT",
              body: JSON.stringify({
                ...transfer,
                ...(editedTransfer ?? {}),
                startDate:
                  typeof editedTransfer?.startDate === "undefined"
                    ? new Date(transfer.startDate)
                    : typeof editedTransfer.startDate === "object"
                      ? editedTransfer.startDate
                      : new Date(
                          editedTransfer.startDate as string,
                        ).toISOString(),
              }),
            },
            processEmptyResponse,
          );

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const approve = useMutation({
            mutationFn: async () => {
              await approveForecastedTransfer();
              info.table.options.meta?.approveRow(transfer.id);
            },
          });

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const del = useMutation({
            mutationFn: async () => {
              await deleteForecastedTransfer();
              info.table.options.meta?.deleteRow(transfer.id);
            },
          });

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const save = useMutation({
            mutationFn: async () => {
              await updateForecastedTransfer();
              const { [transfer.id]: row, ...otherRows } = editedRows ?? {};
              setEditedRows(otherRows);
              info.table.options.meta?.saveRow(editedTransfer);
            },
          });

          return (
            <>
              {info.row.original.status === "proposed" ? (
                <ActionButton
                  variant="icon"
                  icon="/icons/approve.svg"
                  label="Approve"
                  onClick={() => approve.mutate()}
                  disabled={approve.isPending}
                  type="button"
                  className="me-2"
                />
              ) : isEditing ? (
                <ActionButton
                  variant="icon"
                  icon="/icons/save.svg"
                  label="Save"
                  onClick={() => save.mutate()}
                  disabled={save.isPending}
                  type="button"
                  className="me-2"
                />
              ) : (
                <ActionButton
                  variant="icon"
                  icon="/icons/edit.svg"
                  label="Edit"
                  onClick={() => {
                    setEditedRows({
                      ...(editedRows ?? {}),
                      [transfer.id]: transfer,
                    });
                  }}
                  type="button"
                  className="me-2"
                />
              )}
              <ActionButton
                variant="icon"
                icon="/icons/trash.svg"
                label="Cancel"
                onClick={() => del.mutate()}
                disabled={del.isPending}
                type="button"
              />
            </>
          );
        },
      }),
    ],
    [],
  );

  const saveRow = useCallback(
    (transfer: ForecastedTransferRow) => {
      const matchingTransfer = transfers.find((t) => t.id === transfer.id);

      if (typeof matchingTransfer === "undefined") {
        console.error(
          "Could not update values because transfer no longer exists",
        );

        const remainingTransfers = transfers.filter(
          (t) => t.id !== transfer.id,
        );

        setTransfers(remainingTransfers);
      } else {
        matchingTransfer.amount = transfer.amount;
        matchingTransfer.frequency = transfer.frequency;
        matchingTransfer.startDate = new Date(transfer.startDate);
        setTransfers([...transfers]);
      }
    },
    [transfers],
  );

  const deleteRow = useCallback(
    (id: number) => {
      const remainingTransfers = transfers.filter(
        (transfer) => transfer.id !== id,
      );
      const { [id]: removed, ...editedTransfers } = editedRows;

      setTransfers(remainingTransfers);
      setEditedRows(editedTransfers);
    },
    [transfers, editedRows],
  );

  const approveRow = useCallback(
    (id: number) => {
      const remainingTransfers = transfers.filter(
        (transfer) => transfer.id !== id,
      );
      const { [id]: removed, ...editedTransfers } = editedRows;

      setTransfers(remainingTransfers);
      setEditedRows(editedTransfers);
    },
    [transfers, editedRows],
  );

  const { table } = useTable({
    columns,
    data: transfers,
    getRowId: (row) => row.id.toString(),
    meta: {
      editedRows,
      setEditedRows,
      saveRow,
      deleteRow,
      approveRow,
    },
  });

  const { isLoading: isLoadingExport, refetch: refetchExport } =
    useQueryExport();

  const onExport = useCallback(async () => {
    await refetchExport();
  }, [refetchExport]);

  return (
    <>
      {status === "proposed" ? null : (
        <Row>
          <Col>
            <ButtonToolbar className="mb-3">
              <ButtonGroup className="me-3">
                <ActionButton
                  as={Link}
                  to="new"
                  variant="secondary"
                  label="Add New Cashflow"
                  icon="/icons/new.svg"
                />
              </ButtonGroup>
              <ButtonGroup className="me-3">
                <ActionButton
                  variant="secondary"
                  label="Export"
                  icon="/icons/folded-list.svg"
                  onClick={onExport}
                  className="me-2"
                  disabled={isLoadingExport}
                />
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {isPendingTransfers || isPendingAccounts || isPendingHouseholds ? (
            <Loading />
          ) : (
            <Table table={table} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ForecastedTransfers;
