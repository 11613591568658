import type { SecurityCategoryAssetClassColumns } from "../../../api/src/firms/firms.service";
import type { UnpackResponse } from "../../../api/src/lib";
import type { UsersController } from "../../../api/src/users/users.controller";
import type { WealthboxUser } from "../../../api/src/wealthbox/users.service";
import { useAuthenticatedQuery } from "../lib/api";

export function useQueryCrmUsers({ page = 1, enabled = true } = {}) {
  return useAuthenticatedQuery<
    UnpackResponse<UsersController["getAllCrmUsers"]>,
    { data: WealthboxUser[]; page: number },
    [string, string, number]
  >({
    queryKey: ["users", "crm", page],
    queryFn: async (users) => ({
      data: users.data,
      page,
    }),
    urlBuilderFn: (key) => `/${key[0]}/${key[1]}?page=${key[2]}`,
    queryOptions: { enabled },
  });
}

export const securityCategoryAssetClassColumnsMap = new Map(
  Object.entries({
    usLargeCapEquity: {
      col: "us_large_cap_equity",
      label: "US Large Cap Equity",
    },
    usSmallCapEquity: {
      col: "us_small_cap_equity",
      label: "US Small Cap Equity",
    },
    devUsEquity: {
      col: "dev_us_equity",
      label: "Developed ex US Equity",
    },
    marketEquity: {
      col: "market_equity",
      label: "Emerging Markets Equity",
    },
    realEstate: {
      col: "real_estate",
      label: "Real Estate",
    },
    commoditiesAlternatives: {
      col: "commodities_alternatives",
      label: "Commodoties & Alternatives",
    },
    usShortTermBonds: {
      col: "us_short_term_bonds",
      label: "US Short-Term Bonds",
    },
    intermediateTermBonds: {
      col: "intermediate_term_bonds",
      label: "US Intermediate-Term Bonds",
    },
    usLongTermBonds: {
      col: "us_long_term_bonds",
      label: "US Long-Term Bonds",
    },
    inflationLinkedBonds: {
      col: "inflation_linked_bonds",
      label: "Inflation-Linked Bonds",
    },
    usHighYieldBonds: {
      col: "us_high_yield_bonds",
      label: "US High-Yield Bonds",
    },
    usTaxExemptBonds: {
      col: "us_tax_exempt_bonds",
      label: "US Tax Exempt Bonds",
    },
    foreignBonds: {
      col: "foreign_bonds",
      label: "Foreign Bonds",
    },
    cashEquivalents: {
      col: "cash_equivalents",
      label: "Cash & Cash Equivalents",
    },
  }),
) as Map<
  SecurityCategoryAssetClassColumns,
  {
    col: string;
    label: string;
  }
>;
