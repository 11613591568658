import { useMemo } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { ProfileController } from "../../../api/src/profile/profile.controller";
import type { TasksController } from "../../../api/src/tasks/tasks.controller";
import Loading from "../Loading";
import TabContainerWithTabs from "../components/TabContainer";
import { useAuthenticatedFetch } from "../lib/api";
import TaskList from "../tasks/TaskList";
import { deserializeTask } from "../tasks/lib";
import DashboardNav from "./DashboardNav";

const AssignedTasks = () => {
  const { isPending: isPendingProfile, data: dataProfile } =
    useAuthenticatedFetch<UnpackResponse<ProfileController["getProfile"]>>(
      "/profile",
    );
  const user = dataProfile?.data;

  const { isFetching: isFetchingTasks, data: dataTasks } =
    useAuthenticatedFetch<
      SerializedObject<UnpackResponse<TasksController["getAllAssignedTasks"]>>
    >("/tasks/assigned", undefined, {
      enabled: user?.connections.wealthbox ?? false,
    });
  const tasks = useMemo(
    () => (dataTasks?.data ?? []).map(deserializeTask),
    [dataTasks?.data],
  );

  return (
    <TabContainerWithTabs tabs={DashboardNav}>
      <Row>
        <Col>
          {(!isFetchingTasks && tasks.length <= 0) ||
          (!isPendingProfile && !(user?.connections.wealthbox ?? false)) ? (
            <Alert>No Tasks</Alert>
          ) : isFetchingTasks || isPendingProfile ? (
            <Loading message="Tasks" />
          ) : (
            <TaskList tasks={tasks} hideAssignee />
          )}
        </Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default AssignedTasks;
