import {
  ColumnFiltersState,
  Header,
  PaginationState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import {
  Alert,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import type { ContactsController } from "../../../../api/src/contacts/contacts.controller";
import type { Contact } from "../../../../api/src/contacts/lib";
import type { SerializedObject, UnpackResponse } from "../../../../api/src/lib";
import type { WealthboxTag } from "../../../../api/src/wealthbox/lib";
import Loading from "../../Loading";
import ActionButton from "../../components/ActionButton";
import MultiSelector from "../../components/MultiSelector";
import TabContainerWithTabs from "../../components/TabContainer";
import { Table, useTable } from "../../components/Table/Table";
import { TagFilter, multiSelectIncludes } from "../../components/Table/filters";
import {
  onColumnFiltersChange,
  onPaginationChange,
  onSortingChange,
  useTableSettings,
} from "../../components/Table/tableSettings";
import { useAuthenticatedFetch, useAuthenticatedQuery } from "../../lib/api";
import { capitalize } from "../../lib/display";
import ManageNav from "../ManageNav";
import { processFileResponse } from "../../lib/file";
import { HOUSEHOLD_TITLES_OPTIONS, deserializeContact } from "./lib";

type ContactRow = Pick<
  Contact,
  | "id"
  | "household"
  | "name"
  | "givenName"
  | "familyName"
  | "prefix"
  | "suffix"
  | "birthDate"
  | "title"
  | "type"
  | "maritalStatus"
  | "tags"
>;

const columnHelper = createColumnHelper<ContactRow>();

export const TitleFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const onChange = useCallback(
    (values: number[]) => {
      header.column.setFilterValue(values);
    },
    [header.column],
  );

  return (
    <MultiSelector
      options={HOUSEHOLD_TITLES_OPTIONS}
      placeholder="All"
      setValue={onChange}
    />
  );
};

function useQueryExportcontacts() {
  return useAuthenticatedQuery<
    UnpackResponse<ContactsController["exportContacts"]>,
    void,
    [string, string]
  >({
    queryKey: ["contacts", "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}

const Contacts = () => {
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => (
          <Link to={`/clients/contacts/${info.row.original.id}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => "Name",
      }),
      columnHelper.accessor("type", {
        cell: (info) => capitalize(info.getValue()),
        header: () => "Type",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.household?.name, {
        id: "householdName",
        cell: (info) =>
          typeof info.getValue() === "undefined" ? null : (
            <Link to={`/clients/households/${info.row.original.household?.id}`}>
              {info.getValue()}
            </Link>
          ),
        header: () => "Household",
        enableColumnFilter: false,
        minSize: 225,
      }),
      columnHelper.accessor(
        (row) => {
          const option = HOUSEHOLD_TITLES_OPTIONS.find(
            (a) => a.label === capitalize(row.title ?? ""),
          );
          return option?.value.toString() ?? "";
        },
        {
          id: "title",
          cell: (info) => {
            const option = HOUSEHOLD_TITLES_OPTIONS.find(
              (a) => a.value.toString() === info.getValue(),
            );
            return option?.label ?? "";
          },
          header: () => "Title",
          filterFn: multiSelectIncludes,
          meta: {
            filterComponent: TitleFilter,
          },
        },
      ),
      columnHelper.accessor("birthDate", {
        cell: (info) => info.getValue()?.toLocaleDateString(),
        header: () => "Birth Date",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("maritalStatus", {
        cell: (info) => capitalize(info.getValue() ?? ""),
        header: () => "Marital Status",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => (row.tags ?? []).map((tag) => tag.id), {
        id: "tags",
        cell: (info) =>
          (info.row.original.tags ?? []).map((tag: WealthboxTag) => (
            <Badge key={tag.id} bg="secondary" pill className="me-1">
              {tag.name}
            </Badge>
          )),
        header: () => "Tag",
        minSize: 100,
        filterFn: multiSelectIncludes,
        meta: {
          filterComponent: TagFilter,
        },
      }),
    ],
    [],
  );

  const { isPending, isError, data } =
    useAuthenticatedFetch<
      SerializedObject<UnpackResponse<ContactsController["getAll"]>>
    >(`/contacts`);

  const contacts = useMemo(
    () => (data?.data ?? []).map(deserializeContact),
    [data?.data],
  );

  const [tableSettings, setTableSettings] = useTableSettings("contacts", {
    sorting: [{ id: "lastName", desc: false }],
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    tableSettings.filters,
  );
  const [sorting, setSorting] = useState<SortingState>(tableSettings.sorting);
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: tableSettings.pageSize,
    pageIndex: 0,
  });

  const { table } = useTable({
    columns,
    data: contacts,
    getRowId: (row) => row.id.toString(),
    state: { columnFilters, sorting, pagination },
    onColumnFiltersChange: onColumnFiltersChange(
      columnFilters,
      setColumnFilters,
      tableSettings,
      setTableSettings,
    ),
    onSortingChange: onSortingChange(
      sorting,
      setSorting,
      tableSettings,
      setTableSettings,
    ),
    onPaginationChange: onPaginationChange(
      pagination,
      setPagination,
      tableSettings,
      setTableSettings,
    ),
  });
  const { isLoading: isLoadingExport, refetch: refetchExport } =
    useQueryExportcontacts();

  const onExport = useCallback(async () => {
    await refetchExport();
  }, [refetchExport]);

  return (
    <TabContainerWithTabs tabs={ManageNav}>
      <Row>
        <Col>
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                as={Link}
                to="/clients/contacts/new"
                label="New"
                icon="/icons/new.svg"
              />
            </ButtonGroup>
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Connect CRM"
                as={Link}
                icon="/icons/folded-list.svg"
                to="/clients/contacts/mapping"
              />
            </ButtonGroup>
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Export"
                icon="/icons/folded-list.svg"
                onClick={onExport}
                disabled={isLoadingExport}
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          {isPending ? (
            <Loading />
          ) : isError ? (
            <Alert variant="danger">Failed to load contacts</Alert>
          ) : (
            <Table table={table} />
          )}
        </Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default Contacts;
