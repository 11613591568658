import { useContext, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import AllocationBar from "../../components/AllocationBar/AllocationBar";
import PerformancePeriodSelector from "../../components/PerformancePeriodSelector";
import { useAuthenticatedFetch } from "../../lib/api";
import { formatCurrencyComponent, formatPercent } from "../../lib/numbers";
import { getRiskAllocation } from "../../rebalances/lib";
import { HouseholdContext } from "./HouseholdInfo";

const HouseholdPerformance = ({
  rowSelection = {},
  useall = false,
}: {
  rowSelection?: Record<string, boolean>;
  useall?: boolean;
}) => {
  const { household } = useContext(HouseholdContext);
  const { householdId } = useParams();

  const [customPeriodParams, setCustomPeriodParams] = useState("");
  const {
    data: dataHouseholdAccountPerformance,
    isPending: isPendingHouseholdAccountPerformance,
    isError: isErrorHouseholdAccountPerformance,
  } = useAuthenticatedFetch<
    UnpackResponse<HouseholdsController["getHouseholdPerformance"]>
  >(`/households/${householdId}/performance?${customPeriodParams}`);
  const householdAccountPerformance = dataHouseholdAccountPerformance?.data;

  // The user can filter out accounts from this calculation, so we need to
  // calculate these totals in the frontend for the selected accounts only.
  const accountsData =
    useall ||
    Object.keys(rowSelection).length === (household?.accounts ?? []).length
      ? (household?.accounts ?? [])
      : (household?.accounts ?? []).filter(
          (account) => rowSelection[account.id] === true,
        );
  const householdTotalsCurrent = accountsData.reduce(
    (sums, account) => ({
      aum: sums.aum + (account.accountBalance ?? 0),
      cash: sums.cash + (account.cashBalance ?? 0),
    }),
    {
      aum: 0,
      cash: 0,
    },
  );

  // The user can filter out accounts from this calculation, so we need to
  // calculate these totals in the frontend for the selected accounts only.
  const householdAccountPerformanceData =
    useall ||
    Object.keys(rowSelection).length === (household?.accounts ?? []).length
      ? (householdAccountPerformance ?? [])
      : (householdAccountPerformance ?? []).filter(
          (account) =>
            typeof account.accountId !== "undefined" &&
            rowSelection[account.accountId] === true,
        );

  const householdPerformance = householdAccountPerformanceData.reduce(
    (sums, account) => ({
      startingBalance:
        (sums.startingBalance ?? 0) + (account.startingBalance ?? 0),
      balance: sums.balance + account.balance,
      income: sums.income + account.income,
      contributions: sums.contributions + account.contributions,
      withdrawals: sums.withdrawals + account.withdrawals,
      performanceIncome: sums.performanceIncome + account.performanceIncome,
      // Weight the account performance before adding it to the total
      performance:
        sums.performance +
        (account.performance * account.balance) / householdTotalsCurrent.aum,
    }),
    {
      startingBalance: 0,
      balance: 0,
      income: 0,
      contributions: 0,
      withdrawals: 0,
      performanceIncome: 0,
      performance: 0,
    },
  );

  return (
    <Row className="mb-2">
      <Col>
        <Row>
          <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
            Risk Allocation
          </Col>
          <Col as="dd">
            <AllocationBar
              data={household?.riskAllocation?.current}
              converter={getRiskAllocation}
            />
          </Col>
        </Row>
        <PerformancePeriodSelector
          setCustomPeriodParams={setCustomPeriodParams}
        />
        {isPendingHouseholdAccountPerformance ? (
          <Loading message="Performance" />
        ) : isErrorHouseholdAccountPerformance ? (
          <Alert variant="danger">Failed to load performance</Alert>
        ) : (
          <>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Performance
              </Col>
              <Col as="dd">
                {formatPercent(householdPerformance.performance, 2)}
              </Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Dividends/Interest
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(householdPerformance.income, 2)} (
                {formatPercent(
                  householdTotalsCurrent.aum === 0
                    ? Infinity
                    : householdPerformance.income / householdTotalsCurrent.aum,
                  2,
                )}
                )
              </Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Contributions
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(householdPerformance.contributions, 2)}{" "}
                (
                {formatPercent(
                  householdTotalsCurrent.aum === 0
                    ? Infinity
                    : householdPerformance.contributions /
                        householdTotalsCurrent.aum,
                  2,
                )}
                )
              </Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Distributions
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(householdPerformance.withdrawals, 2)} (
                {formatPercent(
                  householdTotalsCurrent.aum === 0
                    ? Infinity
                    : householdPerformance.withdrawals /
                        householdTotalsCurrent.aum,
                  2,
                )}
                )
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default HouseholdPerformance;
