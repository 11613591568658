import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { WebAuth } from "auth0-js";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  InputGroup,
  ListGroup,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import type { AuthController } from "../../../api/src/auth/auth.controller";
import type { AppTheme } from "../../../api/src/auth0/auth0.service";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ProfileController } from "../../../api/src/profile/profile.controller";
import type { EditableUser, User } from "../../../api/src/users/users.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import Content from "../components/Content";
import FormError from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import InfoTooltip from "../components/InfoTooltip";
import MaskedInput from "../components/MaskedInput";
import SubmitButton from "../components/SubmitButton";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutation,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import { ThemeContext } from "../lib/display";
import {
  emailSchema,
  familyNameSchema,
  getSchemaFieldLabel,
  givenNameSchema,
  phoneNumberSchema,
} from "../lib/forms";
import { phoneMask } from "../lib/masks";
import IntegrationDisconnectConfirmation from "./IntegrationDisconnectConfirmation";
import ProfilePicture from "./ProfilePicture";

type ProfileEditableUser = EditableUser & {
  email: string;
  avatar?: File | null;
};

const SUPPORTED_IMAGE_SIZE = 5000 * 1024;

const schema: yup.ObjectSchema<ProfileEditableUser> = yup.object({
  givenName: givenNameSchema.required(),
  familyName: familyNameSchema.required(),
  email: emailSchema.defined(),
  phoneNumber: phoneNumberSchema,
  avatar: yup
    .mixed<File>()
    .nullable()
    .test("fileSize", "Image is too large", (file?: File | null) => {
      if (typeof file === "undefined" || file === null) return true;
      if (file.size > SUPPORTED_IMAGE_SIZE) {
        return false;
      }
      return true;
    }),
  theme: yup
    .string<AppTheme>()
    .oneOf(["system", "light", "dark"])
    .default("system")
    .label("Color Theme"),
});

const EmailField = ({ user }: { user: User }) => {
  const { logout } = useAuth0();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    clearErrors: clearFormErrors,
    setError: setFormError,
    setFocus,
  } = useForm<ProfileEditableUser>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const notificationContext = useContext(NotificationContext);
  const [isChangingEmail, setIsChangingEmail] = useState(false);

  useEffect(() => {
    reset(user);
  }, [user, reset]);

  const verifyEmail = useAuthenticatedMutation<
    UnpackResponse<ProfileController["verifyEmail"]>
  >("/profile/verify-email", {
    method: "POST",
    body: JSON.stringify({ clientId: process.env.REACT_APP_AUTH0_CLIENT_ID }),
  });

  const onVerifyEmail = useMutation({
    mutationFn: async () => {
      clearFormErrors("email");

      try {
        await verifyEmail();
        notificationContext.pushNotification({
          id: `user-${user.id}`,
          header: "Email Verification Sent",
          body: `Email verification sent to ${user.email} updated`,
          variant: "success",
        });
      } catch (err) {
        const message = "Failed to send verification email";
        console.error(message, err);
        setFormError("email", { type: "custom", message });
      }
    },
  });

  const saveEmail = useAuthenticatedMutationAsync<
    UnpackResponse<ProfileController["changeEmail"]>
  >(
    "/profile/change-email",
    async (user: User) => ({
      method: "POST",
      body: JSON.stringify({
        email: user.email,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      }),
    }),
    processEmptyResponse,
  );

  const onSubmit = useMutation({
    mutationFn: async (data: ProfileEditableUser) => {
      clearFormErrors("email");

      if (user.email === data.email) {
        const message = "The email address was not modified";
        setFormError("email", { type: "custom", message });
      } else {
        try {
          await saveEmail(data);
          setIsChangingEmail(false);
          notificationContext.pushNotification({
            id: `user-${user.id}`,
            header: "User Email Saved",
            body: `Email changed to ${data.email}`,
            variant: "success",
          });
          logout({ logoutParams: { returnTo: window.location.href } });
        } catch (err) {
          const message = "Failed to save email";
          console.error(message, err);
          setFormError("email", { type: "custom", message });
        }
      }
    },
  });

  const change = useCallback(() => {
    setIsChangingEmail(true);
    setFocus("email");
  }, [setFocus]);

  const cancel = useCallback(() => {
    setIsChangingEmail(false);
    reset(user);
  }, [user, reset]);

  return (
    <Form.Group className="mb-3" controlId="form-email">
      <Form.Label>{getSchemaFieldLabel(schema.fields.email)}</Form.Label>
      <InputGroup>
        <Form.Control
          type="email"
          disabled={!isChangingEmail || isSubmitting}
          {...register("email")}
        />
        {isChangingEmail ? (
          <>
            <Button variant="secondary" type="button" onClick={cancel}>
              Cancel
            </Button>
            <SubmitButton
              type="button"
              isSubmitting={isSubmitting}
              onClick={handleSubmit((data) => onSubmit.mutateAsync(data))}
            >
              Save
            </SubmitButton>
          </>
        ) : (
          <>
            {user.emailVerified ? null : (
              <Button
                variant="warning"
                type="button"
                onClick={() => onVerifyEmail.mutate()}
              >
                Verify
              </Button>
            )}
            <Button type="button" onClick={change}>
              Change
            </Button>
          </>
        )}
      </InputGroup>
      <FormFieldError field={errors.email} />
      <Form.Text>
        {user.emailVerified ? (
          <span className="text-success">Verified</span>
        ) : (
          <span className="text-warning">Unverified</span>
        )}
      </Form.Text>
    </Form.Group>
  );
};

const PasswordField = ({ user }: { user: User }) => {
  const [isChanging, setIsChanging] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string>();

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useCallback(async () => {
    setIsChanging(true);
    setSuccess(undefined);
    setError(undefined);

    const auth0 = new WebAuth({
      domain:
        process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN ??
        process.env.REACT_APP_AUTH0_DOMAIN ??
        "",
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? "",
    });

    auth0.changePassword(
      {
        email: user.email,
        connection: process.env.REACT_APP_AUTH0_CONNECTION_NAME ?? "",
      },
      (err, result) => {
        setIsChanging(false);

        if (err === null) {
          setSuccess(result);
          notificationContext.pushNotification({
            id: `user-${user.id}`,
            header: "Password Updated",
            body: "User password updated",
            variant: "success",
          });
        } else {
          const message = "Failed to change password";
          console.error(message, err);
          setError(message);
        }
      },
    );
  }, [notificationContext, user.email, user.id]);

  return (
    <Form.Group className="mb-3" controlId="form-password">
      <Form.Label>Password</Form.Label>
      <div>
        <SubmitButton
          label="Change Password"
          type="button"
          onClick={onSubmit}
          isSubmitting={isChanging}
        />
      </div>
      {typeof success === "undefined" ? null : (
        <Form.Text className="text-success">{success}</Form.Text>
      )}
      {typeof error === "undefined" ? null : (
        <Form.Text className="text-danger">{error}</Form.Text>
      )}
    </Form.Group>
  );
};

const UserProfile = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    setValue,
  } = useForm<ProfileEditableUser>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    data: dataProfile,
    isPending,
    isError,
    refetch: refetchProfile,
  } = useAuthenticatedFetch<UnpackResponse<ProfileController["getProfile"]>>(
    "/profile",
  );
  const user = dataProfile?.data;

  useEffect(() => {
    if (typeof dataProfile !== "undefined") {
      reset(dataProfile.data);
    }
  }, [dataProfile, reset]);

  const updateProfile = useAuthenticatedMutationAsync<
    UnpackResponse<ProfileController["updateProfile"]>
  >("/profile", async ({ email, avatar, ...data }: ProfileEditableUser) => ({
    method: "PUT",
    body: JSON.stringify(data),
  }));

  const uploadAvatar = useAuthenticatedMutationAsync<
    UnpackResponse<ProfileController["uploadAvatar"]>
  >("/profile/avatar-upload", async (avatar: File | null) => {
    const formData = new FormData();
    formData.append("avatar", avatar === null ? "" : avatar);
    return {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    };
  });

  const queryClient = useQueryClient();
  const notificationContext = useContext(NotificationContext);
  const { setTheme } = useContext(ThemeContext);

  const onSubmit = useMutation({
    mutationFn: async (data: ProfileEditableUser) => {
      try {
        if (typeof data.avatar !== "undefined") {
          const updatedAvatar = await uploadAvatar(data.avatar);
          queryClient.setQueryData(["/profile/avatar"], {
            data: updatedAvatar.data,
          });
        }
        const body = await updateProfile(data);
        // Strip out the email property since we use a separate flow for editing it
        const updatedUser = body.data;
        reset(updatedUser);

        if (typeof body.data.theme !== "undefined") {
          setTheme(body.data.theme);
        }

        notificationContext.pushNotification({
          id: `user-${user?.id}`,
          header: "User Profile Updated",
          body: "User profile updated",
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save user profile", err);
        notificationContext.pushNotification({
          id: `user-${user?.id}`,
          header: "Failed to Update User Profile",
          body: "User profile was not save",
          variant: "danger",
        });
      }
    },
  });

  const connectWealthbox = useCallback(() => {
    const wealthboxOAuthUrl = `${process.env.REACT_APP_WEALTHBOX_APP_ROOT}/oauth/authorize?client_id=${process.env.REACT_APP_WEALTHBOX_CLIENT_ID}&redirect_uri=${window.location.origin}/auth/wealthbox&response_type=code&scope=login+data`;
    window.open(
      wealthboxOAuthUrl,
      undefined,
      "popup,innerWidth=780,innerHeight=520",
    );
  }, []);

  const connectRedtail = useCallback(() => {
    const redtailOAuthUrl = `/integrations/login/redtail`;
    window.open(
      redtailOAuthUrl,
      undefined,
      "popup,innerWidth=480,innerHeight=460",
    );
  }, []);

  const [confirmingDisconnect, setConfirmingDisconnect] = useState<
    string | undefined
  >();
  const [isDisconnecting, setIsDisconnecting] = useState<
    Record<string, boolean>
  >({});

  const disconnect = useAuthenticatedMutationAsync<
    UnpackResponse<AuthController["disconnectService"]>
  >("/auth/disconnect", async (service: string) => ({
    method: "POST",
    body: JSON.stringify({
      service,
    }),
  }));

  const onDisconnect = useCallback(
    async (service?: string) => {
      if (typeof service === "undefined") {
        console.error("Service to disconnect not provided");
      } else {
        setIsDisconnecting({ ...isDisconnecting, [service]: true });
        await disconnect(service);
        await refetchProfile();
        setIsDisconnecting({ ...isDisconnecting, [service]: false });
      }
    },
    [disconnect, refetchProfile, isDisconnecting],
  );

  const [avatar, setAvatar] = useState("");

  const uploadRef = useRef<HTMLInputElement | null>(null);

  const handleSelectAvatar = useCallback(() => {
    uploadRef.current?.click();
  }, [uploadRef]);

  const handleClearAvatar = useCallback(() => {
    setAvatar("Original");
    setValue("avatar", null);
  }, [setValue]);

  const onChangeAvatar = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file: File | null =
        e.target.files === null ? null : e.target.files[0];
      if (file) {
        setAvatar(URL.createObjectURL(file));
        setValue("avatar", file);
      }
    },
    [setValue],
  );

  const [isOriginalAvatar, setIsOriginalAvatar] = useState(false);

  return isPending ? (
    <Loading />
  ) : isError ? (
    <FormError message="Failed to load user profile" />
  ) : typeof user === "undefined" ? (
    <FormError message="User profile not found" />
  ) : (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      <Row>
        <Col>
          <h1>User Profile</h1>
        </Col>
      </Row>
      <Content>
        <Row>
          <Col xl={3} sm={6} className="mb-3">
            <Form.Group controlId="form-picture">
              <Row>
                <Col xxl={4} md={5} sm={6} xs={4}>
                  <ProfilePicture
                    avatar={avatar}
                    setIsOriginal={setIsOriginalAvatar}
                  />
                </Col>
                <Col xxl={5} xl={7} lg={6} md={5} sm={6} xs={5}>
                  <div className="d-flex flex-column align-items-center">
                    <Button
                      type="button"
                      className="w-100 mb-1 d-flex justify-content-between align-items-center"
                      onClick={handleSelectAvatar}
                      title="Change your avatar"
                    >
                      Upload{" "}
                      <img
                        src="/icons/save.svg"
                        alt="Save icon"
                        height="22"
                        className="svg-light"
                      />
                    </Button>
                    <Button
                      type="button"
                      className="w-100 d-flex justify-content-between align-items-center"
                      onClick={handleClearAvatar}
                      title="Reset your avatar"
                      disabled={
                        avatar === "Original" ||
                        (isOriginalAvatar && avatar.length === 0)
                      }
                    >
                      Reset{" "}
                      <img
                        src="/icons/trash.svg"
                        alt="Trash icon"
                        height="22"
                        width="22"
                        className="svg-light"
                      />
                    </Button>
                  </div>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    ref={uploadRef}
                    onChange={onChangeAvatar}
                    hidden
                  />
                </Col>
              </Row>
              {avatar === "Original" ||
              (isOriginalAvatar && avatar.length === 0) ? (
                <InfoTooltip tooltip="Image provided by Gravatar" />
              ) : null}
            </Form.Group>
            <Row>
              <FormFieldError field={errors.avatar} />
            </Row>
          </Col>
          <Col xl={9} sm={6}>
            <Row>
              <Col xxl={4} xl={6} xs={12}>
                <Form.Group className="mb-3" controlId="form-givenName">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.givenName)}
                  </Form.Label>
                  <Form.Control type="text" {...register("givenName")} />
                  <FormFieldError field={errors.givenName} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xxl={4} xl={6} xs={12}>
                <Form.Group className="mb-3" controlId="form-familyName">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.familyName)}
                  </Form.Label>
                  <Form.Control type="text" {...register("familyName")} />
                  <FormFieldError field={errors.familyName} />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col xxl={6} xl={8} xs={12}>
            <Row>
              <Col md={6} xs={12}>
                <EmailField user={user} />
              </Col>
              <Col md={6} xs={12}>
                <PasswordField user={user} />
              </Col>
              <Col md={6} xs={12}>
                <Form.Group className="mb-3" controlId="form-phoneNumber">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.phoneNumber)}
                  </Form.Label>
                  <MaskedInput
                    name="phoneNumber"
                    type="tel"
                    mask={phoneMask.mask}
                    placeholder="+x (xxx)-xxx-xxxx"
                    control={control}
                  />
                  <FormFieldError field={errors.phoneNumber} />
                </Form.Group>
              </Col>
              <Col md={6} xs={12}>
                <Form.Group className="mb-3" controlId="form-theme">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.theme)}
                  </Form.Label>
                  <Form.Select {...register("theme")}>
                    <option value="system">System (Automatic)</option>
                    <option value="light">Light</option>
                    <option value="dark">Dark</option>
                  </Form.Select>
                  <FormFieldError field={errors.theme} />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <h3>Connected Apps</h3>
          <Col xxl={5} md={6}>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between">
                <div>
                  <Image src="/icons/wealthbox-60x60.png" height={38} rounded />
                  <strong className="ms-2 align-middle">Wealthbox</strong>
                </div>
                <div>
                  {user.connections.wealthbox ? (
                    <>
                      <span className="text-success me-3 align-middle">
                        Connected
                      </span>
                      <Button
                        variant="warning"
                        onClick={() => setConfirmingDisconnect("wealthbox")}
                        disabled={isSubmitting}
                      >
                        Disconnect
                      </Button>
                    </>
                  ) : (
                    <Button onClick={connectWealthbox}>Connect</Button>
                  )}
                </div>
              </ListGroup.Item>
              {process.env.REACT_APP_FEATURE_REDTAIL !== "true" ? null : (
                <ListGroup.Item className="d-flex justify-content-between">
                  <div>
                    <Image src="/icons/redtail-logo.jpg" height={38} rounded />
                    <strong className="ms-2 align-middle">Redtail CRM</strong>
                  </div>
                  <div>
                    {user.connections.redtail ? (
                      <>
                        <span className="text-success me-3 align-middle">
                          Connected
                        </span>
                        <Button
                          variant="warning"
                          onClick={() => setConfirmingDisconnect("redtail")}
                          disabled={isSubmitting}
                        >
                          Disconnect
                        </Button>
                      </>
                    ) : (
                      <Button onClick={connectRedtail}>Connect</Button>
                    )}
                  </div>
                </ListGroup.Item>
              )}
            </ListGroup>
            <IntegrationDisconnectConfirmation
              show={typeof confirmingDisconnect === "string"}
              onConfirm={() => onDisconnect(confirmingDisconnect)}
              onHide={() => setConfirmingDisconnect(undefined)}
              serviceName={confirmingDisconnect ?? ""}
              isSubmitting={isDisconnecting[confirmingDisconnect ?? ""]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} />
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

export default UserProfile;
