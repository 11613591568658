import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Alert } from "react-bootstrap";
import type { DashboardController } from "../../../api/src/dashboard/dashboard.controller";
import type { AggregateHolding } from "../../../api/src/dashboard/dashboard.service";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import SecurityLink from "../components/SecurityLink";
import { Table, useTable } from "../components/Table/Table";
import { useAuthenticatedFetch } from "../lib/api";
import { formatCurrency } from "../lib/numbers";

type HoldingRow = AggregateHolding;

const TopHoldings = () => {
  const columnHelper = useMemo(() => createColumnHelper<HoldingRow>(), []);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "rank",
        cell: (info) => `${info.row.index + 1}.`,
      }),
      columnHelper.accessor("symbol", {
        header: () => "Symbol",
        cell: (info) => (
          <SecurityLink
            symbol={info.getValue()}
            description={info.row.original.description}
          />
        ),
      }),
      columnHelper.accessor("units", {
        cell: (info) => info.getValue().toLocaleString(),
        header: () => "Amount",
        enableColumnFilter: false,
        meta: {
          headerClassName: "text-end",
          className: "text-end",
        },
      }),
      columnHelper.accessor("price", {
        cell: (info) => formatCurrency(info.getValue(), 2),
        header: () => "Unit Price",
        enableColumnFilter: false,
        meta: {
          headerClassName: "text-end",
          className: "text-end",
        },
      }),
      columnHelper.accessor("value", {
        cell: (info) => formatCurrency(info.getValue(), 2),
        header: () => "Value",
        enableColumnFilter: false,
        meta: {
          headerClassName: "text-end",
          className: "text-end",
        },
      }),
    ],
    [columnHelper],
  );

  const { isPending, isError, data } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<DashboardController["getTopHoldings"]>>
  >("/dashboard/holdings/top?count=10");
  const holdings = data?.data;

  const { table } = useTable({
    columns,
    data: holdings ?? [],
    initialState: {
      sorting: [{ id: "value", desc: true }],
    },
    manualPagination: true,
    getRowId: (row) => row.symbol,
    enableFilters: false,
  });

  return isPending ? (
    <Loading message="Top Holdings" />
  ) : isError ? (
    <Alert variant="danger">Failed to load top holdings</Alert>
  ) : (
    <Table table={table} disablePagination />
  );
};

export default TopHoldings;
