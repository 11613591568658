import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ModelsController } from "../../../api/src/models/models.controller";
import type { BenchmarkRequest } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import Content from "../components/Content";
import FormError, { useErrorMessage } from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import SubmitButton from "../components/SubmitButton";
import {
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import { getSchemaFieldLabel, primarySecuritiesSchema } from "../lib/forms";
import BenchmarkDeleteDialog from "./BenchmarkDeleteDialog";
import ModelInfoActionButtons from "./ModelInfoActionButtons";
import ModelPerformance from "./ModelPerformance";
import PrimarySecurities from "./PrimarySecurities";
import { sumModelWeights } from "./lib";

type BenchmarkForm = Omit<BenchmarkRequest, "id">;

const schema: yup.ObjectSchema<BenchmarkForm> = yup
  .object({
    name: yup.string().required().label("Benchmark Name"),
    description: yup.string().label("Description"),
    primarySecurities: primarySecuritiesSchema.required(),
  })
  .test({
    name: "weights-sum-100",
    test: (value) => sumModelWeights(value.primarySecurities) === 100,
    message: "The sum of all weights must equal 100%",
  });

const BenchmarkInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValidating },
    reset,
    control,
    watch,
    getValues,
  } = useForm<BenchmarkForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    fields: primarySecurityFields,
    append: appendPrimarySecurity,
    remove: removePrimarySecurity,
    replace: replacePrimarySecurities,
  } = useFieldArray({
    name: "primarySecurities",
    control,
    keyName: "_field_id",
  });

  const { setError, resetError, errorMessage } = useErrorMessage();

  const { benchmarkId } = useParams();
  const isNew = benchmarkId === "new";

  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    data: dataBenchmark,
  } = useAuthenticatedFetch<UnpackResponse<ModelsController["getBenchmark"]>>(
    `/models/benchmarks/${benchmarkId}`,
    undefined,
    {
      enabled: !isNew,
    },
  );

  useEffect(() => {
    if (typeof dataBenchmark !== "undefined") {
      const { primarySecurities, ...benchmark } = dataBenchmark.data;
      reset(benchmark);
      replacePrimarySecurities(primarySecurities);
    }
  }, [dataBenchmark, reset, replacePrimarySecurities]);

  const createBenchmark = useAuthenticatedMutationAsync<
    UnpackResponse<ModelsController["createBenchmark"]>
  >("/models/benchmarks", async (benchmark: BenchmarkForm) => ({
    method: "POST",
    body: JSON.stringify(benchmark),
  }));

  const updateBenchmark = useAuthenticatedMutationAsync<
    UnpackResponse<ModelsController["updateBenchmark"]>
  >(`/models/benchmarks/${benchmarkId}`, async (benchmark: BenchmarkForm) => ({
    method: "PUT",
    body: JSON.stringify(benchmark),
  }));

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: BenchmarkForm) => {
      try {
        if (isNew) {
          const body = await createBenchmark(data);
          notificationContext.pushNotification({
            id: `benchmark-${body.data}`,
            header: "Benchmark Created",
            body: `${data.name} benchmark created`,
            variant: "success",
          });
          navigate(`../${body.data}`);
        } else {
          const benchmarkBody = await updateBenchmark(data);
          reset(benchmarkBody.data);
          notificationContext.pushNotification({
            id: `benchmark-${benchmarkId}`,
            header: "Benchmark Updated",
            body: `${data.name} benchmark updated`,
            variant: "success",
          });
        }
      } catch (err) {
        console.error("Failed to save benchmark", err);
        notificationContext.pushNotification({
          id: `benchmark-${benchmarkId}`,
          header: "Failed to Save Benchmark",
          body: `Benchmark ${data.name} was not saved`,
          variant: "danger",
        });
      }
    },
  });

  const watchPrimarySecurityArray = watch("primarySecurities");
  const controlledPrimarySecurityFields = primarySecurityFields.map(
    (field, index) => ({
      ...field,
      ...watchPrimarySecurityArray[index],
    }),
  );

  // react-hook-form does not have form-level validation,
  // so we must perform it manually.
  useEffect(() => {
    if (isValidating) {
      const sum = sumModelWeights(watchPrimarySecurityArray);

      if (sum === 100) {
        resetError();
      } else {
        setError("The sum of all weights must equal 100");
      }
    }
  }, [watchPrimarySecurityArray, setError, resetError, isValidating]);

  const [showModal, setShowModal] = useState(false);
  const [deleteBenchmarkId, setDeleteBenchmarkId] = useState(0);
  const handleDeleteBenchmarkClick = useCallback(() => {
    setDeleteBenchmarkId(benchmarkId ? Number(benchmarkId) : 0);
    setShowModal(true);
  }, [benchmarkId]);

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <FormError message="An error occurred" />
  ) : !dataBenchmark && !isNew ? (
    <FormError message="Benchmark not found" />
  ) : (
    <>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
            <Row>
              <Col>
                <h1>Benchmark Details</h1>
              </Col>
              <Col md="auto">
                <ModelInfoActionButtons
                  deleteHandler={handleDeleteBenchmarkClick}
                />
              </Col>
            </Row>
            <Content>
              {errorMessage}
              <Row>
                <Col xxl={10} md={8}>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="form-name">
                        <Form.Label>
                          {getSchemaFieldLabel(schema.fields.name)}
                        </Form.Label>
                        <Form.Control type="text" {...register("name")} />
                        <FormFieldError field={errors.name} />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="form-description">
                        <Form.Label>
                          {getSchemaFieldLabel(schema.fields.description)}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          {...register("description")}
                        />
                        <FormFieldError field={errors.description} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <h2>Securities</h2>
                  <PrimarySecurities
                    fields={controlledPrimarySecurityFields}
                    replace={replacePrimarySecurities}
                    add={appendPrimarySecurity}
                    remove={removePrimarySecurity}
                    register={register}
                    control={control}
                    getValues={getValues}
                    errors={errors.primarySecurities}
                  />
                  <Row>
                    <Col>
                      <SubmitButton isSubmitting={isSubmitting} />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <ModelPerformance model={getValues()} isLoading={isLoading} />
                </Col>
                <BenchmarkDeleteDialog
                  showModal={showModal}
                  setShowModal={setShowModal}
                  deleteBenchmarkId={
                    deleteBenchmarkId ? Number(deleteBenchmarkId) : 0
                  }
                  setDeleteBenchmarkId={setDeleteBenchmarkId}
                  title="delete-benchmark"
                />
              </Row>
            </Content>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default BenchmarkInfo;
