import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { ModelsController } from "../../../api/src/models/models.controller";
import SubmitButton from "../components/SubmitButton";
import {
  processEmptyResponse,
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../lib/api";

const SleeveDeleteDialog = ({
  showModal,
  setShowModal,
  deleteSleeveId,
  setDeleteSleeveId,
  title,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteSleeveId: number;
  setDeleteSleeveId: React.Dispatch<React.SetStateAction<number>>;
  title: string;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // get models using the sleeve
  const { isLoading: isLoadingSuggestions, data: sleeveAssignments } =
    useAuthenticatedFetch<
      UnpackResponse<ModelsController["getSleeveAssignments"]>
    >(`/models/sleeves/${deleteSleeveId}/assignment`, undefined, {
      enabled: deleteSleeveId !== 0,
    });

  // Delete action
  const handleModalClose = useCallback(() => {
    setDeleteSleeveId(0);
    setShowModal(false);
  }, [setDeleteSleeveId, setShowModal]);

  const deleteSleeve = useAuthenticatedMutationAsync<
    UnpackResponse<ModelsController["deleteSleeve"]>
  >(
    `/models/sleeves/${deleteSleeveId}`,
    async () => ({
      method: "DELETE",
    }),
    processEmptyResponse,
  );

  const handleDeleteSleeve = useMutation({
    mutationFn: () => deleteSleeve(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["/models/sleeves?metrics=true"],
      });
      setShowModal(false);
      navigate("/models/sleeves");
    },
  });

  return (
    <Modal
      show={showModal && !isLoadingSuggestions}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Delete Sleeve</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {sleeveAssignments?.data.length === 0 ? (
          <div className="m-2">
            Are you sure you would like to delete this sleeve?
          </div>
        ) : (
          <>
            <div className="m-2">
              This sleeve is attached to the below models. First, please
              un-attach the sleeve from below models.
            </div>
            <ListGroup as="ul">
              {sleeveAssignments?.data.map((sleeve) => (
                <ListGroup.Item key={sleeve.id} as="li">
                  <Link to={`/models/models/${sleeve.id}`}>
                    <span>{sleeve.name}</span>
                  </Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Discard
        </Button>
        <SubmitButton
          hidden={sleeveAssignments?.data.length !== 0}
          variant="danger"
          label="Delete"
          onClick={() => handleDeleteSleeve.mutate()}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default SleeveDeleteDialog;
