import { FC } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const ReportsNav: FC = () => (
  <nav>
    <Nav variant="tabs" defaultActiveKey="/reports/batches">
      <Nav.Item>
        <NavLink to="/reports/batches" className="nav-link">
          Reports
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/reports/templates" className="nav-link">
          Templates
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/reports/exports" className="nav-link">
          Data Exports
        </NavLink>
      </Nav.Item>
    </Nav>
  </nav>
);

export default ReportsNav;
