import React from "react";
import { Form } from "react-bootstrap";
import { useController } from "react-hook-form";
import { IMaskInput } from "react-imask";

const MaskedInput = (props: any) => {
  const { name, control, mask, ...other } = props;

  const {
    field: { onChange, onBlur, value, ref, name: fieldName },
  } = useController({
    name,
    control,
  });

  return (
    <Form.Control
      as={IMaskInput}
      name={fieldName}
      value={value}
      mask={mask}
      onAccept={(value: any) => {
        onChange({ target: { name: fieldName, value } });
      }}
      onBlur={onBlur}
      inputRef={ref}
      {...other}
    />
  );
};

export default MaskedInput;
