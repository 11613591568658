import React from "react";
import { Col, Row } from "react-bootstrap";
import TabContainerWithTabs from "../components/TabContainer";
import CashNav from "./CashNav";
import ForecastedTransfers from "./ForecastedTransfers";

const RecurringCashPredictions = () => (
  <TabContainerWithTabs tabs={CashNav}>
    <Row>
      {/* <Col md={6} className="mr-3">
        <h2>Predicted Transfers</h2>
        <ForecastedTransfers status="proposed" />
      </Col> */}
      <Col>
        <h2>Approved Transfers</h2>
        <ForecastedTransfers status="approved" />
      </Col>
    </Row>
  </TabContainerWithTabs>
);

export default RecurringCashPredictions;
