import { useAuth0 } from "@auth0/auth0-react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import type { BillingReportJobNotification } from "../../api/src/billing/billing.processor";
import type { ReportJobNotification } from "../../api/src/reports/reports.processor";
import { NotificationContext } from "./Notifications";
import { WebSocketGatewayClient } from "./lib/websocket";

export const WebSocketContext = React.createContext<{
  client: WebSocketGatewayClient | null;
}>({ client: null });

export const WebSocketProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const notificationContext = useContext(NotificationContext);

  const handleProcessReportErroredWS = useCallback(
    (notification: ReportJobNotification) => {
      notificationContext.pushNotification({
        id: `report-${notification.reportBatchId}`,
        header: "Failed to create reports",
        body: notification.message,
        variant: "danger",
      });
    },
    [],
  );

  const handleProcessReportCompletedWS = useCallback(
    (notification: ReportJobNotification) => {
      if (!notification.success) {
        handleProcessReportErroredWS(notification);
      } else {
        notificationContext.pushNotification({
          id: `report-${notification.reportBatchId}`,
          header: "Reports Created",
          body: (
            <>
              Reports are ready!{" "}
              <Link to={`/reports/${notification.reportBatchId}`}>
                [View Reports]
              </Link>
            </>
          ),
          variant: "success",
        });
      }
    },
    [handleProcessReportErroredWS],
  );

  const handleBillingReportErrored = useCallback(
    (notification: BillingReportJobNotification) => {
      notificationContext.pushNotification({
        id: `billing-report-${notification.billingReportId}`,
        header: "Failed to create billing report",
        body: notification.message,
        variant: "danger",
      });
    },
    [],
  );

  const handleBillingReportCompleted = useCallback(
    (notification: BillingReportJobNotification) => {
      if (!notification.success) {
        handleBillingReportErrored(notification);
      } else {
        notificationContext.pushNotification({
          id: `billing-report-${notification.billingReportId}`,
          header: "Billing Report Created",
          body: (
            <>
              Billing report is ready!{" "}
              <Link to={`/billing/reports/${notification.billingReportId}`}>
                [View Report]
              </Link>
            </>
          ),
          variant: "success",
        });
      }
    },
    [handleBillingReportErrored],
  );

  const socketEventHandlers: Record<string, (notification: any) => void> =
    useMemo(
      () => ({
        processReportCompletedWS: handleProcessReportCompletedWS,
        processReportErroredWS: handleProcessReportErroredWS,
        billingReportCompleted: handleBillingReportCompleted,
        billingReportErrored: handleBillingReportErrored,
      }),
      [
        handleBillingReportCompleted,
        handleBillingReportErrored,
        handleProcessReportCompletedWS,
        handleProcessReportErroredWS,
      ],
    );

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [client, setClient] = useState<WebSocketGatewayClient | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      const createClient = async () => {
        const token = await getAccessTokenSilently();
        const client = WebSocketGatewayClient.getInstance(token);
        setClient(client);

        for (const [event, handler] of Object.entries(socketEventHandlers)) {
          client.onNotification(event, handler);
        }
      };

      createClient();
    }
  }, [getAccessTokenSilently, socketEventHandlers, isAuthenticated]);

  useEffect(() => {
    return () => {
      if (client !== null) {
        for (const [event, handler] of Object.entries(socketEventHandlers)) {
          client.offNotification(event, handler);
        }
      }
    };
  }, [client, socketEventHandlers]);

  return (
    <WebSocketContext.Provider value={{ client }}>
      {children}
    </WebSocketContext.Provider>
  );
};
