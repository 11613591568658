import { useEffect, useRef } from "react";
import { Form, FormCheckProps } from "react-bootstrap";

const IndeterminateCheckbox = ({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & FormCheckProps) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <Form.Check
      type="checkbox"
      ref={ref}
      className={`pe-auto ${className}`}
      {...rest}
    />
  );
};

export default IndeterminateCheckbox;
