import type { BillingController } from "../../../api/src/billing/billing.controller";
import type { UnpackResponse } from "../../../api/src/lib";
import { useAuthenticatedQuery } from "../lib/api";
import { processFileResponse } from "../lib/file";

export const feeStructureCycleHelpMessage =
  "For advanced billing, select the month whose end matches the end of the previous billing cycle. For arrears billing, select the month whose end matches the end of the current billing cycle.";

export const standardBillingDateHelpMessage = (
  <section>
    <p>
      Monthly fee structures, along with any fee structures with
      quarterly/annual frequencies whose billing cycle includes a month that
      matches this field, will apply fees in the new report
    </p>
    <p>
      If you run monthly or quarterly billing in advance, select the month that
      just completed to run billing for the period in advance. For example,
      select "June" if you are running billing for July (monthly) or Q3
      (quarterly).
    </p>
  </section>
);

export const periodPartitionHelpMessage =
  "Choose between billing based on the actual days in the billing period (e.g. 31/365 for March) versus a set period in the billing period (e.g. 1/12 for monthly billing, 1/4 for quarterly billing).";

export const BillingHelp = () => (
  <section>
    <p>Billing Reports are the account fee calculations for a given period.</p>
    <h3>Firm-Wide Billing</h3>
    <p>
      <strong>Firm-Wide Billing</strong> uses a standard billing cycle to
      calculate fees for <em>all</em> households and accounts with assigned fee
      structures. Select the month and year whose end date represents the end of
      a billing period for arrears billing, and the day before the start of an
      advance billing cycle. Be aware that AdviceCloud allows you to mix and
      match fee structures with different billing cycles in the same billing
      report. You may have fee structures that are on-cycle for the selected
      month, and other fee structures that are off-cycle for that month. The
      off-cycle fee structures will not accrue any fees for that billing report.
    </p>
    <p>
      For example, say an account has an assigned fee structure with a quarterly
      billing cycle, set to bill in advance for the months of January, April,
      July, and October. If the month of December is selected for the new
      billing report, then no fees will be accrued for that account in the
      generated billing report because it will only bill in January. If the
      month of January is selected, the report will include fees for that
      account for the upcoming months of February, March, and April.
    </p>
    <h3>One-Off Billing</h3>
    <p>
      <strong>One-Off Billing</strong> uses a custom dated billing cycle to
      calculate fees for <em>selected</em> households and accounts with assigned
      fee structures. Select the start and end dates of the billing period. For
      arrears billing, the balance on the end date will be used to determine the
      fee. For advance billing, the day before the start date will be used to
      determine the fee. Be aware that AdviceCloud allows you to mix and match
      fee structures with different billing cycles in the same billing report.
      You may have fee structures assigned that bill in advance <em>and</em>{" "}
      arrears among the selected households and accounts for the One-Off Billing
      Report.
    </p>
  </section>
);

export function feeLocationDisplay(
  feeLocation: "S" | "G" | "A",
  feeLocationAccountName: string = "Relocated",
) {
  return feeLocation === "A"
    ? feeLocationAccountName
    : feeLocation === "G"
      ? "Group"
      : "Self";
}

export function useQueryExportBillingReport(reportId: number) {
  return useAuthenticatedQuery<
    UnpackResponse<BillingController["exportBillingReport"]>,
    void,
    [string, string, number, string]
  >({
    queryKey: ["billing", "reports", reportId, "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}
