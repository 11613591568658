import { Col, ColProps, Form, Row } from "react-bootstrap";

export const OptionalLabelValuePair = (
  props: Parameters<typeof LabelValuePair>[0],
) =>
  typeof props.value === "undefined" ||
  props.value === null ||
  props.value === "" ? null : (
    <LabelValuePair {...props} />
  );

/**
 * A field label and value pair that can be oriented vertically or horizontally.
 * @returns A rendered React element
 */
const LabelValuePair = ({
  label,
  value,
  orientation = "vertical",
  className,
  classNameValue,
  widths,
  plaintext = false,
}: {
  label: string;
  value: React.ReactNode;
  orientation?: "vertical" | "horizontal";
  className?: string;
  classNameValue?: string;
  widths?: {
    label?: Pick<ColProps, "xs" | "sm" | "md" | "lg" | "xl" | "xxl">;
    value?: Pick<ColProps, "xs" | "sm" | "md" | "lg" | "xl" | "xxl">;
  };
  plaintext?: boolean;
}) => {
  const valueComp = !plaintext ? (
    value
  ) : (
    // Use the same Bootstrap classname as the Form.Control component
    <div className="form-control-plaintext">{value}</div>
  );

  return (
    <Form.Group
      as={orientation === "vertical" ? undefined : Row}
      className={className}
    >
      <Form.Label
        column={orientation === "horizontal"}
        {...(orientation === "vertical" ? {} : widths?.label)}
      >
        {label}
      </Form.Label>
      {orientation === "vertical" ? (
        valueComp
      ) : (
        <Col {...widths?.value} className={classNameValue}>
          {valueComp}
        </Col>
      )}
    </Form.Group>
  );
};

export default LabelValuePair;
