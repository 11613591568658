import { Table as TanTable } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

function TablePager<TRow>({
  table,
  totalRows = table?.getPrePaginationRowModel().rows.length ?? 0,
  pageSize = table?.getState().pagination.pageSize ?? 10,
  setPageSize = table?.setPageSize ?? (() => {}),
  currentPage = table?.getState().pagination.pageIndex ?? 0,
  setCurrentPage = table?.setPageIndex ?? (() => {}),
}: {
  table?: TanTable<TRow>;
  totalRows?: number;
  pageSize?: number;
  setPageSize?: (val: number) => void;
  currentPage?: number;
  setCurrentPage?: (val: number) => void;
}) {
  const noOfPages = Math.ceil(totalRows / pageSize);
  const visiblePages: number[] = [];

  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (pageNo: number) => setCurrentPage(pageNo);

  useEffect(() => {
    if (noOfPages === 0 || noOfPages === currentPage + 1) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 0) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  for (let i = currentPage - 2; i < noOfPages && visiblePages.length < 5; i++) {
    if (i >= 0 && i < noOfPages) {
      visiblePages.push(i);
    }
  }

  return (
    <div className="d-flex justify-content-between gap-1 mt-1">
      <div>
        Show{" "}
        <Form.Select
          className="d-inline-block w-auto"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          disabled={noOfPages === 0}
        >
          {[10, 20, 40, 80].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Form.Select>{" "}
        of {totalRows.toLocaleString()}
      </div>
      <div className="d-flex justify-content-end flex-wrap">
        <Button
          variant="outline-secondary"
          className="ms-2"
          onClick={() => setCurrentPage(0)}
          disabled={!canGoBack}
        >
          {"<<"}
        </Button>
        <Button
          variant="outline-secondary"
          className="ms-2"
          onClick={() => onPrevPage()}
          disabled={!canGoBack}
        >
          {"<"}
        </Button>
        {visiblePages.map((pageNumber) => (
          <Button
            key={pageNumber}
            variant="outline-secondary"
            className="ms-2"
            onClick={() => onPageSelect(pageNumber)}
            disabled={currentPage === pageNumber}
          >
            {pageNumber + 1}
          </Button>
        ))}
        <Button
          variant="outline-secondary"
          className="ms-2"
          onClick={() => onNextPage()}
          disabled={!canGoNext}
        >
          {">"}
        </Button>
        <Button
          variant="outline-secondary"
          className="ms-2"
          onClick={() => setCurrentPage(noOfPages - 1)}
          disabled={!canGoNext}
        >
          {">>"}
        </Button>
      </div>
    </div>
  );
}

export default TablePager;
