import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { CashSettingsController } from "../../../../api/src/cash-settings/cash-settings.controller";
import type { SerializedObject, UnpackResponse } from "../../../../api/src/lib";
import { NotificationContext } from "../../Notifications";
import AccountCashInfo, {
  AccountCashSettingFormState,
  schema as cashSettingsSchema,
} from "../../cash/AccountCashInfo";
import SubmitButton from "../../components/SubmitButton";
import { useAuthenticatedMutationAsync } from "../../lib/api";
import { displayAccountName } from "../../lib/display";
import AccountBillingInfo, {
  AccountBillingSettings,
  schema as billingSchema,
} from "./AccountBillingInfo";
import { useQueryAccount } from "./lib";

type AccountCashBillingFormState = AccountCashSettingFormState &
  AccountBillingSettings;

const schema = cashSettingsSchema.concat(billingSchema);

const AccountCashBillingSettings = () => {
  const form = useForm<AccountCashBillingFormState>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const { accountId } = useParams();

  const { data: account } = useQueryAccount(parseInt(accountId ?? ""), {
    includeBalances: true,
  });

  const updateCashSettings = useAuthenticatedMutationAsync<
    SerializedObject<
      UnpackResponse<CashSettingsController["updateAccountSetting"]>
    >
  >(
    `/cash-settings/accounts/${accountId}`,
    async (cashSettings: AccountCashSettingFormState) => ({
      method: "PUT",
      body: JSON.stringify(cashSettings),
    }),
  );

  const updateAccountBilling = useAuthenticatedMutationAsync<
    SerializedObject<UnpackResponse<AccountsController["update"]>>
  >(`/accounts/${accountId}/billing`, async (data: AccountBillingSettings) => ({
    method: "PUT",
    body: JSON.stringify(data),
  }));

  const queryClient = useQueryClient();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async (data: AccountCashBillingFormState) => {
      try {
        const [responseBilling] = await Promise.all([
          updateAccountBilling(data),
          updateCashSettings(data),
        ]);
        queryClient.setQueryData(
          ["accounts", parseInt(accountId ?? ""), true, false, 1],
          {
            data: {
              ...account,
              ...responseBilling.data,
            },
          },
        );
        // reset(bodyCashSettings.data);
        notificationContext.pushNotification({
          id: `cash-billing-${accountId}`,
          header: "Cash Settings Updated",
          body: `Account ${displayAccountName(
            account?.displayName,
            account?.displayNumber,
          )} cash & billing settings updated`,
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save account cash & billing settings", err);
        notificationContext.pushNotification({
          id: `cash-billing-${accountId}`,
          header: "Failed to Save Cash & Billing Settings",
          body: `Account ${displayAccountName(
            account?.displayName,
            account?.displayNumber,
          )} cash & billing settings were not saved`,
          variant: "danger",
        });
      }
    },
  });

  return (
    <Row>
      <Col>
        <FormProvider {...form}>
          <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
            <Row>
              <Col xxl={6}>
                <h4>Cash Settings</h4>
                <AccountCashInfo />
              </Col>
              <Col xxl={6}>
                <h4>Billing Settings</h4>
                <AccountBillingInfo />
              </Col>
            </Row>
            <Row>
              <Col>
                <SubmitButton isSubmitting={isSubmitting} />
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </Col>
    </Row>
  );
};

export default AccountCashBillingSettings;
