import { Col, Row } from "react-bootstrap";
import type { RebalanceMetrics } from "../../../api/src/rebalances/rebalances.service";
import SummaryPill from "../components/SummaryPill";
import { formatCurrency } from "../lib/numbers";

const RebalanceListSummary = ({
  rebalances,
}: {
  rebalances: RebalanceMetrics[];
}) => (
  <Row>
    <Col className="mb-4">
      <SummaryPill
        value={rebalances?.length ?? 0}
        label="Households"
        icon="/icons/folded-list.svg"
      />
    </Col>
    <Col className="mb-4">
      <SummaryPill
        value={formatCurrency(
          rebalances?.reduce(
            (sum, rebalance) => sum + (rebalance.buyTotal ?? 0),
            0,
          ) ?? 0,
        )}
        label="Buy $"
        icon="/icons/buy.svg"
      />
    </Col>
    <Col className="mb-4">
      <SummaryPill
        value={formatCurrency(
          rebalances?.reduce(
            (sum, rebalance) => sum + (rebalance.sellTotal ?? 0),
            0,
          ) ?? 0,
        )}
        label="Sell $"
        icon="/icons/sell.svg"
      />
    </Col>
    <Col className="mb-4">
      <SummaryPill
        value={formatCurrency(
          rebalances?.reduce(
            (sum, rebalance) =>
              sum + (rebalance.buyTotal ?? 0) - (rebalance.sellTotal ?? 0),
            0,
          ) ?? 0,
        )}
        label="Net $"
        icon="/icons/cash-register.svg"
      />
    </Col>
  </Row>
);

export default RebalanceListSummary;
