import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { ReportTemplate } from "../../../api/src/reports/reports.service";
import ActionButton from "../components/ActionButton";
import TabContainerWithTabs from "../components/TabContainer";
import {
  ReportPageOrientationFilter,
  ReportPeriodFilter,
  ReportRateOfReturnFilter,
} from "../components/Table/filters";
import { Table, useTable } from "../components/Table/Table";
import { capitalize } from "../lib/display";
import Loading from "../Loading";
import { useQueryReportTemplates } from "./lib";
import ReportsNav from "./ReportsNav";

const ReportTemplates = () => {
  const columnHelper = useMemo(() => createColumnHelper<ReportTemplate>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => (
          <Link to={`/reports/templates/${info.row.original.id}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => "Name",
      }),
      columnHelper.accessor("period", {
        cell: (info) => info.getValue(),
        header: () => "Period",
        meta: {
          filterComponent: ReportPeriodFilter,
        },
      }),
      columnHelper.accessor("orientation", {
        cell: (info) => capitalize(info.getValue()),
        header: () => "Page Orientation",
        meta: {
          filterComponent: ReportPageOrientationFilter,
        },
      }),
      columnHelper.accessor("rateOfReturnType", {
        cell: (info) => capitalize(info.getValue()),
        header: () => "Rate of Return",
        meta: {
          filterComponent: ReportRateOfReturnFilter,
        },
      }),
      columnHelper.accessor("subReports", {
        cell: (info) => info.getValue().length,
        header: () => "Section Count",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("createdTime", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "Created",
        enableColumnFilter: false,
      }),
    ],
    [columnHelper],
  );

  const { isPending, data: reportTemplates } = useQueryReportTemplates();

  const { table } = useTable({
    columns,
    data: reportTemplates ?? [],
    getRowId: (row) => row.id.toString(),
    initialState: {
      sorting: [{ id: "name", desc: false }],
    },
  });

  return (
    <TabContainerWithTabs tabs={ReportsNav}>
      <Row>
        <Col className="d-flex justify-content-between">
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                as={Link}
                to="new"
                variant="secondary"
                label="New"
                icon="/icons/new.svg"
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          {isPending ? (
            <Loading message="report templates" />
          ) : (
            <Table table={table} />
          )}
        </Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default ReportTemplates;
