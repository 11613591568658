import { UseQueryResult } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/table-core";
import { useCallback, useMemo, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import type { SerializedObject } from "../../../api/src/lib";
import type { Transaction } from "../../../api/src/transactions/lib";
import Loading from "../Loading";
import { deserializeDate } from "../lib/api";
import { naLabel } from "../lib/display";
import { formatCurrency } from "../lib/numbers";
import SecurityLink from "./SecurityLink";
import { Table, useTable } from "./Table/Table";
import {
  TransactionSubTypeFilter,
  TransactionTypeFilter,
  multiSelectIncludes,
  numberStringFilter,
  transactionSubTypeOptions,
  transactionTypeOptions,
} from "./Table/filters";

export function deserializeTransaction(
  transaction: SerializedObject<Transaction>,
): Transaction {
  return {
    ...transaction,
    transactionDate: deserializeDate(transaction.transactionDate),
    reportedDate: deserializeDate(transaction.reportedDate),
    settleDate: deserializeDate(transaction.settleDate),
  };
}

const typeLabelToValue = Object.fromEntries(
  transactionTypeOptions.map((option) => [
    option.label,
    option.value.toString(),
  ]),
);
const typeValueToLabel = Object.fromEntries(
  transactionTypeOptions.map((option) => [
    option.value.toString(),
    option.label,
  ]),
);

const subTypeLabelToValue = Object.fromEntries(
  transactionSubTypeOptions.map((option) => [
    option.label,
    option.value.toString(),
  ]),
);
const subTypeValueToLabel = Object.fromEntries(
  transactionSubTypeOptions.map((option) => [
    option.value.toString(),
    option.label,
  ]),
);

type TransactionRow = Transaction;

const TransactionsTable = ({
  additionalColumns = [],
  isLoading,
  isError,
  transactions,
  queryExport,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalColumns?: ColumnDef<Transaction, any>[];
  isLoading: boolean;
  isError: boolean;
  transactions: Transaction[];
  queryExport?: UseQueryResult<void, Error>;
}) => {
  const columnHelper = useMemo(() => createColumnHelper<TransactionRow>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("transactionDate", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "Transaction Date",
        enableColumnFilter: false,
      }),
      columnHelper.accessor(
        (row) => {
          return typeLabelToValue[row.type] ?? "";
        },
        {
          id: "type",
          cell: (info) => {
            return typeValueToLabel[info.getValue()] ?? "";
          },
          header: () => "Type",
          filterFn: multiSelectIncludes,
          meta: {
            filterComponent: TransactionTypeFilter,
          },
        },
      ),
      columnHelper.accessor(
        (row) => {
          return subTypeLabelToValue[row.subType] ?? "";
        },
        {
          id: "subType",
          cell: (info) => {
            return subTypeValueToLabel[info.getValue()] ?? "";
          },
          header: () => "Sub Type",
          filterFn: multiSelectIncludes,
          meta: {
            filterComponent: TransactionSubTypeFilter,
          },
        },
      ),
      columnHelper.accessor("amount", {
        cell: (info) => {
          const val = info.getValue();
          return val === null ? naLabel : formatCurrency(val, 2);
        },
        header: () => "Amount",
        filterFn: numberStringFilter,
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("securitySymbol", {
        cell: (info) => <SecurityLink symbol={info.getValue() ?? ""} />,
        header: () => "Security Symbol",
      }),
      columnHelper.accessor("unitPrice", {
        cell: (info) => {
          const val = info.getValue();
          return val === null ? naLabel : formatCurrency(val, 2);
        },
        header: () => "Unit Price",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("units", {
        cell: (info) => {
          const val = info.getValue();
          return val === null
            ? naLabel
            : val.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              });
        },
        header: () => "Units",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.description, {
        id: "description",
        header: () => "Description",
      }),
    ],
    [columnHelper],
  );

  const [excludeCashSweep, setExcludeCashSweep] = useState(true);

  const handleCheckedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setExcludeCashSweep(e.target.checked),
    [],
  );

  const { table } = useTable({
    columns: [columns[0], ...additionalColumns, ...columns.slice(1)],
    data: transactions.filter(
      (transaction) => !excludeCashSweep || transaction.subType !== "Sweep",
    ),
    initialState: {
      sorting: [{ id: "transactionDate", desc: true }],
    },
    getRowId: (row) => row.id.toString(),
    autoResetAll: false,
  });

  const onExport = useCallback(() => queryExport?.refetch(), [queryExport]);

  return isLoading ? (
    <Loading message="Transactions" />
  ) : isError ? (
    <Alert variant="danger">An error occurred</Alert>
  ) : (
    <>
      <Row>
        <Col className="d-flex justify-content-start align-items-center gap-3 mb-3">
          <Form.Check
            type="switch"
            label="Exclude Cash Sweep Transactions"
            checked={excludeCashSweep}
            onChange={handleCheckedChange}
          />
          {typeof queryExport === "undefined" ? null : (
            <ButtonToolbar>
              <ButtonGroup>
                <Button
                  type="button"
                  onClick={onExport}
                  disabled={queryExport.isLoading}
                >
                  Export
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Table table={table} />
        </Col>
      </Row>
    </>
  );
};

export default TransactionsTable;
