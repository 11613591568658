import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ColumnFiltersState,
  createColumnHelper,
  OnChangeFn,
  PaginationState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import {
  Alert,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Row,
} from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { HouseholdWithMetrics } from "../../../../api/src/households/households.service";
import type { SerializedObject, UnpackResponse } from "../../../../api/src/lib";
import type { Model } from "../../../../api/src/models/models.service";
import Loading from "../../Loading";
import ActionButton from "../../components/ActionButton";
import { useErrorMessage } from "../../components/FormError";
import IndeterminateCheckbox from "../../components/IndeterminateCheckbox";
import ModelAssignDialog from "../../components/ModelAssignDialog";
import TabContainerWithTabs from "../../components/TabContainer";
import { Table, useTable } from "../../components/Table/Table";
import {
  AssignedToFilter,
  multiSelectIncludes,
  StatusFilter,
  statusOptions,
  TagFilter,
} from "../../components/Table/filters";
import {
  onColumnFiltersChange,
  onPaginationChange,
  onSortingChange,
  useTableSettings,
} from "../../components/Table/tableSettings";
import { useQueryCrmUsers } from "../../firm/lib";
import { useAuthenticatedMutationAsync } from "../../lib/api";
import { formatCurrency } from "../../lib/numbers";
import { useQueryModels } from "../../models/lib";
import ManageNav from "../ManageNav";
import {
  displayHouseholdStatus,
  useQueryExportHouseholds,
  useQueryHouseholds,
} from "./lib";

type HouseholdRow = Pick<
  HouseholdWithMetrics,
  | "id"
  | "name"
  | "householdBalance"
  | "cashBalance"
  | "isActive"
  | "latestRebalanceId"
  | "latestRebalanceDate"
  | "tags"
> & {
  model?: Model;
  assignedUser?: { id: number; name: string };
};

const columnHelper = createColumnHelper<HouseholdRow>();

const columns = [
  columnHelper.accessor((row) => row.name, {
    id: "name",
    cell: (info) => (
      <Link to={`/clients/households/${info.row.original.id}`}>
        {info.getValue()}
      </Link>
    ),
    header: () => "Name",
    minSize: 225,
  }),
  columnHelper.accessor((row) => row.householdBalance ?? 0, {
    id: "householdBalance",
    cell: (info) => formatCurrency(info.getValue()),
    header: () => "AUM",
    enableColumnFilter: false,
    meta: {
      className: "text-end",
      headerClassName: "text-end",
    },
  }),
  columnHelper.accessor((row) => row.cashBalance ?? 0, {
    id: "cashBalance",
    cell: (info) => formatCurrency(info.getValue()),
    header: () => "Cash",
    enableColumnFilter: false,
    meta: {
      className: "text-end",
      headerClassName: "text-end",
    },
  }),
  columnHelper.accessor((row) => row.model?.name ?? "", {
    id: "model",
    cell: (info) =>
      info.getValue() === "" ? (
        ""
      ) : (
        <Link to={`/models/models/${info.row.original.model?.id}`}>
          {info.getValue()}
        </Link>
      ),
    header: () => "Model",
    minSize: 225,
  }),
  columnHelper.accessor((row) => row.assignedUser?.id.toString() ?? "", {
    id: "assignedUserId",
    cell: (info) => info.row.original.assignedUser?.name ?? "",
    header: () => "Assigned To",
    minSize: 100,
    filterFn: multiSelectIncludes,
    meta: {
      filterComponent: AssignedToFilter,
    },
  }),
  columnHelper.accessor(
    (row) =>
      row.latestRebalanceDate
        ? new Date(row.latestRebalanceDate).toLocaleDateString()
        : "",
    {
      id: "rebalance",
      cell: (info) =>
        info.getValue() === "" ? (
          ""
        ) : (
          <Link to={`/rebalances/${info.row.original.latestRebalanceId}`}>
            {info.getValue()}
          </Link>
        ),
      header: () => "Pending Rebalance",
      enableColumnFilter: false,
    },
  ),
  columnHelper.accessor(
    (row) => {
      const status = statusOptions.find(
        (a) => a.label === displayHouseholdStatus(row),
      );
      return status?.value.toString() ?? "";
    },
    {
      id: "isActive",
      cell: (info) => {
        const status = statusOptions.find(
          (a) => a.value.toString() === info.getValue(),
        );
        return status?.label ?? "";
      },
      header: () => "Status",
      filterFn: multiSelectIncludes,
      meta: {
        filterComponent: StatusFilter,
      },
    },
  ),
  columnHelper.accessor((row) => (row.tags ?? []).map((tag) => tag.name), {
    id: "tags",
    cell: (info) =>
      (info.row.original.tags ?? []).map((tag) => (
        <Badge key={tag.name} bg="secondary" pill className="me-1">
          {tag.name}
        </Badge>
      )),
    header: () => "Tag",
    minSize: 100,
    filterFn: multiSelectIncludes,
    meta: {
      filterComponent: TagFilter,
    },
  }),
];

export const HouseholdsTable = () => {
  const [params] = useSearchParams();
  const filteredIds = params.getAll("id").map((id) => parseInt(id));

  const {
    isPending: isPendingHouseholds,
    isError: isErrorHouseholds,
    data: dataHouseholds,
  } = useQueryHouseholds({ includeBalances: true, includeCRM: true });
  const households = dataHouseholds?.data;

  const {
    isPending: isPendingModels,
    isError: isErrorModels,
    data: dataModels,
  } = useQueryModels();
  const models = dataModels?.data;

  const {
    isPending: isPendingAssignees,
    isError: isErrorAssignees,
    data: dataAssignees,
  } = useQueryCrmUsers();

  const householdsWithModel = (households ?? []).map((household) => ({
    ...household,
    model: (models ?? []).find(
      (model) => model.id === household.assignedModelId,
    ),
    assignedUser: (dataAssignees?.data ?? []).find(
      (user) => user.id === household.assignedUserId,
    ),
  }));

  const [tableSettings, setTableSettings] = useTableSettings("households", {
    sorting: [{ id: "name", desc: false }],
    filters: [
      {
        id: "isActive",
        value: "Active",
      },
    ],
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    tableSettings.filters,
  );
  const [sorting, setSorting] = useState<SortingState>(tableSettings.sorting);
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: tableSettings.pageSize,
    pageIndex: 0,
  });

  const { table } = useTable({
    columns,
    data: householdsWithModel.filter(
      (household) =>
        filteredIds.length <= 0 || filteredIds.includes(household.id),
    ),
    getRowId: (row) => row.id.toString(),
    state: { columnFilters, sorting, pagination },
    onColumnFiltersChange: onColumnFiltersChange(
      columnFilters,
      setColumnFilters,
      tableSettings,
      setTableSettings,
    ),
    onSortingChange: onSortingChange(
      sorting,
      setSorting,
      tableSettings,
      setTableSettings,
    ),
    onPaginationChange: onPaginationChange(
      pagination,
      setPagination,
      tableSettings,
      setTableSettings,
    ),
    autoResetAll: false,
  });

  return isPendingHouseholds || isPendingModels || isPendingAssignees ? (
    <Loading />
  ) : isErrorHouseholds || isErrorModels || isErrorAssignees ? (
    <Alert variant="danger">An error occurred</Alert>
  ) : !households || households.length <= 0 ? (
    <Alert>No households found</Alert>
  ) : (
    <Table table={table} />
  );
};

const EditableHouseholdsTable = ({
  rowSelection,
  setRowSelection,
  isLoading,
  isError,
  householdsWithModel,
}: {
  rowSelection?: RowSelectionState;
  setRowSelection?: OnChangeFn<RowSelectionState>;
  isLoading: boolean;
  isError: boolean;
  householdsWithModel: HouseholdWithMetrics[];
}) => {
  const [params] = useSearchParams();
  const filteredIds = params.getAll("id").map((id) => parseInt(id));

  const editableColumns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      }),
      ...columns,
    ],
    [],
  );

  const [tableSettings, setTableSettings] = useTableSettings("households", {
    sorting: [{ id: "name", desc: false }],
    filters: [
      {
        id: "isActive",
        value: "Active",
      },
    ],
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    tableSettings.filters,
  );
  const [sorting, setSorting] = useState<SortingState>(tableSettings.sorting);
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: tableSettings.pageSize,
    pageIndex: 0,
  });

  const { table } = useTable({
    columns: editableColumns,
    data: householdsWithModel.filter(
      (household) =>
        filteredIds.length <= 0 || filteredIds.includes(household.id),
    ),
    getRowId: (row) => row.id.toString(),
    state: { rowSelection, columnFilters, sorting, pagination },
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: onColumnFiltersChange(
      columnFilters,
      setColumnFilters,
      tableSettings,
      setTableSettings,
    ),
    onSortingChange: onSortingChange(
      sorting,
      setSorting,
      tableSettings,
      setTableSettings,
    ),
    onPaginationChange: onPaginationChange(
      pagination,
      setPagination,
      tableSettings,
      setTableSettings,
    ),
    autoResetAll: false,
  });

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <Alert variant="danger">An error occurred</Alert>
  ) : !householdsWithModel || householdsWithModel.length <= 0 ? (
    <Alert>No households found</Alert>
  ) : (
    <Table table={table} />
  );
};

const Households = () => {
  const {
    isPending: isPendingHouseholds,
    isError: isErrorHouseholds,
    data: dataHouseholds,
  } = useQueryHouseholds({ includeBalances: true, includeCRM: true });

  const {
    isPending: isPendingModels,
    isError: isErrorModels,
    data: dataModels,
  } = useQueryModels();

  const {
    isPending: isPendingAssignees,
    isError: isErrorAssignees,
    data: dataAssignees,
  } = useQueryCrmUsers();

  const households = useMemo(
    () =>
      typeof dataHouseholds === "undefined"
        ? undefined
        : dataHouseholds.data.map((household) => ({
            ...household,
            model: (dataModels?.data ?? []).find(
              (model) => model.id === household.assignedModelId,
            ),
            assignedUser: (dataAssignees?.data ?? []).find(
              (user) => user.id === household.assignedUserId,
            ),
          })),
    [dataAssignees?.data, dataHouseholds, dataModels?.data],
  );

  const modelOptions = isPendingModels
    ? [
        <option key={null} value="">
          Loading...
        </option>,
      ]
    : isErrorModels
      ? [
          <option key={null} value="">
            There was an error loading models
          </option>,
        ]
      : [
          <option key={null} value="">
            Select a model
          </option>,
          ...(dataModels?.data ?? [])
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
            .map((model) => (
              <option key={model.id} value={model.id}>
                {model.name}
              </option>
            )),
        ];

  const updateHouseholdsModel = useAuthenticatedMutationAsync<
    SerializedObject<
      UnpackResponse<HouseholdsController["bulkAssignModelToHouseholds"]>
    >
  >(
    `/households/model/assign`,
    async (data: {
      modelId: number;
      households: { [key: number]: boolean };
    }) => ({
      method: "PUT",
      body: JSON.stringify(data),
    }),
  );

  const { setError, resetError } = useErrorMessage();
  const [rowSelection, setRowSelection] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedModelId, setModelId] = useState(0);

  const handleAssignModelClick = useCallback(() => {
    setModelId(0);
    setShowModal(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const { isLoading: isLoadingExport, refetch: refetchExport } =
    useQueryExportHouseholds();

  const onExport = useCallback(async () => {
    await refetchExport();
  }, [refetchExport]);

  const queryClient = useQueryClient();

  const onSubmit = useMutation({
    mutationFn: async (data: {
      modelId: number;
      households: { [key: number]: boolean };
    }) => {
      resetError();

      try {
        const updatedData = await updateHouseholdsModel(data);
        const updatedDataDic = _.keyBy(updatedData.data, "household_id");
        setRowSelection({});
        queryClient.setQueryData(
          ["households", { includeAccounts: true, includeBalances: true }, 1],
          {
            data: dataHouseholds?.data.map((household) =>
              !(household.id in updatedDataDic)
                ? household
                : {
                    ...household,
                    assignedModelId: updatedDataDic[household.id].model_id,
                  },
            ),
          },
        );
      } catch (err) {
        const message = "Failed to assign model to households";
        setError(message);
        console.error(message, err);
      }
    },
  });

  const handleSaveChanges = useCallback(async () => {
    await onSubmit.mutateAsync({
      modelId: selectedModelId,
      households: rowSelection,
    });
    setShowModal(false);
  }, [selectedModelId, rowSelection, onSubmit]);

  return (
    <TabContainerWithTabs tabs={ManageNav}>
      <Row>
        <Col>
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                as={Link}
                to="/clients/households/new"
                label="New"
                icon="/icons/new.svg"
              />
            </ButtonGroup>
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Connect CRM"
                as={Link}
                icon="/icons/compare.svg"
                to="/clients/households/mapping"
              />
            </ButtonGroup>
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Export"
                icon="/icons/folded-list.svg"
                onClick={onExport}
                disabled={isLoadingExport}
              />
            </ButtonGroup>
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Assign Model"
                icon="/icons/assign.svg"
                onClick={handleAssignModelClick}
                disabled={Object.keys(rowSelection).length === 0}
              />
            </ButtonGroup>
            <ButtonGroup>
              <ActionButton
                variant="secondary"
                label="Create Report"
                as={Link}
                icon="/icons/bar-chart.svg"
                to={`/reports/batches/new?type=custom&${Object.keys(
                  rowSelection,
                )
                  .map((householdId) => `household_id=${householdId}`)
                  .join("&")}`}
                disabled={Object.keys(rowSelection).length <= 0}
                className={
                  Object.keys(rowSelection).length <= 0 ? "disabled" : ""
                }
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <EditableHouseholdsTable
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            isError={isErrorHouseholds || isErrorModels || isErrorAssignees}
            isLoading={
              isPendingHouseholds || isPendingModels || isPendingAssignees
            }
            householdsWithModel={households ?? []}
          />
        </Col>
        <ModelAssignDialog
          showModal={showModal}
          handleModalClose={handleModalClose}
          handleSaveChanges={handleSaveChanges}
          rowSelection={rowSelection}
          selectedModelId={selectedModelId}
          setModelId={setModelId}
          modelOptions={modelOptions}
          isSaving={onSubmit.isPending}
          title="households"
        />
      </Row>
    </TabContainerWithTabs>
  );
};

export default Households;
